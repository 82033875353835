import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocsub } from '../../actions/docsubActions';
import { fetchDocentes } from '../../actions/docenteActions';
import { fetchSubdisciplinas } from '../../actions/subdisciplinaActions';
import { fetchUniversidades } from '../../actions/universidadAction';
import { fetchDisciplinas } from '../../actions/disciplinaActions';
import debounce from 'lodash/debounce';
import * as XLSX from 'xlsx';
import { Table, Form, Button } from 'react-bootstrap';
import CustomPrintModal from '../CustomPrintModal';
import { getSubdisciplinaFullName, getPersonaFullName, estadoDocente, getPersonaFullNameAppat } from '../../util/valueCalculator';
import excelIcono from '../../assets/excel-icono.png';

const ReporteDocente = () => {
  const dispatch = useDispatch();

  const docentes = useSelector((state) => state.docente.docentes) || [];
  const docsubs = useSelector((state) => state.docsub.docsubs) || [];
  const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas) || [];
  const universidades = useSelector((state) => state.universidad.universidades) || [];
  const disciplinas = useSelector((state) => state.disciplina.disciplinas) || [];

  useEffect(() => {
    dispatch(fetchDocsub());
    dispatch(fetchDocentes());
    dispatch(fetchSubdisciplinas());
    dispatch(fetchUniversidades());
    dispatch(fetchDisciplinas());
  }, [dispatch]);

  const [filters, setFilters] = useState({ universidad: 'todo', disciplina: 'todo', subdisciplina: 'todo', estadoDocente: 'todo' });
  const [searchTerm, setSearchTerm] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [columnIndex, setColumnIndex] = useState(0);
  const columnOrders = [
    ['N°', 'C.I.', 'Universidad', 'Apellidos y Nombres', 'Subdisciplina', 'Estado'],
    ['N°', 'C.I.', 'Apellidos y Nombres', 'Universidad', 'Subdisciplina', 'Estado'],
  ];

  const columnsOrder = columnOrders[columnIndex];

  const debouncedSearchTerm = useMemo(() => debounce((value) => setSearchTerm(value), 300), []);

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  // Filtrado y agrupamiento de datos
  const filteredGrouped = useMemo(() => {
    if (!docentes.length || !docsubs.length || !subdisciplinas.length || !universidades.length) return [];

    const grouped = docentes.map((docente) => {
      const docSubItems = docsubs.filter((docsub) => docsub.iddoc === docente.id);

      const subdisciplinasDocente = docSubItems.map((docsub) => {
        const subdisciplina = subdisciplinas.find((sub) => sub.id === docsub.idsub);
        return getSubdisciplinaFullName(subdisciplina) || '';
      }).join(';  ');

      const universidad = universidades.find((univ) => univ.id === docente.iduniv);

      return {
        docente,
        subdisciplinasDocente,
        universidad,
        docSubItems
      };
    }).filter((group) => {
      const { docente, universidad } = group;

      const docenteMatch = filters.estadoDocente === "todo" || `${docente.estado}` === filters.estadoDocente;
      const universidadMatch = filters.universidad === 'todo' || universidad?.sigla === filters.universidad;
      const disciplinaMatch = filters.disciplina === 'todo' || group.docSubItems.some((docsub) => subdisciplinas.find((sub) => sub.id === docsub.idsub)?.iddisc === parseInt(filters.disciplina));
      const subdisciplinaMatch = filters.subdisciplina === 'todo' || group.docSubItems.some((docsub) => subdisciplinas.find((sub) => sub.id === docsub.idsub)?.id === parseInt(filters.subdisciplina));

      return (
        docenteMatch &&
        universidadMatch &&
        disciplinaMatch &&
        subdisciplinaMatch &&
        searchTerm.split(' ').every(term =>
          (`${getPersonaFullName(docente)}`.toLowerCase().includes(term.toLowerCase()) ||
           `${docente.ci}`.toLowerCase().includes(term.toLowerCase())
          )
        )
      );
    });

    // Ordenar según la tercera columna
    const thirdColumn = columnsOrder[2];

    grouped.sort((a, b) => {
      let aValue, bValue;
      switch (thirdColumn) {
        case 'Universidad':
          aValue = a.universidad?.sigla || '';
          bValue = b.universidad?.sigla || '';
          if (aValue === bValue) {
            // Ordenar secundariamente por Apellidos y Nombres si las universidades son iguales
            const aFullName = `${a.docente.appat} ${a.docente.apmat} ${a.docente.nombres}`;
            const bFullName = `${b.docente.appat} ${b.docente.apmat} ${b.docente.nombres}`;
            return aFullName.localeCompare(bFullName);
          }
          return aValue.localeCompare(bValue);

        case 'Apellidos y Nombres':
          aValue = `${a.docente.appat} ${a.docente.apmat} ${a.docente.nombres}`;
          bValue = `${b.docente.appat} ${b.docente.apmat} ${b.docente.nombres}`;
          return aValue.localeCompare(bValue);

        case 'Subdisciplina':
          aValue = a.subdisciplinasDocente || '';
          bValue = b.subdisciplinasDocente || '';
          return aValue.localeCompare(bValue);

        case 'Estado':
          aValue = estadoDocente(a.docente.estado).estadoName || '';
          bValue = estadoDocente(b.docente.estado).estadoName || '';
          return aValue.localeCompare(bValue);

        default:
          return 0;
      }
    });

    return grouped;
  }, [docentes, docsubs, subdisciplinas, universidades, filters, searchTerm, columnsOrder]);

  const getPreviewContent = () => {
    const content = {
      head: [columnsOrder],
      body: filteredGrouped.map((item, index) => {
        return columnsOrder.map((column) => {
          if (column === 'Universidad') return item.universidad?.sigla || '';
          if (column === 'Subdisciplina') return item.subdisciplinasDocente || '';
          if (column === 'Apellidos y Nombres') return getPersonaFullNameAppat(item.docente) || '';
          if (column === 'N°') return index + 1;
          if (column === 'C.I.') return item.docente.ci || '';
          if (column === 'Estado') return estadoDocente(item.docente.estado).estadoName || '';
          return '';
        });
      }),
    };
    return content;
  };

  const handleColumnSwap = () => {
    setColumnIndex((prevIndex) => (prevIndex + 1) % columnOrders.length);
  };

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleFilters = (filter, value) => {
    if (filter === "disciplina") {
      setFilters((prev) => ({ ...prev, disciplina: value, subdisciplina: "todo" }));
    } else {
      setFilters((prev) => ({ ...prev, [filter]: value }));
    }
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const filteredDisciplinas = [...disciplinas].sort((a, b) => a.nombre.localeCompare(b.nombre));
  let filteredSubdisciplinas = [...subdisciplinas].sort((a, b) => a.nombre.localeCompare(b.nombre));

  if (filters.disciplina !== "todo") {
    filteredSubdisciplinas = [...filteredSubdisciplinas].filter(sub => `${sub.iddisc}` === filters.disciplina);
  }

  const getTitulos = (tipo) => { 
    let titulo = "XVIII JUEGOS DEPORTIVOS NACIONALES DE DOCENTES UNIVERSITARIOS";
    let titulo1 = "";
    let titulo2 = "";

    if (searchTerm === "") {
      titulo1 = tipo === "pdf" ? "REPORTE DE DOCENTES" : "DOCENTES";
      if (filters.universidad !== "todo") { 
        titulo1 += tipo === "pdf" ? ` DE LA ${filters.universidad}` : ` ${filters.universidad}`;
      }
      if (filters.estadoDocente !== "todo") { 
        titulo1 += ` ${estadoDocente(filters.estadoDocente).estadoName}S`;
      }
      if (filters.subdisciplina !== "todo") {
        titulo1 += tipo === "pdf" ? "" : " POR SUBDISCIPLINA";
        const selectedSubdis = subdisciplinas.find(sub => `${sub.id}` === filters.subdisciplina);
        titulo2 = tipo === "pdf" ? `SUBDISCIPLINA ${getSubdisciplinaFullName(selectedSubdis)}` : `${getSubdisciplinaFullName(selectedSubdis)}`;
      } else if (filters.disciplina !== "todo") {
        const selectedDis = disciplinas.find(dis => `${dis.id}` === filters.disciplina);
        titulo1 += tipo === "pdf" ? "" : " POR DISCIPLINA";
        titulo2 = tipo === "pdf" ? `DISCIPLINA ${selectedDis.nombre}` : `${selectedDis.nombre}`;
      }
    } else {
      const docInicial = filteredGrouped.length > 0 ? filteredGrouped[0].docente.id : null;
      const todosMismosId = filteredGrouped.every(item => item.docente.id === docInicial);

      if (todosMismosId && docInicial) {
        const docente = docentes.find(doc => doc.id === docInicial);
        titulo1 = tipo === "pdf" ? "REPORTE DE DOCENTE" : `INSCRIPCIONES DOCENTE C.I.: ${docente.ci}`;
        titulo2 = tipo === "pdf" ? `INSCRIPCIONES  ${getPersonaFullName(docente)} C.I.: ${docente.ci}` : `${getPersonaFullName(docente)}`;
      } else if (filteredGrouped.length > 0) {
        titulo1 = tipo === "pdf" ? "REPORTE DE DOCENTES" : `DOCENTES INSCRIPCIONES VARIAS`;
        titulo2 = "INSCRIPCIONES VARIAS";
      } else {
        titulo2 = "";
      }
    }

    return { titulo, titulo1, titulo2 };
  }

  const exportToExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet([
      columnsOrder,
      ...filteredGrouped.map((group, index) => {
        return columnsOrder.map((column) => {
          if (column === 'Universidad') return group.universidad?.sigla || '';
          if (column === 'Subdisciplina') return group.subdisciplinasDocente || '';
          if (column === 'Apellidos y Nombres') return getPersonaFullNameAppat(group.docente) || '';
          if (column === 'N°') return index + 1;
          if (column === 'C.I.') return group.docente.ci || '';
          if (column === 'Estado') return estadoDocente(group.docente.estado).estadoName || '';
          return '';
        });
      }),
    ]);

    const { titulo1, titulo2 } = getTitulos("excel");
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${titulo2}`);
    XLSX.writeFile(wb, `${titulo1}.xlsx`);
  };

  let title1, title2;
  if (showPreviewModal) {
    const { titulo1, titulo2 } = getTitulos("pdf");
    title1 = titulo1;
    title2 = titulo2;
  }

  return (
    <div className="container-large">
      <h1>Reporte de Docentes</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="link" onClick={handleColumnSwap} style={{ color: 'black', fontSize: '20px', padding: '0', marginRight: '10px' }}>
          →
        </Button>
        <div>
          <Button variant="dark" size="sm" onClick={handleShowPreview} className='action-button' style={{ padding: "5.5px" }}>
            Previsualizar
          </Button>
          <Button size="sm" variant="outline-success" onDoubleClick={exportToExcel}>
            <img src={excelIcono} alt="excel" className="icon-class" /> Exportar Excel
          </Button>
        </div>
      </div>
      <div className="row align-items-center mb-4" style={{ fontSize: "0.9rem" }}>
        <div className="col-6 col-md-3">
          <Form.Label className="mt-2">Buscar:</Form.Label>
          <Form.Control type="text" size="sm" placeholder="Buscar por docente..." 
                        onChange={(e) => debouncedSearchTerm(e.target.value)} />
        </div>
        <div className="col-6 col-md-2">
          <Form.Label className="mt-2">Universidad:</Form.Label>
          <Form.Control as="select" size="sm" name="universidad" value={filters.universidad} 
                        onChange={(e) => handleFilters("universidad", e.target.value)}>
            <option value="todo">- Todas -</option>
            {universidades.map((universidad) => (
              <option key={universidad.id} value={universidad.sigla}>
                {universidad.sigla}
              </option>
            ))}
          </Form.Control>
        </div>
        <div className="col-6 col-md-3 mt-2">
          <Form.Label>Filtrar por estado:</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={filters.estadoDocente}
            onChange={(e) => handleFilters("estadoDocente", e.target.value)}
            className="mr-2"
          >
            <option value="todo">- Todo -</option>
            {[2, 3].map(estado => (
              <option key={estado} value={estado}>{`${estadoDocente(estado).estadoName}`}</option>
            ))}
          </Form.Control>
        </div>
      </div>
      <div className="table-container" style={{maxHeight: '480px',overflowY: 'scroll' }}>
        <Table bordered hover className="table-bordered" style={{ fontSize: 'small' }}>
          <thead className="sticky-header custom-table-header">
            <tr>
              {columnsOrder.map((column) => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredGrouped.map((group, index) => {
              return (
                <tr key={group.docente.id}>
                  {columnsOrder.map((column) => {
                    if (column === 'Universidad') return <td key={column}>{group.universidad?.sigla || ''}</td>;
                    if (column === 'Subdisciplina') return <td key={column}>{group.subdisciplinasDocente}</td>;
                    if (column === 'Apellidos y Nombres') return <td key={column}>{getPersonaFullNameAppat(group.docente) || ''}</td>;
                    if (column === 'N°') return <td key={column}>{index + 1}</td>;
                    if (column === 'C.I.') return <td key={column}>{group.docente.ci || ''}</td>;
                    if (column === 'Estado') return <td key={column}>{estadoDocente(group.docente.estado).estadoName || ''}</td>;
                    return null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1={title1}
        title2={title2}
        content={printContent}
        copia={false}
        filters={filters}
      />
    </div>
  );
};

export default ReporteDocente;
