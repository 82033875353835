const initialState = {
    usuarios: [],
    usuario: null,
  };
  
  const usuarioReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_USUARIOS':
        return { ...state, usuarios: action.payload };
      case 'GET_USUARIO_BY_ID':
        return { ...state, usuario: action.payload };
      case 'CREATE_USUARIO':
        return { ...state, usuarios: [...state.usuarios, action.payload] };
      case 'UPDATE_USUARIO':
        return {
          ...state,
          usuarios: state.usuarios.map(u =>
            u.id === action.payload.id ? action.payload : u
          ),
        };
      case 'DELETE_USUARIO':
        return {
          ...state,
          usuarios: state.usuarios.filter(u => u.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default usuarioReducer;
