import { useEffect, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { fetchDeportistaAprobadoByCi, fetchDocenteAprobadoByCi } from "../../actions/docenteActions"
import { useDispatch, useSelector } from "react-redux"
import PerfilDocente from "./PerfilDocente"
import { fetchSubdisciplinas } from "../../actions/subdisciplinaActions"
import { approveDocenteSubdisciplinas } from "../../actions/docsubActions"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { docenteActions } from "../../reducers/docente_slice"
import MensajeCentral from "../mensajes/MensajeCentral"
const MySwal = withReactContent(Swal);
const DesaprobarDocente = () =>{
    const [ci, setCi] = useState("")
    const dispatch = useDispatch()
    const deportista = useSelector((state) => state.docente.current);
     useEffect(()=>{
        dispatch(fetchSubdisciplinas())
    },[dispatch])
    const [error, setError] = useState(null)
    const handleSubmitGetDocente = (e) => {
        e.preventDefault()
        dispatch(fetchDeportistaAprobadoByCi(ci))
    }
    const estadoEvento= useSelector(state => state.evento.estado)

    if (estadoEvento !==1 ) {
      return <MensajeCentral>No permitido</MensajeCentral>;
    }
    const handleDesaprobar=()=>{
      if(deportista && deportista.estado === 3){
        const docenteData = {
            iddoc: deportista.id,
            estado: 2
          };
        
        try {
            dispatch(approveDocenteSubdisciplinas(deportista.id, docenteData)).then(()=> {
                dispatch(docenteActions.setCurrentDocente(null))
                MySwal.fire({
                    title: 'Se ha retrocedido la aprobación del deportista.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                      popup: 'swal2-popup',
                      title: 'swal2-title',
                      confirmButton: 'swal2-confirm',
                    },
                  });
                 
            })
          } catch (error) {
            toast.error('Error al retroceder la aprobación.');
          }}
    }
    return (
        <div className="d-flex align-items-center flex-column min-vh-100">
        <h2 className="mt-4">Retroceder aprobación</h2>
        <div className="row w-100 justify-content-center">
          <div className="col-md-5 col-lg-3">
            <Form onSubmit={handleSubmitGetDocente} className="text-center">
              {error && <p className="text-danger">{error}</p>}
              <input
                type="text"
                placeholder="CI del docente"
                value={ci}
                onChange={(e) => setCi(e.target.value)}
                className="form-control mb-3 mt-3"
              />
              <div className="d-flex justify-content-between">
                <Button variant="dark" className="mb-3 me-2" type="submit">
                  Consultar
                </Button>
                <Button variant="danger" disabled={deportista && deportista.estado < 3} className="mb-3" type="button" onClick={handleDesaprobar}>
                  Retroceder aprobación
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="row w-100 justify-content-center">
          <div className="col-md-10">
            {deportista && <PerfilDocente />}
          </div>
        </div>
      </div>
    )
}

export default DesaprobarDocente