import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { fetchSubdisciplinasByDocente, updateDocenteSubdisciplinas, approveDocenteSubdisciplinas } from '../../actions/docsubActions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import placeholder from "../../assets/docentes/docente_default.png";
import { calculateAge, genderMap, getUploadUrl, categoryMap, getModalidadByEdad, getSubdisciplinaFullName } from '../../util/valueCalculator';
import { getSubdisciplinasByUniversidad } from '../../actions/universidadAction';
import { fetchSubdisciplinasByUniversidad } from '../../actions/subdisciplinaActions';

const MySwal = withReactContent(Swal);


const InscripcionDocente = ({ docente, onRequestClose, titulo, funcionalidad }) => {
//  console.log("titulo=", titulo, "funcionalidad=", funcionalidad);
  const dispatch = useDispatch();
  const eventoData = useSelector(state => state.evento);
  const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
  const docsubs = useSelector(state => state.docsub.docsubsByDocente);
  const docsubsUniv = useSelector((state) => state.docsub.docsubsByUniv);
  const subdisciplinasByUniversidad = useSelector(state => state.universidad.subdisciplinasByUniversidad || []);
  const [selectedSubdisciplina, setSelectedSubdisciplina] = useState(null);
  const [inscribedSubdisciplines, setInscribedSubdisciplines] = useState([]);
  const [initialInscribedSubdisciplines, setInitialInscribedSubdisciplines] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [modified, setModified] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
//  console.log("Subdis", subdisciplinas)
  useEffect(() => {
    const loadSubdisciplinas = async () => {
      if (docente) {
        setLoading(true);
        await dispatch(fetchSubdisciplinasByDocente(docente.id));
        await dispatch(fetchSubdisciplinasByUniversidad(docente.iduniv)); // Añade esta línea
        setLoading(false);
      }
    };
    loadSubdisciplinas();
  }, [docente, dispatch]);

  useEffect(() => {
    if (docente && docsubs && subdisciplinas) {
      const inscritas = docsubs.filter(docsub => docsub.iddoc === docente.id);
      const subdisciplinasInscritas = inscritas.map(ins => {
        return subdisciplinas.find(sub => sub.id === ins.idsub);
      }).filter(sub => sub !== undefined);

      setInscribedSubdisciplines(subdisciplinasInscritas);
      setInitialInscribedSubdisciplines(subdisciplinasInscritas);

      const hasApprovedSubdisciplina = docente.estado === 3;
      setIsApproved(hasApprovedSubdisciplina);
    }
  }, [docente, docsubs, subdisciplinas]);

  const handleCheckRequirements = () => {
    if (!selectedSubdisciplina) {
        toast.error('Por favor seleccione una subdisciplina.');
        return;
    }

    const maxSub = eventoData.maxsub;
    const subdisciplina = subdisciplinas.find(sub => sub.id.toString() === selectedSubdisciplina);

    if (!subdisciplina) {
        toast.error('La subdisciplina seleccionada no es válida.');
        return;
    }

    const inscribedDisciplines = [...new Set(inscribedSubdisciplines.map(sub => sub.iddisc))];
    const subdisciplinaDisciplinaId = subdisciplina.iddisc;
    const inscribedInSameDisciplina = inscribedSubdisciplines.filter(sub => sub.iddisc === subdisciplinaDisciplinaId);

    
    if (inscribedSubdisciplines.some(inscribed => inscribed.id === subdisciplina.id)) {
      toast.error('La subdisciplina ya está inscrita.');
      return;
  }

    if (inscribedDisciplines.length >= maxSub && !inscribedInSameDisciplina.length) {
        toast.error(`No puede inscribirse en más de ${maxSub} disciplinas.`);
        return;
    }

    
    const inscritosPorUniversidad = docsubsUniv.filter(ins => ins.idsub === subdisciplina.id && ins.iduniv === docente.iduniv).length;
    
    if (inscritosPorUniversidad >= subdisciplina.maxdep) {
        toast.error(`No hay plazas disponibles en esta subdisciplina para la universidad ${docente.Universidad.sigla}.`);
        return;
    }
    if(subdisciplina.tipo_participacion !== 'Postas'){
      const pruebasSubdisciplinas = inscribedInSameDisciplina.filter(sub => sub.tipo !== 'Postas');
      if (pruebasSubdisciplinas.length >= 3) {
              toast.error('No puede inscribirse en más de 3 subdisciplinas en la misma disciplina (excluyendo Postas).');
              return;
          }
    }
   else{
   // Verificar si ya tiene una subdisciplina de tipo 'Postas' en la misma disciplina
   const tienePosta = inscribedInSameDisciplina.some(sub => sub.tipo_participacion === 'Postas');

   if (subdisciplina.tipo_participacion === 'Postas' && tienePosta) {
     toast.error('No puede inscribirse en más de una subdisciplina de tipo POSTAS por disciplina.');
     return;
   }
   }

    const edad = calculateAge(docente.fecnac, eventoData.fechaedad);
    const edadYears = Number(edad.years);
    const edadMin = Number(subdisciplina.edadmin);
    
    if (edadYears < edadMin) {
        toast.error(`El docente no cumple con la edad mínima requerida para la subdisciplina (Edad mínima: ${subdisciplina.edadmin} años).`);
        return;
    }


    setInscribedSubdisciplines([...inscribedSubdisciplines, subdisciplina]);
    setSelectedSubdisciplina(null);
    setModified(true)
    toast.success(`El docente cumple con los requisitos para la subdisciplina ${subdisciplina.nombre}.`);
};

  const handleSelectChange = (e) => {
    setSelectedSubdisciplina(e.target.value);
  };

  const handleRemoveSubdisciplina = (subdisciplinaId) => {
    setInscribedSubdisciplines(prev => [...prev.filter(sub => sub.id !== subdisciplinaId)]);
    setModified(true)
  };

  const handleSave = () => {
    MySwal.fire({
      title: 'Atención',
      text: '¿Está seguro que desea guardar los cambios?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      customClass: {
        popup: 'swal2-popup',
        title: 'swal2-title',
        cancelButton: 'swal2-cancel',
        confirmButton: 'swal2-confirm',
      },
      position: 'center',
      padding: '1rem',
      background: '#fff',
      timer: null,
      timerProgressBar: false,
    }).then((result) => {
      if (result.isConfirmed) {
        saveDocenteSubdisciplines();
      }
    });
  };

  const saveDocenteSubdisciplines = async () => {
    const currentIds = inscribedSubdisciplines.map(sub => sub.id);
    const initialIds = initialInscribedSubdisciplines.map(sub => sub.id);

    const hasChanges = currentIds.length !== initialIds.length || 
                       currentIds.some(id => !initialIds.includes(id)) || 
                       initialIds.some(id => !currentIds.includes(id));

    if (!hasChanges) {
      toast.info('No se han realizado cambios en las inscripciones.');
      return;
    }

    const docenteData = {
      iddoc: docente.id,
      subdisciplinas: inscribedSubdisciplines.map(sub => ({ id: sub.id })),
      iduniv: docente.iduniv,
      estado:2
    };

    try {
      await dispatch(updateDocenteSubdisciplinas(docenteData));
      MySwal.fire({
        title: 'Inscripción registrada correctamente.',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'swal2-popup',
          title: 'swal2-title',
          confirmButton: 'swal2-confirm',
        },
      });
      setModified(false)
      setInitialInscribedSubdisciplines(inscribedSubdisciplines);
      toast.success('Inscripciones guardadas correctamente.');
    } catch (error) {
      toast.error('Error al registrar la inscripción.');
    }
  };

  const handleApprove = async () => {
    const currentIds = inscribedSubdisciplines.map(sub => sub.id);
    const initialIds = initialInscribedSubdisciplines.map(sub => sub.id);

    const hasChanges = currentIds.length !== initialIds.length || 
                       currentIds.some(id => !initialIds.includes(id)) || 
                       initialIds.some(id => !currentIds.includes(id));
    if (hasChanges) {
      toast.error('Hay cambios no guardados. Primero debe guardar los cambios.');
      return;
    }
    if (currentIds.length === 0) {
      toast.error('El docente no está inscrito en ninguna subdisciplina. No se puede aprobar.');
      return;
    }

    MySwal.fire({
      title: 'Aprobar Inscripciones',
      text: '¿Está seguro que desea aprobar las inscripciones? Una vez aprobado, no podrá realizar modificaciones.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, aprobar',
      cancelButtonText: 'No',
      customClass: {
        popup: 'swal2-popup',
        title: 'swal2-title',
        cancelButton: 'swal2-cancel',
        confirmButton: 'swal2-confirm',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await approveDocente();
      }
    });
  };

  const approveDocente= async () => {
    const docenteData = {
      iddoc: docente.id,
      estado: 3
    };

    try {
      await dispatch(approveDocenteSubdisciplinas(docente.id, docenteData));
      MySwal.fire({
        title: 'Inscripciones aprobadas correctamente.',
        icon: 'success',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'swal2-popup',
          title: 'swal2-title',
          confirmButton: 'swal2-confirm',
        },
      });
      onRequestClose();
    } catch (error) {
      toast.error('Error al aprobar las inscripciones.');
    }
  };

  if (!docente) {
    return null;
  }

  const edad = calculateAge(docente.fecnac, eventoData.fechaedad);
  const formattedDate = new Date(eventoData.fechaedad).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
  const modalidadDocente = getModalidadByEdad(edad.years)
  const fotoUrl = docente.foto1 ? getUploadUrl(docente.foto1) : placeholder;

  const filteredSubdisciplinas = subdisciplinas.filter(sub => 
    (sub.genero === docente.genero) 
    && ((modalidadDocente === "LIBRE" && sub.categoria=== "L")
        || (modalidadDocente === "SENIOR" && (sub.categoria=== "S" || sub.categoria=== "L"))
        || (modalidadDocente === "MASTER" && (sub.categoria=== "M" || sub.categoria=== "S" || sub.categoria=== "L"))
      )
    )
    .sort((a, b) => {
      if (a.nombre < b.nombre) return -1;
      if (a.nombre > b.nombre) return 1;
      if (a.genero < b.genero) return -1;
      if (a.genero > b.genero) return 1;
      if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
      if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
      return 0;
    });
    const selectOptions = filteredSubdisciplinas.map(subdisciplina => ({
      value: subdisciplina.id.toString(),
      label: getSubdisciplinaFullName(subdisciplina)
    }));
    const searchTerms = searchTerm.toLowerCase().split(' ').filter(term => term);
    
    const filteredOptions = filteredSubdisciplinas.filter((subdisciplina) => {
      const fullName = getSubdisciplinaFullName(subdisciplina).toLowerCase();
      return searchTerms.every(term => fullName.includes(term));
    });
  return (
    <div>
      <ToastContainer />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <img src={fotoUrl} alt="Foto del docente" style={{ width: '150px', height: '150px', objectFit: 'cover', marginRight: '20px' }}/>
        <div>
          <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#003366' }}>
            {docente.nombres} {docente.appat} {docente.apmat}
          </div>
          <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#000000' }}>
            C.I.: {docente.ci}
          </div>
          <div>
          <span style={{ fontSize: '16px' }}> Al {formattedDate} tendrá </span>  <strong>{edad.years} años</strong>
          </div>
          <div>
            <p>
              <span style={{ fontSize: '16px' }}>Categoría:</span> {genderMap[docente.genero]}
              <br/>
              <span style={{ fontSize: '16px' }}>Modalidad:</span> {modalidadDocente}
              </p>        
          </div>
        </div>
      </div>

      {isApproved && (
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <p style={{ color: 'green', fontWeight: 'bold' }}>DOCENTE APROBADO</p>
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: '1', marginRight: '10px' }}>
          <h5>Seleccionar Subdisciplina</h5>
          <Form.Control
          type="text"
          placeholder="Filtrar opciones..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginBottom: '10px' }}
        />
        <Form.Control
          as="select"
          value={selectedSubdisciplina || ''}
          onChange={handleSelectChange}
          disabled={isApproved}
        >
          <option value="" disabled>Seleccione una subdisciplina</option>
          {filteredOptions.map(subdisciplina => (
            <option key={subdisciplina.id} value={subdisciplina.id.toString()}>
              {`${subdisciplina.nombre} (${categoryMap[subdisciplina.categoria].nombre} - ${genderMap[subdisciplina.genero]})`}
            </option>
          ))}
        </Form.Control>
        <Button
          onClick={handleCheckRequirements}
          disabled={!selectedSubdisciplina || isApproved}
          style={{ marginTop: '10px' }}
          className="btn btn-warning"
        >
          Inscribir
        </Button>
        </div>
        <div style={{ flex: '1', marginLeft: '10px' }}>
          <h4>Subdisciplinas Inscritas</h4>
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            {inscribedSubdisciplines.map(subdisciplina => (
              <li key={subdisciplina.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Button variant="outline-danger"
                  size="sm" onClick={() => handleRemoveSubdisciplina(subdisciplina.id)} style={{ marginRight: '10px' }} className="action-button" disabled={isApproved}>
                  <i className="bi bi-dash-lg"></i>
                </Button>
                {`${subdisciplina.nombre} (${categoryMap[subdisciplina.categoria].nombre} - ${genderMap[subdisciplina.genero]})`}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button onClick={onRequestClose} variant="secondary" className="btn btn-secondary" style={{ marginRight: '10px' }} disabled={isApproved}>
          Cancelar
        </Button>
        <Button onClick={handleSave} disabled={(inscribedSubdisciplines.length === 0 && initialInscribedSubdisciplines.length === 0) || isApproved ||!modified} variant="primary">
          Grabar
        </Button>
        {funcionalidad === '2' && (
          <Button onClick={handleApprove} variant="success" className="btn btn-success" style={{ marginLeft: '40px', width: '150px', height: '50px' }} disabled={isApproved}>
            Aprobar
          </Button>
        )}
      </div>

      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro que desea guardar los cambios?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>Cancelar</Button>
          <Button variant="primary" onClick={() => {
            saveDocenteSubdisciplines();
            setShowConfirmationModal(false);
          }}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InscripcionDocente;
