import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSubdisciplina,
  modifySubdisciplina,
  fetchSubdisciplinasByDisciplinaId,
  fetchSubdisciplinaById,
} from "../../actions/subdisciplinaActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { useInputFile } from "../../hooks/useInputImage";
import { categoryMap, getUploadUrl } from "../../util/valueCalculator";

//COMPONENTE
//Formulario de subdisciplina
const SubdisciplinaForm = ({
  showModal,
  handleClose,
  disciplina = null,
  currentSubdisciplina,
}) => {

  const [nombre, setNombre] = useState("");
  const [genero, setGenero] = useState("");
  const [categoria, setCategoria] = useState("");
  const [relato, setRelato] = useState("");
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();

  const [edadmin, setEdadmin] = useState(18);
  const [edadmax, setEdadmax] = useState(1);
  const [edadreq, setEdadreq] = useState(0);
  const [nroedadreq, setNroedadreq] = useState(0);
  const [estado, setEstado] = useState("1");
  const [tipo, setTipo] = useState("0");
  const [tipoParticipacion, setTipoParticipacion] = useState("");
  const [cont, setCont] = useState(1);
  const [maxdep, setMaxdep] = useState(1);
  const [error, setError] = useState("");
  const [prefix, setPrefix] = useState("");
  const discId = disciplina ? disciplina.id : currentSubdisciplina.iddisc;
  const estadoEvento= useSelector(state => state.evento.estado)
  useEffect(() => {
    if (currentSubdisciplina) {
      const disciplinaNombre = currentSubdisciplina.nombre.split(' ')[0];
      const subdisciplinaNombre = currentSubdisciplina.nombre.replace(disciplinaNombre, "").trim();
      setPrefix(disciplinaNombre);
      setNombre(subdisciplinaNombre);
      setGenero(currentSubdisciplina.genero);
      setCategoria(currentSubdisciplina.categoria);
      setRelato(currentSubdisciplina.relato);
      setEdadmin(currentSubdisciplina.edadmin);
      setEdadmax(currentSubdisciplina.edadmax);
      setEdadreq(currentSubdisciplina.edadreq);
      setNroedadreq(currentSubdisciplina.nroedadreq ?? 0);
      setEstado(currentSubdisciplina.estado);
      setTipo(currentSubdisciplina.tipo);
      setTipoParticipacion(currentSubdisciplina.tipo_participacion);
      setCont(currentSubdisciplina.cont ? currentSubdisciplina.cont :1);
      setMaxdep(currentSubdisciplina.maxdep ? currentSubdisciplina.maxdep : 1);
    } else if (disciplina) {
      setPrefix(disciplina.nombre);
      setNombre("");
    } else {
      resetForm();
    }
  }, [currentSubdisciplina, disciplina]);

  const resetForm = () => {
    resetFoto1();

    setNombre("");
    setGenero("");
    setCategoria("");
    setRelato("");
    setEdadmin(18);
    setEdadmax(1);
    setEdadreq(1);
    setNroedadreq(1);
    setEstado("1");
    setTipoParticipacion("");
    setTipo("0");
    setCont(1);
    setMaxdep(1);
    setError("");
    setPrefix(disciplina ? disciplina.nombre : "");
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    const missingFields = [];
   // if (!nombre) missingFields.push("Nombre de la Subdisciplina");
    if (!genero) missingFields.push("Género");
    if (!categoria) missingFields.push("Categoría");
    if (!edadmin) missingFields.push("Edad mínima");
    if (!edadmax) missingFields.push("Cantidad mínima");
    if (maxdep <= 0) missingFields.push("Cantidad máxina");

    if (missingFields.length > 0) {
      setError(
        `Por favor complete los siguientes campos: ${missingFields.join(", ")}`
      );
      return;
    }

    const formData = new FormData();
    formData.append("iddisc", discId);
    if(categoria === "L" || categoria==="S" || categoria==="M")
   { formData.append("nombre", `${prefix} ${nombre}`.toUpperCase());
    formData.append("edadmin", categoryMap[categoria].edadMin);
    formData.append("categoria", categoria);
   } 
    formData.append("relato", relato);
    formData.append("foto1", foto1 instanceof File ? foto1 : null);
  
    formData.append("genero", genero);
    formData.append("edadmax", edadmax);
    formData.append("edadreq", edadreq);
    formData.append("nroedadreq", nroedadreq);
    formData.append("estado", estado);
    formData.append("tipo_participacion", tipoParticipacion);
    formData.append("tipo", tipo);
    formData.append("cont", cont);
    formData.append("maxdep", maxdep);

    if (!foto1 && currentSubdisciplina?.foto1)
      formData.append("foto1", currentSubdisciplina.foto1);

    if (currentSubdisciplina) {
      dispatch(modifySubdisciplina(currentSubdisciplina.id, formData)).then(
        () => {
          dispatch(fetchSubdisciplinaById(currentSubdisciplina.id)).then(()=>{
            handleClose();
          })
      }
      );
    } else {
      dispatch(addSubdisciplina(formData)).then(() => {
        handleClose();
        dispatch(fetchSubdisciplinasByDisciplinaId(discId));
      });
    }
  };


  const handleNombreChange = (e) => {
    const inputNombre = e.target.value;
    if (inputNombre.startsWith(prefix)) {
      setNombre(inputNombre.slice(prefix.length).trimStart());
    }
  };
  const handleModalidadChange = (inputModalidad) => {
    setCategoria(inputModalidad);
    setEdadmin(categoryMap[inputModalidad].edadMin)

  };
 const actualFoto1Url = currentSubdisciplina && currentSubdisciplina.foto1 ? getUploadUrl(currentSubdisciplina.foto1) : null;
 
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      onExited={resetForm}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {currentSubdisciplina
            ? `Editar Subdisciplina ${currentSubdisciplina.nombre}`
            : `Nueva Subdisciplina de ${disciplina.nombre}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit}
          className="modal-oval contenedor"
        >
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group
            controlId="formNombreSubdisciplina"
            className="row"
          >
            <div className="col-md-12 modal-input">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre de la Subdisciplina"
                value={`${prefix} ${nombre}`}
                onChange={handleNombreChange}
                required
              />
            </div>
          </Form.Group>
          <Form.Group
            controlId="formRelatoSubdisciplina"
            className="row"
          >
            <div className="col-md-12 modal-input">
              <Form.Label>Relato</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Relato"
                value={relato}
                onChange={(e) => setRelato(e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group
            controlId="formGeneroSubdisciplina"
            className="row"
          >
            <div className="col-md-6 modal-input">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                value={genero}
                onChange={(e) => setGenero(e.target.value)}
                required
              >
                <option value="" disabled>Seleccionar categoría</option>
                <option value="V">VARONES</option>
                <option value="D">DAMAS</option>
              </Form.Control>
            </div>
            <div className="col-md-6 modal-input">
              <Form.Label>Modalidad</Form.Label>
              <Form.Control
                as="select"
                value={categoria}
                onChange={(e) => handleModalidadChange(e.target.value)}
                required
              >
                <option value="" disabled>Seleccionar modalidad</option>
                <option value="L">LIBRE</option>
                <option value="S">SENIOR</option>
                <option value="M">MASTER</option>
              </Form.Control>
            </div>
          </Form.Group>

          <Form.Group className="row">
          
            <div className="col-md-6 modal-input">
              <Form.Label>Tipo de participación</Form.Label>
              <Form.Control
                as="select"
                value={tipoParticipacion}
                onChange={(e) => setTipoParticipacion(e.target.value)}
                required
              >
                <option value="" disabled>Seleccionar tipo</option>
                <option value="Partidos">PARTIDOS</option>
                <option value="Pruebas">PRUEBAS</option>
                <option value="Postas">POSTAS</option>
              </Form.Control>
            </div>
            
          </Form.Group>
          <Form.Group className="row"> 
            <div className="col-md-6 modal-input">
              <Form.Label>Cantidad mínima requerida</Form.Label>
              <Form.Control
                type="number"
                placeholder="cantidad"
                value={edadmax}
                onChange={(e) => setEdadmax(e.target.value)}
                required
              />
            </div>
            <div className="col-md-6 modal-input">
              <Form.Label>Cantidad Máxima permitida </Form.Label>
              <Form.Control
                type="number"
                placeholder="cantidad"
                value={maxdep}
                onChange={(e) => setMaxdep(e.target.value)}
                required
              />
            </div> 
          </Form.Group>
          <Form.Group
            controlId="formEstadoSubdisciplina"
            className="row"
          >
          </Form.Group>

          <Form.Group
            controlId="formFotosSubdisciplina"
            className="row"
          >
            <div className="col-md-6 modal-input">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                isInvalid={!isFoto1Valid}
                onChange={(e) => handleFoto1Change(e.target.files[0])}
              />
                <Form.Control.Feedback type="invalid">
                Por favor selecciona una imagen válida.
              </Form.Control.Feedback>
              {(foto1 && isFoto1Valid) && (
                <img
                  className="img-fluid mb-2 mt-3"
                  style={{ maxHeight: "200px" }}
                  src={URL.createObjectURL(foto1)}
                />
              )}
            </div>
            <div className="col-md-6 modal-input">
                {actualFoto1Url && (
                  <>
                    <Form.Label>Imagen actual</Form.Label>
                    <img
                      className="img-fluid mb-2"
                      style={{ maxHeight: "200px" }}
                      src={actualFoto1Url}
                    />
                  </>
                )}
              </div>
          </Form.Group>
          <Row>
            <Col></Col>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="btn-modal"
            >
              Cancelar
            </Button>
            <Button
              variant={currentSubdisciplina ? "warning" : "info"}
              type="submit"
              className="btn-modal"
            >
              {currentSubdisciplina ? "Actualizar" : "Crear"}
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SubdisciplinaForm;
