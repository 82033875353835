import { useDispatch, useSelector } from "react-redux";
import { fetchUsuarioById } from "../../actions/usuarioActions";
import { getPersonaFullName, getSubdisciplinaFullName, getUploadUrl } from "../../util/valueCalculator";
import { useEffect, useState } from "react";
import placeholder from '../../assets/docentes/docente_default.png';
import { Button, Card, Col, Row } from "react-bootstrap";
import FormPerfilUsuario from "./FormPerfilUsuario";


const PerfilUsuario= () => {
    const dispatch = useDispatch();
    const idUsu = useSelector((state) => state.sesion.id);
    const usuario = useSelector((state) => state.usuario.usuario)
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
      if (idUsu) {
        dispatch(fetchUsuarioById(idUsu));
      }
    }, [idUsu, dispatch]);

   
    if (!usuario) {
      return (<p>Esperando datos del usuario...</p>);
    }
    const handleShowModal = usuario => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };
  const responsabilidadesSorted = [...usuario.responsabilidades].sort((a, b) => {

      return a.Rol.nombre > b.Rol.nombre ? 1 : -1;

  });
const fotoUrl = usuario.persona ? getUploadUrl(usuario.persona.foto1) : placeholder
    const nombreCompleto = usuario.persona ? getPersonaFullName(usuario.persona) : ""
    return (
        <>
      <Card border="1" style={{width: '90%', margin: '0 auto', marginTop: '20px' }}>
        <Card.Body>
        <Row>
            <Col xs={12} md={3}>
              <Card.Img
                variant="top"
                src={fotoUrl}
                style={{ width: "100%", maxWidth:"300px", height: "300px", objectFit: "cover", margin: '0 auto' }}
              />
            </Col>
            <Col xs={12} md={9}>
            <Row>
            <Col xs={12} md={6}>
              <Card.Text>
                <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                    {nombreCompleto}</span> <br/>
                <br/>
                </Card.Text>
                </Col>
                </Row><Row>
                <Col xs={12} md={4}>
              <Card.Text>
                <strong>Usuario: </strong> {usuario.login} <br/>
               </Card.Text>
               <Card.Text>
                <strong>C.I.: </strong> {usuario.persona? usuario.persona.ci:""} <br/>
                <strong>Universidad:  </strong> {usuario.persona? usuario.persona.Universidad.sigla:""} <br/>
              </Card.Text>
              
            </Col>
            <Col xs={12} md={8}>
                <strong>Responsabilidades:</strong>
                <br/>
                {responsabilidadesSorted.length > 0 ? (
                  <ul>
                    {responsabilidadesSorted.map((res) => (
                      <li key={res.id}>
                        {`${res.Rol.nombreDescriptivo} 
                          ${res.Rol.nombre === "enca_universidad" ? usuario.persona.Universidad.sigla :""}
                          ${res.Rol.nombre === "admin_disciplina" ? getSubdisciplinaFullName(res.Subdisciplina):""}
                          `}
                     </li>
                    ))}
                  </ul>
                ) : (
                  "No tiene roles."
                )}
            </Col>
            </Row></Col>
          </Row>
        </Card.Body>
        <Card.Footer>
            <Button onClick={handleShowModal} className="m-3" variant="warning">Cambiar datos de sesión</Button>
        </Card.Footer>
      </Card>
      <FormPerfilUsuario showModal={showModal} handleClose={handleClose}/>
    
      </>
      );
  };
  
  export default PerfilUsuario;
  