import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../assets/cabeceraCud.png';
import { estadoDocente } from '../util/valueCalculator';
let titulo ="XVIII JUEGOS DEPORTIVOS NACIONALES DE DOCENTES UNIVERSITARIOS"
      
const CustomPrintModal = ({ show, handleClose, title1,title2, content, filters=null }) => {
    const [pdfUrl, setPdfUrl] = useState(null);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        const doc = new jsPDF('p', 'pt', 'letter'); // Establecer tamaño de hoja a carta
    
        const marginLeftRight = 55; // Márgenes izquierdo y derecho
        const marginTop =40; // Margen superior
        const marginBottom = 75; // Ajustar el margen inferior para bajar el pie de página
        const headerHeight = 100; // Altura de la cabecera
        const additionalSpace = 20; // Espacio adicional entre el título y la tabla
        const waterText = filters && filters.estadoDocente !== '3' ? (filters.estadoDocente!='' && filters.estadoDocente!='todo'?estadoDocente(filters.estadoDocente).estadoName : "B O R R A D O R" ) : null
        const addHeader = (doc) => {
            doc.setTextColor(0, 0, 0); 
            doc.addImage(cabeceraCud, 'PNG', marginLeftRight, marginTop, doc.internal.pageSize.getWidth() - (marginLeftRight * 2), headerHeight);
            doc.setFontSize(12);
            doc.setFont('times', 'bold'); // Título en negrilla
            doc.text(titulo, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 10, { align: 'center' });
            doc.text(title1, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 25, { align: 'center' });
            if (title2 !== "") {
                doc.text(title2, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 40, { align: 'center' });
            }
        };
    
        const addFooter = (doc, pageNumber) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();
    
            doc.setDrawColor(0, 0, 0); // Color negro para la línea del pie de página
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0); // Color negro para el texto del pie de página
            doc.setFont("times", "normal");
            doc.setFontSize(8);
            doc.text('Oficina La Paz: Calle Hermanos Manchego N° 2559 Edif. CEUB Telf (Fax): (591-2)243-4909  Oficina Tarija: El Tejar - Campus Universitario Edif. Biblioteca Central', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(8);
            doc.text(`Impreso el: ${new Date().toLocaleString()}`, pageWidth - marginLeftRight, pageHeight - marginBottom + 40, { align: 'right' });
            doc.text(`${pageNumber}`, pageWidth / 2, pageHeight - marginBottom + 40, { align: 'center' }); // Número de página centrado en el pie de página
        };
    
        const addWatermark = (doc,texto="B O R R A D O R") => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(25);
            doc.setTextColor(150, 150, 150);
            doc.text(texto, pageWidth / 2, pageHeight / 2.5, { align: 'center', angle: 45 });
        };
        // Inicializa el número de página
        let pageNumber = 1;
    
        if (typeof content === 'string') {
            addHeader(doc);
            doc.text(content, marginLeftRight, marginTop + headerHeight + 40 + additionalSpace);
            addFooter(doc, pageNumber);
        } else {
            autoTable(doc, {
                head: content.head,
                body: content.body,
                startY: marginTop + headerHeight + 40 + additionalSpace,
                margin: { top: marginTop + headerHeight + 40 + additionalSpace, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom }, // Ajustar márgenes para incluir cabecera y pie de página
                didDrawPage: function (data) {
                   
                    if(waterText){
                        addWatermark(doc, waterText)}
                    addHeader(doc);
                    addFooter(doc, pageNumber);
                    pageNumber++;
                },
                pageBreak: 'auto', // Asegurar que el contenido se mueva a una nueva página si es necesario
                styles: { 
                    lineColor: [0, 0, 0], // Color de borde (negro)
                    lineWidth: 0.5, // Grosor del borde más delgado
                    fontSize:9.5
                },
                headStyles: {
                    fillColor: [255, 255, 255], // Fondo blanco para el encabezado
                    textColor: [0, 0, 0], // Texto negro para el encabezado
                    lineWidth: 0.5, // Grosor del borde del encabezado más delgado
                    lineColor: [0, 0, 0], // Color de borde del encabezado
                },
                bodyStyles: {
                    fillColor: [255, 255, 255], // Fondo blanco para el cuerpo
                    textColor: [0, 0, 0], // Texto negro para el cuerpo
                    lineWidth: 0.5, // Grosor del borde del cuerpo más delgado
                    lineColor: [0, 0, 0], // Color de borde del cuerpo
                },
            });
        }
    
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Para quitar la Marca de Agua, debe seleccionar el filtro por estado APROBADO</p>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="LISTADO GENERAL"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModal;

