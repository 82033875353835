// src/selectors.js
import { createSelector } from '@reduxjs/toolkit';

const selectRawRoles = state => state.rol.roles;
const selectRawProcesos = state => state.proceso.procesos;
const selectRawUsuarios = state => state.usuario.usuarios;
const selectRawPaises = state => state.pais.paises || [];
const selectRawPaisesFromCiudad = state => state.pais.paises || [];
const selectRawCiudades = state => state.ciudad.ciudades || [];
const selectRawRolProcesos = state => state.rolproc.rolproc || [];
const selectRawUsuarioRoles = state => state.usuarioRol.usuaroles || []; // Cambiar esta línea para usar el nuevo array
const selectRawDisciplinas = state => state.disciplina.disciplinas || [];

export const selectSubdisciplinasByDisciplinaId = (state, disciplinaId) => {
    return state.subdisciplina.subdisciplinasByDisciplinaId[disciplinaId] || [];
  };

export const selectSubdisciplinas = state => state.subdisciplina.subdisciplinas;

export const selectSubdisciplinaById = state => state.subdisciplina.subdisciplina;

// Selector memoizado para disciplinas
export const selectDisciplinas = createSelector(
  [selectRawDisciplinas],
  disciplinas => disciplinas
);


export const selectUsuarioRoles = (state) => state.usuarioRol.usuariorol;

export const selectRolProcesos = createSelector(
  [selectRawRolProcesos],
  rolproc => rolproc
);

export const selectRoles = createSelector(
  [selectRawRoles],
  roles => roles || []
);

export const selectProcesos = createSelector(
  [selectRawProcesos],
  procesos => procesos || []
);

export const selectUsuarios = createSelector(
  [selectRawUsuarios],
  usuarios => usuarios || [] 
);

// Selector memoizado para paises
export const selectPaises = createSelector(
  [selectRawPaises],
  paises => paises
);

// Selector memoizado para ciudades
export const selectCiudades = createSelector(
  [selectRawCiudades],
  ciudades => ciudades
);

// Selector memoizado para paises desde CiudadList
export const selectPaisesFromCiudad = createSelector(
  [selectRawPaisesFromCiudad],
  paises => paises
);

export const selectUsuariosConRoles = createSelector(
    [selectUsuarios, selectRawUsuarioRoles, selectRoles],
    (usuarios, usuarioRoles, roles) => {
      return usuarios.map(usuario => {
        const rolesUsuario = usuarioRoles.filter(ur => ur.idusu === usuario.id)
                                           .map(ur => roles.find(rol => rol.id === ur.idrol))
                                           .filter(Boolean); // Filtrar roles no encontrados
        return {
          ...usuario,
          roles: rolesUsuario
        };
      });
    }
  );
  
