// src/actions/docenteActions.js

import { docenteActions } from '../reducers/docente_slice';
import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const docentesService = apiService('/docentes');

export const updateIngresoDocente = (id) => async dispatch => {
  try {
    const data = await docentesService.updateIngreso(id);
    toast.success(data.message);
  } catch (error) {
    toast.error(error.message || 'Error al actualizar el ingreso del docente.');
  }
};


export const fetchDocenteByCi = (ci) => async (dispatch) => {
  try { //console.log("En action ci=",ci)
    const data = await docentesService.getAllBySomeId('ci',ci);
    dispatch(docenteActions.setCurrentDocente(data));
    
    return data;
  } catch (error) {
    //console.log("Con error ena action ..",error)
    toast.error('Error al buscar el docente por CI');
  }
};

export const fetchDeportistaAprobadoByCi = (ci) => async (dispatch) => {
  const publicDocenteService = apiService('/public-docente');
  try { //console.log("En action ci=",ci)
    const data = await publicDocenteService.getAllBySomeId('ci',ci);
    dispatch(docenteActions.setCurrentDocente(data.docente));
    return data;
  } catch (error) {
    //console.log("Con error ena action ..",error)
    toast.error('Error al buscar el deportista por CI');
  }
};
export const fetchDocentes = () => async dispatch => {
  try {
    const data = await docentesService.getAll()
    dispatch(docenteActions.setDocentes(data));
    return data;
    
  } catch (error) {
    toast.error(`Error al cargar la lista de docentes`);
  }
};
export const fetchDocentesByUniversidad = (iduniv) => async dispatch => {
  try {
    const data = await docentesService.getAllBySomeId('universidad', iduniv)
    //console.log("en action de docente despues del controlador con respuesta:",data)
    dispatch(docenteActions.setDocentes(data));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de docentes de la universidad`);
  }
};

export const fetchDocenteById = (id) => async dispatch => {
  try {
    const data = await docentesService.getById(id)
    return data;
  } catch (error) {
    toast.error(`Error al cargar el docente seleccionado`);
  }
};

export const addDocente = (docente) => async dispatch => {
  try {
    const data = await docentesService.create(docente);
    toast.success(`Docente creado exitosamente`);
  } catch (error) {
     // Supongamos que el backend envía errores en error.response.data.errors
     if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    } else {
      throw ['Ocurrió un error desconocido.'];
    }
  }
};
export const addDocenteMasivo = (docentesData) => async dispatch => {
  try {
    const data = await docentesService.createByTipo("masivo", docentesData);
    return data;
  } catch (error) {
    // Lanzar el error para que pueda ser capturado en el componente
    throw error.response ? error.response.data : { mensaje: 'Error al crear los docentes' };
  }
}
export const updateDocente = (id, docente) => async dispatch => {
  try {
    const data = await docentesService.update(id, docente);
    toast.success(`Docente editado exitosamente`);
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      throw error.response.data.errors;
    } else {
      throw ['Ocurrió un error desconocido.'];
    }
}};

export const removeDocente= (id) => async dispatch => {
  try {
    const data = await docentesService.remove(id);
    toast.success(`Docente eliminado exitosamente`);
  } catch (error) {
    toast.error(`Error al eliminar el docente seleccionado`);
  }
};
