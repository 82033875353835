// src/services/disciplinaService.js

import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/disciplinas');

export const getDisciplinas = async () => {
  const response = await api.get('/');
  return response.data;
};

export const getDisciplinaById = async (id) => {
  const response = await api.get(`/${id}`);
  return response.data;
};

export const createDisciplina = async (formData) => {
  const response = await api.post('/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateDisciplina = async (id, formData) => {
  const response = await api.put(`/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
return response.data;
};

export const deleteDisciplina = async (id) => {
  const response = await api.delete(`/${id}`);
  return response.data;
};
