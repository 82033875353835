import { Button, Modal } from "react-bootstrap";

const DeleteModal = ({ show, handleClose, elemento, children}) => {

    return (
        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>Estás seguro que deseas eliminar {elemento}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
         {children}
        </Modal.Footer>
      </Modal>
    )

}

export default DeleteModal;