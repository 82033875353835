// src/actions/fixturedetActions.js

import { fixturedetActions } from '../reducers/fixturedet_slice';
import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const fixturedetService = apiService('/fixturedets');

export const fetchFixturedets = () => async dispatch => {
  try {
    const data = await fixturedetService.getAll();
    dispatch(fixturedetActions.setFixturedets(data));
  } catch (error) {
    toast.error('Error al cargar la lista de detalles de fixtures');
  }
};

export const fetchFixturedetsByFixtureId = (idfix) => async dispatch => {
  try {
    const data = await fixturedetService.getAllBySomeId('fixture', idfix);
   //console.log("data=",data)
    dispatch(fixturedetActions.setFixturedets(data));
  } catch (error) {
    toast.error('Error al cargar la lista de detalles de fixtures por ID de fixture');
  }
};

export const addFixturedet = (fixturedet) => async dispatch => {
//  console.log("Action create, data=",fixturedet)
  try {
    const data = await fixturedetService.create(fixturedet);
    toast.success('Detalle de fixture creado exitosamente');
  } catch (error) {
    toast.error('Error al crear el detalle de fixture');
  }
};

export const updateFixturedet = (id, fixturedet) => async dispatch => {
//  console.log("en action de fixturedet id-",id," fixture=",fixturedet)
  try {
    const data = await fixturedetService.update(id, fixturedet);
    toast.success('Detalle de fixture actualizado exitosamente');
  } catch (error) {
    toast.error('Error al actualizar el detalle de fixture');
  }
};

export const removeFixturedet = (id) => async dispatch => {
  try {
    await fixturedetService.remove(id);
    toast.success('Detalle de fixture eliminado exitosamente');
  } catch (error) {
    toast.error('Error al eliminar el detalle de fixture');
  }
};

// Nueva función para obtener un detalle del fixture por ID
export const getFixturedetById = (id) => async dispatch => {
  try {
    const data = await fixturedetService.getById(id);
    dispatch(fixturedetActions.setFixturedet(data));
  } catch (error) {
    toast.error('Error al cargar el detalle del fixture');
  }
};
