// components/Footer.jsx
import React from "react";

function Footer() {
  return (
    <footer >
     
       <div>
          <p>© 2024 CUD - Confederación Universitaria de Docentes.</p>
        
      </div>

      </footer>
  );
}

export default Footer;