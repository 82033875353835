import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Modal, Stack } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import defaultImage from "../../assets/noticias/default.png";
import { UPLOADS_BASE_URL } from "../../util/axiosInstance";
import { getAuthToken } from "../../util/auth";
import '../../styles/Noticias.css'
import { categoryMap, genderMap, getUploadUrl } from "../../util/valueCalculator";
const token = getAuthToken();
const monthNames = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function formatear(fecha) {
  if (fecha) {
    const [year, month, day] = fecha.split("T")[0].split("-");
    const date = new Date(year, month - 1, day);
     const monthName = monthNames[parseInt(month) - 1];
    return { day, month: monthName, year };
  } else {
    return { day: "", month: "", year: "" };
  }
}
const CardNoticia = ({ noticia, showModal }) => {
  const [foto1Preview, setFoto1Preview] = useState(defaultImage);
  const [foto2Preview, setFoto2Preview] = useState(defaultImage);
  const [showImageModal, setShowImageModal] = useState(false);

  useEffect(() => {
    if (noticia) {
      if (noticia.foto1) {
        setFoto1Preview(getUploadUrl(noticia.foto1));
     } 
     if (noticia.foto2) {
         setFoto2Preview(getUploadUrl(noticia.foto2));
     }
    }
  }, [noticia]);

  const handleImage1Error = () => {
    setFoto1Preview(defaultImage);
  };
  const handleImage2Error = () => {
    setFoto2Preview(defaultImage);
  };

  const handleCarouselClick = () => {
    setShowImageModal(true);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };
  
  const subdisNombre = noticia.tipo === "subdisciplina" && noticia.Subdisciplina ? noticia.Subdisciplina.nombre : null;
  const genero = noticia.tipo === "subdisciplina" && noticia.Subdisciplina ? genderMap[noticia.Subdisciplina.genero] : null;
  const categoria = noticia.tipo === "subdisciplina" && noticia.Subdisciplina ? categoryMap[noticia.Subdisciplina.categoria].nombre : null;

  const { day, month, year } = formatear(noticia.fecha);

  return (
    <>
      <Card className="my-3 rounded card-noticia">
        <div className="row">
          <div className="col-12 col-md-4 carousel-container" onClick={handleCarouselClick}>
            <Carousel>
              {noticia.foto1 && (
                <Carousel.Item>
                  <img
                    className="d-block w-100 fixed-size-img"
                    src={foto1Preview}
                    onError={handleImage1Error}
                    alt="First slide"
                  />
                </Carousel.Item>
              )}
              {noticia.foto2 && (
                <Carousel.Item>
                  <img
                    className="d-block w-100 fixed-size-img"
                    src={foto2Preview}
                    onError={handleImage2Error}
                    alt="Second slide"
                  />
                </Carousel.Item>
              )}
            </Carousel>
          </div>
          <div className="col-12 col-md-8 d-flex flex-column" style={{backgroundColor:"#ececec"}}>
            <Card.Body>
              <h5 className="noticia-title">{noticia.titulo}</h5>
              <Stack direction="horizontal" gap={2}>
                {noticia.tipo === "general" && <Badge bg="dark">GENERAL</Badge>}
                {subdisNombre && <Badge bg="secondary">{subdisNombre}</Badge>}
                {genero && <Badge bg={genero === "VARONES" ? "primary" : "danger"}>{genero}</Badge>}
                {categoria && <Badge bg="warning">{categoria}</Badge>}
              </Stack>
              <p className="noticia-date">{`${day} de ${month} de ${year}`}</p>
              <Card.Text>{noticia.relato}</Card.Text>
            </Card.Body>
          </div>
        </div>
      </Card>

      <Modal show={showImageModal} onHide={handleCloseImageModal} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{noticia.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>
            {noticia.foto1 && (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={foto1Preview}
                  alt="Imagen 1"
                />
              </Carousel.Item>
            )}
            {noticia.foto2 && (
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={foto2Preview}
                  alt="Second slide"
                />
              </Carousel.Item>
            )}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CardNoticia;
