const MensajeCentral = ({accionBloqueada, accionDesbloqueadora, children = null, image=null}) => {
    let mensaje =""

    if(accionBloqueada === "inscripcionDocente") 
    {mensaje = (<>La <span className="highlight">inscripción de docentes </span></>)}
            
    if(accionBloqueada === "aprobacionDeportista") 
    {mensaje = (<>La <span className="highlight">aprobación de deportistas </span></>)}
              
    if(accionBloqueada === "fixtures") 
    {mensaje = (<>La <span className="highlight">gestión del fixture </span> </> )}

    if(accionBloqueada === "noticias") 
    {mensaje = (<>La <span className="highlight">gestión de noticias </span></>)}

    if(accionBloqueada === "medalleros") 
        {mensaje = (<>La <span className="highlight">gestión de medalleros </span></>)}

    if(accionBloqueada === "backup") 
        {mensaje = (<>La <span className="highlight">generación del backup </span></>)}

    if(accionDesbloqueadora === "preinscripcionUniversidades") 
    {mensaje = (
        <>
        {mensaje} se habilitará cuando termine la
        <span className="highlight">
          {" "}
          pre-inscripción de universidades a las subdisciplinas
        </span>
      </>
    )}

    if(accionDesbloqueadora === "desarrollo") 
        {mensaje = (
            <>
            {mensaje} se habilitará cuando comience la 
            <span className="highlight">
              {" "}
              fase de desarrollo del evento
            </span>
          </>
        )}
    if(accionDesbloqueadora === "cierre") 
            {mensaje = (
                <>
                {mensaje} se habilitará cuando comience la 
                <span className="highlight">
                  {" "}
                  fase de cierre del evento
                </span>
              </>
            )}
    return (
        <div className="centered-message">
        <h1 className="message-text">
                {children? children : mensaje}
        </h1>
        {image? image:""}
      </div>
    )
}

export default MensajeCentral