import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Importa componentes de react-bootstrap
import { FaExclamationTriangle } from 'react-icons/fa'; // Importa el ícono de advertencia
import { useNavigate } from 'react-router-dom';

const DownloadAppModal = () => {
  const [showModal, setShowModal] = useState(true); // El modal se mostrará automáticamente al cargar el componente
  const navigate = useNavigate()
  // URL de descarga de la aplicación en Google Drive
  const downloadUrl = 'https://drive.google.com/file/d/1zR6JftquASUi_SSsJikMrHAfIpIZL4Yg/view?usp=sharing';

  // Función para cerrar el modal
  const handleCloseModal = () => {
    navigate(-1)
  };

  return (
    <div className="d-flex align-items-center flex-column">
     <h1>Descargar Aplicación Móvil</h1>
       
          <p style={{ fontSize: '1.2em', textAlign: 'center' }}>
             Haga Click en el botón para ser redirigido a descargar la aplicación cuddeportes.
          </p>
          <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleCloseModal} style={{margin:"5px"}}>
          <i className="bi bi-arrow-left"></i>  Volver
          </Button>
          {/* Enlace que redirige al usuario a la URL de Google Drive */}
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer" className="btn btn-danger" style={{margin:"5px"}}>
            Ir a la descarga
          </a>
          </div>
    </div>
  );
};

export default DownloadAppModal;
