import { UPLOADS_BASE_URL } from "./axiosInstance";

export const calculateAge = (birthdate, referenceDate) => {
    const birth = new Date(birthdate);
    const reference = new Date(referenceDate);

    let years = reference.getFullYear() - birth.getFullYear();
    let months = reference.getMonth() - birth.getMonth();
    let days = reference.getDate() - birth.getDate();

    if (days < 0) {
      months -= 1;
      days += new Date(reference.getFullYear(), reference.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return { years, months, days };
};


export const categoryMap = {
    'S': {orden:2, nombre:'SENIOR', edadMin: 46},
    'M': {orden:3, nombre:'MASTER', edadMin: 57},
    'L': {orden:1, nombre:'LIBRE', edadMin:18}
  };
export const genderMap = {
    'D': 'DAMAS',
    'V': 'VARONES'
  };

  export const getSubdisciplinaFullName = (subdis) =>{
    if(subdis && typeof subdis === 'object'){
    const genero = !subdis.modalidad && subdis.genero && genderMap[subdis.genero[0]]? genderMap[subdis.genero[0]] 
                                : (subdis.modalidad && subdis.categoria && genderMap[subdis.categoria[0]]? genderMap[subdis.categoria[0]]:"");
    const categoria =  !subdis.modalidad && subdis.categoria && categoryMap[subdis.categoria[0]]? categoryMap[subdis.categoria[0]].nombre 
                                 :(subdis.modalidad && categoryMap[subdis.modalidad[0]]? categoryMap[subdis.modalidad[0]].nombre:"");
                           
                                 return `${subdis.nombre} ${genero} ${categoria} `;
  }else{return "Sin definir"}
  }
  export const getSubdisciplinaShortName = (subdis) =>{
    if(subdis && typeof subdis === 'object'){
    const genero = !subdis.modalidad && subdis.genero? subdis.genero[0] 
                                : (subdis.modalidad && subdis.categoria? subdis.categoria[0]:"");
    const categoria =  !subdis.modalidad && subdis.categoria? subdis.categoria[0] 
                                 :(subdis.modalidad? subdis.modalidad[0]:"");
    
    const shortName = subdis.nombre.length > 26 ? subdis.nombre.slice(0, 26) : subdis.nombre;
                      
    return `${shortName} ${genero}-${categoria} `;
  }else{return "Sin definir"}
  }
  
  export const getPersonaFullName = (persona) =>{
    if(persona && typeof persona === 'object'){
     return `${persona.nombres} ${persona.appat} ${persona.apmat} `;
   }
    return ""
  }
    
  export const getPersonaFullNameAppat = (persona) =>{
    if(persona && typeof persona === 'object'){
      return `${persona.appat} ${persona.apmat} ${persona.nombres}`;
    }
    return ""
 
 }
 export const excelSheetName = (nombre) => {
  if(nombre)
   { const shortName = nombre.length > 26 ? nombre.slice(0, 26) : nombre;                
    return shortName;}
    return ""
 }
  export const getUploadUrl= (image) => {
    if(image){
    const fileName = image.split('uploads\\')[1] || image;
    return `${UPLOADS_BASE_URL}/${fileName}`}

    return null

  }
  export const formatearFecha = (fecha) => {
    if (fecha) return fecha.split("T")[0];
    else return "";
}
export const formatearFechaToExport = (fecha) => {
  if (fecha) {
    // Crear la fecha usando la zona horaria de Bolivia (GMT-4)
    let utcDate = new Date(new Date(fecha).toLocaleString("en-US", { timeZone: "America/La_Paz" }));
    // Ajustar la fecha manualmente para compensar cualquier discrepancia
    utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset());
    // Extraer el día, mes y año
    const day = utcDate.getDate().toString().padStart(2, '0');
    const month = (utcDate.getMonth() + 1).toString().padStart(2, '0');
    const year = utcDate.getFullYear();

    // Formatear la fecha en el formato deseado: día/mes/año
    return `${day}/${month}/${year}`;
  } else {
    return "";
  }
};
export function formatShowFecha(inputDate) {
    // Crear la fecha usando la zona horaria de Bolivia (GMT-4)
    let date = new Date(new Date(inputDate).toLocaleString("en-US", { timeZone: "America/La_Paz" }));
  
    // Ajustar la fecha manualmente para compensar cualquier discrepancia
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    const months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];

    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day} de ${months[month]} de ${year}`;
    
    return formattedDate;
}
  export const getModalidadByEdad= (edadRecibida) => {
   const edad = Number(edadRecibida)
    if(edad >= categoryMap['M'].edadMin) return categoryMap['M'].nombre;
    if(edad >= categoryMap['S'].edadMin) return categoryMap['S'].nombre;
    return categoryMap['L'].nombre;
  }
  export const getBackgroundColor = (modalidad) => {
    if (modalidad === "LIBRE") {
      return '#086d39';
    } else if (modalidad === "SENIOR") {
      return '#b1a108';
    } else {
      return '#a90b00';
    }
  };
 export const fasesEvento = (estado) => {
    if (estado === 0) return "PREINSCRIPCIÓN DE UNIVERSIDADES";
    if (estado === 1) return "INSCRIPCIONES DEPORTISTAS";
    if (estado === 2) return "DESARROLLO";
    if (estado === 3) return "CIERRE";
    return "SIN ESPECIFICAR";
  }

  export const estadoDisciplina = (estadoRecibido) => {
    const estado = Number(estadoRecibido)
    if (estado === 0) return "Inactivo";
    if (estado === 1) return "Activo";
    return "Sin especificar";
  }
  export const estadoDocente = (estadoRecibido) => {
    const estado = Number(estadoRecibido)
    if (estado === 0) return {estadoName: "INACTIVO",color: "secondary"};
    if (estado === 1) return {estadoName: "SIN INSCRIPCIONES",color: "dark"};
    if (estado === 2) return {estadoName: "PRE INSCRITO",color: "warning"};
    if (estado === 3) return {estadoName: "APROBADO",color: "success"};
    return {estadoName: "SIN ESPECIFICAR",color: "light"};;
  }
  export const tipoPredio = (tipoRecibido) => {
    const tipo = Number(tipoRecibido)
    if (tipo === 1) return "AIRE LIBRE";
    if (tipo === 2) return "BAJO TECHO";
    return "SIN ESPECIFICAR";
  }
  
 
  export const estadoTecnico = (estado) => {
    if (estado > 0) return "Activo";
    return "Inactivo";
  }
  export const tipo2Persona = (tipo, plural=false) => {
    if (tipo === "tecnico") return "CUERPO TÉCNICO";
    if (tipo === "personal") return "PERSONAL DE APOYO";
    if (tipo === "invitado") return !plural? "INVITADO" : "INVITADOS";
    if (tipo === "comite") return "COMITÉ ORGANIZADOR";
    if (tipo === "docente") return  !plural? "DEPORTISTA" : "DEPORTISTAS";
    return "";
  }
  export const normalizeString = (str) => {
    return str
      .normalize("NFD") // Descompone los caracteres con tilde en su forma básica
      .replace(/[\u0300-\u036f]/g, "") // Elimina los caracteres diacríticos (tildes)
      .replace(/\s+/g, "") // Elimina los espacios
      .toUpperCase(); // Convierte a mayúsculas para hacer la comparación insensible a mayúsculas/minúsculas
  };
  export const generoPorCategoria = (genero) =>{
    if(genero.toLowerCase() === "femenino" || genero.toLowerCase() === "damas")
        return "D"
    if(genero.toLowerCase() === "masculino" || genero.toLowerCase() === "varones")
        return "V"
    return null
}
export const categoriaPorGenero = (cat, genero=true) =>{
  if(cat.toLowerCase() === "d")
      return genero? "FEMENINO" : "DAMAS"
  if(cat.toLowerCase() === "v")
       return genero? "MASCULINO" : "VARONES"
  return null
}