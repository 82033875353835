import { createSlice } from "@reduxjs/toolkit";
const initialState ={
  predios:[],
  current: null
}
const predioSlice= createSlice({
  name: "predio",
  initialState: initialState,
  reducers: { 
    setPredios(state, action) {
    return { ...state, predios: action.payload };  
    },

    setCurrentPredio(state, action) {
      return { ...state, current: action.payload }; 
    }
  }
});

export const predioActions = predioSlice.actions;
export default predioSlice.reducer;
