import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ActionsContainer = ({onPrint = null, onBack=null}) => {
    const navigate = useNavigate();
    return ( <div className='container-actions'>
    {onBack &&       
    <Button variant="light" onClick={onBack? onBack : () => navigate(-1)}>
    <i className="bi bi-arrow-left"></i>  Volver
    </Button>}
    {onPrint && (
      <Button variant="dark" onClick={onPrint} className="mr-2">
      <i className="bi bi-printer"></i> Imprimir
      </Button>
    )}
  </div>)
}
export default ActionsContainer;