import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../styles/Medalleros.css";
import { fetchDisciplinas } from "../../actions/disciplinaActions";
import { fetchSubdisciplinas, fetchSubdisciplinasByDisciplinaId, fetchSubdisciplinasByUsuarioId } from "../../actions/subdisciplinaActions";
import { fetchMedallero, fetchMedalleros, fetchMedallerosByDisciplina, fetchMedallerosByUsuarioId } from "../../actions/medalleroActions";
import { Button, Form, Modal } from "react-bootstrap";
import ItemMedallero from "./ItemMedalleroDesarrollo";
import { medalleroActions } from "../../reducers/medallero_slice";
import FormMedallero from "./FormMedallero";
import MensajeCentral from "../mensajes/MensajeCentral";
import * as XLSX from "xlsx";
import { categoryMap, genderMap } from "../../util/valueCalculator";
import CustomPrintModal from "../CustomPrintModal";
import { sortSubdisciplinas } from "../../util/functions";
const Medalleros = () => {
  const dispatch = useDispatch();
  const [subdisciplinaId, setSubdisciplinaId] = useState("");
  const medalleros = useSelector((state) => state.medallero.medalleros);
  const [modal, setModal] = useState({ tipo: 0, titulo: "", body: <></> });
  const { rolActivo, id } = useSelector((state) => state.sesion);
  const [subdisciplinas, setSubdisciplinas] = useState([])
  const [sortOrder, setSortOrder] = useState('asc');
  const estadoEvento= useSelector(state => state.evento.estado)
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    genero: "",
    categoria: "",
  });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
    if (rolActivo === "admin_disciplina") {
      dispatch(fetchMedallerosByUsuarioId(id, "admin_disciplina"));
    } else if (rolActivo === "admin_evento") {
      dispatch(fetchMedallerosByUsuarioId(id, "admin_evento"));
    }
  }, [rolActivo, dispatch]);

  useEffect(() => {
      const subdisList = medalleros.map(med => med.Subdisciplina)
      setSubdisciplinas(subdisList)
    }, [medalleros]);

    const filteredMedalleros = medalleros
      .filter(
        (medallero) =>
          medallero.Subdisciplina.nombre.toLowerCase().includes(searchTerm.toLowerCase()) &&
          medallero.Subdisciplina.genero.includes(filters.genero) &&
          medallero.Subdisciplina.categoria.includes(filters.categoria) &&
          medallero.Subdisciplina.estado===1
      )
      .sort((a, b) => sortSubdisciplinas(a.Subdisciplina, b.Subdisciplina, categoryMap));
  

      // if (estadoEvento < 2) {
      //   return (
      //     <MensajeCentral accionBloqueada="medalleros" accionDesbloqueadora="desarrollo"/>
      //   );
      // }  //Show modal
  const handleShowModal = (tipo, medallero) => {
    dispatch(medalleroActions.setCurrentMedallero(medallero));
    let titulo = "";
    let body = <></>;
    if (tipo === 2) {
      titulo = "Editar Medallero";
      body = <FormMedallero onHandleClose={handleClose} />;
    }
    setModal({ tipo, titulo, body });
  };

  //Cerrar modal
  const handleClose = () => {
    dispatch(medalleroActions.setCurrentMedallero(null));
    setModal({ tipo: 0, titulo: "", body: <></> });
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilters = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };
  const sortedList = [...subdisciplinas].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.nombre> b.nombre ? 1 : -1;
    }
    return a.nombre < b.nombre ? -1 : 1;
  });

  const getContentForPrintAndExport = () => {
    const content = {
      head: [['N°', 'SUBDISCIPLINA', 'CATEGORÍA', 'MODALIDAD', 'ORO', 'PLATA', 'BRONCE']],
      body: filteredMedalleros.map((medallero, index) => [
        index + 1,
        medallero.Subdisciplina.nombre,
        genderMap[medallero.Subdisciplina.genero], // Asignar categoría a género
        categoryMap[medallero.Subdisciplina.categoria].nombre, // Asignar modalidad a categoría
        medallero.OroUniversidad ? medallero.OroUniversidad.sigla : 'Sin definir',
        medallero.PlataUniversidad ? medallero.PlataUniversidad.sigla : 'Sin definir',
        medallero.BronceUniversidad ? medallero.BronceUniversidad.sigla : 'Sin definir',
      ]),
    };
    return content;
  };
  const handleShowPreview = () => {
    setPrintContent(getContentForPrintAndExport());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleExportExcel = () => {
    const data = filteredMedalleros.map((medallero, index) => ({
      Nro: index + 1,
      Subdisciplina: medallero.Subdisciplina.nombre,
      Categoria: genderMap[medallero.Subdisciplina.genero], // Asignar categoría a género
      Modalidad: categoryMap[medallero.Subdisciplina.categoria].nombre, // Asignar modalidad a categoría
      Oro: medallero.OroUniversidad ? medallero.OroUniversidad.sigla : 'Sin definir',
      Plata: medallero.PlataUniversidad ? medallero.PlataUniversidad.sigla : 'Sin definir',
      Bronce: medallero.BronceUniversidad ? medallero.BronceUniversidad.sigla : 'Sin definir',
    }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filters.genero===""? "Todos los medalleros": (filters.genero === "D"?"CATEGORÍA DAMAS": "CATEGORÍA VARONES"));
    XLSX.writeFile(workbook, 'medalleros_por_subdisciplinas.xlsx');
  };


  return (
    <>
      <div className="medalleros-page container mt-2">
        <h1>Medalleros</h1>
        <Form>
          <Form.Group className="row">
            <div className="col-6 col-md-4 modal-input">
              <Form.Label>Buscar</Form.Label>
              <Form.Control
                type="text"
                placeholder="Buscar por subdisciplina"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="col-6 col-md-2 modal-input">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                value={filters.genero}
                onChange={(e) => handleFilters("genero", e.target.value)}
                required
              >
                <option value="">- Todas -</option>
                <option value="V">VARONES</option>
                <option value="D">DAMAS</option>
              </Form.Control>
            </div>
            <div className="col-6 col-md-3 modal-input">
              <Form.Label>Modalidad</Form.Label>
              <Form.Control
                as="select"
                value={filters.categoria}
                onChange={(e) => handleFilters("categoria", e.target.value)}
                required
              >
                <option value="">- Todas -</option>
                <option value="L">LIBRE</option>
                <option value="S">SENIOR</option>
                <option value="M">MASTER</option>
              </Form.Control>
            </div>
            <div className="col-lg-6 modal-input">
          <Button variant="dark" onClick={handleShowPreview} className="mr-2">
            Imprimir PDF
          </Button>
          <Button variant="success" onDoubleClick={handleExportExcel}>
            Exportar Excel
          </Button>
        </div>
          </Form.Group>
        </Form>
        <div className="medalleros-list">
          {filteredMedalleros.map((medallero) => (
            <ItemMedallero
              key={medallero.Subdisciplina.id}
              medallero={medallero}
              showModal={handleShowModal}
            />
          ))}
        </div>
      </div>
      <Modal
        show={modal.tipo > 0}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.body}</Modal.Body>
      </Modal>
      <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1={`MEDALLEROS POR SUBDISCIPLINAS ${filters.genero===""? "": (filters.genero === "D"?"CATEGORÍA DAMAS": "CATEGORÍA VARONES")}`}
          title2={estadoEvento < 3 ? "B O R R A D O R" : "A P R O B A D A"}
          content={printContent}
        />
    </>
  );
};

export default Medalleros;
