const getGeneroText = (genero) => {
    return genero === "D" ? "DAMAS" : "VARONES";
  };

  const calculateAge = (birthdate, referenceDate) => {
    const birth = new Date(birthdate);
    const reference = new Date(referenceDate);
  
    let years = reference.getFullYear() - birth.getFullYear();
    let months = reference.getMonth() - birth.getMonth();
    let days = reference.getDate() - birth.getDate();
  
    if (days < 0) {
      months -= 1;
      days += new Date(reference.getFullYear(), reference.getMonth(), 0).getDate();
    }
  
    if (months < 0) {
      years -= 1;
      months += 12;
    }
  
    return { years, months, days };
  };
  const getCategoriaText = (categoria) => {
    switch (categoria) {
      case "S":
        return "SENIOR";
      case "M":
        return "MASTER";
      case "L":
        return "LIBRE";
      default:
        return "";
    }
  };
  
  // Exportar las funciones
  export { getGeneroText, getCategoriaText, calculateAge };
  