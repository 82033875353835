// src/actions/noticiaActions.js

import { noticiaActions } from '../reducers/noticia_slice';
import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const noticiasService = apiService('/noticias');

export const fetchNoticias = () => async dispatch => {
  try {
    const data = await noticiasService.getAll()
    dispatch(noticiaActions.setNoticias(data));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de noticias.`);
  }
};
export const fetchNoticiasByTipo = (tipo, id) => async dispatch => {
  try {
    const data = await noticiasService.getAllBySomeId(tipo, id)
    //console.log("en action de noticia despues del controlador con respuesta:",data)
    dispatch(noticiaActions.setNoticias(data));
  } catch (error) {
    toast.error(`Error al cargar la lista de noticias.`);
  }
};
export const fetchNoticiasByDisciplina = (iddisc) => async dispatch => {
  try {
    const data = await noticiasService.getAllBySomeId('disciplina', iddisc)
    //console.log("en action de noticia despues del controlador con respuesta:",data)
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de noticias de la disciplina`);
  }
};

export const fetchNoticiasBySubdisciplina = (idsubdis) => async dispatch => {
  try {
    const data = await noticiasService.getAllBySomeId('subdisciplina', idsubdis)
    //console.log("en action de noticia despues del controlador con respuesta:",data)
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de noticias de la disciplina`);
  }
};
export const addNoticia = (noticia) => async dispatch => {
  try {
    const data = await noticiasService.create(noticia);
    toast.success(`Noticia creada exitosamente.`);
  } catch (error) {
    const theError = error.response.data.error;
   //console.log("Mensaje", theError.data)
   //console.log("Status", theError.status)

    toast.error(`Error al crear la nueva noticia`,theError);
  }
};

export const updateNoticia = (id, noticia) => async dispatch => {
  try {
    const data = await noticiasService.update(id, noticia);
    toast.success(`Noticia editada exitosamente`);
  } catch (error) {
    toast.error(`Error al editar la noticia seleccionada.`);
  }
};

export const removeNoticia= (id) => async dispatch => {
  try {
    const data = await noticiasService.remove(id);
    toast.success(`Noticia eliminada exitosamente`);
  } catch (error) {
    toast.error(`Error al eliminar la noticia seleccionada.`);
  }
};
