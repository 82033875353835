import "../styles/Inicio.css";
import portada from "../assets/portadacortada.jpeg";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchEvento } from "../actions/eventoAction";

const PaginaInicio = ({children = null}) => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchEvento(2024))
  },[dispatch])

  return (
    <>
      <div className="inicio-container">
        <img
          src={portada}
          alt="Imagen 1"
          className="inicio-image"
        />
        <div className="overlay"></div>
        {children}
      </div>
      
    </>
  );
};
export default PaginaInicio;
