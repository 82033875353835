import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProcesos } from '../../actions/procesoActions';
import { fetchRolProcesos, removeRolProcesos, addRolProcesos } from '../../actions/rolprocActions';
import { selectRolProcesos } from '../../selectors';
import { Modal, Button, ListGroup, Form, InputGroup, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';

//COMPONENTE PARA GESTIONAR LOS PROCESOS DE UN ROL
const RolProceso = ({ show, handleClose, rol }) => {
    const dispatch = useDispatch();
    const rolProcesos = useSelector(selectRolProcesos);
    const { procesos } = useSelector(state => state.proceso);
    const [selectedProcesos, setSelectedProcesos] = useState([]);
    const [availableProcesos, setAvailableProcesos] = useState([]);
    const [visibleCount, setVisibleCount] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (rol && rol.id) {
            dispatch(fetchProcesos());
            dispatch(fetchRolProcesos(rol.id));
        }
    }, [dispatch, rol]);

    useEffect(() => {
        if (procesos) {
            setAvailableProcesos(procesos);
        }
    }, [procesos]);

    useEffect(() => {
        if (Array.isArray(rolProcesos) && Array.isArray(procesos)) {
            const selected = rolProcesos.map(rolProc => {
                const proceso = procesos.find(p => p.id === rolProc.idproc);
                return {
                    ...rolProc,
                    ...proceso,
                    selected: true
                };
            });
            setSelectedProcesos(selected);

            const available = procesos.filter(proceso => !rolProcesos.some(rp => rp.idproc === proceso.id));
            setAvailableProcesos(available);
        }
    }, [rolProcesos, procesos]);

    const handleAddProcesos = () => {
        const selected = availableProcesos.filter(proceso => proceso.selected);
        setSelectedProcesos([...selectedProcesos, ...selected]);
        setAvailableProcesos(availableProcesos.filter(proceso => !proceso.selected));
    };

    const handleRemoveProcesos = () => {
        const removed = selectedProcesos.filter(proceso => proceso.selected);
        setAvailableProcesos([...availableProcesos, ...removed]);
        setSelectedProcesos(selectedProcesos.filter(proceso => !proceso.selected));
    };

    const handleCheckboxChange = (e, proceso, type) => {
        const updatedList = type === 'available'
            ? availableProcesos.map(p => p.id === proceso.id ? { ...p, selected: e.target.checked } : p)
            : selectedProcesos.map(p => p.id === proceso.id ? { ...p, selected: e.target.checked } : p);

        type === 'available' ? setAvailableProcesos(updatedList) : setSelectedProcesos(updatedList);
    };

    const handleSave = async () => {
        try {
            await dispatch(removeRolProcesos(rol.id));
            for (const proceso of selectedProcesos) {
                const procesoData = {
                    idrol: rol.id,
                    idproc: proceso.id
                };
                await dispatch(addRolProcesos(procesoData));
            }
            toast.success('Procesos actualizados correctamente');
            handleClose();
        } catch (error) {
            toast.error(`Error al guardar los procesos: ${error.message}`);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredProcesos = availableProcesos.filter(proceso =>
        proceso.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (!rol || !rol.id) {
        return null; // o un indicador de carga
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Gestionar Procesos del Rol: {rol.nombre}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container">
    <div className="row">
        <div className="col-md-6">
            <div className="w-100">
                <h5>Procesos en el Rol</h5>
                <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {selectedProcesos.map(proceso => (
                        <ListGroup.Item key={proceso.id}>
                            <Form.Check
                                type="checkbox"
                                label={`${proceso.nombre}`}
                                checked={proceso.selected || false}
                                onChange={(e) => handleCheckboxChange(e, proceso, 'selected')}
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <Button variant="outline-danger" className="mt-2 mb-3" onClick={handleRemoveProcesos}><i className="bi bi-dash"></i> Quitar</Button>
            </div>
        </div>
        <div className="col-md-6">
            <div className="w-100">
                <h5>Procesos Disponibles</h5>
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Buscar por nombre"
                        aria-label="Buscar por nombre"
                        aria-describedby="basic-addon2"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </InputGroup>
                <Form.Group controlId="visibleCount">
                    <Form.Label>Número de elementos visibles</Form.Label>
                    <Form.Control
                        type="number"
                        value={visibleCount}
                        onChange={(e) => setVisibleCount(Number(e.target.value))}
                        min={1}
                    />
                </Form.Group>
                <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {filteredProcesos.slice(0, visibleCount).map(proceso => (
                        <ListGroup.Item key={proceso.id}>
                            <Form.Check
                                type="checkbox"
                                label={`${proceso.nombre}`}
                                checked={proceso.selected || false}
                                onChange={(e) => handleCheckboxChange(e, proceso, 'available')}
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                <Button variant="outline-info" className="mt-2" onClick={handleAddProcesos}> <i className="bi bi-plus-lg"></i>  Agregar</Button>
            </div>
        </div>
    </div>
</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Cancelar
                </Button>   
                <Button variant="info" onClick={handleSave}>
                Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RolProceso;
