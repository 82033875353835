import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ItemTecnico from "./ItemTecnico";
import { fetchTecnicosByUniversidad } from "../../actions/tecnicoActions";
import CrearButton from "../generales/CrearButton";
import FormTecnico from "./FormTecnico";
import DeleteTecnico from "./DeleteTecnico";
import PerfilTecnicoQR from "./PerfilTecnicoQR";
import { fetchUniversidadBySigla } from "../../actions/universidadAction";
import HabilitacionTecnico from "./ConfirmChange";

const ListCuerpoTecnico = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ tecnicos, setTecnicos ] = useState([]);
    const universidadLocation = location.state ? location.state.universidad || location.state.universidadLocation : null;
    const [sort, setSort] = useState({ by: "ci", order: "asc" });
    const [searchTerm, setSearchTerm] = useState([""]);
    const [modal, setModal] = useState({ tipo: 0, titulo: "", body: <></>, size:"ld" });
    const [current, setCurrent] = useState(null)
    const universidadSigla = useSelector(state => state.sesion.universidadSigla);
    const [universidad, setUniversidad]=useState(null)
    const estadoEvento= useSelector(state => state.evento.estado)
    const rolActivo = useSelector(state => state.sesion.rolActivo);
    const [filters, setFilters] = useState({
      tipo2: ""
    });

    const handleFilters = (filter, value) => {
      setFilters((prev) => ({ ...prev, [filter]: value }));
    };

    const fetchTecnicos = useCallback(async () => {
        if(universidadLocation){
            dispatch(fetchTecnicosByUniversidad(universidadLocation.id)).then((data) => {
                setTecnicos(data)
                setUniversidad(universidadLocation)
            })
        } else if (universidadSigla) {
            dispatch(fetchUniversidadBySigla(universidadSigla))
              .then((data) => { 
                setUniversidad(data)
                dispatch(fetchTecnicosByUniversidad(data.id)).then((data2) => {
                    setTecnicos(data2)
                })
               });
          }
      }, [dispatch,universidadLocation,universidadSigla]);

    useEffect(()=>{
        fetchTecnicos()
    }, [fetchTecnicos])
      

    
  const handleSort = (e) => {
    const { name, value } = e.target;
    console.log("handleSort", name, value)
    setSort((prevSort) => ({ ...prevSort, [name]: value }));
  };
  const handleSearchChange = (e) => {
    const phrase = e.target.value;
    const wordsArray = phrase.split(' ').filter(word => word); // Separa en palabras y filtra cualquier palabra vacía
    setSearchTerm(wordsArray);
  };
  
  const handleActualizarLista= () =>{
    fetchTecnicos()
  }
  const handleShowModal = useCallback((tipo, tecnico) => {
      let modalTitulo = "";
      let body = <></>;
      let size = "xl"
      if (tipo === 1) {
        modalTitulo = "Nueva Persona";
        body = <FormTecnico currentTecnico={tecnico} handleClose={handleClose} universidad={universidad} actualizarLista={handleActualizarLista}/>;
      } else  if (tipo === 2) {
        modalTitulo = "Editar Persona";
        body = <FormTecnico currentTecnico={tecnico} handleClose={handleClose} universidad={universidad} actualizarLista={handleActualizarLista}/>;
      } else  if (tipo === 3) {
        size="md"
        modalTitulo = "Confirmar eliminación";
        body = <DeleteTecnico currentTecnico={tecnico} handleClose={handleClose} actualizarLista={handleActualizarLista}/>;
      }else if (tipo === 4) {
        modalTitulo = "Perfil Personal Extra";
        body = <PerfilTecnicoQR currentTecnico={tecnico} handleClose={handleClose} />;
      } else if (tipo === 5) {
        size="md"
        modalTitulo = (tecnico.estado === 0? "Habilitación":"Deshabilitación" )+ " Personal Extra";
        body = <HabilitacionTecnico currentTecnico={tecnico} handleClose={handleClose} actualizarLista={handleActualizarLista} />;
      } 
      setModal({ tipo, titulo: modalTitulo, body, size });
    },
    [dispatch, universidad]
  );

  const handleClose = () => {
    setModal({ tipo: 0, titulo: "", body: <></> });
 } 
 const sortedList = [...tecnicos].sort((a, b) => {
      if (sort.order === "asc") {
        return a[sort.by] > b[sort.by] ? 1 : -1;
      }
      return a[sort.by] < b[sort.by] ? -1 : 1;
    });

    const filteredList = sortedList.filter(tecnico => {
      return searchTerm.every(term => 
          (tecnico.appat.toLowerCase().includes(term.toLowerCase()) ||
          tecnico.ci.toLowerCase().includes(term.toLowerCase()) ||
          tecnico.nombres.toLowerCase().includes(term.toLowerCase()) ||
          tecnico.apmat.toLowerCase().includes(term.toLowerCase())) &&
          tecnico.tipo2.includes(filters.tipo2)
        );
      });

    return(
        <div className="container-large">
        <h1>PERSONAL EXTRA - {universidad ? universidad.nombre : ""}</h1>
        <div className="row align-items-center mb-4">
          <div className="col-md-2">
            <Button
              variant="light"
              onClick={() => navigate(-1)}
              style={{ width: "auto" }}
            >
             <i className="bi bi-arrow-left"></i>   Volver
            </Button>
          </div>
          <div className="col-md-2">
            <Form.Label className="mt-2">Buscar:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Buscar..."
              onChange={handleSearchChange}
              className="mr-3"
            />
          </div>
          <div className="col-md-2">
            <Form.Label className="mt-2">Ordenar por:</Form.Label>
            <div className="d-flex">
              <Form.Control
                as="select"
                name="by"
                value={sort.by}
                onChange={handleSort}
                className="mr-3"
              >
                <option value="ci">CI</option>
                <option value="appat">Apellido paterno</option>
            </Form.Control>
             
            </div>
            
          </div>
            <div className="col-md-3 modal-input">
          <Form.Label className="mt-2">Tipo</Form.Label>
          <Form.Control
            as="select"
            value={filters.tipo2}
            onChange={(e) => handleFilters("tipo2", e.target.value)}
            required
          >
            <option value="">Todos</option>
            <option value="tecnico">Cuerpo técnico</option>
            <option value="personal">Personal de apoyo</option>
          </Form.Control>
        </div>
            <div className="col-md-3 d-flex mt-4 justify-content-end">
            {estadoEvento<2 && rolActivo === "enca_universidad" && (
                <CrearButton onHandleCreate={() => handleShowModal(1, null)}>
                Agregar personal extra
              </CrearButton>
            )}
            </div>
        </div>
        <div
          className="table-container1"
          style={{ maxHeight: "550px", overflowY: "scroll" }}
        >
          <Table
          size="sm"
            striped
            bordered
            hover
          >
            <thead className="sticky-header1">
              <tr>
                <th className="col-cod">N°</th>
                <th >C.I.</th>
                <th >Apellido paterno</th>
                <th >Apellido materno</th>
                <th >Nombre</th>
                <th >Estado</th>
                <th> Universidad </th>
                <th >Tipo</th>
                <th> Subdisciplinas </th>
                <th className="col-actions-md">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredList.map((tecnico, index) => {
                const indice = index + 1;
                return (
                  <ItemTecnico
                    key={tecnico.ci}
                    tecnico={tecnico}
                    indice={indice}
                    showModal={handleShowModal}
                    // inscripcionDocente={() => handleShowModal(5, persona)}
                    // funcionalidad={funcionalidadNum}
                    actualizarLista={handleActualizarLista}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
        <Modal show={modal.tipo > 0} onHide={handleClose} size={modal.size} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modal.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.body}</Modal.Body>
      </Modal>
    </div>
    )

}

export default ListCuerpoTecnico