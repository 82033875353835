import React, { useState, useEffect } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addFixturedet, updateFixturedet, removeFixturedet, fetchFixturedetsByFixtureId } from "../../actions/fixturedetActions";
import { fetchPredios } from "../../actions/predioActions";
import { fetchUniversidades } from "../../actions/universidadAction";
import { BsCheckCircle, BsTrash } from "react-icons/bs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { formatearFecha } from "../../util/valueCalculator";

dayjs.extend(utc);
dayjs.extend(timezone);

function formatear(fecha) {
  if (fecha) {
    const fec = fecha.split("T")[0];
    const [year, month, day] = fec.split("-");
    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
}

const FormFixturedet = ({ tipoParticipacion, fixture, onHandleClose }) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [universidades, setUniversidades] = useState([]);
  const [modifiedRows, setModifiedRows] = useState([]);
  const [showDelete, setShowDelete] = useState([]);

  const predios = useSelector((state) => state.predio.predios);
  const allUniversidades = useSelector((state) => state.universidad.universidades);
  const fixturedets = useSelector((state) => state.fixturedet.fixturedets);

  useEffect(() => {
    dispatch(fetchPredios());
    dispatch(fetchUniversidades());
  }, [dispatch]);

  useEffect(() => {
    if (allUniversidades) {
      setUniversidades(allUniversidades);
    }
  }, [allUniversidades]);

  useEffect(() => {
    if (fixture) {
      dispatch(fetchFixturedetsByFixtureId(fixture.id));
    }
  }, [dispatch, fixture]);

  useEffect(() => {
    if (fixturedets) {
      const formattedFixturedets = fixturedets.map(fixtureDet => ({
        ...fixtureDet,
        fecha: fixtureDet.fecha && formatearFecha(fixtureDet.fecha),
        hora: fixtureDet.hora ? fixtureDet.hora : fixture.hora,
      }));
      const emptyRow = createEmptyRow(fixture, formattedFixturedets);
      setRows([...formattedFixturedets, emptyRow].sort((a, b) => a.hora.localeCompare(b.hora)));
    }
  }, [fixturedets, fixture]);

  function createEmptyRow(fixture, fixturedets) {
    const hourMinute = fixture.hora ? fixture.hora.split(":") : ["07", "00"];
    const date = fixture.fecha ? fixture.fecha.split("T")[0] : new Date().toISOString().split("T")[0];
    const predio = fixture.idpredio ? fixture.idpredio : "";
    const latestHour = fixturedets.length ? fixturedets.reduce((latest, current) => current.hora > latest ? current.hora : latest, fixturedets[0].hora) : `${hourMinute[0].padStart(2, "0")}:${hourMinute[1].padStart(2, "0")}`;
    return {
      descripcion: fixture.descripcion || "", // Copiar la descripción del fixture
      idpredio: predio,
      fecha: date,
      hora: latestHour,
      sigla1: "",
      sigla2: "",
    };
  }

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);

    if (!modifiedRows.includes(index)) {
      setModifiedRows([...modifiedRows, index]);
    }
  };

  const handleSaveRow = (index) => {
    const row = rows[index];
    if (row.sigla1 === row.sigla2 && tipoParticipacion === "Partidos") {
      alert("Las universidades no pueden ser las mismas");
      return;
    }
   

    const dataToSend = {
      id: row.id,
      idfix: fixture.id,
      descripcion: row.descripcion,
      idpredio: fixture.idpredio,
      fecha: formatearFecha(fixture.fecha),
      hora: row.hora,
      sigla1: row.sigla1,
      sigla2: row.sigla2,
      comentario: row.comentario,
    };

    if (row.id) {
      dispatch(updateFixturedet(row.id, dataToSend));
    } else {
      dispatch(addFixturedet(dataToSend));
    }

    const latestHour = rows.reduce((latest, current) => current.hora > latest ? current.hora : latest, rows[0].hora);
    const newRow = {
      descripcion: fixture.descripcion || "", // Copiar la descripción del fixture en la nueva fila
      idpredio: fixture.idpredio,
      fecha: formatearFecha(fixture.fecha),
      hora: latestHour,
      sigla1: "",
      sigla2: "",
      comentario:""
    };

    if (!row.id) {
      setRows([...rows, newRow].sort((a, b) => a.hora.localeCompare(b.hora)));
    }

    setModifiedRows(modifiedRows.filter(modIndex => modIndex !== index));
  };

  const handleDeleteRow = (index) => {
    const row = rows[index];
    if (row.id) {
      dispatch(removeFixturedet(row.id));
    }
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleRowDoubleClick = (index) => {
    setShowDelete([...showDelete, index]);
  };
//  console.log("Detalles del fixture",fixture,rows)
  const renderRow = (row, index) => (
    <tr key={index} onDoubleClick={() => handleRowDoubleClick(index)}>
     
      <td>
        <Form.Control
          type="time"
          name="hora"
          value={row.hora}
          onChange={(e) => handleInputChange(index, e)}
          required
          readOnly={index === 0}  // Disabling the time input for the first row
        />
      </td>
      <td>
        <Form.Control
          type="text"
          name="descripcion"
          value={row.descripcion}
          maxLength={255} 
          onChange={(e) => handleInputChange(index, e)}
        />
      </td>
      {tipoParticipacion === "Partidos" && <>
        <td>
        <Form.Control
          as="select"
          name="sigla1"
          value={row.sigla1}
          onChange={(e) => handleInputChange(index, e)}
          required
        >
          <option value="">Seleccionar universidad</option>
          {universidades.map((universidad) => (
            <option key={universidad.id} value={universidad.sigla}>{universidad.sigla}</option>
          ))}
        </Form.Control>
      </td>
      <td>
        <Form.Control
          as="select"
          name="sigla2"
          value={row.sigla2}
          onChange={(e) => handleInputChange(index, e)}
          required
        >
          <option value="">Seleccionar universidad</option>
          {universidades.map((universidad) => (
            <option key={universidad.id} value={universidad.sigla}>{universidad.sigla}</option>
          ))}
        </Form.Control>
      </td>
            </>}
            <td>
        <Form.Control
          type="text"
          name="comentario"
          value={row.comentario}
          onChange={(e) => handleInputChange(index, e)}
        />
      </td>
      <td>
        {(modifiedRows.includes(index) || index === rows.length - 1) && (
          <Button variant="success" onClick={() => handleSaveRow(index)}>
            <BsCheckCircle size={24} />
          </Button>
        )}
        {showDelete.includes(index) && (
          <Button variant="danger" onClick={() => handleDeleteRow(index)}>
            <BsTrash size={24} />
          </Button>
        )}
      </td>
    </tr>
  );

  return (
    <div className="contenedor-wide">
      <h2>Fixture: {fixture.descripcion}</h2>
      <p>
        <strong>Fecha:</strong> {formatear(fixture.fecha)}{" "}
        <strong>Hora:</strong> {fixture.hora}{" "}
        <strong>Predio:</strong> {fixture.Predio.nombre}
      </p>
      
      <div
          className="table-container1"
          style={{ maxHeight: "550px", overflowY: "scroll" }}
        >
      <Table  bordered hover>
        <thead>
          <tr>
            <th>Hora</th>
            <th >Descripción</th>
            {tipoParticipacion === "Partidos" && <>
              <th>Sigla 1</th>
              <th>Sigla 2</th>
            </>}
            <th>Notas</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => renderRow(row, index))}
        </tbody>
      </Table>
      <p>Doble click en una fila para ELIMINAR</p>
      </div>
      <Button
        variant="secondary"
        onClick={onHandleClose}
        className="btn-modal"
      >
        Cancelar
      </Button>
    </div>
  );
};

export default FormFixturedet;
