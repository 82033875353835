
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { cambiarFaseEvento, fetchEvento } from "../../actions/eventoAction";
import { fasesEvento } from "../../util/valueCalculator";

const FaseForm = ({gestion, handleClose,cambioLaFase=()=>{console.log("No entra alla")}}) => {
  const dispatch = useDispatch();
    const [estado, setEstado] = useState("")
    const [motivo, setMotivo] = useState("")
    const [ isSending, setIsSending ] = useState(false)

    const handleSubmit = (e) => {
      e.preventDefault()

      setIsSending(true)
      const fd = new FormData();
        // Agregar los datos de 'datos' al FormData
        fd.set("estado", estado);
        fd.set("motivo", motivo);
        
        dispatch(cambiarFaseEvento(gestion, fd))
          .then(() => {
                setIsSending(false)
                dispatch(fetchEvento(gestion))
                handleClose(estado);
                   }) 
                .catch(errores => {
                 //console.log("Errores", errores)
                    setIsSending(false)
                    for(const err of errores){
                      toast.error(err)
                    }
                  });
      
    }
  
  return (
    <>
    <Form
                onSubmit={handleSubmit}
                className="modal-oval"
              >   
                <Form.Group className="row">
                  <div className="col-md-12 modal-input">
                  <Form.Label>Fase</Form.Label>
                    <Form.Control
                      as="select"
                      value={estado}
                      onChange={e => setEstado(e.target.value)}
                      disabled={isSending}
                      required
                    >
                      <option value="">Seleccionar fase</option>
                      {[0,1,2,3].map((estado, index) => (
                        <option key={estado} value={estado}>
                          {`${index+1} ${fasesEvento(estado)}`}
                        </option>
                      ))}
                    </Form.Control>
                  </div>

                  <div className="col-md-12 modal-input">
                    <Form.Label>Motivo</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Escriba el motivo para cambiar de fase y las acciones que piensa realizar..."
                      value={motivo}
                      onChange={(e) => setMotivo(e.target.value)}
                      required
                    />                    
                  </div>
                  </Form.Group>
                <Button
                  variant="warning"
                  type="submit"
                  className="btn-modal"
                  disabled={isSending}
                >
                  Actualizar
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleClose(-2)}
                  className="btn-modal"
                  disabled={isSending}
                >
                  Cancelar
                </Button>
              </Form>
    </>
  );
};

export default FaseForm;
