import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, useNavigate } from "react-router-dom";
import { register } from "../../actions/authAction";

const Login = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    login: "",
    contrasena: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    dispatch(register(formData)).then(() => {
      navigate("/");
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <Form
        onSubmit={handleSubmitLogin}
        className="form-auth"
      >
        <h3 className="special-title">Encuentro deportivo de docentes</h3>
        {error && <p>{error}</p>}
        <div className="mb-3">
          <label
            htmlFor="nombre"
            className="form-label"
          >
            Nombre completo
          </label>
          <input
            type="text"
            className="form-control"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="email"
            className="form-label"
          >
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="login"
            className="form-label"
          >
            Usuario
          </label>
          <input
            type="text"
            className="form-control"
            id="login"
            name="login"
            value={formData.login}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="contrasena"
            className="form-label"
          >
            Contraseña
          </label>
          <input
            type="password"
            className="form-control"
            id="contrasena"
            name="contrasena"
            value={formData.contrasena}
            onChange={handleChange}
            required
          />
        </div>
        <button
          disabled={isSubmitting}
          className="button btn-custom-primary w-100"
        >
          {isSubmitting ? "Iniciando..." : "Iniciar sesión"}
        </button>
      </Form>
    </>
  );
};
export default Login;
