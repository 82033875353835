const initialState = {
    roles: [],
    rol: null,
  };
  
  const rolReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ROLES':
        return { ...state, roles: action.payload };
      case 'GET_ROL_BY_ID':
        return { ...state, rol: action.payload };
      case 'CREATE_ROL':
        return { ...state, roles: [...state.roles, action.payload] };
      case 'UPDATE_ROL':
        return {
          ...state,
          roles: state.roles.map(r =>
            r.id === action.payload.id ? action.payload : r
          ),
        };
      case 'DELETE_ROL':
        return {
          ...state,
          roles: state.roles.filter(r => r.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default rolReducer;
