import { useEffect,useRef, useState } from "react"
import { Button, Form } from "react-bootstrap"
import { fetchDeportistaAprobadoByCi, fetchDocenteAprobadoByCi, fetchDocenteByCi } from "../../actions/docenteActions"
import { useDispatch, useSelector } from "react-redux"
import PerfilDocente from "./PerfilDocente"
import { fetchSubdisciplinas } from "../../actions/subdisciplinaActions"
import { approveDocenteSubdisciplinas } from "../../actions/docsubActions"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { docenteActions } from "../../reducers/docente_slice"
import MensajeCentral from "../mensajes/MensajeCentral"
import PerfilDocenteQR from "./PerfilDocenteQR"
const MySwal = withReactContent(Swal);
const BuscadorDocente = () =>{
    const [ci, setCi] = useState("")
    const dispatch = useDispatch()
    const [deportista, setDeportista] = useState()
     useEffect(()=>{
        dispatch(fetchSubdisciplinas())
    },[dispatch])
    const [error, setError] = useState(null)
     const inputRef = useRef(null);

  const handleSubmitGetDocente = (e) => {
    e.preventDefault();
    setDeportista({ ci: ci });

    // Selecciona el texto del input después de enviar el formulario
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

    const estadoEvento= useSelector(state => state.evento.estado)

    return (
        <div className="d-flex align-items-center flex-column">
        <h2 className="mt-3">Buscar Deportista</h2>
        <div className="row w-100 justify-content-center">
          <div className="col-md-5 col-lg-4">
            <Form onSubmit={handleSubmitGetDocente} className="text-center">
              {error && <p className="text-danger">{error}</p>}
              <input
                type="text"
                placeholder="N° Cédula de Identidad"
                value={ci}
                onChange={(e) => setCi(e.target.value)}
                className="form-control mb-3 mt-3" 
 		 ref={inputRef}            
   />
                <Button variant="dark" className="mb-3 me-2" type="submit">
                  Consultar
                </Button>
            </Form>
          </div>
        </div>
        <div className="row w-100 justify-content-center">
          <div className="col-md-10">
            {deportista && <PerfilDocenteQR currentDocente={deportista} />}
          </div>
        </div>
      </div>
    )
}

export default BuscadorDocente
