import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPredios } from "../../actions/predioActions";
import { Button, Modal, Table } from "react-bootstrap";
import CrearButton from "../generales/CrearButton";
import { predioActions } from "../../reducers/predio_slice";
import FormPredio from './FormPredio'
import CardPredio from './CardPredio'
import DeletePredio from './DeletePredio'
import { useNavigate } from "react-router-dom";  // Import useNavigate for navigation
import { tipoPredio } from "../../util/valueCalculator";

const PrediosList = () => {
    const dispatch = useDispatch();
    const predios = useSelector((state) => state.predio.predios);
    const [modal, setModal] = useState({
        tipo: 0,
        titulo: "",
        body: <></>,
    });

    const navigate = useNavigate();  // Initialize useNavigate

    useEffect(() => {
        dispatch(fetchPredios());
    }, [dispatch]);

    const handleShowModal = (tipo, predio) => {
        dispatch(predioActions.setCurrentPredio(predio));
        let titulo = '';
        let body = <></>;
        if (tipo === 1) { titulo = "Nuevo Predio"; body = (<FormPredio onHandleClose={handleClose} />) }
        else if (tipo === 2) { titulo = "Editar Docente"; body = (<FormPredio onHandleClose={handleClose} />) }
        else if (tipo === 3) { titulo = "Confirmar eliminación"; body = (<DeletePredio onHandleClose={handleClose} />) }
        else if (tipo === 4) { titulo = "Información del predio"; body = (<CardPredio />) }
        setModal({ tipo, titulo, body });
    }

    const handleClose = () => {
        dispatch(predioActions.setCurrentPredio(null));
        setModal({ tipo: 0, titulo: "", body: <></> });
    };

    const handleBack = () => {
        navigate('/');  // Navigate to the root (menu) page
    };

    return (
        <>
            <div className="contenedor container-medium">
                <h1>Lista de Predios</h1>
                {/* Botón de Volver */}
                <div className="d-flex justify-content-start">
                            <Button variant="secondary" onClick={handleBack}>
                            <i className="bi bi-arrow-left"></i>  Volver
                            </Button>
                </div>
                {/* Cabecera de acciones */}
                <div>
                    <CrearButton onHandleCreate={() => handleShowModal(1, null)} className='float-end'>
                        Nuevo Predio
                    </CrearButton>
                </div>

                {/* Lista */}
                <div className="table-container">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th className="col-cod">N°</th>
                                <th>Nombre</th>
                                <th>Dirección</th>
                                <th>Tipo</th>
                                <th>Estado</th>
                                <th className="col-actions-md">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {predios.map((predio, index) => {
                                return (
                                    <tr key={predio.id}>
                                        <td>{index + 1}</td>
                                        <td>{predio.nombre}</td>
                                        <td>{predio.direccion}</td>
                                        <td>{tipoPredio(predio.tipo)}</td>
                                        <td>{predio.estado === 1 ? "Activo" : "Inactivo"}</td>
                                        <td>
                                            <Button
                                                variant="outline-dark"
                                                size="sm"
                                                onClick={() => handleShowModal(4, predio)}
                                                className="action-button"
                                            >
                                                Ver
                                            </Button>
                                            <Button
                                                variant="outline-warning"
                                                size="sm"
                                                onClick={() => handleShowModal(2, predio)}
                                                className="action-button"
                                            >
                                                <i className="bi bi-pencil"></i>
                                            </Button>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => handleShowModal(3, predio)}
                                            >
                                                <i className="bi bi-trash"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
               
            </div>
            {/* Modal */}
            <Modal
                show={modal.tipo > 0}
                onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modal.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.body}</Modal.Body>
            </Modal>
        </>
    );
}
export default PrediosList;
