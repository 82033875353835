// src/actions/disciplinaActions.js

import { getDisciplinas, getDisciplinaById, createDisciplina, updateDisciplina, deleteDisciplina } from '../services/disciplinaService';

export const fetchDisciplinas = () => async dispatch => {
  try {
    const data = await getDisciplinas();
    
    dispatch({ type: 'GET_DISCIPLINAS', payload: data });
  } catch (error) {
    console.error('fetchDisciplinas error:', error);
  }
};

export const fetchDisciplinaById = (id) => async dispatch => {
  try {
    const data = await getDisciplinaById(id);
   //console.log('fetchDisciplinaById data:', data); // Depuración
    dispatch({ type: 'GET_DISCIPLINA_BY_ID', payload: data });
  } catch (error) {
    console.error('fetchDisciplinaById error:', error);
  }
};

export const addDisciplina = (formData) => async dispatch => {
  try {console.log("Entro a action disc..");
    const data = await createDisciplina(formData);
   //console.log('addDisciplina data:', data); // Depuración
    dispatch({ type: 'CREATE_DISCIPLINA', payload: data });
  } catch (error) {
    console.error('addDisciplina error:', error);
  }
};

export const modifyDisciplina = (id, formData) => async dispatch => {
  try {
    const data = await updateDisciplina(id, formData);
   //console.log('modifyDisciplina data:', data); // Depuración
    dispatch({ type: 'UPDATE_DISCIPLINA', payload: data });
  } catch (error) {
    console.error('modifyDisciplina error:', error);
  }
};

export const removeDisciplina = (id) => async dispatch => {
  try {
    const data = await deleteDisciplina(id);
   //console.log('removeDisciplina data:', data); // Depuración
    dispatch({ type: 'DELETE_DISCIPLINA', payload: id });
  } catch (error) {
    console.error('removeDisciplina error:', error);
  }
};
