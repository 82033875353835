import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocsub, fetchDocsubByUniversidad } from '../../actions/docsubActions';
import { fetchDocentes, fetchDocentesByUniversidad } from '../../actions/docenteActions';
import { fetchSubdisciplinas, fetchSubdisciplinasByUniversidad } from '../../actions/subdisciplinaActions';
import { fetchUniversidades } from '../../actions/universidadAction';
import { fetchDisciplinas } from '../../actions/disciplinaActions';
import debounce from 'lodash/debounce';
import * as XLSX from 'xlsx';
import { Table, Form, Button, Modal } from 'react-bootstrap';
import CustomPrintModal from '../CustomPrintModal';
import { getSubdisciplinaFullName, getPersonaFullName, estadoDocente, getPersonaFullNameAppat, excelSheetName, getSubdisciplinaShortName, categoryMap, genderMap } from '../../util/valueCalculator';
import excelIcono from '../../assets/excel-icono.png'
import PrintModalListas from './PrintModalListas';
import { calculateAge } from '../Utils';

const ReporteDisciplinas = () => {
  const dispatch = useDispatch();

  const docentes = useSelector((state) => state.docente.docentes) || [];
  const inscripciones = useSelector((state) => state.docsub.docsubs) || [];
  const inscripcionesByUniv = useSelector((state) => state.docsub.docsubsByUniv) || [];
  const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas) || [];
  const universidades = useSelector((state) => state.universidad.universidades) || [];
  const disciplinas = useSelector((state) => state.disciplina.disciplinas) || [];
 
  const sesion = useSelector(state => state.sesion);
  const rolActivo = sesion?.rolActivo;
  const [docsubs, setDocsubs] = useState([])

  const [filters, setFilters] = useState({ universidad: 'todo', 
    disciplina: 'todo', 
    subdisciplina: 'todo',
   estadoDocente:'todo' });

const [searchTerm, setSearchTerm] = useState("");
useEffect(() => {
    console.log("rolActivo:", rolActivo);
    console.log("sesion:", sesion);
    if(rolActivo === "admin_evento"){
      dispatch(fetchDocsub());
      dispatch(fetchDocentes());
      dispatch(fetchSubdisciplinas());
      dispatch(fetchUniversidades());
      dispatch(fetchDisciplinas());
    }else if(rolActivo === "enca_universidad"){
       const idUniv = sesion.universidadId;
      dispatch(fetchDocsubByUniversidad(idUniv));
      dispatch(fetchDocentesByUniversidad(idUniv));
      dispatch(fetchSubdisciplinasByUniversidad(idUniv));
      dispatch(fetchDisciplinas()); 
      if(sesion.universidadSigla) setFilters(prev => ({...prev, universidad:sesion.universidadSigla}))
    }
  }, [dispatch,rolActivo,sesion]);
  const memoizedInscripciones = useMemo(() => inscripciones, [inscripciones]);
  const memoizedInscripcionesByUniv = useMemo(() => inscripcionesByUniv, [inscripcionesByUniv]);
    
  useEffect(() => {
 
    if (rolActivo === "admin_evento") {
      console.log("rolActivo seteando:", memoizedInscripciones);
      setDocsubs(memoizedInscripciones);
    } else if(rolActivo === "enca_universidad"){
      console.log("rolActivo seteando univ:", memoizedInscripcionesByUniv);
      setDocsubs(memoizedInscripcionesByUniv);
    }
  }, [rolActivo, memoizedInscripciones, memoizedInscripcionesByUniv]);
  
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [columnIndex, setColumnIndex] = useState(0);
  const [filterBy, setFilterBy] = useState("");
  const eventoData = useSelector(state => state.evento);
  const columnOrders = [
    ['N°','Subdisciplina', 'Universidad', 'Apellidos y Nombres','C.I.', 'Estado'],
    ['N°','Subdisciplina', 'Apellidos y Nombres','C.I.', 'Universidad', 'Estado'],
    ['N°','Subdisciplina','C.I.', 'Apellidos y Nombres', 'Universidad', 'Estado'],
  ];

  const columnsOrder = columnOrders[columnIndex];

  const debouncedSearchTerm = useMemo(() => debounce((value) => setSearchTerm(value), 300), []);

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  const filteredList = useMemo(() => {
    if (!docentes.length || !docsubs.length || !subdisciplinas.length || (rolActivo === "admin_evento"? !universidades.length : false)) return [];

    const filtered = docsubs.filter((docsub) => {
      const docente = docentes.find((doc) => doc.id === docsub.iddoc);
      const subdisciplina = subdisciplinas.find((sub) => sub.id === docsub.idsub);
      const universidad = docente?.Universidad
      
      const docenteMatch = filters.estadoDocente === "todo" || `${docente.estado}` === filters.estadoDocente;
      const universidadMatch = filters.universidad === 'todo' || universidad?.sigla === filters.universidad;
      const disciplinaMatch = filters.disciplina === 'todo' || subdisciplina?.iddisc === parseInt(filters.disciplina);
      const subdisciplinaMatch = filters.subdisciplina === 'todo' || subdisciplina?.id === parseInt(filters.subdisciplina);

      return (
        docenteMatch &&
        universidadMatch &&
        disciplinaMatch &&
        subdisciplinaMatch &&
        searchTerm.split(' ').every(term =>
          (`${getPersonaFullName(docente)}`.toLowerCase().includes(term.toLowerCase()) ||
           `${docente.ci}`.toLowerCase().includes(term.toLowerCase())
          )
        )
      );
    });

    filtered.sort((a, b) => {
      for (let column of columnsOrder) {
        let aValue, bValue;
        const aDoc = docentes.find((doc) => doc.id === a.iddoc)
        const bDoc = docentes.find((doc) => doc.id === b.iddoc)
     
        if (column === 'Universidad' && rolActivo==="admin_evento") {
          const aUniv = universidades.find((univ) => univ.id === aDoc?.iduniv)
          const bUniv = universidades.find((univ) => univ.id === bDoc?.iduniv)
          aValue = aUniv?.sigla || '';
          bValue = bUniv?.sigla || '';
        } else if (column === 'Subdisciplina') {
          const aSubdis = subdisciplinas.find((sub) => sub.id === a.idsub)
          const bSubdis = subdisciplinas.find((sub) => sub.id === b.idsub)
          aValue = getSubdisciplinaFullName(aSubdis) || '';
          bValue = getSubdisciplinaFullName(bSubdis) || '';
        } else if (column === 'Apellidos y Nombres') {
          aValue = `${aDoc?.appat} ${aDoc?.apmat} ${aDoc?.nombres}`;
          bValue = `${bDoc?.appat} ${bDoc?.apmat} ${bDoc?.nombres}`;
        }else if (column === 'C.I.') {
          aValue = aDoc?.ci
          bValue = bDoc?.ci
        }
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
      }
      return 0;
    });

    return filtered;
  }, [docentes, docsubs, subdisciplinas, universidades, filters, searchTerm, columnsOrder]);
  
  
  const getPreviewContent = () => {
    // Crear un objeto para almacenar docentes por subdisciplina
    const docentesPorSubdisciplina = {};
  
    // Agrupar los docentes por subdisciplina
    filteredList.forEach((item) => {
      const docente = docentes.find((doc) => doc.id === item.iddoc);
      const subdisciplina = subdisciplinas.find((sub) => sub.id === item.idsub);
      
      if (docente && subdisciplina) {
        if (!docentesPorSubdisciplina[subdisciplina.id]) {
          docentesPorSubdisciplina[subdisciplina.id] = [];
        }
        docentesPorSubdisciplina[subdisciplina.id].push([
          getPersonaFullNameAppat(docente),
          docente.ci,
          calculateAge(docente.fecnac, eventoData.fechaedad).years,
          docente.Universidad.sigla,
          estadoDocente(docente.estado).estadoName,
        ]);
      }
    });
    const paginas = subdisciplinas.map((sub)=>({...sub, titulo:getSubdisciplinaFullName(sub)}))
    const cabecera = [['N°', 'Nombre Completo', 'CI', 'Edad','Universidad','Estado']]
    return {paginas, docentesPorSubdisciplina,cabecera}
  };

  const handleColumnSwap = () => {
    setColumnIndex((prevIndex) => (prevIndex + 1) % columnOrders.length);
  };

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleFilters = (filter, value) => {
    if(filter === "disciplina") 
      {setFilters((prev) => ({ ...prev, disciplina: value, subdisciplina:"todo" }));}
    else{setFilters((prev) => ({ ...prev, [filter]: value }));}
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };
  const filteredDisciplinas = [...disciplinas].sort((a, b) => a.nombre.localeCompare(b.nombre))
  
  let filteredSubdisciplinas = [...subdisciplinas].sort((a, b) => a.nombre.localeCompare(b.nombre))
  
  if(filters.disciplina!=="todo"){
    filteredSubdisciplinas = [...filteredSubdisciplinas].filter(sub=>`${sub.iddisc}` === filters.disciplina)
  }

  //DEFINICION DE LOS TITULOS
  const getTitulos = (tipo) => { 
    let titulo ="XVIII JUEGOS DEPORTIVOS NACIONALES DE DOCENTES UNIVERSITARIOS"
    let titulo1 = ""
    let titulo2= "";
    if(searchTerm === "")
    {   titulo1 = tipo === "pdf"? "REPORTE DE DOCENTES POR DISCIPLINA" : "DOCENTES POR DISCIPLINA"
        if(filters.universidad !== "todo") { titulo1 += tipo === "pdf"? ` DE LA ${filters.universidad}` : ` ${filters.universidad}`}
        if(filters.estadoDocente !== "todo") { titulo1 += ` ${estadoDocente(filters.estadoDocente).estadoName}S`}
      
        if(filters.subdisciplina !== "todo") {
          const selectedSubdis = subdisciplinas.find(sub => `${sub.id}` === filters.subdisciplina)
          titulo1 += tipo === "pdf"? "" : ` ${getSubdisciplinaShortName(selectedSubdis)}`
          titulo2 = tipo === "pdf"? `SUBDISCIPLINA ${getSubdisciplinaFullName(selectedSubdis)}` 
                                    : `${getSubdisciplinaShortName(selectedSubdis)}`
        
        } else if(filters.disciplina !== "todo") {
            const selectedDis = disciplinas.find(dis => `${dis.id}` === filters.disciplina)
            titulo1 += tipo === "pdf"? "" : ` ${selectedDis.nombre.toUpperCase()}`
            titulo2 = tipo === "pdf"? `DISCIPLINA ${selectedDis.nombre}` : `${selectedDis.nombre}`
            }
    } else { 
        const docInicial = filteredList.length > 0? filteredList[0].iddoc : null;
        const todosMismosId = filteredList.every(item => item.iddoc === docInicial);

        if (todosMismosId && docInicial) {
          const docente = docentes.find(doc => doc.id === docInicial)
          titulo1 = tipo === "pdf"? "REPORTE DE DOCENTE" : `INSCRIPCIONES DOCENTE C.I.: ${docente.ci}`
          titulo2 = tipo === "pdf"? `INSCRIPCIONES  ${getPersonaFullName(docente)} C.I.: ${docente.ci}`:`${getPersonaFullName(docente)}`;
        } else if (filteredList.length > 0) { 
          titulo1 = tipo === "pdf"? "REPORTE DE DOCENTES" : `DOCENTES INSCRIPCIONES VARIAS`
          titulo2 = "INSCRIPCIONES VARIAS";// Si hay varios docentes diferentes
        } else { titulo2 = "";// Si no hay docentes en la lista}
    }}
    return {titulo, titulo1, titulo2}
  }
  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    let hasSheets = false;
    const {paginas, docentesPorSubdisciplina} = getPreviewContent()
    paginas.forEach((subdisciplina) => {
        // Obtener los docentes para la subdisciplina actual
       const docentes = docentesPorSubdisciplina[subdisciplina.id] || [];
        if (docentes.length > 0) {
            // Mapear los datos de los docentes
            const data = docentes.map((docente, index) => {
            const universidad = docente[3]
                return {
                    'N°': index + 1,
                    'DOCENTE': docente[0],
                    'CI': docente[1],
                    'EDAD': docente[2],
                    'UNIVERSIDAD': universidad,
                    'ESTADO': docente[4],
                    'DISCIPLINA':  subdisciplina.nombre,
                    'CATEGORIA':  genderMap[subdisciplina.genero],
                    'MODALIDAD':  categoryMap[subdisciplina.categoria].nombre,
                };
            });

            // Crear una hoja para la subdisciplina actual
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, getSubdisciplinaShortName(subdisciplina));
            hasSheets = true;
        }
    });

    // Añadir una hoja vacía temporal si no se han añadido otras hojas
    if (!hasSheets) {
        const worksheet = XLSX.utils.aoa_to_sheet([['No hay datos disponibles']]);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sin datos');
    }

    const {titulo1} = getTitulos("excel");
    XLSX.writeFile(workbook, `${titulo1}.xlsx`);
};
  let title1, title2;
  if(showPreviewModal){
    const {titulo1, titulo2} = getTitulos("pdf")
    title1 = titulo1;
    title2 = titulo2
  }

  return (
    <div className="container-large">
      <h1>Reportes por Disciplinas</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="link" onClick={handleColumnSwap} style={{ color: 'black', fontSize: '20px', padding: '0', marginRight: '10px' }}>
          →
        </Button>
        <div>
          <Button variant="dark" size="sm" onClick={() => handleShowPreview("lista")} className='action-button' style={{padding:"5.5px"}}>
            Previsualizar
          </Button>
          {/* <Button variant="dark" size="sm" onClick={() => handleShowPreview("listas")} className='action-button' style={{padding:"5.5px"}}>
            Previsualizar por subdisciplinas
          </Button> */}
          <Button  size="sm" variant="outline-success" onDoubleClick={exportToExcel}>
            <img src={excelIcono} alt="excel" className="icon-class" /> Exportar Excel
          </Button>
        </div>
      </div>
      <div className="row align-items-center mb-4" style={{fontSize:"0.9rem"}}>
        <div className="col-6 col-md-3">
          <Form.Label className="mt-2">Buscar:</Form.Label>
          <Form.Control type="text" size="sm" placeholder="Buscar por docente..." 
                        onChange={(e) => debouncedSearchTerm(e.target.value)} />
        </div>
          {rolActivo === "admin_evento" &&   <div className="col-6 col-md-2">
          <Form.Label className="mt-2">Universidad:</Form.Label>
          <Form.Control as="select" size="sm" name="universidad" value={filters.universidad} 
                        onChange={(e) => handleFilters( "universidad", e.target.value )}>
            <option value="todo">- Todas -</option>
            {universidades.map((universidad) => (
              <option key={universidad.id} value={universidad.sigla}>
                {universidad.sigla}
              </option>
            ))}
          </Form.Control>
        </div>}
        <div className="col-6 col-md-2">
          <Form.Label className="mt-2">Disciplina:</Form.Label>
          <Form.Control as="select" size="sm" name="disciplina" value={filters.disciplina} 
                        onChange={(e) => handleFilters( "disciplina", e.target.value )}>
            <option value="todo">- Todas -</option>
            {filteredDisciplinas.map((disciplina) => (
              <option key={disciplina.id} value={disciplina.id}>
                {disciplina.nombre}
              </option>
            ))}
          </Form.Control>
        </div>
        <div className="col-6 col-md-3">
          <Form.Label className="mt-2">Subdisciplina:</Form.Label>
          <Form.Control as="select" size="sm" name="subdisciplina" value={filters.subdisciplina} 
                        onChange={(e) => handleFilters( "subdisciplina", e.target.value )}>
            <option value="todo">- Todas -</option>
            {filteredSubdisciplinas.map((subdisciplina) => (
              <option key={subdisciplina.id} value={subdisciplina.id}>
                {getSubdisciplinaFullName(subdisciplina)}
              </option>
            ))}
          </Form.Control>
        </div>
        <div className="col-6 col-md-2 mt-2">
              <Form.Label>Filtrar por estado:</Form.Label>
              <Form.Control
                as="select"
                 size="sm"
                value={filters.estadoDocente}
                onChange={(e) => handleFilters( "estadoDocente", e.target.value )}
                className="mr-2"
              >
                <option value="todo">- Todo -</option>
                {[2, 3].map(estado => (
                  <option key={estado} value={estado}>{`${estadoDocente(estado).estadoName}`}</option>
                ))}
              </Form.Control>
            </div>
      </div>
      <div className="table-container" style={{ maxHeight: '550px', overflowY: 'scroll' }}>
        <Table bordered hover className="table-bordered" style={{ fontSize: 'small' }}>
          <thead className="sticky-header custom-table-header">
            <tr>
              {columnsOrder.map((column) => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          {filteredList.map((item, index) => {
              const docente = docentes.find((doc) => doc.id === item.iddoc);
              const subdisciplina = subdisciplinas.find((sub) => sub.id === item.idsub);
              const universidad = docente?.Universidad
              return (
                <tr key={item.id}>
                  {columnsOrder.map((column) => {
                    if (column === 'Universidad') return <td key={column}>{universidad?.sigla || ''}</td>;
                    if (column === 'Subdisciplina') return <td key={column}>{getSubdisciplinaFullName(subdisciplina) || ''}</td>;
                    if (column === 'Apellidos y Nombres') return <td key={column}>{getPersonaFullNameAppat(docente) || ''}</td>;
                    if (column === 'N°') return <td key={column}>{index+1}</td>;
                    if (column === 'C.I.') return <td key={column}>{docente.ci || ''}</td>;
                    if (column === 'Estado') return <td key={column}>{estadoDocente(docente.estado).estadoName || ''}</td>;
                    return null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <PrintModalListas
        show={showPreviewModal}
        handleClose={handleClosePreview}
        agrupadores = {printContent.paginas}
        listasPorAgrupador ={printContent.docentesPorSubdisciplina}
        cabecera={printContent.cabecera}
        title1={title1}
        title2={title2}
        copia={false}
        filters={filters}
      />
    </div>
  );
};

export default ReporteDisciplinas;
