import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx"; // Importa la librería xlsx
import { fetchSubdisciplinas, fetchSubdisciplinasByUniversidad } from "../../actions/subdisciplinaActions";
import { inscribirDocentesMasivo } from "../../actions/docsubActions";
import { toast } from "react-toastify";
import { calculateAge, categoryMap, genderMap, getSubdisciplinaFullName, normalizeString } from "../../util/valueCalculator";
import ejemploExcel from '../../assets/ejemplo-excelinscripcion.png'
import '../../styles/ImportExcel.css'; 
const erroresImportacion = 
{    e1: "La categoría solo puede ser DAMAS o VARONES",
     e2: "La modalidad solo puede ser LIBRE, SENIOR o MASTER.",
     e3: "La disciplina NO existe o la universidad seleccionada no está preinscrita:  ",
     e4: "La disciplina NO está habilitada o la universidad seleccionada no está preinscrita:",
     e5: "El docente NO tiene DISCIPLINAS a inscribir:",
     e6: "El docente NO pertecene a la UNIVERSIDAD seleccionada:  ",
     e7: "Una o varias fila(s) tiene(n) la columna CI vacía.",
     e8: "No puede inscribirse en más disciplinas"
}
const advertenciasImportacion = 
{    a1: "El docente no tiene disciplinas a inscribir:",
     a2: "Hay registros duplicados con el mismo CI: "
}

const ImportarExcelInscripciones = ({ universidadId }) => {
    const [importedData, setImportedData] = useState([]);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState([]);
    const [warnings, setWarnings] = useState([]);
    const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
    const docentes = useSelector((state) => state.docente.docentes);
    const docsubs = useSelector((state) => state.docsub.docsubsByUniv);
    
    const eventoData = useSelector(state => state.evento);
   
    useEffect(() => {
        dispatch(fetchSubdisciplinasByUniversidad(universidadId));
    }, [dispatch,universidadId]);

    const getSubdisciplinaId = (nombre, categoria, modalidad) => {
        
        const subdisciplinasFinded = subdisciplinas.filter(d => normalizeString(d.nombre)  === normalizeString(nombre));
        if(subdisciplinasFinded.length > 0){
            const subdisciplina = subdisciplinasFinded.find(d => 
              { 
                return( normalizeString(d.nombre) === normalizeString(nombre) &&
                normalizeString(d.genero) === normalizeString(categoria[0]) &&
                normalizeString(d.categoria) === normalizeString(modalidad[0])
          )    });
            return subdisciplina ? subdisciplina : -2;
        }
        else{
            return -1
        }
  };

  const getDocente= (ci) => {
        const docente = docentes.find(d => d.ci === `${ci}`);
        return docente ? docente : null;
    }
    const getSubdisciplinasDocente =(docente) => {
        if (docsubs && subdisciplinas) {
            const inscritas = docsubs.filter(docsub => docsub.iddoc === docente.id);
            const inscribedSubdisciplines = inscritas.map(ins => {
              return subdisciplinas.find(sub => sub.id === ins.idsub);
            }).filter(sub => sub !== undefined);
           
        return inscribedSubdisciplines
        }
    } 
  const handleCheckRequirements = (docente, subdisciplina, newSubdisciplinas,newDocsubs) => {
    const maxSub = eventoData.maxsub;

    const docenteSubdisciplines = getSubdisciplinasDocente(docente)
    const inscribedSubdisciplines = [...docenteSubdisciplines, ...newSubdisciplinas];

    const inscribedDisciplines =[...new Set(inscribedSubdisciplines.map(sub => sub.iddisc))];
    const subdisciplinaDisciplinaId = subdisciplina.iddisc;
    const inscribedInSameDisciplina = inscribedSubdisciplines.filter(sub => sub.iddisc === subdisciplinaDisciplinaId);

    const fullName = getSubdisciplinaFullName(subdisciplina)
    if (inscribedSubdisciplines.some(inscribed => inscribed.id === subdisciplina.id)) {
        return({checked:1, mensaje: `El docente ${docente.appat} ci=${docente.ci} ya está inscrito en la subdisciplina ${fullName}`});
    }

    if (inscribedDisciplines.length >= maxSub && !inscribedInSameDisciplina.length) {
        return({checked:0, mensaje: `El docente ${docente.appat} ci=${docente.ci} ya está inscrito en el N° máximo de disciplinas permitidas.`}); 
    }
    if(subdisciplina.tipo_participacion !== 'Postas'){
        const pruebasSubdisciplinas = inscribedInSameDisciplina.filter(sub => sub.tipo !== 'Postas');
        if (pruebasSubdisciplinas.length >= 3) {
            return({checked:0, mensaje: `El docente ${docente.appat} ci=${docente.ci} no puede inscribirse en más de 3 subdisciplinas por disciplina. (excluyendo Postas)`});
        }
    }else{
        const tienePosta = inscribedSubdisciplines.filter(sub => sub.tipo_participacion === 'Postas');

        if (tienePosta.length >= 1) {
            return({checked:0, mensaje: `El docente ${docente.appat} ci=${docente.ci} no puede inscribirse en más de una subdisciplina tipo Postas por disciplina.`});
        }
    }
   
    const allDocSubs = [...newDocsubs, ...docsubs]
    const inscritosPorUniversidad = allDocSubs.filter(ins => ins.idsub === subdisciplina.id && ins.iduniv === docente.iduniv).length;
   
    if (inscritosPorUniversidad >= subdisciplina.maxdep) {
        const diferencia =  inscritosPorUniversidad - subdisciplina.maxdep
        let inicioMensaje = "Ha llegado al limite "
        if(diferencia > 0) inicioMensaje = `Ha soprepasado por ${diferencia} el limite `

        return({checked:0, mensaje: `${inicioMensaje} de plazas disponibles en la subdisciplina ${fullName} para la universidad ${docente.Universidad.sigla}.`});
        
    }

    return({checked:2, mensaje: `El docente cumple con los requisitos para la subdisciplina ${fullName}.`});
};
    const handleFileChange = (e) => {
 
        const file = e.target.files[0];
        if (file) {
            const newDocsubs = []
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target.result;
                const workbook = XLSX.read(binaryStr, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const rawData = XLSX.utils.sheet_to_json(sheet, { raw: true });
                const uniqueErrors = new Set();
                const uniqueWarnings = new Set();
                const ciCounts = {};
                const data = rawData.map((row) => {
                const ci=row['CI'] || '';

                if (ci === '') {
                    uniqueErrors.add(erroresImportacion['e7']);
                    return null;  // Devolver null si el docente no existe
                }

                const docenteExiste = getDocente(ci)
    
                if (!docenteExiste) {
                    uniqueErrors.add(erroresImportacion['e6'] + ` CI: ${ci}`);
                    return null;  // Devolver null si el docente no existe
                }
                const docente = { ...docenteExiste, error: false };

                if (ciCounts[ci]) {
                    ciCounts[ci]++;
                    docente.error = true;
                } else {
                    ciCounts[ci] = 1;
                }

                    const disciplinas = [];
                    const categoria = genderMap[docente.genero] || "";
                    let error = false;

                    // if(categoria!== 'DAMAS' && categoria !== 'VARONES') {
                    //   uniqueErrors.add(erroresImportacion['e1']);
                    //   error = true;
                    // }else if(categoria&& docente.genero !== categoria[0]){
                    //    //console.log("")
                    //     uniqueErrors.add(`El docente ${docente.appat} con CI: ${ci} solo puede inscribirse en categoria: ${genderMap[docente.genero]}`);
                    // }

                    const edad = calculateAge(docente.fecnac, eventoData.fechaedad);
                    const edadYears = Number(edad.years);
                    let i = 1;
                    while (row[`DISCIPLINA ${i}`] && row[`MODALIDAD ${i}`]) {
                        const modalidad =  row[`MODALIDAD ${i}`].toUpperCase()
                        const nombre =  row[`DISCIPLINA ${i}`].toUpperCase()
                        if(modalidad !== 'LIBRE' && modalidad !== 'SENIOR' && modalidad !== 'MASTER') {
                           uniqueErrors.add(erroresImportacion['e2']);
                            error = true;
                        }else{
                            const edadMin = categoryMap[modalidad[0]].edadMin;                    
                            if (edadYears < edadMin) {
                                error = true;
                                uniqueErrors.add(`El docente ${docente.appat} con CI: ${ci} tiene ${edadYears}. No cuenta con la edad mínima de ${edadMin} años para la modalidad ${modalidad}`);
                            }
                        }
                        const subdis = getSubdisciplinaId(nombre, categoria, modalidad);
                        if(!subdis.id) {
                          if(subdis === -1 ) { uniqueErrors.add(erroresImportacion['e3']+` ${nombre}`);}
                          else{ uniqueErrors.add(erroresImportacion['e4']+` ${nombre} - ${modalidad} - ${categoria}`);}
                          error = true;
                        }else{
                            
                        const {checked, mensaje} = handleCheckRequirements(docente, subdis, disciplinas,newDocsubs)

                        if(checked === 2){
                            newDocsubs.push({iddoc:docente.id, idsub:subdis.id, estado:1, iduniv:universidadId})
                        }
                        else {uniqueErrors.add(mensaje);error = true;}

                        }

                        let disciplina = {
                            id:subdis.id,
                            nombre,
                            modalidad,
                            categoria,
                            error
                        };
                      
                        disciplinas.push(disciplina);
                        i++;
                    }
    
                    if(disciplinas.length === 0) uniqueWarnings.add(advertenciasImportacion['a1']+` CI:${docente.ci} ${docente.appat} ${docente.apmat} ${docente.nombres}`)
                     
                    return {
                        ci: row['CI'] || '',
                        appat: docente.appat,
                        apmat: docente.apmat,
                        nombres: docente.nombres,
                        disciplinas,
                        error:docente.error
                    };
                }
              ).filter(docente => docente !== null);
              
                 // Añadir advertencia por CIs duplicados
                 Object.keys(ciCounts).forEach(ci => {
                    if (ciCounts[ci] > 1) {
                        uniqueErrors.add(advertenciasImportacion['a2'] + ` ${ci}`);
                    }
                });

              setWarnings([...uniqueWarnings])
              setErrors([...uniqueErrors])
                setImportedData(data); // Guardar los datos importados en el estado
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const transformedData = importedData
            .filter(docente => docente.disciplinas.length > 0) // Filtrar docentes sin disciplinas
            .map((docente) => {
              const transformedDisciplinas = docente.disciplinas.map((disciplina) => ({
                idsub: disciplina.id,
                nombre:disciplina.nombre,
                genero:disciplina.categoria[0],
                categoria:disciplina.modalidad[0]
            }));
              return {
                iduniv:universidadId,
                ci: docente.ci,
                subdisciplinas: transformedDisciplinas,
              };
            });
         //console.log("Datos importados:", transformedData);
        dispatch(inscribirDocentesMasivo(transformedData))
            .then(data => {
                setWarnings([])
                toast.success("Docentes inscritos correctamente.");
                toast.success(data.mensaje);
                if (data.errores) {
                  const errores = data.errores.map(error => `Error: ${error.errores[0]}`);
                  setErrors(errores);
                } else {
                  setErrors([])
                  setImportedData([]); // Clear imported data after successful submission
              }
            })
            .catch(error => {
                toast.error(error.mensaje);
                const errores = error.errores ? error.errores.map(err => `Error: ${err.errores[0]}`) : [];
                setErrors(errores);
            });
    };

    return (
        <>
        <p style={{color:'blue'}}><strong>REVISA LA IMAGEN DE EJEMPLO</strong>. De acuerdo al CI (Cédula de identidad) del docente, se guardará la DISCIPLINA y MODALIDAD que se desea inscribir.
        </p><p>Para cada inscripción a una disciplina, se debe indicar su modalidad de la siguiente manera: DISICPLINA 1 - MODALIDAD 1, DISICPLINA 2 - MODALIDAD 2, ...     </p>
              <div className="img-excel-container">
                    <img src={ejemploExcel} className="img-excel" alt="Example" />
            </div> 
    <Form onSubmit={handleSubmit}>
                <Form.Control
                    type="file"
                    name="importedData"
                    accept=".xlsx"
                    onChange={handleFileChange}
                />
                 {errors.length === 0 && (
                    <Button variant="info" className="float-end mt-3 mb-3" type="submit">Crear</Button>
                 )}
                </Form>
            
            {errors.sort().length > 0 && (
                <Alert variant="danger" className="mt-3">
                    <ul>
                        <p>REVISE SU ARCHIVO EXCEL Y REALICE LAS CORRECCIONES</p>
                        {errors.map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </Alert>
            )}  
            {warnings.sort().length > 0 && (
               <div style={{color:"orange", border:"2px solid light-orange", marginTop:"10px"}}>
                    <ul>
                        {warnings.map((warning, index) => (
                            <li key={index}>{warning}</li>
                        ))}
                    </ul>
                </div>
            )}
            {importedData.length > 0 && (
                <Table striped bordered hover className="mt-3">
                    <thead>
                        <tr>
                            <th>CI</th>
                            <th>Nombre Completo</th>
                            <th>Disciplinas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {importedData.map((docente, index) =>
                 
                        {   const colorText=docente.disciplinas.length === 0? "orange":(docente.error? "DarkRed":"black");
                            return (
                            <tr key={index}>
                                <td  style={{color:colorText}} >{docente.ci}</td>
                                <td  style={{color:colorText}} > {`${docente.appat} ${docente.apmat} ${docente.nombres}`}</td>
                                <td  style={{color:colorText}} >
                                    {docente.disciplinas.map((disciplina, idx) => {
                                       return(
                                        <React.Fragment key={idx}>
                                         {(disciplina.error) ?
                                            <div style={{color:"red"}}> 
                                                {getSubdisciplinaFullName(disciplina)}
                                            </div>
                                           : (  <div>
                                                     {getSubdisciplinaFullName(disciplina)}
                                                </div>)
                                          }
                                        </React.Fragment>
                                    )})}
                                </td>
                            </tr>
                        )})}
                    </tbody>
                </Table>
            )}
        </>
    );
};

export default ImportarExcelInscripciones;