// src/reducers/paisReducer.js

const initialState = {
    paises: [],
    pais: null,
  };
  
  const paisReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_PAISES':
        return { ...state, paises: action.payload };
      case 'GET_PAIS_BY_ID':
        return { ...state, pais: action.payload };
      case 'CREATE_PAIS':
        return { ...state, paises: [...state.paises, action.payload] };
      case 'UPDATE_PAIS':
        return {
          ...state,
          paises: state.paises.map(p =>
            p.id === action.payload.id ? action.payload : p
          ),
        };
      case 'DELETE_PAIS':
        return {
          ...state,
          paises: state.paises.filter(p => p.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default paisReducer;
  
  
  