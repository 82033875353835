import { toast } from 'react-toastify';
import apiService from '../services/apiService';
import { recepcionActions } from '../reducers/recepcion_slice'; // Ajusta la ruta según la ubicación de tu slice


// Crear una instancia de servicio API específica para las recepciones
const recepcionService = apiService('/recepciones');

// Acción para verificar o crear una recepción
export const verificarOCrearRecepcion = (ci, fecha, hora) => async (dispatch) => {
  try {
    // Realizar la solicitud POST a la ruta específica
    const response = await recepcionService.createByTipo('verificar-o-crear', { ci, fecha, hora }); // Ajustar el método para utilizar la ruta correcta

    return response; // Devolver la respuesta para ser utilizada en el componente
  } catch (error) {
    console.error('Error al registrar la recepción:', error);
    toast.error('Error al registrar la recepción.');
  }
};

// Otras acciones CRUD (si son necesarias) pueden implementarse de manera similar
export const fetchRecepciones = () => async dispatch => {
  try {
    const data = await recepcionService.getAll();
    dispatch(recepcionActions.setRecepciones(data));
    return data;
  } catch (error) {
    toast.error('Error al cargar la lista de recepciones.');
  }
};

export const fetchRecepcionById = (id) => async dispatch => {
  try {
    const data = await recepcionService.getById(id);
    dispatch(recepcionActions.setRecepcion(data));
    return data;
  } catch (error) {
    toast.error('Error al cargar los datos de la recepción.');
  }
};

export const updateRecepcion = (id, recepcion) => async dispatch => {
  try {
    const data = await recepcionService.update(id, recepcion);
    toast.success('Recepción actualizada correctamente.');
    dispatch(recepcionActions.updateRecepcion({ id, changes: data })); // Asumiendo que hay una acción updateRecepcion en el slice
  } catch (error) {
    toast.error('Error al actualizar la recepción.');
  }
};

export const removeRecepcion = (id) => async dispatch => {
  try {
    await recepcionService.remove(id);
    toast.success('Recepción eliminada exitosamente.');
    dispatch(recepcionActions.removeRecepcion(id)); // Asumiendo que hay una acción removeRecepcion en el slice
  } catch (error) {
    toast.error('Error al eliminar la recepción.');
  }
};

export const reiniciarRecepcion = () => async dispatch => {
  try {
    await recepcionService.removeAll('reiniciar');
    toast.success('Recepción reiniciada exitosamente.');
  } catch (error) {
    toast.error('Error al reiniciar la recepción.');
  }
};
