// src/reducers/fixturedet_slice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fixturedets: [],
  fixturedet: null,
};

const fixturedetSlice = createSlice({
  name: 'fixturedet',
  initialState,
  reducers: {
    setFixturedets(state, action) {
      state.fixturedets = action.payload;
    },
    setFixturedet(state, action) {
      state.fixturedet = action.payload;
    },
    clearFixturedet(state) {
      state.fixturedet = null;
    },
  },
});

export const fixturedetActions = fixturedetSlice.actions;
export default fixturedetSlice.reducer;

