import { Button } from "react-bootstrap";

const CrearButton = ({ children, onHandleCreate, className='' }) => {
  let classes='mb-3';
  if(className){ classes += ' '+ className}
  return (
        <Button
        variant="info"
        onClick={onHandleCreate}
        className={classes}
      >
        <i className="bi bi-plus-lg"></i> {children}
      </Button>
    )
}
export default CrearButton;