const initialState = {
    rolproc: [],
    loading: false,
    error: null,
  };
  
  const rolprocReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ROL_PROCESOS':
        return { ...state, rolproc: action.payload };
      case 'DELETE_ROL_PROCESOS':
        return { ...state, rolproc: state.rolproc.filter(rp => rp.idrol !== action.payload) };
      case 'SAVE_ROL_PROCESOS':
        return { ...state, rolproc: [...state.rolproc, action.payload] };
      default:
        return state;
    }
  };
  
  export default rolprocReducer;
  
  