import { useDispatch } from "react-redux";
import { fetchUniversidades } from "../../actions/universidadAction";
import { useEffect, useState } from "react";
import { Button, Form, Col, Row, Modal, Image } from "react-bootstrap";
import { addUsuario, addUsuarioTipoAdministrativo, fetchDocenteDataByCi, fetchUsuarios, modifyUsuario, modifyUsuarioTipoAdministrativo } from "../../actions/usuarioActions";
import { getUploadUrl } from "../../util/valueCalculator";
import { useInputFile } from "../../hooks/useInputImage";
import { toast } from "react-toastify";

const initialState = {
  nombres: "",
  appat: "",
  apmat: "",
  ci: "",
  iduniv: "",
  iddoc: "",
  fecnac: "1930-01-01",
  tipo: "administrativo",
  foto1: "",
  relato: "",
  genero: "O",
  login: "",
  contrasena: "",
  estado: 1
  }
const FormUsuario = ({ currentUsuario = null, showModal, handleClose }) => {
  const [datos, setDatos] = useState(initialState);
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();
  const [ isSending, setIsSending ] = useState(false)
  const dispatch = useDispatch();
  const [universidades, setUniversidades] = useState([]);
  const [ci, setCi] = useState('');
  const [docenteVerified, setDocenteVerified] = useState(false);
  const [personaExistente, setPersonaExistente] = useState(false);
  useEffect(() => {
    if (currentUsuario) {
      if(currentUsuario.persona){
        if(currentUsuario.persona.tipo === "docente") setDocenteVerified(true);
        const { responsabilidades, persona } = currentUsuario;
        setDatos({ ...persona, 
            login: currentUsuario.login, 
            estado:currentUsuario.estado, 
            contrasena:"",
            iddoc: currentUsuario.iddoc });
    }else{
      setDatos({...initialState, login:currentUsuario.login,estado:currentUsuario.estado})
      setDocenteVerified(false);
      
    }
  }
  }, [currentUsuario]);

  useEffect(() => {
    const fetchAndSetUniversidades = async () => {
      const universidadesFetched = await dispatch(fetchUniversidades());
      if (universidadesFetched) {
        setUniversidades(universidadesFetched);
      }
    };
    fetchAndSetUniversidades();
  }, [dispatch]);

 

  function handleInputChange(event) {
    const { name, value } = event.target;
    if(name === "tipo") resetForm()
    setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
  }

  const handleVerifyDocente = async () => {
    const docente = await dispatch(fetchDocenteDataByCi(ci));
    if (!docente.message) {
      setDocenteVerified(true);
      const {id, representante, ...docenteInfo} = docente
      setDatos({ ...docenteInfo, 
          login: `${docente.appat.toLowerCase()}${ci}`, 
          estado: 1, 
          contrasena:ci,
          iddoc: docente.id });
      toast.success('Persona verificada exitosamente');
    } else {
      if(docente.message.includes("Ya existe un usuario")) setCi("")
      resetForm()
      toast.error(docente.message);
    }
  };
  
    // Función para manejar el cambio de estado del checkbox
    const handleCheckboxChange = (event) => {
      resetForm()
      setPersonaExistente(event.target.checked);
    };
  function handleSubmit(e) {
    e.preventDefault();
    
    setIsSending(true)
    const fd = new FormData();
console.log("Datos", datos)
    if(personaExistente && docenteVerified){
      // Agregar los datos de 'datos' al FormData
      fd.set("login", datos.login);
      fd.set("contrasena", datos.contrasena);
      fd.set("estado", datos.estado);
      fd.set("iddoc", datos.iddoc);

     
          if (currentUsuario) {
            dispatch(modifyUsuario(currentUsuario.id, fd)).then(() => {
              setIsSending(false)
              closeForm();
              dispatch(fetchUsuarios())
                 }) .catch(errores => {
                  setIsSending(false)
                  for(const err of errores){
                    toast.error(err)
                  }
                });
          } else{
            dispatch(addUsuario(fd)).then(() => {
              setIsSending(false)
              closeForm();
              dispatch(fetchUsuarios())
                 }) .catch(errores => {
                  setIsSending(false)
                  for(const err of errores){
                    toast.error(err)
                  }
                });
          }
    }
    else
    if(datos.tipo === "administrativo"){
    // Agregar los datos de 'datos' al FormData
    for (const key in datos) {
      if (datos.hasOwnProperty(key) && key !== "foto1") {
        fd.set(key, datos[key]);
      }
    }
    fd.set("nombres", datos.nombres.trim().toUpperCase());
    fd.set("appat", datos.appat.trim().toUpperCase());
    fd.set("apmat", datos.apmat.trim().toUpperCase());  
    fd.set("ci", datos.ci.trim());  
    // Agregar la foto si está presente
    if (foto1) {
      fd.append("foto1", foto1);
    } else if (datos.foto1) {
      fd.append("foto1", datos.foto1);
    }

    // // Para debug: mostrar el contenido del FormData en la consola
    // for (const pair of fd.entries()) {
    // //  console.log(`${pair[0]}: ${pair[1]}`);
    // }

    if (currentUsuario) {
      dispatch(modifyUsuarioTipoAdministrativo(currentUsuario.id, fd)).then(() => {
        setIsSending(false)
        closeForm();
        dispatch(fetchUsuarios())
        })
        .catch(errores => {
          setIsSending(false)
          for(const err of errores){
            toast.error(err)
          }
        });
    } else{
      dispatch(addUsuarioTipoAdministrativo(fd)).then(() => {
        setIsSending(false)
        closeForm();
        dispatch(fetchUsuarios())
           })
           .catch(errores => {
            setIsSending(false)
            for(const err of errores){
              toast.error(err)
            }
          });;
    }
   }else{
    setIsSending(false)
    toast.error("No puede editar los datos de un docente en este formulario.")}
  }

  const resetForm = () => {
    setDocenteVerified(false);
    resetFoto1();
    setDatos(prev => ({
      ...prev,
        nombres: "",
        appat: "",
        apmat: "",
        ci: "",
        iduniv: "",
        fecnac: "1930-01-01",
        foto1: "",
        relato: "",
        genero: "O",
        login: "",
        contrasena: "",
        estado: 1
        }))
  }
  const closeForm = () => {
    handleClose();
    resetForm();
    setDatos(prev => ({
      ...prev,
        tipo: "administrativo"
        }))
  }
 const actualFoto1Url = currentUsuario && currentUsuario.persona ? getUploadUrl(currentUsuario.persona.foto1) : null;
  const isDisabled = docenteVerified || isSending || personaExistente
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeForm}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {currentUsuario ? "Editar Usuario" : "Nuevo Usuario"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <Form
                onSubmit={handleSubmit}
                className="modal-oval"
              >   
              <Form.Label className="mb-4">DATOS PERSONALES</Form.Label>
              <Form.Group controlId="formCiDocente" className="row">
             
              {/* <div className="col-md-3 modal-input">
                    <Form.Label>Tipo de usuario</Form.Label>
                    <Form.Control
                      as="select"
                      value={datos.tipo}
                      name="tipo"
                      onChange={handleInputChange}
                      required
                      disabled={!!currentUsuario}
                    >
                       <option value="administrativo">Administrativo</option>
                       <option value="docente">Docente</option>
                    </Form.Control>
                  </div> */}
                  {!currentUsuario &&(
              
              <>
                  <div className="col-md-3 modal-input">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id="default-checkbox"
                      label="Persona existente"
                      checked={personaExistente} // Mantener el estado sincronizado
                      onChange={handleCheckboxChange} // Actualizar el estado cuando cambie
                    />
                  </div>
                {personaExistente && 
                <>
                <div className="col-md-3 modal-input">
                <Form.Label>CI de la persona</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CI de la persona"
                  value={ci}
                  onChange={(e) => setCi(e.target.value)}
                  required={personaExistente}
                />
                </div>
                <div className="col-md-3 modal-input">
                
                  <Button variant="secondary" onClick={handleVerifyDocente} className="mt-4">
                    Verificar si existe
                  </Button>
                </div>
                </>
            }  
              </>
            )}  
          </Form.Group>
               <Form.Group className="row">
                  <div className="col-md-4 modal-input">
                    <Form.Label>Nombres</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombres"
                      placeholder="Nombre(s)"
                      value={datos.nombres}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-4 modal-input">
                    <Form.Label>Apellido paterno</Form.Label>
                    <Form.Control
                      type="text"
                      name="appat"
                      placeholder="Primer apellido"
                      value={datos.appat}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-4 modal-input">
                    <Form.Label>Apellido materno</Form.Label>
                    <Form.Control
                      type="text"
                      name="apmat"
                      placeholder="Segundo apellido"
                      value={datos.apmat}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                <div className="col-md-4 modal-input">
                    <Form.Label>C.I.</Form.Label>
                    <Form.Control
                      type="text"
                      name="ci"
                      placeholder="N° Cédula de Identidad"
                      value={datos.ci}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-8 modal-input">
                    <Form.Label>Universidad</Form.Label>
                    <Form.Control
                      as="select"
                      name="iduniv"
                      value={datos.iduniv}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    >
                          <option
                            key="default"
                            value=""
                            disabled
                            >
                            - Seleccionar universidad -
                          </option>
                      {universidades.map((uni) => (
                        <option key={uni.id} value={uni.id}>{uni.nombre}</option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
                <Form.Group className="row">
                  <div className="col-md-6 modal-input">
                    <Form.Label>Foto</Form.Label>
                      <Form.Control
                        type="file"
                        isInvalid={!isFoto1Valid}
                        onChange={(e) => handleFoto1Change(e.target.files[0])}
                        disabled={isDisabled}
                      />
                      <Form.Control.Feedback type="invalid">
                            Por favor selecciona una imagen válida.
                      </Form.Control.Feedback>
                      {(foto1 && isFoto1Valid) && (
                          <img
                          className="img-fluid img-cuadrada-150 mb-2 mt-3"
                          src={URL.createObjectURL(foto1)}
                          />
                      )}
                  </div>
                  <div className="col-md-6 modal-input" style={{display:"flex", flexDirection:"column"}}>
                  {actualFoto1Url && (
                      <>
                      <Form.Label>Foto actual</Form.Label>
                      <Image 
                          className="img-fluid img-cuadrada-150 mb-2"
                          src={actualFoto1Url}
                      />
                      </>
                  )}
                  </div>
                  <Col
              md={4}
              className="d-flex flex-column align-items-center"
            >
            </Col>
                </Form.Group>
                <Form.Label className="mb-4 mt-4">
                  DATOS DE INICIO DE SESIÓN
                </Form.Label>
                <Form.Group className="row">
                  <div className="col-md-4 modal-input">
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control
                      type="text"
                      name="login"
                      placeholder="Nombre de usuario"
                      value={datos.login}
                      onChange={handleInputChange}
                      required
                      disabled={isSending}
                    />
                  </div>
                  <div className="col-md-4 modal-input">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      name="contrasena"
                      placeholder="Nueva contraseña"
                      value={datos.contrasena}
                      onChange={handleInputChange}
                      required={!currentUsuario? true : false}
                      disabled={isSending}
                    />
                  </div>
                  <div className="col-md-4 modal-input">
                    <Form.Label>Estado</Form.Label>
                    <Form.Control
                      as="select"
                      name="estado"
                      value={datos.estado}
                      onChange={handleInputChange}
                      disabled={isSending}
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </Form.Control>
                  </div>
                </Form.Group>

                <Button
                  variant={currentUsuario ? "warning" : "info"}
                  type="submit"
                  className="btn-modal"
                >
                  {currentUsuario ? "Actualizar" : "Crear"}
                </Button>
                <Button
                  variant="secondary"
                  onClick={closeForm}
                  className="btn-modal"
                >
                  Cancelar
                </Button>
              </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FormUsuario;
