// src/actions/ciudadActions.js

import { universidadActions } from '../reducers/universidad_slice';
import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const universidadesService = apiService('/universidades');

export const fetchUniversidades = () => async dispatch => {
  try {
    const data = await universidadesService.getAll()
    dispatch(universidadActions.setUniversidades(data));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de universidades.`);
  }
};
export const fetchUniversidadesBySubdis = (idsub) => async dispatch => {
  try {
      const data = await universidadesService.getAllBySomeId('subdisciplina', idsub);
      dispatch(universidadActions.setSubdisciplinas(data))
      return data;
  } catch (error) {
      toast.error(`Error al obtener universidades de la subdisciplina`);
  }
};

export const fetchUniversidadById = (id) => async dispatch => {
  try {
    const data = await universidadesService.getById(id)
    dispatch(universidadActions.setCurrentUniversidad(data));
    } catch (error) {
      toast.error(`Error al cargar los datos de la universidad.`);
 
  }
};
export const fetchUniversidadBySigla = (sigla) => async dispatch => {
  try {
    const data = await universidadesService.getBySomeAttribute('sigla',sigla)
    // dispatch(universidadActions.setCurrentUniversidad(data));
    return data;
    } catch (error) {
      toast.error(`Error al cargar los datos de la universidad.`);
  }
};

export const addUniversidad = (universidad) => async dispatch => {
  try {
    const data = await universidadesService.create(universidad);
    toast.success(`Universidad creada con éxito.`);
  } catch (error) {
      toast.error(`Error al crear nueva universidad.`);
 
  }
};

export const updateUniversidad = (id, universidad) => async dispatch => {
  try {
    const data = await universidadesService.update(id, universidad);
    toast.success(`Universidad editada con éxito.`);
   } catch (error) {
    toast.error(`Error al editar universidad.`);
 
  }
};

export const removeUniversidad= (id) => async dispatch => {
  try {
    const data = await universidadesService.remove(id);
    toast.success(`Universidad eliminada con éxito.`);
     return data;
  } catch (error) {
    toast.error(`Error al eliminar universidad.`);
    toast.error(`Hay docentes u otros elementos que dependen de la universidad.`);
   }
};

export const updateRepresentantes = (iduniv, body) => async dispatch => {
  try {
   
    const data = await universidadesService.updateSomeDato('representantes',iduniv, body);
    toast.success(`Representantes de la universidad designados con éxito`);
    return data;
     }  catch (error) {
      toast.error(`Error al designar nuevo representante de la universidad`);
    }
};

export const updateSubdisciplinasUniversidad = (iduniv, body) => async dispatch => {
  try {
   
    const data = await universidadesService.updateSomeDato('subdisciplinas',iduniv, body);
    toast.success(`Subdisciplinas de la universidad inscritas con éxito`);
    return data;
     }  catch (error) {
      toast.error(`Error al inscribir subdisciplinas de la universidad`);
    }
};

export const getSubdisciplinasByUniversidad = (iduniv) => async dispatch => {
  try {//console.log("Entro al action para cargar subdisciplinas con ", iduniv)
      const data = await universidadesService.getAllBySomeId('getsub', iduniv);
      dispatch(universidadActions.setSubdisciplinas(data))
      return data;
  } catch (error) {
      toast.error(`Error al obtener subdisciplinas de la universidad`);
  }
};