import React from 'react';
import { genderMap, getModalidadByEdad, tipo2Persona } from '../../util/valueCalculator';

const ItemPersona = ({ persona, indice,filters}) => {
   const tipo1 = persona.tipo === "docente" && persona.estado === 3? "DEPORTISTA" : "";
    const tipo2 =  persona.estado2 === 1 && tipo2Persona(persona.tipo2)
    return (
        <tr>
            <td>{indice}</td>
            <td>{persona.appat}</td>
            <td>{persona.apmat}</td>
            <td>{persona.nombres}</td>
            <td>{persona.ci}</td>
            <td>{persona.Universidad.sigla}</td>
            <td>{tipo1}{tipo1 && tipo2? "; ":""} {tipo2}</td>
           {filters.tipo === "docente" && 
           <>
            {/* <td>{genderMap[persona.genero]}</td> */}
            <td>{getModalidadByEdad(persona.edad)}</td>
            </>}
        </tr>
    );
}

export default ItemPersona;
