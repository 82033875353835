import createAxiosInstance from "../util/axiosInstance";

const apiService = (path) => {
  const api = createAxiosInstance(path);

  return {
    getAll: async () => {
      const response = await api.get('/');
      return response.data;
    },
    getById: async (id) => {
      const response = await api.get(`/${id}`);
      return response.data;
    },
    create: async (data) => {
      const response = await api.post('/', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    },

    update: async (id, data) => {
      const response = await api.put(`/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
    remove: async (id) => {
      const response = await api.delete(`/${id}`);
      return response.data;
    },
    removeAll: async (some) => {
      const response = await api.delete(`/${some}`);
      return response.data;
    },
    getAllBySomeId: async (some, id) => {
      const response = await api.get(`/${some}/${id}`);
      return response.data;
    },
    getAllBySomeIdAndRol: async (some, id, rol) => {
      const response = await api.get(`/${some}/${rol}/${id}`);
      return response.data;
    },
    getBySomeAttribute: async (some, attribute) => {
      const response = await api.get(`/${some}/${attribute}`);
      return response.data;
    },
    updateSomeDato: async (some, id, data) => {
      const response = await api.put(`/${some}/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    },
    createByTipo: async (tipo, data) => {
      const response = await api.post(`/${tipo}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    },
    // Nueva función para realizar el backup
    backupDatabase: async (data) => {
      const response = await api.post('/backup', data);
      return response.data;
    }
  };
};

export default apiService;
