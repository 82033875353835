// src/services/paisService.js

import createAxiosInstance from "../util/axiosInstance";


const api = createAxiosInstance('/paises');

export const getPaises = async () => {
  const response = await api.get('/');
  return response.data;
};

export const getPaisById = async (id) => {
  const response = await api.get(`/${id}`);
 return response.data;
};

export const createPais = async (pais) => {
  const response = await api.post('/', pais);
  return response.data;
};

export const updatePais = async (id, pais) => {
  const response = await api.put(`/${id}`, pais);
 return response.data;
};

export const deletePais = async (id) => {
  const response = await api.delete(`/${id}`);
  return response.data;
};
