import { useDispatch } from "react-redux";
import { sesionActions } from "../../reducers/session_slice";
import { actionLogout } from "../../util/auth";
import { useNavigate } from "react-router-dom";

const LogoutButton = ({...props}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  function handleLogout() {
    dispatch(sesionActions.logout());
    navigate('/');
}
  return (
    <button
      onClick={handleLogout}
      {...props}
    >
      Cerrar sesión
    </button>
  );
};
export default LogoutButton;
