import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchFixturedetsByFixtureId } from "../../actions/fixturedetActions";
import { fetchPredios } from "../../actions/predioActions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function formatear(fecha) {
  if (fecha) {
    const fec = fecha.split("T")[0];
    const [year, month, day] = fec.split("-");
    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
}

const FixtureDetailsView = ({ fixture }) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const fixturedets = useSelector((state) => state.fixturedet.fixturedets);
  const predios = useSelector((state) => state.predio.predios);

  useEffect(() => {
    if (fixture) {
      dispatch(fetchFixturedetsByFixtureId(fixture.id));
      dispatch(fetchPredios());
    }
  }, [dispatch, fixture]);

  useEffect(() => {
    if (fixturedets) {
      const formattedFixturedets = fixturedets.map(fixtureDet => ({
        ...fixtureDet,
        fecha: fixtureDet.fecha ? fixtureDet.fecha.split("T")[0] : "",
        hora: fixtureDet.hora ? fixtureDet.hora : fixture.hora,
      }));
      setRows(formattedFixturedets.sort((a, b) => a.hora.localeCompare(b.hora)));
    }
  }, [fixturedets, fixture]);

  const getPredioName = (idpredio) => {
    const predio = predios.find(pred => pred.id === idpredio);
    return predio ? predio.nombre : "Desconocido";
  };

  const renderRow = (row, index) => (
    <tr key={index} style={{ fontSize: '12px' }}>
      <td>{formatear(row.fecha)}</td>
      <td>{row.hora}</td>
      <td>{row.descripcion}</td>
      <td>{getPredioName(row.idpredio)}</td>
      <td>{row.sigla1}</td>
      <td>{row.sigla2}</td>
      <td>{row.comentario}</td>
    </tr>
  );

  return (
    <div className="contenedor-wide">
      <Table striped bordered hover>
        <tbody>
          {rows.map((row, index) => renderRow(row, index))}
        </tbody>
      </Table>
    </div>
  );
};

export default FixtureDetailsView;
