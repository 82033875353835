import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Table, Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocentesByUniversidad } from "../../actions/docenteActions";
import { fetchDocsubByUniversidad } from "../../actions/docsubActions";
import { fetchDisciplinas } from "../../actions/disciplinaActions";
import { fetchSubdisciplinas } from "../../actions/subdisciplinaActions";
import CrearButton from "../generales/CrearButton";
import { docenteActions } from "../../reducers/docente_slice";
import DocenteForm from "./FormDocente";
import DeleteDocente from "./DeleteDocente";
import ItemDocente from "./ItemDocente";
import PerfilDocente from "./PerfilDocente";
import InscripcionDocente from "./InscripcionDocente";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { fetchUniversidadBySigla } from "../../actions/universidadAction";
import ImportarExcelDocentes from "./ImportarExcelDocentes.js";
import ImportarExcelInscripciones from "./ImportarExcelnscripciones.js";
import PanelOpciones from "./PanelOpciones.js";
import excelIcono from '../../assets/excel-icono.png'
import { calculateAge, estadoDocente, getModalidadByEdad } from "../../util/valueCalculator.js";
import MensajeCentral from "../mensajes/MensajeCentral.js";
const ListDocente = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { titulo, funcionalidad } = useParams();
  const universidadSigla = useSelector(state => state.sesion.universidadSigla);
  const universidadParam = location.state ? location.state.universidad : null;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({estado:"", modalidad:""});
  const docentes = useSelector((state) => state.docente.docentes);
  const [sort, setSort] = useState({ by: "appat", order: "asc" });
  const [modal, setModal] = useState({ tipo: 0, titulo: "", body: <></> });
  const [panelOpcionesModal, setPanelOpcionesModal] = useState({show:false, funcionalidad:""});
  const [searchTerm, setSearchTerm] = useState("");
  const evento= useSelector(state => state.evento)
  const estadoEvento= evento.estado
  const rolActivo = useSelector(state => state.sesion.rolActivo);
 
  const [universidad, setUniversidad] = useState(null);

  useEffect(() => {
    if (universidadParam) {
      setUniversidad(universidadParam);
    } else if (universidadSigla) {
      dispatch(fetchUniversidadBySigla(universidadSigla))
        .then((data) => { setUniversidad(data) });
    }
  }, [dispatch, universidadSigla, universidadParam]);

  useEffect(() => {
    if (universidad) {
      dispatch(fetchDocentesByUniversidad(universidad.id));
      dispatch(fetchDisciplinas());
      dispatch(fetchSubdisciplinas());
      dispatch(fetchDocsubByUniversidad(universidad.id));
    }
  }, [dispatch, universidad]);

  const sortedList = useMemo(() => {
    return [...docentes].sort((a, b) => {
      if (sort.order === "asc") {
        return a[sort.by] > b[sort.by] ? 1 : -1;
      }
      return a[sort.by] < b[sort.by] ? -1 : 1;
    });
  }, [docentes, sort]);

  const debouncedSearchTerm = useMemo(
    () => debounce((value) => setSearchTerm(value), 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  const funcionalidadNum = Number(funcionalidad);

  const funcionalidadesInscripciones = funcionalidadNum === 1 || funcionalidadNum === 2

  const searchedList = useMemo(() => {
    return sortedList.filter(
      (docente) =>
        docente.appat.toLowerCase().includes(searchTerm.toLowerCase()) ||
        docente.ci.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [sortedList, searchTerm]);

  const filteredList = useMemo(()=>{
    return searchedList.filter(docente => {
      const edad = calculateAge(docente.fecnac, evento.fechaedad).years
      const modalidad = getModalidadByEdad(edad)
      return ((funcionalidadesInscripciones? docente.estado>0 : true)
      && (filters.estado !== "" ? `${docente.estado}` === filters.estado : true)
      && (filters.modalidad !== "" ? `${modalidad}` === filters.modalidad : true))}
     )
  },[searchedList,funcionalidadesInscripciones,filters])

  const handleFilters = (filter, value) => {
      setFilters((prev) => ({ ...prev, [filter]: value }));
  };

  const handleShowModal = useCallback(
    (tipo, docente) => {
      dispatch(docenteActions.setCurrentDocente(docente));
      let modalTitulo = "";
      let body = <></>;
      if (tipo === 1) {
        modalTitulo = "Nuevo Docente";
        body = (
          <DocenteForm
            universidad={universidad}
            onHandleClose={handleClose}
            funcionalidad={1}
            titulo="Nuevo Docente"
          />
        );
      } else if (tipo === 2) {
        modalTitulo = "Editar Docente";
        body = (
          <DocenteForm
            universidad={universidad}
            onHandleClose={handleClose}
            funcionalidad={2}
            titulo="Editar Docente"
          />
        );
      } else if (tipo === 3) {
        modalTitulo = "Confirmar eliminación";
        body = <DeleteDocente onHandleClose={handleClose} />;
      } else if (tipo === 4) {
        modalTitulo = "Perfil del docente";
        body = <PerfilDocente />;
      } else if (tipo === 5) {
        modalTitulo = `Inscripción del docente`;
        body = (
          <InscripcionDocente
            docente={docente}
            onRequestClose={() => {
              handleClose();
              refreshDocsubData();
            }}
            titulo={titulo}
            funcionalidad={funcionalidad}
          />
        );
      } else if (tipo === 6) {
        modalTitulo = "Importar datos de docentes";
        body = <ImportarExcelDocentes universidadId={universidad.id} onFinish={() => {
          handleClose();
          refreshDocsubData();
        }}/>;
      } else if (tipo === 7) {
        modalTitulo = "Importar datos de docentes";
       
        body = <ImportarExcelInscripciones universidadId={universidad.id} onFinish={() => {
          handleClose();
          refreshDocsubData();
        }}/>;
      }
      setModal({ tipo, titulo: modalTitulo, body });
    },
    [dispatch, universidad, funcionalidad]
  );

  const handleClose = useCallback(() => {
    dispatch(docenteActions.setCurrentDocente(null));
    setModal({ tipo: 0, titulo: "", body: <></> });
  }, [dispatch]);

  const refreshDocsubData = useCallback(() => {
    dispatch(fetchDocentesByUniversidad(universidad.id));
    dispatch(fetchDocsubByUniversidad(universidad.id));
  }, [dispatch, universidad]);

  const handleSort = (e) => {
    const { name, value } = e.target;
    setSort((prevSort) => ({ ...prevSort, [name]: value }));
  };

  const handleSearchChange = (e) => {
    debouncedSearchTerm(e.target.value);
  };

  const handleShowPanelOpcionesModal = (funcionalidad) => {
    setPanelOpcionesModal({show:true, funcionalidad});
  };
  
  const handleClosePanel = () => {
    setPanelOpcionesModal({show:false, funcionalidad:""});
  };
  if (estadoEvento < 1 && (funcionalidadNum === 1 || funcionalidadNum === 2)) {
    return (
      <MensajeCentral accionBloqueada="inscripcionDocente" accionDesbloqueadora="preinscripcionUniversidades"/>
    );
  }
  return (
    <>
      <div className="container-large">
        <h1>{titulo? titulo: "INSCRIPCIONES"} - {universidad ? universidad.nombre : (rolActivo ==="enca_universidad"? universidadSigla:"")}</h1>
        <div className="row mb-4" style={{fontSize:"0.9rem"}}>
            <div className="col-6 col-md-3">
              <Form.Label className="mt-2">Buscar:</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                placeholder="Buscar por apellido o CI"
                onChange={handleSearchChange}
                className="mr-3"
              />
            </div>
            <div className="col-6 col-md-3">
              <Form.Label className="mt-2">Ordenar por:</Form.Label>
              <div className="d-flex flex-column">
                <Form.Control
                  as="select"
                  name="by"
                   size="sm"
                  value={sort.by}
                  onChange={handleSort}
                  className="mb-2"
                >
                  <option value="ci">CI</option>
                  <option value="appat">Apellido</option>
                </Form.Control>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <Form.Label className="mr-2">Filtrar por estado:</Form.Label>
              <Form.Control
                as="select"
                 size="sm"
                value={filters.estado}
                onChange={(e) => handleFilters("estado", e.target.value)}
                className="mr-2"
              >
                <option value="">Seleccionar estado</option>
                {[1, 2, 3].filter(es=> funcionalidadNum!== 3? es!==0: true).map(estado => (
                  <option key={estado} value={estado}>{`${estadoDocente(estado).estadoName}`}</option>
                ))}
              </Form.Control>
            </div>
            <div className="col-6 col-md-2 mt-2">
          <Form.Label>Modalidad:</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={filters.modalidad}
            onChange={(e) => handleFilters("modalidad", e.target.value)}
            className="mr-2"
          >
            <option value="">- Todo -</option>
            {["LIBRE","SENIOR","MASTER"].map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </Form.Control>
        </div>
            {funcionalidadNum === 3 && (
              <div className="col-md-3 col-5 d-flex flex-column mt-4 justify-content-end">
                <CrearButton onHandleCreate={() => handleShowModal(1, null)}>
                  Nuevo Docente
                </CrearButton>
              </div>
            )}
          </div>
        <div className="container">
  <div className="row mt-3 mb-4">
    <div className="col-3 col-md-2 mb-2 mb-md-0">
      <Button
      size="sm"
        variant="light"
        onClick={() => navigate(-1)}
        style={{ width: "100%" }} // Para asegurarse de que ocupa el ancho completo en pantallas pequeñas
      >
         <i className="bi bi-arrow-left"></i>  Volver
      </Button>
    </div>
    <div className="col-12 col-md-10 d-flex flex-wrap justify-content-md-end">
      <Button
      size="sm"
        variant="outline-success"
        onClick={() => handleShowPanelOpcionesModal("exportar")}
        className="action-button ml-md-2 mb-2 mb-md-0"
      >
        <img src={excelIcono} alt="excel" className="icon-class" /> Exportar a Excel
      </Button>
      <Button
      size="sm"
        variant="outline-dark"
        onClick={() => handleShowPanelOpcionesModal("imprimir")}
        className="action-button ml-md-2 mb-2 mb-md-0"
      >
        <i className="bi bi-printer"></i> Imprimir
      </Button>
      { estadoEvento ===1 && (
        <>
          {funcionalidadNum === 1 && (
            <Button 
            size="sm"
              variant="outline-info" 
              onClick={() => handleShowModal(7, null)}
              className="ml-md-2 mb-2 mb-md-0"
            >
              <i className="bi bi-file-arrow-down"></i> Importar pre-inscripciones
            </Button>
          )} </>)}
{ estadoEvento < 2 && (
<>
          {funcionalidadNum === 3 && (
            <Button 
              variant="outline-info" 
              onClick={() => handleShowModal(6, null)}
              className="ml-md-2 mb-2 mb-md-0"
            >
              <i className="bi bi-file-arrow-down"></i> Importar Docentes
            </Button>
          )}
      </>)}
    </div>
  </div>
</div>
       
        <div
          className="table-container1"
          style={{ maxHeight: "550px", overflowY: "scroll" }}
        >
          <Table
            striped
            bordered
            hover
            size="sm"
            className="table-bordered"
          >
            <thead className="sticky-header1">
              <tr>
                <th className="col-cod">N°</th>
                <th style={{ width: "10%" }}>C.I.</th>
                <th style={{ width: "15%" }}>Paterno</th>
                <th style={{ width: "15%" }}>Materno</th>
                <th style={{ width: "15%" }}>Nombres</th>
                <th>Universidad</th>
                <th style={{ width: "15%" }}>Estado</th>
                <th className="col-actions-xl">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredList.map((docente, index) => {
                const indice = index + 1;
                return (
                  <ItemDocente
                    key={docente.id}
                    docente={docente}
                    indice={indice}
                    universidadSigla={universidad?.sigla}
                    showModal={handleShowModal}
                    inscripcionDocente={() => handleShowModal(5, docente)}
                    funcionalidad={funcionalidadNum}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
       
      </div>
      <Modal show={modal.tipo > 0} onHide={() => {
              handleClose();
              refreshDocsubData();
            }} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{modal.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.body}</Modal.Body>
      </Modal>

      <PanelOpciones show={panelOpcionesModal.show} 
              funcionalidadNum={funcionalidadNum} 
              {...(panelOpcionesModal.funcionalidad === "exportar" ? { exportar: true } : { imprimir: true })}
              filteredList={filteredList}
              universidad={universidad}
              handleClose={handleClosePanel}
              filters={{...filters, estadoDocente: filters.estado, searchTerm: searchTerm}}/>
    </>
  );
};

export default ListDocente;
