import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubdisciplinas } from "../../actions/subdisciplinaActions";
import { Button, Form, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { genderMap } from "../../util/valueCalculator";
import { fetchDisciplinas } from "../../actions/disciplinaActions";

const SelectSubdisciplinas = ({
  rol,
  subdisciplinasSelected,
  onSaveSubdisciplinas,
  onClose,
}) => {
  const dispatch = useDispatch();

  const subdisciplinas = useSelector(
    (state) => state.subdisciplina.subdisciplinas
  );
  const disciplinas = useSelector(
    (state) => state.disciplina.disciplinas
  );
  const [availableSubdis, setAvailableSubdis] = useState([]);
  const [visibleCount, setVisibleCount] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");  
  const [subdisciplinaSelected, setSubdisciplinaSelected] = useState(null);


  useEffect(() => {
    dispatch(fetchSubdisciplinas());
    dispatch(fetchDisciplinas());
  }, [dispatch]);

  useEffect(() => {
    if (subdisciplinasSelected) {
      const available = subdisciplinas.filter(
        (sub) => !subdisciplinasSelected.some((subSel) => subSel.id === sub.id)
      );
      setAvailableSubdis(available);
    } else {
      setAvailableSubdis(subdisciplinas);
    }
  }, [subdisciplinas, subdisciplinasSelected]);

  const handleSelectDisciplina = (disciplina) => {
    
    console.log("groupedByDisciplina[disciplina]",disciplina)
    setSubdisciplinaSelected(disciplina);
  };

  const groupedByDisciplina = availableSubdis.reduce((acc, sub) => {
    const dis = disciplinas.find(dis => dis.id === sub.iddisc)
    const key = `${dis.nombre} - ${genderMap[sub.genero]}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(sub);
    return acc;
  }, {});

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDisciplinas = Object.keys(groupedByDisciplina).filter((key) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  ).sort((a, b) => a > b ? 1 : -1);

  return (
    <Modal
      show={true}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="md"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Disciplinas Disponibles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="w-100">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Buscar por nombre"
                aria-label="Buscar por nombre"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
            <Form.Group control="visibleCount" className="mb-3">
              <Form.Label>Número de elementos visibles</Form.Label>
              <Form.Control
                type="number"
                value={visibleCount}
                onChange={(e) => setVisibleCount(Number(e.target.value))}
                min={1}
              />
            </Form.Group>
            <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
              {filteredDisciplinas.slice(0, visibleCount).map((key) => (
                <ListGroup.Item key={key}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      onChange={() => handleSelectDisciplina(groupedByDisciplina[key])}
                      type="radio"
                      name="disciplina"
                      id={key}
                    />
                    <label className="form-check-label" htmlFor={key}>
                      {key}
                    </label>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          variant="info"
          onClick={() => {
            onSaveSubdisciplinas(rol, subdisciplinaSelected);
            onClose();
          }}
        >
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectSubdisciplinas;