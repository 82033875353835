// src/components/ciudad/CiudadForm.js

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { addUniversidad, fetchUniversidades, updateUniversidad } from '../../actions/universidadAction';
import { fetchCiudades } from '../../actions/ciudadActions';
import { toast } from 'react-toastify';
import { useInputFile } from '../../hooks/useInputImage';
import { getUploadUrl } from '../../util/valueCalculator';

const UniversidadForm = ({ currentUniversidad, showModal, handleClose }) => {
  const [datos, setDatos] = useState({
    sigla: '',
    nombre: '',
    relato: '',
    idciudad: '',
    escudo:'',
    estado: 1
  });
  const {
    file: escudo,
    handleFileChange: handleEscudoChange,
    resetInputFile: resetEscudo,
    isValid: isEscudoValid
  } = useInputFile();

  const [ ciudades, setCiudades ] = useState([]);
  

  const dispatch = useDispatch();


  useEffect(() => {
    if (currentUniversidad) {
      setDatos({
        sigla: currentUniversidad.sigla,
        nombre: currentUniversidad.nombre,
        relato: currentUniversidad.relato,
        idciudad: currentUniversidad.idciudad,
        escudo: currentUniversidad.escudo || null,
        estado: currentUniversidad.estado
      })
    }
  }, [currentUniversidad]);

  //Obtenemos las ciudades para seleccionar 
  useEffect(() => {
    const fetchAndSetCiudades = async () => {
      const ciudadesFetched = await dispatch(fetchCiudades());
      if(ciudadesFetched){
        setCiudades(ciudadesFetched);
      }
   
    };

    fetchAndSetCiudades();
    }, [dispatch]);
  //Gestionamos los cambios en los inputs del form
  function handleInputChange(event) {
    const { name, value } = event.target;
    setDatos(prevDatos => ({...prevDatos, [name] : value}))
  }

  //Submit el formulario
  function handleSubmit(e) {
    e.preventDefault();
    const fd = new FormData(e.target);
   // Si no se ha subido una nueva foto, usar la foto actual
    fd.set('escudo', escudo)    
 
    if (currentUniversidad) {
      dispatch(updateUniversidad(currentUniversidad.id, fd)).then(() => {
        handleClose();
        dispatch(fetchUniversidades());  // Actualiza la lista de disciplinas
      });
    } else {
      dispatch(addUniversidad(fd)).then(() => {
        handleClose();
        dispatch(fetchUniversidades());  // Actualiza la lista de disciplinas
      });
    }
  }
  const actualEscudoUrl = currentUniversidad && currentUniversidad.escudo ? getUploadUrl(currentUniversidad.escudo) : null;
   
  return (
    <div className="contenedor">
      <Form onSubmit={handleSubmit} className="modal-oval">
         <Form.Group className="row">
            <div className="col-md-6 modal-input">
              <Form.Label>Sigla</Form.Label>
              <Form.Control
                type="text"
                name="sigla"
                placeholder="Sigla de la universidad"
                value={datos.sigla}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-md-6 modal-input">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                placeholder="Nombre de la universidad"
                value={datos.nombre}
                onChange={handleInputChange}
                required
              />
            </div>
         </Form.Group>
         <Form.Group className="row">
            <div className="col-md-6 modal-input">
            <Form.Label>Ciudad</Form.Label>
              <Form.Control
                as="select"
                name='idciudad'
                value={datos.idciudad}
                onChange={handleInputChange}
                 className='modal-input'
                required
              >
                <option value="">- Seleccionar ciudad -</option>
                {ciudades.map(ciudad => (
                  <option key={ciudad.id} value={ciudad.id}>
                    {ciudad.nombre}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-md-6 modal-input">
            <Form.Label>Relato</Form.Label>
              <Form.Control
                as="textarea"
                name='relato'
                rows={4}
                placeholder="Relato"
                value={datos.relato}
                onChange={handleInputChange}
              />  
            </div>
         </Form.Group>
         <Form.Group className="row"
          >
            <div className="col-md-6 modal-input">
              <Form.Label>Escudo</Form.Label>
              <Form.Control
                type="file"
                name='escudo'
                isInvalid={!isEscudoValid}
                onChange={(e) => handleEscudoChange(e.target.files[0])}
              />
               <Form.Control.Feedback type="invalid">
                    Por favor selecciona una imagen válida.
                </Form.Control.Feedback>
                {(escudo && isEscudoValid) && (
                    <img
                    className="img-fluid mb-2 mt-3"
                    style={{ maxHeight: "200px" }}
                    src={URL.createObjectURL(escudo)}
                    />
                )}
           </div>
           <div className="col-md-6 modal-input"  style={{display:"flex", flexDirection:"column"}}>
              {actualEscudoUrl && (
                  <>
                  <Form.Label>Escudo actual</Form.Label>
                  <img
                      className="img-fluid mb-2"
                      style={{ height: "200px", width: "180px" }}
                      src={actualEscudoUrl}
                  />
                  </>
              )}
            </div>
            </Form.Group>
         {/* <Form.Group className="row"
          >
           <div className="col-md-4 modal-input">
             <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                name='estado'
                value={datos.estado}
                onChange={handleInputChange}
              >
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </Form.Control>
              </div>
            </Form.Group> */}
          <Button variant={currentUniversidad? 'warning' : 'info'} type="submit"  className="btn-modal">
            {currentUniversidad ? 'Actualizar' : 'Crear'}
          </Button>
          <Button variant="secondary" onClick={handleClose} className="btn-modal">
            Cancelar
          </Button>   
      </Form>
    </div>
  
  );
};

export default UniversidadForm;
