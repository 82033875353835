import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useSelector } from 'react-redux';
import cabeceraCud from '../../assets/cabeceraCud.png';

let titulo ="XVIII JUEGOS DEPORTIVOS NACIONALES DE DOCENTES UNIVERSITARIOS"

const PrintModalListas = ({ show, handleClose, orientacion='p',agrupadores, cabecera, title1, title2, listasPorAgrupador, marcaDeAgua = false,filters={} }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const eventoData = useSelector(state => state.evento);

    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, agrupadores, listasPorAgrupador]);

    const generatePdf = () => {
        const doc = new jsPDF(orientacion==='h'?'landscape':'p', 'pt', 'letter'); // Establecer tamaño de hoja a carta
        
        const marginLeftRight = orientacion==='h'? 40:55;
        const marginTop = 40;
        const marginBottom = orientacion==='h'? 50: 75;
        const headerHeight = orientacion==='h'?80: 100;
        const additionalSpace = 65;
        
        const addHeader = (doc, title) => {
            doc.setTextColor(0, 0, 0);
            doc.addImage(cabeceraCud, 'PNG', marginLeftRight, marginTop, doc.internal.pageSize.getWidth() - (marginLeftRight * 2), headerHeight);
            doc.setFontSize(12);
            doc.setFont('times', 'bold');
            doc.text(titulo, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 10, { align: 'center' });
            doc.text(title1, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 25, { align: 'center' });
            doc.text(title, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 40, { align: 'center' });
               
        };

        const addFooter = (doc, pageNumber) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();
            doc.setDrawColor(0, 0, 0);
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
            doc.setFont("times", "normal");
            doc.setFontSize(8);
            doc.text('Oficina La Paz: Calle Hermanos Manchego N° 2559 Edif. CEUB Telf (Fax): (591-2)243-4909  Oficina Tarija: El Tejar - Campus Universitario Edif. Biblioteca Central', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.text(`Impreso el: ${new Date().toLocaleString()}`, pageWidth - marginLeftRight, pageHeight - marginBottom + 40, { align: 'right' });
            doc.text(`${pageNumber}`, pageWidth / 2, pageHeight - marginBottom + 40, { align: 'center' });
        };

        const addWatermark = (doc) => {
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(30);
            doc.setTextColor(150, 150, 150);
            doc.text('BORRADOR', pageWidth / 2.5, pageHeight / 2.5, { align: 'center', angle: 45 });
        };

        let pageNumber = 1;
        let indice = 0
        agrupadores.forEach((agrupador, index) => {
            const lista = listasPorAgrupador[agrupador.id] || [];
       
            if (lista.length > 0) {
                indice++
                if (indice > 1) {
                    doc.addPage();
                    pageNumber++;
                }
                autoTable(doc, {
                    didDrawPage: function (data) {
                        if(marcaDeAgua === true || (filters.estadoDocente && filters.estadoDocente!== '3'))
                            {addWatermark(doc)}
                            addHeader(doc,  agrupador.titulo);
                            pageNumber = data.pageNumber;
                            addFooter(doc, data.pageNumber);
                    },
                    head: cabecera,
                    body: lista.map((item, i) => [
                        i + 1,
                       ...item
                    ]),
                    startY: marginTop + headerHeight + additionalSpace,
                    margin: { top: marginTop + headerHeight + additionalSpace, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom },
                
                    pageBreak: 'auto',
                    styles: {
                        fontSize: 9,
                        lineColor: [0, 0, 0],
                        lineWidth: 0.5,
                    },
                    headStyles: {
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0],
                    },
                    bodyStyles: {
                        fillColor: [255, 255, 255],
                        textColor: [0, 0, 0],
                        lineWidth: 0.5,
                        lineColor: [0, 0, 0],
                    },
                });
            }
        });

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Reporte de Docentes por Subdisciplina</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <p>Para quitar la Marca de Agua, debe seleccionar el filtro por estado APROBADO</p>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="LISTADO GENERAL"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PrintModalListas;