import { createSlice } from "@reduxjs/toolkit";
const initialState ={
  noticias:[],
  current: null
}
const noticiaSlice= createSlice({
  name: "noticia",
  initialState: initialState,
  reducers: { 
    setNoticias(state, action) {
    return { ...state, noticias: action.payload };  
    },

    setCurrentNoticia(state, action) {
      return { ...state, current: action.payload }; 
    }
  }
});

export const noticiaActions = noticiaSlice.actions;
export default noticiaSlice.reducer;
