import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsuarioById, modifyUsuario } from "../../actions/usuarioActions";


const FormPerfilUsuario = ({ showModal, handleClose }) => {
  const login = useSelector((state) => state.usuario.usuario.login)
  const [datos, setDatos] = useState({
    login: login,
    contrasena: "",
    estado: 1
    }
  );
  const [ isSending, setIsSending ] = useState(false)
  const dispatch = useDispatch();
  const idUsu = useSelector((state) => state.sesion.id);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    
    setIsSending(true)
    const fd = new FormData();
      // Agregar los datos de 'datos' al FormData
      fd.set("login", datos.login);
      fd.set("contrasena", datos.contrasena);
      fd.set("estado", datos.estado);
      
      dispatch(modifyUsuario(idUsu, fd))
        .then(() => {
              setIsSending(false)
              closeForm();
              dispatch(fetchUsuarioById(idUsu))
                 }) 
              .catch(errores => {
               //console.log("Errores", errores)
                  setIsSending(false)
                  for(const err of errores){
                    toast.error(err)
                  }
                });
    
  }

  const resetForm = () => {
    setDatos(prev => ({
        ...prev,
        contrasena: "",
        estado: 1
        }))
  }
  const closeForm = () => {
    handleClose();
    resetForm();
  }
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeForm}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
          DATOS DE INICIO DE SESIÓN
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <Form
                onSubmit={handleSubmit}
                className="modal-oval"
              >   
                <Form.Group className="row">
                  <div className="col-md-4 modal-input">
                    <Form.Label>Usuario</Form.Label>
                    <Form.Control
                      type="text"
                      name="login"
                      placeholder="Nombre de usuario"
                      value={datos.login}
                      onChange={handleInputChange}
                      required
                      disabled={isSending}
                    />
                  </div>
                  <div className="col-md-4 modal-input">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      name="contrasena"
                      placeholder="Nueva contraseña"
                      value={datos.contrasena}
                      onChange={handleInputChange}
                      disabled={isSending}
                    />
                  </div>
                </Form.Group>

                <Button
                  variant="warning"
                  type="submit"
                  className="btn-modal"
                >
                  Actualizar
                </Button>
                <Button
                  variant="secondary"
                  onClick={closeForm}
                  className="btn-modal"
                >
                  Cancelar
                </Button>
              </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FormPerfilUsuario;
