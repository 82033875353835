import { Button, Card } from "react-bootstrap";
import defaultImage from '../../assets/disciplinas/default.jpg'
import { UPLOADS_BASE_URL } from "../../util/axiosInstance";
import { getAuthToken } from "../../util/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUploadUrl } from "../../util/valueCalculator";


const CardDisciplina = ({disciplina, handleEdit, handleDelete, disabled }) => {
  const dispatch = useDispatch();
    const [fotoPreview, setFotoPreview] = useState(defaultImage);
    const navigate = useNavigate();

    useEffect(() => {
        if (disciplina) {
          if (disciplina.foto1) {
            setFotoPreview(getUploadUrl(disciplina.foto1));
          }else if (disciplina.foto2) {
            setFotoPreview(getUploadUrl(disciplina.foto2));
             }
        }
      }, [disciplina]);

      const handleViewSubdisciplinas = (disciplina) => {
        dispatch({ type: 'GET_DISCIPLINA_BY_ID', payload: disciplina });
        navigate(`/disciplina-admin/${disciplina.id}`);
      };
      const handleClick = (disciplina) => {
        if (!disabled) {
          handleViewSubdisciplinas(disciplina);
        }
      };
       // Define el estilo para el Card
  const cardStyle = {
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.5 : 1,
    backgroundColor: disabled ? '#f0f0f0' : 'white',
  };
    return (  <Card onClick={() => handleClick(disciplina)}  style={cardStyle}>
    <Card.Img variant="top" src={fotoPreview || defaultImage} alt={disciplina.nombre} className="image-card"/>
    <Card.Body className="card-body">
      <Card.Title>{disciplina.nombre}</Card.Title>
      <div className="card-footer">
        <Button variant="outline-dark" size="sm" onClick={e => { e.stopPropagation(); handleEdit(disciplina); }}>
          <i className="bi bi-pencil"></i>
        </Button>
        <Button variant="outline-dark" size="sm" onClick={e => { e.stopPropagation(); handleDelete(disciplina.id); }}>
          <i className="bi bi-trash"></i>
        </Button>
      </div>
    </Card.Body>
  </Card>)
}

export default CardDisciplina;