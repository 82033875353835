import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Button, FormControl, Modal, Form } from "react-bootstrap";
import {
  fetchSubdisciplinas,
  fetchSubdisciplinasByUsuarioId,
} from "../../actions/subdisciplinaActions";
import ListFixtures from "../fixtures/ListFixturesDesarrollo"; // Asegúrate de que la ruta sea correcta
import ListNoticias from "../noticia/ListNoticiasDesarrollo";
import ItemMedalleroDesarrollo from "../medallero/ItemMedalleroDesarrollo";
import { fetchMedallero } from "../../actions/medalleroActions";
import { getCategoriaText, getGeneroText } from "../Utils.js"
import { categoryMap, genderMap } from "../../util/valueCalculator.js";
import MensajeCentral from "../mensajes/MensajeCentral.js";
import { toast } from "react-toastify";

const SubdisciplinasDesarrollo = () => {
  const navigate = useNavigate();
  const { tipo } = useParams(); // obtener tipo de los parámetros de la URL
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    genero: "",
    categoria: "",
  });
  const [showModal, setShowModal] = useState({ tipo:0,title:"", body:(<></>)});
  const [showNoticiasModal, setShowNoticiasModal] = useState(false);
  const [showMedalleroModal, setShowMedalleroModal] = useState(false);
  const [selectedSubdisciplina, setSelectedSubdisciplina] = useState(null);
  const subdisciplinas = useSelector(
    (state) => state.subdisciplina.subdisciplinas
  );
  const { rolActivo, id } = useSelector((state) => state.sesion);
  const estadoEvento= useSelector(state => state.evento.estado)

  useEffect(() => {
    if (rolActivo === "admin_disciplina") {
      dispatch(fetchSubdisciplinasByUsuarioId(id, "admin_disciplina"));
    } else if (rolActivo === "admin_evento") {
      dispatch(fetchSubdisciplinas());
    }
  }, [dispatch, rolActivo]);


  const handleFilters = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };
  //MOSTRAR MODAL

  const handleShowModal = (tipo, subdisciplina) => {
    //Seteamos la subdisciplina seleccionada
    setSelectedSubdisciplina(subdisciplina);
    
    //De acuerdo al tipo de modal definimos el titulo y el contenido del modal
    let modal = { tipo:0, title:"", body:(<></>)}
    if(tipo === 1) {
      modal = {tipo:1, 
               title:"Fixtures", 
               body:(<ListFixtures subdisciplina={subdisciplina} onClose={handleCloseModal} />)
              }
    }
    else if(tipo === 2) {
      modal = {tipo:2, 
                title:"Noticias", 
                body:(<ListNoticias subdisciplina={subdisciplina} onClose={handleCloseModal} />)
              }
    }
    else if(tipo === 3) {
      if (subdisciplina) {
          dispatch(fetchMedallero(subdisciplina.id)).then((data) => {
            modal = {tipo:3, 
              title:"Medalleros", 
              body:(<ItemMedalleroDesarrollo medallero={data} onClose={handleCloseModal} />)
            }
            setShowModal(modal);
        });
        }
    }
    setShowModal(modal)
  };

  //CLOSE MODAL
  const handleCloseModal=()=>{
    setSelectedSubdisciplina(null);
   setShowModal({ tipo:0, 
                title:"", 
                body:(<></>)
              })
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

 
  const sortedList = [...subdisciplinas].sort((a, b) => {
    const compareStrings = (str1, str2) => str1.localeCompare(str2);

    const compare = compareStrings(a.nombre, b.nombre);
    if (compare !== 0) return compare;

    const compareGenero = compareStrings(a.genero, b.genero);
    if (compareGenero !== 0) return compareGenero;

    const compareCategoria = () =>{
      if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
      if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
     return 0
    }
    return compareCategoria();
  });

  const filteredSubdisciplinas = sortedList.filter(
    (subdisciplina) =>
      subdisciplina.nombre.toLowerCase().includes(searchTerm.toLowerCase()) &&
      subdisciplina.genero.includes(filters.genero) &&
      subdisciplina.categoria.includes(filters.categoria)
  );
  
  if (estadoEvento < 1 && (tipo === "1" || tipo === "2")) {
    return (
      <MensajeCentral accionBloqueada={tipo === "2"? "noticias" : "fixtures"} accionDesbloqueadora="preinscripcionUniversidades"/>
    );
  }
  return (
    <>
      <div className="contenedor container-medium">
       
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
           
          <h1> GESTIÓN DE {tipo === "2" ? "NOTICIAS" : (tipo === "1" ? "FIXTURES" : "DISICPLINAS")}</h1>
          </div>
          <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            variant="light"
            onClick={() => navigate("/")}
            className="float-start"
          >
            <i className="bi bi-arrow-left"></i>   Volver
          </Button>
          </div>
        <Form.Group
        controlId="filtrosSubdisciplinas"
        className="row"
      >
        <div className="col-md-4 modal-input">
          <Form.Label>Buscar</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Categoría</Form.Label>
          <Form.Control
            as="select"
            value={filters.genero}
            onChange={(e) => handleFilters("genero", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="V">VARONES</option>
            <option value="D">DAMAS</option>
          </Form.Control>
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Modalidad</Form.Label>
          <Form.Control
            as="select"
            value={filters.categoria}
            onChange={(e) => handleFilters("categoria", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="L">LIBRE</option>
            <option value="S">SENIOR</option>
            <option value="M">MASTER</option>
          </Form.Control>
        </div>
      </Form.Group>
        <div
          className="table-container"
          style={{ height: "700px", overflowY: "scroll" }}
        >
          <Table
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>N°</th>
                <th>Disciplina</th>
                <th>Categoría</th>
                <th>Modalidad</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {rolActivo === "admin_evento" && tipo === "2" && (
                <tr key="general">
                  <td>0</td>
                  <td colSpan={3}>GENERAL</td>
                   <td>
                   
                      <Button
                        variant="outline-info"
                        size="sm"
                        onClick={() => handleShowModal(2,{id:0, nombre:"Evento en general"})}
                      >
                        Noticias
                      </Button>
                  </td>
                </tr>
              )}
              {filteredSubdisciplinas.map((subdisciplina, index) => (
                <tr key={subdisciplina.id}>
                  <td>{index + 1}</td>
                  <td>{subdisciplina.nombre}</td>
                  <td>{getGeneroText(subdisciplina.genero)}</td>
                  <td>{getCategoriaText(subdisciplina.categoria)}</td>
                  <td>
                    {tipo === "1" && (
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => handleShowModal(1,subdisciplina)}
                      >
                        Fixture
                      </Button>
                    )}
                    {tipo === "2" && (
                      <Button
                        variant="outline-info"
                        size="sm"
                        onClick={() => handleShowModal(2,subdisciplina)}
                      >
                        Noticias
                      </Button>
                    )}
                    {tipo === "3" && (
                      <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => handleShowModal(3,subdisciplina)}
                      >
                        Medallero
                      </Button>
                    )}
                    {tipo === "4" && (
                      <>
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={() => handleShowModal(1,subdisciplina)}
                        >
                          Fixture
                        </Button>
                        <Button
                          variant="outline-info"
                          size="sm"
                          onClick={() => handleShowModal(2,subdisciplina)}
                          style={{ marginLeft: "5px" }}
                        >
                          Noticias
                        </Button>
                        <Button
                          variant="outline-success"
                          size="sm"
                          onClick={() => handleShowModal(3,subdisciplina)}
                          style={{ marginLeft: "5px" }}
                        >
                          Medallero
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {selectedSubdisciplina && showModal.tipo > 0 
      && (
            <Modal
              show={true}
              onHide={handleCloseModal}
              size="xl"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {selectedSubdisciplina.nombre}{selectedSubdisciplina.id>0 && ` - ${genderMap[selectedSubdisciplina.genero]} - ${categoryMap[selectedSubdisciplina.categoria].nombre}`}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {showModal.body}
              </Modal.Body>
            </Modal>
        )}
    </>
  );
};

export default SubdisciplinasDesarrollo;
