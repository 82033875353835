import { Button, Modal } from "react-bootstrap";
import MensajeCentral from "../mensajes/MensajeCentral";
import { fasesEvento } from "../../util/valueCalculator";

const ModalCambioFase =({show, onClose, fase}) => {
    return (
        <Modal show={show} onHide={onClose} size="lg">
          <Modal.Body>
                <MensajeCentral><p style={{fontSize:"1.3rem"}}>El evento ha cambiado de fase</p>
                                    <p style={{fontSize:"1.9rem"}} className="highlight">FASE ACTUAL:</p> 
                                    {fasesEvento(fase)}
                </MensajeCentral>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={onClose}>Aceptar</Button>
          </Modal.Footer>
        </Modal>
      );
}
export default ModalCambioFase