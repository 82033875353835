// src/actions/paisActions.js

import { getPaises, getPaisById, createPais, updatePais, deletePais } from '../services/paisService';

export const fetchPaises = () => async dispatch => {
  try {
    const data = await getPaises();
   //console.log('fetchPaises data:', data); // Depuración
    dispatch({ type: 'GET_PAISES', payload: data });
  } catch (error) {
    console.error('fetchPaises error:', error);
  }
};

export const fetchPaisById = (id) => async dispatch => {
  try {
    const data = await getPaisById(id);
   //console.log('fetchPaisById data:', data); // Depuración
    dispatch({ type: 'GET_PAIS_BY_ID', payload: data });
  } catch (error) {
    console.error('fetchPaisById error:', error);
  }
};

export const addPais = (pais) => async dispatch => {
  try {
    const data = await createPais(pais);
   //console.log('addPais data:', data); // Depuración
    dispatch({ type: 'CREATE_PAIS', payload: data });
  } catch (error) {
    console.error('addPais error:', error);
  }
};

export const modifyPais = (id, pais) => async dispatch => {
  try {
    const data = await updatePais(id, pais);
   //console.log('modifyPais data:', data); // Depuración
    dispatch({ type: 'UPDATE_PAIS', payload: data });
  } catch (error) {
    console.error('modifyPais error:', error);
  }
};

export const removePais = (id) => async dispatch => {
  try {
    const data = await deletePais(id);
   //console.log('removePais data:', data); // Depuración
    dispatch({ type: 'DELETE_PAIS', payload: id });
  } catch (error) {
    console.error('removePais error:', error);
  }
};
