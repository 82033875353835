import { useDispatch, useSelector } from "react-redux";
import { addNoticia, fetchNoticias, fetchNoticiasByTipo, updateNoticia } from "../../actions/noticiaActions";
import { fetchDisciplinas } from "../../actions/disciplinaActions";
import { fetchSubdisciplinas, fetchSubdisciplinasByDisciplinaId, fetchSubdisciplinasByUsuarioId } from "../../actions/subdisciplinaActions";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useInputFile } from "../../hooks/useInputImage";
import { getUploadUrl } from "../../util/valueCalculator";

// Fecha actual para la fecha de la noticia
const currentDate = new Date().toISOString().split('T')[0];

const NoticiaForm = ({ subdisciplina = null, onHandleClose }) => {
  const {rolActivo, id} = useSelector(state => state.sesion)
//  console.log("En NoticiaForm",rolActivo,subdisciplina)
  const tipo = subdisciplina && subdisciplina.id === 0 && rolActivo === "admin_evento"? "general":"subdisciplina"; 
  const subdisId = subdisciplina? subdisciplina.id : "";
  const disId = subdisciplina? subdisciplina.iddisc : "";
  const [datos, setDatos] = useState({
    fecha: currentDate,
    titulo: '',
    relato: '',
    estado: 1,
    tipo: tipo,
    disciplinaId: disId,
    subdisciplinaId: subdisId,
  });
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();

  const {
    file: foto2,
    handleFileChange: handleFoto2Change,
    resetInputFile: resetFoto2,
    isValid: isFoto2Valid
  } = useInputFile();

  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const currentNoticia = useSelector(state => state.noticia.current);
 
  useEffect(() => {
    if (currentNoticia) {
      const { fecha, ...datosNoticia } = currentNoticia;
      const formattedFecha = fecha ? fecha.split('T')[0] : '';
      setDatos({ ...datosNoticia, fecha: formattedFecha });
    }
  }, [currentNoticia]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
   //const upperValue = value.toUpperCase(); // Convertir el valor a mayúsculas
    if (name === 'foto1' || name === 'foto2') {
      const file = event.target.files[0];
       // Validar el tipo de archivo
       if (file && !file.type.startsWith('image/')) {
        setErrors(["Por favor, seleccione un archivo de imagen para " + name]);
        return;
      }
      setDatos(prevDatos => ({ ...prevDatos, [name]: file }));
    } else {
      setDatos(prevDatos => ({ ...prevDatos, [name]: value }));
    }
  };
  const validateForm = () => {
    const validationErrors = [];
    
    if (!datos.titulo) {
      validationErrors.push("El título es obligatorio.");
    }
    if (!datos.relato) {
      validationErrors.push("El relato es obligatorio.");
    }
    if (!foto1 && !currentNoticia?.foto1) {
      validationErrors.push("Es obligatorio subir una imagen para el campo Foto 1.");
    }
    if (datos.tipo === 'disciplina' && !datos.disciplinaId) {
      validationErrors.push("Debe seleccionar una disciplina.");
    }
    if (datos.tipo === 'subdisciplina' && !datos.subdisciplinaId) {
      validationErrors.push("Debe seleccionar una subdisciplina.");
    }
    
    return validationErrors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    const fd = new FormData(e.target);

    fd.set('tipo', tipo);
    if(tipo === "subdisciplina"){
      fd.set('subdisciplinaId', subdisId);
      fd.set('disciplinaId', disId);
    }else if(tipo === "general"){
      fd.set('subdisciplinaId', null);
      fd.set('disciplinaId', null);
    }
      fd.set('foto1', foto1);
      fd.set('foto2', foto2);

    
    if (currentNoticia) {
      dispatch(updateNoticia(currentNoticia.id, fd)).then(() => {
        onHandleClose();
        dispatch(fetchNoticiasByTipo(tipo, subdisId))  // Actualiza la lista de noticias
      });
    } else {
      dispatch(addNoticia(fd)).then(() => {
        onHandleClose();
      dispatch(fetchNoticiasByTipo(tipo, subdisId))  // Actualiza la lista de noticias
      });
    }
  };

  const actualFoto1Url = currentNoticia && currentNoticia.foto1 ? getUploadUrl(currentNoticia.foto1) : null;
  const actualFoto2Url = currentNoticia && currentNoticia.foto2 ? getUploadUrl(currentNoticia.foto2) : null;
  
  return (
    <div className="contenedor">
      <Form onSubmit={handleSubmit} className="modal-oval">
      {errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}
        <Form.Group className="row">
          <div className="col-md-6 modal-input">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              name="fecha"
              value={datos.fecha}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-6 modal-input">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              name="titulo"
              placeholder="Título"
              value={datos.titulo}
              onChange={handleInputChange}
              required
            />
          </div>
        </Form.Group>
        <Form.Group className="row">
          <div className="col-md-12 modal-input">
            <Form.Label>Relato</Form.Label>
            <Form.Control
              as="textarea"
              name="relato"
              placeholder="Relato"
              value={datos.relato}
              onChange={handleInputChange}
              required
              maxLength={500} 
            />
          </div>
        </Form.Group>
        <Form.Group className="row">
          <div className="col-md-6 modal-input">
            <Form.Label>Foto 1</Form.Label>
              <Form.Control
              type="file"
              isInvalid={!isFoto1Valid}
              onChange={(e) => handleFoto1Change(e.target.files[0])}
              accept="image/png, image/jpeg, image/jpg" 
            />
             <Form.Control.Feedback type="invalid">
                  Por favor selecciona una imagen válida.
                </Form.Control.Feedback>
                {(foto1 && isFoto1Valid) && (
                  <img
                    className="img-fluid mb-2 mt-3"
                    style={{ maxHeight: "200px" }}
                    src={URL.createObjectURL(foto1)}
                  />
                )}
          </div>
          <div className="col-md-6 modal-input">
                {actualFoto1Url && (
                  <>
                    <Form.Label>Foto 1 (actual)</Form.Label>
                    <img
                      className="img-fluid mb-2"
                      style={{ maxHeight: "200px" }}
                      src={actualFoto1Url}
                    />
                  </>
                )}
              </div>
              </Form.Group>
              <Form.Group className="row">
          <div className="col-md-6 modal-input">
            <Form.Label>Foto 2</Form.Label>
             <Form.Control
              type="file"
              isInvalid={!isFoto2Valid}
              onChange={(e) => handleFoto2Change(e.target.files[0])}
              accept="image/png, image/jpeg, image/jpg" 
            />
             <Form.Control.Feedback type="invalid">
                  Por favor selecciona una imagen válida.
              </Form.Control.Feedback>
              {(foto2 && isFoto2Valid) && (
                  <img
                    className="img-fluid mb-2 mt-3"
                    style={{ maxHeight: "200px" }}
                    src={URL.createObjectURL(foto2)}
                  />
                )}
          </div>
          <div className="col-md-6 modal-input">
                {actualFoto2Url && (
                  <>
                    <Form.Label>Foto 2 (actual)</Form.Label>
                    <img
                      className="img-fluid mb-2"
                      style={{ maxHeight: "200px" }}
                      src={actualFoto2Url}
                    />
                  </>
                )}
              </div>
        </Form.Group>
        <Form.Group className="row">
            <div className="col-md-4 modal-input">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              as="select"
              name="estado"
              value={datos.estado}
              onChange={handleInputChange}
              required
            >
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </Form.Control>
          </div>
        </Form.Group>
        <Button variant={currentNoticia ? 'warning' : 'info'} type="submit" className="btn-modal">
          {currentNoticia ? 'Actualizar' : 'Crear'}
        </Button>
        <Button variant="secondary" onClick={onHandleClose} className="btn-modal">
          Cancelar
        </Button>
      </Form>
    </div>
  );
};

export default NoticiaForm;