import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ItemInvitado from "./ItemInvitado";
import { fetchPersonasTipo } from "../../actions/personasTipoActions";
import CrearButton from "../generales/CrearButton";
import FormInvitado from "./FormInvitado";
import DeleteInvitado from "./DeleteInvitado";
import PerfilInvitado from "./PerfilInvitado";

const ListInvitados = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ invitados, setInvitados ] = useState([]);
    const [sort, setSort] = useState({ by: "ci", order: "asc" });
    const [searchTerm, setSearchTerm] = useState("");
    const [modal, setModal] = useState({ tipo: 0, titulo: "", body: <></> });
    const rolActivo = useSelector(state => state.sesion.rolActivo);
    const [filters, setFilters] = useState({
      tipo2: ""
    });

    const fetchInvitados = useCallback(async () => {
            dispatch(fetchPersonasTipo("invitado")).then((data) => {
              console.log("Fetching invitados response:",data)
              setInvitados(data)
            })
      }, [dispatch]);

    useEffect(()=>{
        fetchInvitados()
    }, [fetchInvitados])
      

    
  const handleSort = (e) => {
    const { name, value } = e.target;
    setSort((prevSort) => ({ ...prevSort, [name]: value }));
  };
  const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
  };
  
  const handleFilters = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };

  const handleActualizarLista= () =>{
    fetchInvitados()
  }
  const handleShowModal = (tipo, invitado) => {
      let modalTitulo = "";
      let body = <></>;
      if (tipo === 1) {
        modalTitulo = "Nueva Persona";
        body = <FormInvitado currentInvitado={invitado} handleClose={handleClose} actualizarLista={handleActualizarLista}/>;
      } 
      else  if (tipo === 2) {
        modalTitulo = "Editar Persona";
        body = <FormInvitado currentInvitado={invitado} handleClose={handleClose} actualizarLista={handleActualizarLista}/>;
      } else  if (tipo === 3) {
        modalTitulo = "Confirmar eliminación";
        body = <DeleteInvitado currentInvitado={invitado} handleClose={handleClose} actualizarLista={handleActualizarLista}/>;
      }
      else if (tipo === 4) {
        modalTitulo = "Perfil de la persona";
        body = <PerfilInvitado currentInvitado={invitado} handleClose={handleClose} />;
      } 
      setModal({ tipo, titulo: modalTitulo, body });
    }

  const handleClose = () => {
    setModal({ tipo: 0, titulo: "", body: <></> });
 } 
 const sortedList = [...invitados].sort((a, b) => {
      if (sort.order === "asc") {
        return a[sort.by] > b[sort.by] ? 1 : -1;
      }
      return a[sort.by] < b[sort.by] ? -1 : 1;
    });
   
    const filteredList = sortedList.filter(invitado => {
        return searchTerm.split(' ').every(term => 
          (invitado.appat.toLowerCase().includes(term.toLowerCase()) ||
          invitado.ci.toLowerCase().includes(term.toLowerCase()) ||
          invitado.nombres.toLowerCase().includes(term.toLowerCase()) ||
          invitado.apmat.toLowerCase().includes(term.toLowerCase())) && 
          invitado.tipo2.includes(filters.tipo2)
        );
      });

    return(
        <div className="container-large">
        <h1>COMITÉ ORGANIZADOR E INVITADOS</h1>
        <div className="row align-items-center mb-4">
          <div className="col-md-2">
            <Button
              variant="light"
              onClick={() => navigate(-1)}
              style={{ width: "auto" }}
            >
             <i className="bi bi-arrow-left"></i>   Volver
            </Button>
          </div>
          <div className="col-4 col-md-3">
            <Form.Label className="mt-2">Buscar:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Buscar..."
              onChange={handleSearchChange}
              className="mr-3"
            />
          </div>
          <div className="col-4 col-md-2">
            <Form.Label className="mt-2">Ordenar por:</Form.Label>
            <div className="d-flex">
              <Form.Control
                as="select"
                name="by"
                value={sort.by}
                onChange={handleSort}
                className="mr-3"
              >
                <option value="ci">CI</option>
                <option value="appat">Apellido paterno</option>
            </Form.Control>
             
            </div>
          </div>
          <div className="col-4 col-md-2">
          <Form.Label className="mt-2">Tipo</Form.Label>
          <Form.Control
            as="select"
            value={filters.tipo2}
            onChange={(e) => handleFilters("tipo2", e.target.value)}
            required
          >
            <option value="">Todos</option>
            <option value="invitado">Invitados</option>
            <option value="comite">Comité organizador</option>
          </Form.Control>
        </div>
            <div className="col-md-3 d-flex mt-4 justify-content-end">
            {rolActivo === "admin_evento" && (
                <CrearButton onHandleCreate={() => handleShowModal(1, null)}>
                Nueva persona
              </CrearButton>
            )}
            </div>
        </div>
        <div
          className="table-container1"
          style={{ maxHeight: "550px", overflowY: "scroll" }}
        >
          <Table
            size="sm"
            bordered
            hover
          >
            <thead className="sticky-header1">
              <tr>
                <th className="col-cod">N°</th>
                <th >C.I.</th>
                <th >Paterno</th>
                <th >Materno</th>
                <th >Nombre</th>
                <th >Descripción</th>
                <th >Tipo</th>
                <th> Universidad </th>
                <th >Estado</th>
                <th className="col-actions-md">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredList.map((invitado, index) => {
                const indice = index + 1;
                return (
                  <ItemInvitado
                    key={invitado.ci}
                    invitado={invitado}
                    indice={indice}
                    showModal={handleShowModal}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
        <Modal show={modal.tipo > 0} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{modal.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.body}</Modal.Body>
      </Modal>
    </div>
    )

}

export default ListInvitados