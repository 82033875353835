import { useDispatch, useSelector } from "react-redux";
import { fetchNoticiasByTipo, removeNoticia } from "../../actions/noticiaActions";
import { Button } from "react-bootstrap";

const DeleteNoticia = ({ tipo, onHandleClose }) => {
  const dispatch = useDispatch();
  const currentNoticia = useSelector((state) => state.noticia.current);

  const handleDelete = () => {
    dispatch(removeNoticia(currentNoticia.id)).then(() => {
      onHandleClose();
      const id = tipo === "general" ? 0: currentNoticia.subdisciplinaId
      dispatch(fetchNoticiasByTipo(tipo, id))
    });
  };

  return (
    <>
      <p>
        Estás seguro que deseas eliminar la noticia{" "}
        {currentNoticia && currentNoticia.titulo}?
      </p>
      <div className="custom-button-group">
        <Button variant="secondary" onClick={onHandleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDelete} className="ml-2">
          Eliminar
        </Button>
      </div>
    </>
  );
};

export default DeleteNoticia;