import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDisciplina,
  modifyDisciplina,
  fetchDisciplinas,
} from "../../actions/disciplinaActions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useInputFile } from "../../hooks/useInputImage";
import { getUploadUrl } from "../../util/valueCalculator";

const DisciplinaForm = ({ currentDisciplina, showModal, handleClose }) => {
  const [nombre, setNombre] = useState("");
  const [relato, setRelato] = useState("");
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();

  const {
    file: foto2,
    handleFileChange: handleFoto2Change,
    resetInputFile: resetFoto2,
    isValid: isFoto2Valid
  } = useInputFile();

  const [tipo, setTipo] = useState(0); // por defecto, normal
  const [estado, setEstado] = useState("1"); // Por defecto, activo
  const estadoEvento= useSelector(state => state.evento.estado)

  useEffect(() => {
    if (currentDisciplina) {
      setNombre(currentDisciplina.nombre);
      setRelato(currentDisciplina.relato);
      setTipo(currentDisciplina.tipo);
      setEstado(currentDisciplina.estado);
    } else {
      setNombre("");
      setRelato("");
      setTipo(0);
      setEstado("1"); // Resetear a activo
    }
  }, [currentDisciplina]);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("nombre", nombre);
    formData.append("relato", relato);

    if (foto1 instanceof File || foto1 instanceof Blob)
      formData.append("foto1", foto1);
    if (foto2 instanceof File || foto2 instanceof Blob)
      formData.append("foto2", foto2);
    formData.append("tipo", tipo);
    formData.append("estado", 1);

    if (!foto1 && currentDisciplina?.foto1)
      formData.append("foto1", currentDisciplina.foto1);
    if (!foto2 && currentDisciplina?.foto2)
      formData.append("foto2", currentDisciplina.foto2);
    
    if (currentDisciplina) {
      dispatch(modifyDisciplina(currentDisciplina.id, formData)).then(() => {
        handleReset();
        dispatch(fetchDisciplinas()); // Actualiza la lista de disciplinas
      });
    } else {
      dispatch(addDisciplina(formData)).then(() => {
        handleReset();
        dispatch(fetchDisciplinas()); // Actualiza la lista de disciplinas
      });
    }
  };

  function handleReset() {
    setNombre("");
    setRelato("");
    resetFoto1();
    resetFoto2();
    setTipo(0);
    setEstado("1");
    handleClose();
  }
  const actualFoto1Url = currentDisciplina && currentDisciplina.foto1 ? getUploadUrl(currentDisciplina.foto1) : null;
  const actualFoto2Url = currentDisciplina && currentDisciplina.foto2 ? getUploadUrl(currentDisciplina.foto2) : null;
  
  return (
    <Modal
      show={showModal}
      onHide={handleReset}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {currentDisciplina ? "Editar Disciplina "+currentDisciplina.nombre : "Nueva Disciplina"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="contenedor">
          <Form
            onSubmit={handleSubmit}
            className="modal-oval"
          >
            <Form.Group
              controlId="formNombreDisciplina"
              className="row"
            >
              <div className="col-md-6 modal-input">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nombre de la Disciplina"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  required
                />
              </div>
              <div className="col-md-6 modal-input">
                <Form.Label>Relato</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Relato"
                  value={relato}
                  onChange={(e) => setRelato(e.target.value)}
                />
              </div>
            </Form.Group>

            <Form.Group
              controlId="formTipoDisciplina"
              className="row"
            >
              <div className="col-md-6 modal-input">
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  as="select"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                  required
                >
                  <option value="0">Normal</option>
                  <option value="1">Extendida</option>
                </Form.Control>
              </div>
             {/* {estadoEvento===0 && <div className="col-md-6 modal-input">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  as="select"
                  value={estado}
                  onChange={(e) => setEstado(e.target.value)}
                >
                  <option value="1">Activo</option>
                  <option value="0">Inactivo</option>
                </Form.Control>
              </div>} */}
            </Form.Group>
            <Form.Group
              controlId="formFoto1Disciplina"
              className="row"
            >
              <div className="col-md-6 modal-input">
                <Form.Label>Foto mediana</Form.Label>
                <Form.Control
                  type="file"
                  isInvalid={!isFoto1Valid}
                  onChange={(e) => handleFoto1Change(e.target.files[0])}
                />
                 <Form.Control.Feedback type="invalid">
                  Por favor selecciona una imagen válida.
                </Form.Control.Feedback>
                {(foto1 && isFoto1Valid) && (
                  <img
                    className="img-fluid mb-2 mt-3"
                    style={{ maxHeight: "200px" }}
                    src={URL.createObjectURL(foto1)}
                  />
                )}
              </div>
              <div className="col-md-6 modal-input">
                {actualFoto1Url && (
                  <>
                    <Form.Label>Foto mediana actual</Form.Label>
                    <img
                      className="img-fluid mb-2"
                      style={{ maxHeight: "200px" }}
                      src={actualFoto1Url}
                    />
                  </>
                )}
              </div>
            </Form.Group>
            <Form.Group
              controlId="formFoto2Disciplina"
              className="row"
              >
                <div className="col-md-6 modal-input">
              <Form.Label>Foto grande</Form.Label>
              <Form.Control
                type="file"
                isInvalid={!isFoto2Valid}
                onChange={(e) => handleFoto2Change(e.target.files[0])}
              />
                <Form.Control.Feedback type="invalid">
                Por favor selecciona una imagen válida.
              </Form.Control.Feedback>
              {(foto2 && isFoto2Valid) && (
                  <img
                    className="img-fluid mb-2 mt-3"
                    style={{ maxHeight: "200px" }}
                    src={URL.createObjectURL(foto2)}
                  />
                )}
               </div>
               <div className="col-md-6 modal-input">
                {actualFoto2Url && (
                    <>
                      <Form.Label>Foto grande actual</Form.Label>
                      <img
                        className="img-fluid mb-2"
                        style={{ maxHeight: "200px" }}
                        src={actualFoto2Url}
                      />
                    </>
                  )}
               </div>
            </Form.Group>
            <Button
              variant={currentDisciplina ? "warning" : "info"}
              type="submit"
              className="btn-modal"
            >
              {currentDisciplina ? "Actualizar" : "Crear"}
            </Button>
            <Button
              variant="secondary"
              onClick={handleReset}
              className="btn-modal"
            >
              Cancelar
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DisciplinaForm;
