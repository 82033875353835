// src/actions/fixtureActions.js

import { fixtureActions } from '../reducers/fixture_slice';
import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const fixturesService = apiService('/fixtures');

export const fetchFixtures = () => async dispatch => {
  try {
    const data = await fixturesService.getAll()
    dispatch(fixtureActions.setFixtures(data));
    return data
    
  } catch (error) {
    toast.error(`Error al cargar la lista de fixtures`);
  }
};
export const fetchFixturesBySubdisciplina = (idsub) => async dispatch => {
  try {
    const data = await fixturesService.getAllBySomeId('subdisciplina', idsub)
    //console.log("en action de fixture despues del controlador con respuesta:",data)
    dispatch(fixtureActions.setFixtures(data));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de fixtures de la subdisciplina`);
  }
};
export const fetchFixturesByDisciplina = (iddisc) => async dispatch => {
  try {
    const data = await fixturesService.getAllBySomeId('disciplina', iddisc)
    //console.log("en action de fixture despues del controlador con respuesta:",data)
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de fixtures de la disciplina`);
  }
};


export const addFixture = (fixture) => async dispatch => {
//  console.log("Entro a action de crear fixtures...con ",fixture)
  try {
    const data = await fixturesService.create(fixture);
    toast.success(`Fixture creado exitosamente`);
  } catch (error) {
    const theError = error.response.data.error;
   //console.log("Mensaje", theError.data)
   //console.log("Status", theError.status)

    toast.error(`Error al crear el nuevo fixture`,theError);
  }
};

export const updateFixture = (id, fixture) => async dispatch => {
  try {
    const data = await fixturesService.update(id, fixture);
    toast.success(`Fixture editado exitosamente`);
  } catch (error) {
    toast.error(`Error al editar el fixture seleccionada`);
  }
};

export const removeFixture= (id) => async dispatch => {
  try {
    const data = await fixturesService.remove(id);
    toast.success(`Fixture eliminado exitosamente`);
  } catch (error) {
    toast.error(`Error al eliminar el fixture seleccionado`);
  }
};
