import { eventoActions } from "../reducers/evento_slice";
import apiService from "../services/apiService";
import { toast } from 'react-toastify';

const eventoService = apiService('/evento');

export const fetchEvento = (gestion) => async dispatch => {
    try {
      const data = await eventoService.getById(gestion)
     dispatch(eventoActions.setEvento(data));

      } catch (error) {
       //console.log('Error al leer datos del evento.');
     }
  };


export const updateEvento = (gestion, updateData) => async dispatch => {
    try {
        const data = await eventoService.update(`${gestion}`, updateData);
       // dispatch(eventoActions.updateEvento(data));
        toast.success(`Datos del evento actualizados...`);
    } catch (error) {
      toast.error('Error al actualizar datos del evento');
    }
  }
export const cambiarFaseEvento = (gestion, fase) => async dispatch => {
  try {
    const data = await eventoService.updateSomeDato("fase", gestion, fase);
   // dispatch(eventoActions.updateEvento(data));
  
    toast.success(`Datos del evento actualizados...`);
} catch (error) {
  toast.error('Error al actualizar datos del evento');
}

}
export const fetchPersonasAsistentesEvento = (gestion) => async dispatch => {
  try {
    const data = await eventoService.getAllBySomeId("personas-asistentes", gestion)
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de personas asistentes del evento`);
  }
};