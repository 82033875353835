import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Table, Button, Modal, FormControl, Form } from 'react-bootstrap';
import { fetchSubdisciplinas, fetchSubdisciplinasByUniversidad } from '../../actions/subdisciplinaActions';
import InscripcionSubdisciplina from './InscripcionSubdisciplina';
import { fetchUniversidadBySigla } from '../../actions/universidadAction';
import { categoryMap, genderMap, getSubdisciplinaFullName } from '../../util/valueCalculator';
import MensajeCentral from '../mensajes/MensajeCentral';
import CustomPrintModal from '../CustomPrintModal';
import PrintModalListas from '../reportes/PrintModalListas';
import { toast } from 'react-toastify';
import InscripcionUniversidad from '../universidad/InscripcionUniversidad';

const SubdisciplinasPreparacion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { funcionalidad } = useParams();
  const universidadSigla = useSelector(state => state.sesion.universidadSigla);
  const universidadParam = location.state? location.state.universidad: null;
  const [universidad, setUniversidad] = useState(null);
  const dispatch = useDispatch();
  const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
  const [selectedSubdisciplina, setSelectedSubdisciplina] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    genero: "",
    categoria: "",
  });
  const evento = useSelector((state) => state.evento);
  const estadoEvento= evento.estado
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [showInscripcionModal, setShowInscripcionModal] = useState(false);
  useEffect(() => {
    if(universidadParam){
      setUniversidad(universidadParam)
    }else if(universidadSigla){
    dispatch(fetchUniversidadBySigla(universidadSigla))
    .then((data)=>{setUniversidad(data)})
  }

}, [dispatch, universidadSigla,universidadParam]);

  useEffect(() => {
    if(universidad)
    dispatch(fetchSubdisciplinasByUniversidad(universidad.id));
  }, [dispatch,universidad]);

  const permitirInscripcion = !funcionalidad || funcionalidad !== '1'
  console.log("estadoEvento", estadoEvento, funcionalidad)
  if (estadoEvento !== 1  && permitirInscripcion) {
    return (
      <MensajeCentral accionBloqueada="inscripcionDocente" accionDesbloqueadora="preinscripcionUniversidades"/>
    );
  }

  const handleDocentesClick = (subdisciplina) => {
    setSelectedSubdisciplina(subdisciplina);
    setShowModal(true);
  };

  const handleFilters = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getGeneroText = (genero) => {
    return genero === 'D' ? 'DAMAS' : 'VARONES';
  };

  const getCategoriaText = (categoria) => {
    switch (categoria) {
      case 'S':
        return 'SENIOR';
      case 'M':
        return 'MASTER';
      case 'L':
        return 'LIBRE';
      default:
        return '';
    }
  };
  

  const handlePrintList = () => {
    setPrintContent(getPrintContent());
    setShowPreviewModal(true);
  }
  
  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const filteredSubdisciplinas = subdisciplinas.filter((subdisciplina) =>
    subdisciplina.nombre.toLowerCase().includes(searchTerm.toLowerCase()) &&
      subdisciplina.genero.includes(filters.genero) &&
      subdisciplina.categoria.includes(filters.categoria)
  );

  // Ordenar por nombre, género y modalidad
  const sortedSubdisciplinas = filteredSubdisciplinas.sort((a, b) => {
     if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) return -1;
    if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) return 1;
     if (a.genero < b.genero) return -1;
    if (a.genero > b.genero) return 1;
    if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
    if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
    return 0;
  });

  
  const getPrintContent = () => {
    const head = [['N°','Nombre', 'Categoría', 'Modalidad']];
    const body = sortedSubdisciplinas.filter((subdis)=>subdis.genero.includes(filters.genero)).map((subdis, index) => [
        index+1,
        subdis.nombre,
        genderMap[subdis.genero],
        categoryMap[subdis.categoria].nombre
    ]);
    return { head, body };
};


  const titulo = permitirInscripcion? "INSCRIPCIÓN POR DISCIPLINA":"SUBDISCIPLINAS PRE-INSCRITAS"
  return (
    <>
      <div className="container-medium">
         <h1>{titulo} - {universidad ? universidad.nombre : universidadSigla}</h1>
   
          <div className="row justify-content-between mb-4 mt-4">
          <Form.Group
        controlId="filtrosSubdisciplinas"
        className="row"
      >
        <div className="col-md-4 modal-input">
          <Form.Label>Buscar</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Categoría</Form.Label>
          <Form.Control
            as="select"
            value={filters.genero}
            onChange={(e) => handleFilters("genero", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="V">VARONES</option>
            <option value="D">DAMAS</option>
          </Form.Control>
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Modalidad</Form.Label>
          <Form.Control
            as="select"
            value={filters.categoria}
            onChange={(e) => handleFilters("categoria", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="L">LIBRE</option>
            <option value="S">SENIOR</option>
            <option value="M">MASTER</option>
          </Form.Control>
        </div>
      </Form.Group>
         </div>
         <div className="container">
  <div className="row mt-3 mb-4">
    <div className="col-3 col-md-2 mb-2 mb-md-0">
      <Button
      size="sm"
        variant="light"
        onClick={() => navigate(-1)}
        style={{ width: "100%" }} // Para asegurarse de que ocupa el ancho completo en pantallas pequeñas
      >
         <i className="bi bi-arrow-left"></i>  Volver
      </Button>
    </div>
   {!permitirInscripcion&& <div className="col-12 col-md-10 d-flex flex-wrap justify-content-md-end">
      <Button
        size="sm"
          variant="outline-dark"
          onClick={handlePrintList}
          className="action-button ml-md-2 mb-2 mb-md-0"
        >
        <i className="bi bi-printer"></i> Imprimir
      </Button>
    </div>}
  </div>
</div>
        <div className="table-container" style={{ height: '700px', overflowY: 'scroll' }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>N°</th>
                <th>Disciplina</th>
                <th>Categoría</th>
                <th>Modalidad</th>
               {permitirInscripcion && (<>
                <th>Acciones</th>
                </>)}
              </tr>
            </thead>
            <tbody>
              {sortedSubdisciplinas.map((subdisciplina, index) => (
                <tr key={subdisciplina.id}>
                  <td>{index + 1}</td>
                  <td>{subdisciplina.nombre}</td>
                  <td>{getGeneroText(subdisciplina.genero)}</td>
                  <td>{getCategoriaText(subdisciplina.categoria)}</td>
                  {permitirInscripcion &&(<>
                  <td>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleDocentesClick(subdisciplina)}
                    >
                      Inscribir Docentes
                    </Button>
                  </td></>
                )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl" centered>
          <Modal.Header closeButton>
            <Modal.Title>Inscripción de Docentes en {selectedSubdisciplina && getSubdisciplinaFullName(selectedSubdisciplina)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedSubdisciplina && (
              <InscripcionSubdisciplina
                subdisciplina={selectedSubdisciplina}
                universidad={universidad}
                onRequestClose={() => setShowModal(false)}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
     {!permitirInscripcion&& <CustomPrintModal
                show={showPreviewModal}
                handleClose={handleClosePreview}
                title1={`${universidad?.sigla} - SUBDISCIPLINAS PREINSCRITAS ${filters.genero===""? "": (filters.genero === "D"?"CATEGORÍA DAMAS": "CATEGORÍA VARONES")}`}
                title2={evento.estado === 0 ?"B O R R A D O R": "A P R O B A D A"}
                content={printContent}
            />
}
 
    </>
  );
};

export default SubdisciplinasPreparacion;
