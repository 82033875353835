// src/reducers/ciudadReducer.js

const initialState = {
    ciudades: [],
    ciudad: null,
  };
  
  const ciudadReducer = (state = initialState, action) => {
   
    switch (action.type) {
      case 'GET_CIUDADES':
        return { ...state, ciudades: action.payload };
      case 'GET_CIUDAD_BY_ID':
        return { ...state, ciudad: action.payload };
      case 'CREATE_CIUDAD':
        return { ...state, ciudades: [...state.ciudades, action.payload] };
      case 'UPDATE_CIUDAD':
        return {
          ...state,
          ciudades: state.ciudades.map(c =>
            c.id === action.payload.id ? action.payload : c
          ),
        };
      case 'DELETE_CIUDAD':
        return {
          ...state,
          ciudades: state.ciudades.filter(c => c.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default ciudadReducer;
  