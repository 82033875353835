import "../../styles/Medalleros.css";
import { Button } from "react-bootstrap";
import Medalla from "./Medalla";

const CardMedallero = ({ medallero}) => {
  const categoria=medallero.Subdisciplina.genero === "V"? "VARONES" : "DAMAS"
  const modalidad = medallero.Subdisciplina.categoria === "S" ? "SENIOR" : 
                  (medallero.Subdisciplina.categoria === "M" ? "MASTER" : 
                  (medallero.Subdisciplina.categoria === "L" ? "LIBRE" : ""));
  
  const classes=categoria === "DAMAS" ? "card-medallero card-damas" : "card-medallero card-varones"
 
  return (
    <div className={classes}>
    <div className="medallero-title">
      <h4>{medallero.Subdisciplina.nombre}</h4>
      <p style={{fontWeight:"1rem"}}>{`${categoria} ${modalidad}`}</p>
    </div>
    <div className="medallero-medals">
      <Medalla medalla="oro" universidad={medallero.OroUniversidad} />
      <Medalla medalla="plata" universidad={medallero.PlataUniversidad} />
      <Medalla medalla="bronce" universidad={medallero.BronceUniversidad} />
    </div>
  </div>
  );
};

export default CardMedallero;
