import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../styles/Medalleros.css";
import { fetchDisciplinas } from "../../actions/disciplinaActions";
import { fetchMedalleros } from "../../actions/medalleroActions";
import { Form, Modal, Button } from "react-bootstrap";
import CardMedallero from "./CardMedallero";
import { medalleroActions } from "../../reducers/medallero_slice";
import FormMedallero from "./FormMedallero";
import CustomPrintModal from "../CustomPrintModal";
import * as XLSX from "xlsx";
import MensajeCentral from "../mensajes/MensajeCentral";
import esperandoImg from "../../assets/esperando.png"
import { categoryMap, genderMap } from "../../util/valueCalculator";
import { sortSubdisciplinas } from "../../util/functions";

const Medalleros = () => {
  const dispatch = useDispatch();
  const [disciplinaId, setDisciplinaId] = useState("");
  const disciplinas = useSelector((state) => state.disciplina.disciplinas);
  const medalleros = useSelector((state) => state.medallero.medalleros);
  const [modal, setModal] = useState({ tipo: 0, titulo: "", body: <></> });
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    genero: "",
    categoria: "",
  });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const estadoEvento= useSelector(state => state.evento.estado)
  const rolActivo = useSelector(state => state.sesion.rolActivo);
 
  useEffect(() => {
    dispatch(fetchDisciplinas());
    dispatch(fetchMedalleros());
  }, [dispatch]);
  // if (estadoEvento < 2) {
  //   return (
  //     <>
  //     <MensajeCentral image={  <img src={esperandoImg} alt="Ya inician los juegos" width={200} height={200}/> }>
  //           Esperando a que inicien los juegos... 
  //     </MensajeCentral>
  //     </>
  //   );
  // }
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
    };
  
    const handleFilters = (filter, value) => {
      setFilters((prev) => ({ ...prev, [filter]: value }));
    };
  
    const handleShowModal = (tipo, medallero) => {
      dispatch(medalleroActions.setCurrentMedallero(medallero));
      let titulo = "";
      let body = <></>;
      if (tipo === 2) {
        titulo = "Editar Medallero";
        body = <FormMedallero onHandleClose={handleClose} />;
      }
      setModal({ tipo, titulo, body });
    };
  
    const handleClose = () => {
      dispatch(medalleroActions.setCurrentMedallero(null));
      setModal({ tipo: 0, titulo: "", body: <></> });
    };
  
    let listMedalleros = medalleros;
    if (disciplinaId) {
      listMedalleros = medalleros.filter(
        (medallero) => `${medallero.Subdisciplina.iddisc}` === disciplinaId
      );
    }
  
    const filteredMedalleros = listMedalleros
      .filter(
        (medallero) =>{
          return(
          medallero.Subdisciplina.nombre.toLowerCase().includes(searchTerm.toLowerCase()) &&
          medallero.Subdisciplina.genero.includes(filters.genero) &&
          medallero.Subdisciplina.categoria.includes(filters.categoria)&&
          medallero.Subdisciplina.estado === 1)}
      )
      .sort((a, b) => sortSubdisciplinas(a.Subdisciplina, b.Subdisciplina, categoryMap));
  
    const getContentForPrintAndExport = () => {
      const content = {
        head: [['N°', 'SUBDISCIPLINA', 'CATEGORÍA', 'MODALIDAD', 'ORO', 'PLATA', 'BRONCE']],
        body: filteredMedalleros.map((medallero, index) => [
          index + 1,
          medallero.Subdisciplina.nombre,
          genderMap[medallero.Subdisciplina.genero], // Asignar categoría a género
          categoryMap[medallero.Subdisciplina.categoria].nombre, // Asignar modalidad a categoría
          medallero.OroUniversidad ? medallero.OroUniversidad.sigla : 'Sin definir',
          medallero.PlataUniversidad ? medallero.PlataUniversidad.sigla : 'Sin definir',
          medallero.BronceUniversidad ? medallero.BronceUniversidad.sigla : 'Sin definir',
        ]),
      };
      return content;
    };
  
    const handleShowPreview = () => {
      setPrintContent(getContentForPrintAndExport());
      setShowPreviewModal(true);
    };
  
    const handleClosePreview = () => {
      setShowPreviewModal(false);
    };
  
    const handleExportExcel = () => {
      const data = filteredMedalleros.map((medallero, index) => ({
        Nro: index + 1,
        Subdisciplina: medallero.Subdisciplina.nombre,
        Categoria: genderMap[medallero.Subdisciplina.genero], // Asignar categoría a género
        Modalidad: categoryMap[medallero.Subdisciplina.categoria].nombre, // Asignar modalidad a categoría
        Oro: medallero.OroUniversidad ? medallero.OroUniversidad.sigla : 'Sin definir',
        Plata: medallero.PlataUniversidad ? medallero.PlataUniversidad.sigla : 'Sin definir',
        Bronce: medallero.BronceUniversidad ? medallero.BronceUniversidad.sigla : 'Sin definir',
      }));
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, filters.genero===""? "Todos los medalleros": (filters.genero === "D"?"CATEGORÍA DAMAS": "CATEGORÍA VARONES"));
      XLSX.writeFile(workbook, 'medalleros_por_subdisciplinas.xlsx');
    };
  
    return (
      <>
        <h1>Medalleros</h1>
        <Form>
          <Form.Group className="row">
            <div className="col-6 col-md-4 modal-input">
              <Form.Label>Buscar</Form.Label>
              <Form.Control
                type="text"
                placeholder="Buscar por nombre de subdisciplina"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="col-6 col-md-3 modal-input">
              <Form.Label>Disciplina</Form.Label>
              <Form.Control
                as="select"
                value={disciplinaId}
                onChange={(e) => setDisciplinaId(e.target.value)}
              >
                <option key="default" value="">
                  - Todas -
                </option>
                {disciplinas.map((disciplina) => (
                  <option key={disciplina.id} value={disciplina.id}>
                    {disciplina.nombre.toUpperCase()}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-6 col-md-2 modal-input">
              <Form.Label>Categoría</Form.Label>
              <Form.Control
                as="select"
                value={filters.genero}
                onChange={(e) => handleFilters("genero", e.target.value)}
                required
              >
                <option value="">- Todas -</option>
                <option value="V">VARONES</option>
                <option value="D">DAMAS</option>
              </Form.Control>
            </div>
            <div className="col-6 col-md-3 modal-input">
              <Form.Label>Modalidad</Form.Label>
              <Form.Control
                as="select"
                value={filters.categoria}
                onChange={(e) => handleFilters("categoria", e.target.value)}
                required
              >
                <option value="">- Todas -</option>
                <option value="L">LIBRE</option>
                <option value="S">SENIOR</option>
                <option value="M">MASTER</option>
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
       {rolActivo === "admin_evento"&&  <div className="d-flex justify-content-end mb-4">
          <Button variant="dark" onClick={handleShowPreview} className="mr-2">
            Imprimir PDF
          </Button>
          <Button variant="success" onDoubleClick={handleExportExcel}>
            Exportar Excel
          </Button>
        </div>}
        <div className="medalleros-list">
          {filteredMedalleros.map((medallero) => (
            <CardMedallero
              key={medallero.Subdisciplina.id}
              medallero={medallero}
              showModal={handleShowModal}
            />
          ))}
        </div>
        <Modal show={modal.tipo > 0} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>{modal.titulo}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modal.body}</Modal.Body>
        </Modal>
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1={`MEDALLEROS POR SUBDISCIPLINAS ${filters.genero===""? "": (filters.genero === "D"?"CATEGORÍA DAMAS": "CATEGORÍA VARONES")}`}
          title2={estadoEvento < 3 ? "B O R R A D O R" : "A P R O B A D A"}
          content={printContent}
        />
      </>
    );

  }
 


export default Medalleros;
