import { getProcesos, getProcesoById, createProceso, updateProceso, deleteProceso } from '../services/procesoService';

export const fetchProcesos = () => async dispatch => {
  try {
    const data = await getProcesos();
   //console.log("despues de service procesos:",data)
    dispatch({ type: 'GET_PROCESOS', payload: data });
  } catch (error) {
    console.error('fetchProcesos error:', error);
  }
};

export const fetchProcesoById = (id) => async dispatch => {
  try {
    const data = await getProcesoById(id);
    dispatch({ type: 'GET_PROCESO_BY_ID', payload: data });
  } catch (error) {
    console.error('fetchProcesoById error:', error);
  }
};

export const addProceso = (proceso) => async dispatch => {
  try {
    const data = await createProceso(proceso);
    dispatch({ type: 'CREATE_PROCESO', payload: data });
  } catch (error) {
    console.error('addProceso error:', error);
  }
};

export const modifyProceso = (id, proceso) => async dispatch => {
  try {
    const data = await updateProceso(id, proceso);
    dispatch({ type: 'UPDATE_PROCESO', payload: data });
  } catch (error) {
    console.error('modifyProceso error:', error);
  }
};

export const removeProceso = (id) => async dispatch => {
  try {
    const data = await deleteProceso(id);
    dispatch({ type: 'DELETE_PROCESO', payload: id });
  } catch (error) {
    console.error('removeProceso error:', error);
  }
};
