import { useDispatch, useSelector } from "react-redux";
import { fetchFixturesBySubdisciplina, removeFixture } from "../../actions/fixtureActions";
import { Button } from "react-bootstrap";

const DeleteFixture = ({ onHandleClose }) => {
  const dispatch = useDispatch();
  const currentFixture = useSelector((state) => state.fixture.current);

  const handleDelete = () => {
    dispatch(removeFixture(currentFixture.id)).then(() => {
      onHandleClose();
      dispatch(fetchFixturesBySubdisciplina(currentFixture.idsub)); // Actualiza la lista de fixtures
    });
  };

  return (
    <>
      <p>
        ¿Estás seguro que deseas eliminar el fixture?
      </p>
      <div className="custom-button-group">
        <Button
          variant="secondary"
          onClick={onHandleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          className="ml-2"
        >
          Eliminar
        </Button>
      </div>
    </>
  );
};

export default DeleteFixture;