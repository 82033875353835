import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Subdisciplina.css";
import "../../styles/Disciplina.css";
import { UPLOADS_BASE_URL } from "../../util/axiosInstance";
import { getAuthToken } from "../../util/auth";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Row, Col } from "react-bootstrap";
import defaultImage from "../../assets/disciplinas/default.jpg";
import {
  fetchSubdisciplinaById,
  fetchSubdisciplinas,
  fetchSubdisciplinasByDisciplinaId,
  removeSubdisciplina,
} from "../../actions/subdisciplinaActions";
import SubdisciplinaForm from "./SubdisciplinaForm";
import { categoryMap, genderMap, getUploadUrl } from "../../util/valueCalculator";

const token = getAuthToken();

const Subdisciplina = ({subdisciplinaProp = null, onClose = () => {}}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subdisId } = useParams();
  const [fotoPreview, setFotoPreview] = useState(null);
  const subdisciplina = useSelector(
    (state) => state.subdisciplina.subdisciplina
  );
  const estadoEvento= useSelector(state => state.evento.estado)
  const disciplina = useSelector((state) => state.disciplina.disciplina);
  const rolActivo = useSelector(state => state.sesion.rolActivo);
 
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    
    if (subdisciplina) {
      if(subdisciplinaProp && subdisciplinaProp.id !== subdisciplina.id){
        dispatch(fetchSubdisciplinaById(subdisciplinaProp.id));
      }else{
      if (subdisciplina.foto1) {
        setFotoPreview(getUploadUrl(subdisciplina.foto1));
      } else if(disciplina)
              {if(disciplina.foto1) setFotoPreview(getUploadUrl(disciplina.foto1));
               else if(disciplina.foto2) setFotoPreview(getUploadUrl(disciplina.foto2))
              }
      }
      } else if(subdisId){
      dispatch(fetchSubdisciplinaById(subdisId));
      }else if(subdisciplinaProp){
        dispatch(fetchSubdisciplinaById(subdisciplinaProp.id));
      }
  }, [subdisciplina]);

  const handleImageError = () => {
    setFotoPreview(defaultImage);
  };

  const handleDelete = async () => {
    if (
      window.confirm("¿Está seguro de que desea eliminar esta subdisciplina?")
    ) {
      dispatch(removeSubdisciplina(subdisciplina.id)).then(() => {
        navigate(`/disciplina-admin/${subdisciplina.iddisc}`);
      });
    }
  };

  const handleEdit = () => {
    dispatch(fetchSubdisciplinaById(subdisciplina.id));
    setShowModal(true);
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (!subdisciplina) {
    return <p>Subdisciplina no encontrada</p>;
  }
  
  const handleBack = () => {
    if(subdisciplinaProp){onClose()}
   else{ navigate(`/disciplina-admin/${subdisciplina.iddisc}`)}
  }
  return (
    <div className="subdisciplina-page">
      <Button
        variant="light"
        onClick={() => handleBack()}
        className="back-button"
      >
        <i className="bi bi-arrow-left"></i> Volver
      </Button>
      <div className="subdisciplina-container">
        {fotoPreview !== '' && <div
          className="subdisciplina-image-container"
          style={{ backgroundImage: `url(${fotoPreview})` }}
          onError={handleImageError}
        ></div>}
       
        <div className="subdisciplina-details-container">
          <h1 className="subdisciplina-title" style={{fontSize:subdisciplinaProp? "1.7rem":""}}>
            {subdisciplina.nombre} <br/> {`${genderMap[subdisciplina.genero]} ${categoryMap[subdisciplina.categoria].nombre}`}</h1>
          <div className="subdisciplina-info">
            <div style={{ whiteSpace: 'pre-wrap' }} className="subdisciplina-info-item">
              <p>{subdisciplina.relato}</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong>Categoría</strong>
              <p>{subdisciplina.genero === "V" ? "VARONES" : "DAMAS"}</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong>Modalidad</strong>
              <p>
                {subdisciplina.categoria === "L"
                  ? "LIBRE"
                  : subdisciplina.categoria === "M"
                  ? "MASTER"
                  : "SENIOR"}
              </p>
            </div>
            <div className="subdisciplina-info-item">
              <strong>Cantidad mínima requerida</strong>
              <p>{subdisciplina.edadmax} deportista(s)</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong>Cantidad máxima permitida</strong>
              <p>{subdisciplina.maxdep} deportista(s)</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong>Edad Mínima</strong>
              <p>{subdisciplina.edadmin} años</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong>Tipo de participación</strong>
              <p>{subdisciplina.tipo_participacion.toUpperCase()}</p>
            </div>
          
            { rolActivo==="admin_evento" &&
            <div className="subdisciplina-info-item">
              <strong>Estado</strong>
              <p>{subdisciplina.estado === 1 ? "ACTIVO" : "INACTIVO"}</p>
            </div>}
          </div>
        </div>
      </div>
     { estadoEvento === 0 && rolActivo==="admin_evento" && <div className="subdisciplina-buttons">
        <Button
          variant="warning"
          onClick={() => handleEdit(subdisciplina)}
          className="action-button"
        >
          <i className="bi bi-pencil"></i> Editar
        </Button>
        <Button
          variant="danger"
          onClick={() => handleDelete(subdisciplina.id)}
          className="action-button"
        >
          <i className="bi bi-trash"></i> Eliminar
        </Button>
        {/* <Button
          variant="info"
          disabled
          onClick={() => navigate(`/fixture/${subdisciplina.id}`)}
        >
          <i className="bi bi-calendar"></i> Gestionar Calendario
        </Button>
        <Button
          variant="info"
          disabled
          onClick={() => navigate(`/noticias/${subdisciplina.id}`)}
        >
          <i className="bi bi-newspaper"></i> Gestionar Noticias
        </Button>
        <Button
          variant="info"
          disabled
          onClick={() => navigate(`/medallero/${subdisciplina.id}`)}
        >
          <i className="bi bi-trophy"></i> Gestionar Medallero
        </Button> */}
      </div>}
      <SubdisciplinaForm
        showModal={showModal}
        handleClose={handleCloseModal}
        currentSubdisciplina={subdisciplina}
      />
    </div>
  );
};

export default Subdisciplina;
