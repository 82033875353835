import React from 'react';
import { Button } from "react-bootstrap";
import { estadoDocente } from '../../util/valueCalculator';
import { useSelector } from 'react-redux';

const ItemDocente = ({ docente, indice, universidadSigla, showModal, inscripcionDocente, funcionalidad }) => {
    const {color, estadoName } = estadoDocente(docente.estado)
    const estadoEvento= useSelector(state => state.evento.estado)

    return (
        <tr>
            <td>{indice}</td>
            <td>{docente.ci}</td>
            <td>{docente.appat}</td>
            <td>{docente.apmat}</td>
            <td>{docente.nombres}</td>
            <td>{universidadSigla}</td>
            <td><span className={`badge text-bg-${color}`}>{estadoName}</span></td>
            {/* <td>{docente.estado === 1 ? "Activo" : "Inactivo"}</td> */}
            <td>
            
                <Button
                    variant="outline-dark"
                    size="sm"
                    onClick={() => showModal(4, docente)}
                    className="action-button"
                >
                    Perfil
                </Button>
                {docente.estado < 3 && estadoEvento <2 && (
                <>
                    {(funcionalidad === 1 || funcionalidad === 2) && (
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={inscripcionDocente}
                            className="action-button"
                        >
                            Inscripción
                        </Button>
                    )}
                    {funcionalidad === 3 && (
                        <>
                            <Button
                                variant="outline-warning"
                                size="sm"
                                onClick={() => showModal(2, docente)}
                                className="action-button"
                            >
                                <i className="bi bi-pencil"></i>
                            </Button>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => showModal(3, docente)}
                            >
                                <i className="bi bi-trash"></i>
                            </Button>
                        </>
                    )}
                </>
            )}
            </td>
        </tr>
    );
}

export default ItemDocente;
