import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Modal, Row, Alert } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { formatShowFecha, fasesEvento } from "../../util/valueCalculator";
import FaseForm from "./FaseForm";
import { fetchEvento } from "../../actions/eventoAction";
import MensajeCentral from "../mensajes/MensajeCentral";
import ModalCambioFase from "./ModalCambioFase";

const EventoIndex = () => {
  const evento = useSelector(state => state.evento);
  const [showModal, setShowModal] = useState({ tipo: 0, title: "", body: <></> });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ cambio, setCambio ] = useState(-2)

  useEffect(()=>{
    dispatch(fetchEvento(evento.gestion))
  },[dispatch])

  const fase = fasesEvento(evento.estado);

  const handleShowModal = (tipo) => {
    let modal = {};
    if (tipo === 1) {
      modal = {
        tipo: 1,
        title: "Modificar datos generales",
        body: <></>
      };
    } else if (tipo === 2) {
      if(evento.estado === 0){
        modal = {
          tipo: 3,
          title: "Cambiar de fase",
          body: <MensajeCentral>Para avanzar de fase debe grabar la  <span className="highlight">participación de las universidades</span> en las subdisciplinas.</MensajeCentral>
        }
      }else{
      modal = {
        tipo: 1,
        title: "Cambiar de fase",
        body: <FaseForm gestion={evento.gestion} handleClose={handleClose} />
      };}
    }
    setShowModal(modal);
  };

  const handleClose = (cambio) => {
    setCambio(Number(cambio));
    setShowModal({ tipo: 0, title: "", body: <></> });
  };

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <> 
      <Card border="1" style={{ width: '50%', margin: '0 auto', marginTop: '20px' }}>
        <Card.Body>
          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }} className="text-center">
              <Card.Text>
                <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                  {evento.nombre}
                </span>
                <br/>
                <br/>
              </Card.Text>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} style={{ fontSize: '1.4rem' }}>
              <Card.Text>
                <strong>ACRÓNIMO: </strong>
                <span className="d-inline d-md-none"><br/></span>
                <span className="d-inline d-md-inline-block">{evento.acronimo}</span>
              </Card.Text>
              <Card.Text>
                <strong>FECHA DE INICIO: </strong>
                <span className="d-inline d-md-none"><br/></span>
                <span className="d-inline d-md-inline-block">{formatShowFecha(evento.fechain)}</span>
              </Card.Text>
              <Card.Text>
                <strong>FECHA DE FINALIZACIÓN: </strong>
                <span className="d-inline d-md-none"><br/></span>
                <span className="d-inline d-md-inline-block">{formatShowFecha(evento.fechafin)}</span>
              </Card.Text>
              <Card.Text>
                <strong>FECHA LÍMITE DE INSCRIPCIONES: </strong>
                <span className="d-inline d-md-none"><br/></span>
                <span className="d-inline d-md-inline-block">{formatShowFecha(evento.fechalim)}</span>
              </Card.Text>
              <Card.Text>
                <strong>FASE ACTUAL: </strong>
                <span className="d-inline d-md-none"><br/></span>
                <span className="d-inline d-md-inline-block">{fase}</span>
              </Card.Text>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Alert variant="warning" className="d-flex align-items-center">
                <FaExclamationTriangle className="mr-2" size="1.5em" />
                <span>El proceso de cambio de fase es crítico, por lo que le recomendamos que tenga mucho cuidado, ya que podría tener efectos colaterales en los datos.</span>
              </Alert>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
        <Button onClick={handleBack} className="m-3" variant="light">
          <i className="bi bi-arrow-left"></i>  Volver</Button>
        
           <Button onClick={() => handleShowModal(2)} className="m-3" variant="warning">Cambiar de fase</Button>
        </Card.Footer>
      </Card>
      <Modal
        show={showModal.tipo > 0}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {showModal.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showModal.body}
        </Modal.Body>
      </Modal>
      <ModalCambioFase show={cambio >= 0} onClose={()=>setCambio(-2)} fase={cambio}/>
    </>
  );
}

export default EventoIndex;
