import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProcesos, removeProceso } from '../../actions/procesoActions';
import { selectProcesos } from '../../selectors';
import ProcesoForm from './ProcesoForm';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ActionsContainer from '../generales/ActionsContainer';
import CustomPrintModal from '../CustomPrintModal';
//import './ProcesoList.css'; // Importa tu archivo CSS

// FUNCION para imprimir
function printList(sortedList) {
  const content = {
    head: [['ID', 'Nombre', 'Descripción', 'Estado']],
    body: sortedList.map(proceso => [
      proceso.id,
      proceso.nombre,
      proceso.descripcion,
      proceso.estado === 1 ? 'Activo' : 'Inactivo'
    ])
  };
  return content;
}

// COMPONENTE ProcesoList
const ProcesoList = () => {
  const dispatch = useDispatch();
  const procesos = useSelector(selectProcesos);
  const [showModal, setShowModal] = useState(false);
  const [currentProceso, setCurrentProceso] = useState(null);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });

  useEffect(() => {
      dispatch(fetchProcesos());
  }, [dispatch]);

  const handleDelete = id => {
    dispatch(removeProceso(id));
  };

  const handleEdit = proceso => {
    setCurrentProceso(proceso);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentProceso(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentProceso(null);
  };

  const sortedList = [...procesos].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }
    return a[sortBy] < b[sortBy] ? -1 : 1;
  });

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['ID', 'Nombre', 'Descripción', 'Estado']],
      body: sortedList.map(proceso => [
        proceso.id,
        proceso.nombre,
        proceso.descripcion,
        proceso.estado === 1 ? 'Activo' : 'Inactivo'
      ])
    };
    return content;
  };

  return (
    <div className="contenedor container-medium">
      <h1>Lista de Procesos</h1>
      
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="mr-2">Ordenar por:</Form.Label>
          <Form.Control
            as="select"
            value={sortBy}
            onChange={e => setSortBy(e.target.value)}
            className="mr-3"
          >
            <option value="id">ID</option>
            <option value="nombre">Nombre</option>
          </Form.Control>
          <Form.Control
            as="select"
            value={sortOrder}
            onChange={e => setSortOrder(e.target.value)}
          >
            <option value="asc">Ascendente</option>
            <option value="desc">Descendente</option>
          </Form.Control>
        </Form.Group>
        <Button variant="info" onClick={handleCreate} className="mb-3">
          <i className="bi bi-plus-lg"></i>
          Nuevo Proceso
        </Button>
      </div>
      <div className='table-container1' style={{ maxHeight: '650px', overflowY: 'scroll' }}>
        <Table striped bordered hover className="table-bordered">
          <thead className="sticky-header1">
            <tr>
              <th className='col-cod'>ID</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Estado</th>
              <th className='col-actions'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map(proceso => (
              <tr key={proceso.id}>
                <td>{proceso.id}</td>
                <td>{proceso.nombre}</td>
                <td>{proceso.descripcion}</td>
                <td>{proceso.estado === 1 ? 'Activo' : 'Inactivo'}</td>
                <td>
                  <Button variant="outline-warning" size="sm" onClick={() => handleEdit(proceso)} className="action-button">
                    <i className="bi bi-pencil"></i>
                  </Button>
                  <Button variant="outline-danger" size="sm" onClick={() => handleDelete(proceso.id)} className="action-button">
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
     
      <ActionsContainer onPrint={handleShowPreview} />
      <ProcesoForm currentProceso={currentProceso} showModal={showModal} handleClose={handleClose} />
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="LISTA DE PROCESOS"
        title2=""
        content={printContent}
        copia={false}
      />
    </div>
  );
};

export default ProcesoList;
