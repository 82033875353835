import { createSlice } from "@reduxjs/toolkit";
const initialState ={
  docentes: [],
  current: null
}
const docenteSlice= createSlice({
  name: "docente",
  initialState: initialState,
  reducers: {
    setDocentes(state, action) {
      return { ...state, docentes: action.payload };  
    },

    setCurrentDocente(state, action) {
      return { ...state, current: action.payload }; 
    }
  }
});

export const docenteActions = docenteSlice.actions;
export default docenteSlice.reducer;
