import { createSlice } from "@reduxjs/toolkit";
import { formatearFecha } from "../util/valueCalculator";
const initialState = {
  gestion: 2024,
  acronimo: "JUDENADUB",
  nombre: "XVIII Juegos Deportivos Nacionales de Docentes Universitarios",
  fechain: "2024-08-31",
  fechafin: "2024-09-07",
  fechalim: "2024-08-15",
  fechaedad: "2024-08-31",
  logo: null,
  logox: null,
  relato:
    'La Confederación Universitaria de Docentes de Bolivia (CUD), a través de su Secretaria de Deportes, convoca todo el estamento docente nacional a participar de los "XVIII Juegos Deportivos Nacionales de Docentes Universitarios de Bolivia" a realizarse en la ciudad de Trinidad Sede de la Universidad Autónoma del Beni "José Ballivián" del 31 de agosto al 07 de septiembre del año 2024',
  maxsub: 3,
};

const eventoSlice = createSlice({
  name: "evento",
  initialState: initialState,
  reducers: {
    setEvento(state, action) {
      if (action.payload){
      const evento = {
        ...action.payload,
        fechain: formatearFecha(action.payload.fechain),
        fechafin: formatearFecha(action.payload.fechafin),
        fechalim: formatearFecha(action.payload.fechalim),
        fechaedad: formatearFecha(action.payload.fechaedad),
      }

      localStorage.setItem('eventoData', JSON.stringify(evento));
  
      return evento;
      }
      else {return initialState;}
    },
    setEventoStoredData() {
      const eventoData = localStorage.getItem('eventoData');
      
      if (eventoData) {
        try{
          const evento = JSON.parse(eventoData);
          return evento;

        } catch (error) {
          console.error('Error parsing evento data from localStorage:', error);
      } }
    }
  },
});

export const eventoActions = eventoSlice.actions;
export default eventoSlice.reducer;
