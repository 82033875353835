import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const tecnicosService = apiService(`/tecnicos`);

export const updateIngresoTecnico = (id) => async dispatch => {
  try {
    const data = await tecnicosService.updateIngreso(id);
    toast.success(data.message);
  } catch (error) {
    toast.error(error.message || 'Error al actualizar el ingreso del técnico.');
  }
};


export const fetchTecnicos = () => async dispatch => {
    try {
      const data = await tecnicosService.getAll()
      return data
    } catch (error) {
      toast.error(`Error al cargar la lista de tecnicos`);
    }
  };
  export const fetchTecnicosByUniversidad = (iduniv) => async dispatch => {
    try {
      const data = await tecnicosService.getAllBySomeId('universidad', iduniv)
      return data;
    } catch (error) {
      toast.error(`Error al cargar la lista de tecnicos de la universidad`);
    }
  };
  
export const fetchDocenteDataByCi = (ci) => async dispatch => {
    try {
      const data = await tecnicosService.getAllBySomeId("docentes",ci);
      return data;
    } catch (error) {
      toast.error("Hubo problemas al obtener datos de la persona...")
    }
  };
  
export const addTecnico = (tecnico) => async dispatch => {
    try {
      const data = await tecnicosService.create(tecnico);
      return data
    } catch (error) {
      throw error.response.data.errors;
      toast.error("Hubo problemas al crear el tecnico, revise los datos... no puede haber dos tecnicos con los mismos datos")
    }
  };
  
  export const modifyTecnico = (id, tecnico) => async dispatch => {
    try {
      const data = await tecnicosService.update(id, tecnico);
      return data
    } catch (error) {
     //console.log("Error", error)
      throw error.response.data.errors;
    }
  };

  export const cambiarEstadoTecnico = (id, estado) => async dispatch => {
    try {
      const data = await tecnicosService.updateSomeDato("estado", id, estado);
      return data
    } catch (error) {
     //console.log("Error", error)
      throw error.response.data.errors;
    }
  };

  export const removeTecnico = (id) => async dispatch => {
    try {
      const data = await tecnicosService.remove(id);
      return data
    } catch (error) {
      toast.error('Error al eliminar personal extra, verifique sus dependencias.');
    }
  };
    
  export const updateSubdisciplinas = (iddoc, formData) => async dispatch => {
    try {
      const data = await tecnicosService.updateSomeDato("subdisciplinas", iddoc, formData);
      return data
    } catch (error) {
     //console.log("Error", error)
      throw error.response.data.errors;
    }
  };