import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

const App = () => {
 
  return (
    <RouterProvider router={router} />
  );
};

export default App;


