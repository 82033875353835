import { useDispatch, useSelector } from "react-redux";
import { fetchUniversidades } from "../../actions/universidadAction";
import {
  addDocente,
  fetchDocentes,
  fetchDocentesByUniversidad,
  updateDocente,
} from "../../actions/docenteActions";
import { useEffect, useState } from "react";
import { Button, Form, Col, Row, Image } from "react-bootstrap";
import placeholder from "../../assets/docentes/docente_default.png";
import { UPLOADS_BASE_URL } from "../../util/axiosInstance";
import { useInputFile } from "../../hooks/useInputImage";
import { formatearFecha, getUploadUrl } from "../../util/valueCalculator";
import { toast } from "react-toastify";

const currentDate = new Date();
const minDate = new Date(
  currentDate.getFullYear() - 100,
  currentDate.getMonth(),
  currentDate.getDate()
)
  .toISOString()
  .split("T")[0];
const maxDate = new Date(
  currentDate.getFullYear() - 20,
  currentDate.getMonth(),
  currentDate.getDate()
)
  .toISOString()
  .split("T")[0];

const DocenteForm = ({ universidad, onHandleClose, funcionalidad, titulo }) => {
  const [datos, setDatos] = useState({
    nombres: "",
    appat: "",
    apmat: "",
    ci: "",
    iduniv: universidad.id,
    fecnac: "",
    tipo: "docente",
    relato: "",
    genero: "",
    estado: 1,
  });
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();

  const dispatch = useDispatch();
  const currentDocente = useSelector((state) => state.docente.current);
  const [ isSending, setIsSending ] = useState(false)
  useEffect(() => {
    if (currentDocente) {
      const { Universidad, fecnac, ...datosDocente } = currentDocente;
      const formattedFecnac = fecnac ? formatearFecha(fecnac) : "";
      setDatos({ ...datosDocente, fecnac: formattedFecnac });
    }
  }, [currentDocente]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    const fd = new FormData(e.target);
   //console.log("Datos a enviar", datos, foto1)
    fd.set("iduniv", datos.iduniv);
    fd.set("foto1", foto1);
    fd.set("nombres", datos.nombres.trim().toUpperCase());
    fd.set("appat", datos.appat.trim().toUpperCase());
    fd.set("apmat", datos.apmat.trim().toUpperCase());  
    fd.set("ci", datos.ci.trim());      
    
    setIsSending(true)
    if (currentDocente && currentDocente.estado < 3) {
      dispatch(updateDocente(currentDocente.id, fd)).then(() => {
        setIsSending(false)
        onHandleClose();
        dispatch(fetchDocentesByUniversidad(universidad.id));
      })
      .catch((errores) => {
        if (Array.isArray(errores)) {
          for (const err of errores) {
            toast.error(err);
          }
        } else {
          toast.error('Ocurrió un error inesperado.');
        }
        setIsSending(false)
      });
    } else {
      dispatch(addDocente(fd)).then(() => {
        setIsSending(false)
        onHandleClose();
        dispatch(fetchDocentesByUniversidad(universidad.id));
      })
      .catch((errores) => {
        if (Array.isArray(errores)) {
          for (const err of errores) {
            toast.error(err);
          }
        } else {
          toast.error('Ocurrió un error inesperado.');
        }
        setIsSending(false)
      });
    }
  }

  const actualFoto1Url = currentDocente && currentDocente.foto1 ? getUploadUrl(currentDocente.foto1) : null;
   
  return (
    <div className="contenedor">
          <Form
            onSubmit={handleSubmit}
            className="modal-oval"
          >
            <Form.Group className="row">
              <div className="col-md-8 modal-input">
                <Form.Label>Universidad</Form.Label>
                <Form.Control
                  as="select"
                  name="iduniv"
                  value={datos.iduniv}
                  onChange={handleInputChange}
                  required
                  disabled={true}
                >
                  <option value={universidad.id}>{universidad.nombre}</option>
                </Form.Control>
              </div>
             
            </Form.Group>
            <Form.Group className="row">
              <div className="col-md-6 modal-input">
                <Form.Label>Foto</Form.Label>
                  <Form.Control
                    type="file"
                    name="foto1"
                    isInvalid={!isFoto1Valid}
                    onChange={(e) => handleFoto1Change(e.target.files[0])}
                    disabled={isSending}
                  />
                   <Form.Control.Feedback type="invalid">
                            Por favor selecciona una imagen válida.
                    </Form.Control.Feedback>
                    {(foto1 && isFoto1Valid) && (
                        <img
                        className="img-fluid img-cuadrada-150 mb-2 mt-3"
                         src={URL.createObjectURL(foto1)}
                        />
                    )}
                </div>
                <div className="col-md-6 modal-input" style={{display:"flex", flexDirection:"column"}}>
                  {actualFoto1Url && (
                      <>
                      <Form.Label>Foto actual</Form.Label>
                      <Image 
                          className="img-fluid img-cuadrada-150 mb-2"
                          src={actualFoto1Url}
                      />
                      </>
                  )}
                  </div>
            
            </Form.Group>
            <Form.Group className="row">
              <div className="col-md-4 modal-input">
                <Form.Label>Nombres</Form.Label>
                <Form.Control
                  type="text"
                  name="nombres"
                  placeholder="Nombre(s)"
                  value={datos.nombres}
                  onChange={handleInputChange}
                  required
                  disabled={isSending}
                />
              </div>
              <div className="col-md-4 modal-input">
                <Form.Label>Apellido paterno</Form.Label>
                <Form.Control
                  type="text"
                  name="appat"
                  placeholder="Primer apellido"
                  value={datos.appat}
                  onChange={handleInputChange}
                  required
                  disabled={isSending}
                />
              </div>
              <div className="col-md-4 modal-input">
                <Form.Label>Apellido materno</Form.Label>
                <Form.Control
                  type="text"
                  name="apmat"
                  placeholder="Segundo apellido"
                  value={datos.apmat}
                  onChange={handleInputChange}
                  required
                  disabled={isSending}
                />
              </div>
            </Form.Group>
            <Form.Group className="row">
            <div className="col-md-4 modal-input">
                <Form.Label>C.I.</Form.Label>
                <Form.Control
                  type="text"
                  name="ci"
                  placeholder="N° Cédula de Identidad"
                  value={datos.ci}
                  onChange={handleInputChange}
                  required
                  disabled={isSending}
                />
              </div>
              <div className="col-md-4 modal-input">
                
                <Form.Label>Género</Form.Label>
                <Form.Control
                  as="select"
                  name="genero"
                  value={datos.genero}
                  onChange={handleInputChange}
                  required
                  disabled={isSending}
                >
                  <option value="" disabled>Seleccionar género</option>
                  <option value="D">FEMENINO</option>
                  <option value="V">MASCULINO</option>
                </Form.Control>
              </div>
              <div className="col-md-4 modal-input">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  name="fecnac"
                  value={datos.fecnac}
                  onChange={handleInputChange}
                  min={minDate}
                  max={maxDate}
                  required
                  disabled={isSending}
                />
              </div>
            </Form.Group>
            <Form.Group className="row">
              <div className="col-md-8 modal-input">
                <Form.Label>Relato</Form.Label>
                <Form.Control
                  as="textarea"
                  name="relato"
                  placeholder="Relato"
                  value={datos.relato}
                  onChange={handleInputChange}
                  disabled={isSending}
                  maxLength={255} 
                />
              </div>
          
            </Form.Group>
         
            <Button
              variant={currentDocente ? "warning" : "info"}
              type="submit"
              className="btn-modal"
              disabled={isSending}
            >
              {isSending ? "Enviando datos..." :(currentDocente ? "Actualizar" : "Crear")}
            </Button>
            <Button
              variant="secondary"
              onClick={onHandleClose}
              className="btn-modal"
            >
              Cancelar
            </Button>
          </Form>
    </div>
  );
};

export default DocenteForm;
