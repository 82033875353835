import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/roles');

export const getRoles = async () => {
  const response = await api.get('/');
  return response.data;
};

export const getRolById = async (id) => {
  const response = await api.get(`/${id}`);
  return response.data;
};

export const createRol = async (rol) => {
  const response = await api.post('/', rol);
  return response.data;
};

export const updateRol = async (id, rol) => {
  const response = await api.put(`/${id}`, rol);
  return response.data;
};

export const deleteRol = async (id) => {
  const response = await api.delete(`/${id}`);
  return response.data;
};
