import { createSlice } from "@reduxjs/toolkit";
const initialState ={
  universidades: [],
  subdisciplinas: [],
  universidad: null
}
const universidadSlice= createSlice({
  name: "universidad",
  initialState: initialState,
  reducers: {
    setUniversidades(state, action) {
      return { ...state, universidades: action.payload };  
    },

    setCurrentUniversidad(state, action) {
      return { ...state, universidad: action.payload };
    
    },
    // addUniversidad(state, action) {
    //   return { ...state, universidades: [...state.universidades, action.payload] };
    // }, 
    // updateUniversidad(state, action) {
    //   return {
    //     ...state,
    //     universidades: state.universidades.map(u =>
    //       u.id === action.payload.id ? action.payload : u
    //     ),
    //   };
    // },
    removeUniversidad(state, action) {
      return {
        ...state,
        universidades: state.universidades.filter(u => u.id !== action.payload),
      };
    },
    setSubdisciplinas(state, action) {
      return { ...state, subdisciplinas: action.payload };  
    },

  },
});

export const universidadActions = universidadSlice.actions;
export default universidadSlice.reducer;
