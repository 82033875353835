const initialState = {
    subdisciplinas: [],
    subdisciplina: null,
    subdisciplinasByDisciplinaId: [],
  };
  
   
  const subdisciplinaReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_SUBDISCIPLINAS_BY_DISCIPLINA_ID':
        return {
          ...state,
          subdisciplinasByDisciplinaId: action.payload,
        };
      case 'GET_SUBDISCIPLINAS':
        return { ...state, subdisciplinas: action.payload };
      case 'GET_SUBDISCIPLINA_BY_ID':
        return { ...state, subdisciplina: action.payload };
      case 'CREATE_SUBDISCIPLINA':
        return { ...state, subdisciplinas: [...state.subdisciplinas, action.payload] };
      case 'UPDATE_SUBDISCIPLINA':
        return {
          ...state,
          subdisciplinas: state.subdisciplinas.map(s =>
            s.id === action.payload.id ? action.payload : s
          ),
        };
      case 'DELETE_SUBDISCIPLINA':
        return {
          ...state,
          subdisciplinas: state.subdisciplinas.filter(s => s.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default subdisciplinaReducer;
  