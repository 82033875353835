import { useDispatch, useSelector } from "react-redux";
import { useInputFile } from "../../hooks/useInputImage";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { fetchTecnicos, fetchDocenteDataByCi, modifyTecnico, addTecnico, updateSubdisciplinas } from "../../actions/tecnicoActions";
import { fetchUniversidades } from "../../actions/universidadAction";
import { Button, Col, Form, Image } from "react-bootstrap";
import { getUploadUrl } from "../../util/valueCalculator";
import SelectSubdisciplinas from "./SelectSubdisciplinas";


const FormTecnico = ({ currentTecnico = null,
     handleClose, actualizarLista, universidad = null }) => {
  const [datos, setDatos] = useState({
    nombres: "",
    appat: "",
    apmat: "",
    ci: "",
    iduniv: "",
    iddoc: "",
    fecnac: "1930-01-01",
    tipo: "administrativo",
    tipo2: "tecnico",
    foto1: "",
    relato: "",
    genero: "O",
    estado: 0 ,
    estado2: 0 
    }
  );
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();
  const rolActivo = useSelector(state => state.sesion.rolActivo);
  const [ isSending, setIsSending ] = useState(false)
  const dispatch = useDispatch();
  const [subdisciplinasSelected, setSubdisciplinasSelected] = useState([]);
  const [ci, setCi] = useState('');
  const [personaExistente, setPersonaExistente] = useState(false);
  const [docenteVerified, setDocenteVerified] = useState(false);

  useEffect(() => {
    if (currentTecnico) {
      if(currentTecnico.tipo === "docente") setDocenteVerified(true);
        const { subdisciplinasTecnico, ...tecnico } = currentTecnico;
        setDatos({ ...tecnico, 
          relato2:tecnico.relato2?tecnico.relato2:"" ,
            iddoc: tecnico.id});
        setSubdisciplinasSelected(subdisciplinasTecnico)
    }
  }, [currentTecnico]);

  function handleInputChange(event) {
    const { name, value } = event.target;
    setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
  }
  if(currentTecnico && currentTecnico.estado2 === 1 ){
    return <p>EDICION NO PERMITIDA, EL PERSONAL EXTRA YA HA SIDO HABILITADO</p>
  }
  const handleVerifyDocente = async () => {
    const docente = await dispatch(fetchDocenteDataByCi(ci));
    if (!docente.message) {
      setDocenteVerified(true);
      const {id, representante, ...docenteInfo} = docente
      setDatos({ ...docenteInfo, 
          tipo2:"tecnico",
          iddoc: docente.id });
      toast.success('Persona verificada exitosamente');
    } else {
      if(docente.message && docente.message.includes("Ya existe un usuario")) setCi("")
      resetForm()
      toast.error(docente.message);
    }
  };
  function handleSubmit(e) {
    e.preventDefault();
    
    setIsSending(true)
    const fd = new FormData();

    if(personaExistente && docenteVerified){
      fd.set("estado2", datos.estado2);
      fd.set("tipo2", datos.tipo2);
      const subdisIds = subdisciplinasSelected.map(sub => sub.id)
      fd.set("subdisciplinas", subdisIds);
    }
    else if(!personaExistente){
    // Agregar los datos de 'datos' al FormData
    for (const key in datos) {
      if (datos.hasOwnProperty(key) && key !== "foto1") {
        fd.set(key, datos[key]);
      }
    }
    // Agregar la foto si está presente
    if (foto1) {
      fd.append("foto1", foto1);
    } else if (datos.foto1) {
      fd.append("foto1", datos.foto1);
    }
    const subdisIds = subdisciplinasSelected.map(sub => sub.id)
    fd.set("subdisciplinas", subdisIds);
    fd.set("iduniv", universidad.id);
    fd.set("estado2", 0);
    fd.set("nombres", datos.nombres.trim().toUpperCase());
    fd.set("appat", datos.appat.trim().toUpperCase());
    fd.set("apmat", datos.apmat.trim().toUpperCase());  
    fd.set("ci", datos.ci.trim());  
  }

    if (currentTecnico || personaExistente) {
      dispatch(modifyTecnico(datos.iddoc, fd)).then(() => {
        setIsSending(false)
        closeForm();
        actualizarLista()
        })
        .catch(errores => {
          setIsSending(false)
          if (Array.isArray(errores)) {
            for (const err of errores) {
              toast.error(err);
            }
          } else {
            // Manejar el caso en el que errores no es iterable
            toast.error("Error al registrar el personal extra");
          }
        });
    } else {
      dispatch(addTecnico(fd)).then(() => {
        setIsSending(false)
        closeForm();
        actualizarLista()
           })
           .catch(errores => {
            setIsSending(false)
            for(const err of errores){
              toast.error(err)
            }
          });
    }
  }
  const handleSaveSubdisciplinas = (lista) => {
    setSubdisciplinasSelected(lista)
  }  
  // Función para manejar el cambio de estado del checkbox
  const handleCheckboxChange = (event) => {
    resetForm()
    setPersonaExistente(event.target.checked);
  };
  const resetForm = () => {
    setDocenteVerified(false);
    resetFoto1();
    setDatos(prev => ({
      ...prev,
        nombres: "",
        appat: "",
        apmat: "",
        ci: "",
        iduniv: "",
        fecnac: "1930-01-01",
        foto1: "",
        relato: "",
        genero: "O",
        login: "",
        contrasena: "",
        estado2: 0
        }))
  }
  const closeForm = () => {
    resetForm();
    handleClose();
    setDatos(prev => ({
      ...prev,
        tipo2: "tecnico"
        }))
  }
 const actualFoto1Url = currentTecnico ? getUploadUrl(currentTecnico.foto1) : null;
  const isDisabled = docenteVerified || isSending || personaExistente
  return (<Form
                onSubmit={handleSubmit}
                className="modal-oval"
              >   
              <Form.Label className="mb-4">DATOS PERSONALES</Form.Label>
              <Form.Group controlId="formCiDocente" className="row">
             
              <div className="col-md-3 modal-input">
                    <Form.Label>Tipo</Form.Label>
                    <Form.Control
                      as="select"
                      value={datos.tipo2}
                      name="tipo2"
                      onChange={handleInputChange}
                      required
                    >
                       <option value="tecnico">Cuerpo técnico</option>
                       <option value="personal">Personal de apoyo</option>
                    </Form.Control>
                  </div>
                  {!currentTecnico &&(
              
              <>
                  <div className="col-md-3 modal-input">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id="default-checkbox"
                      label="Persona existente"
                      checked={personaExistente} // Mantener el estado sincronizado
                      onChange={handleCheckboxChange} // Actualizar el estado cuando cambie
                    />
                  </div>
                {personaExistente && 
                <>
                <div className="col-md-3 modal-input">
                <Form.Label>CI de la persona</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CI de la persona"
                  value={ci}
                  onChange={(e) => setCi(e.target.value)}
                  required={personaExistente}
                />
                </div>
                <div className="col-md-3 modal-input">
                
                  <Button variant="secondary" onClick={handleVerifyDocente} className="mt-4">
                    Verificar si existe
                  </Button>
                </div>
                </>
            }  
              </>
            )}  
            {rolActivo==="admin_evento" && <div className="col-md-3 modal-input">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                   as="select"
                   name="estado2"
                  value={datos.estado2}
                  onChange={handleInputChange}
                >
                    <option value="1">Activo</option>
                    <option value="0">Inactivo</option>
                </Form.Control>
                </div>}
          </Form.Group>
               <Form.Group className="row">
                  <div className="col-md-4 modal-input">
                    <Form.Label>Nombres</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombres"
                      placeholder="Nombre(s)"
                      value={datos.nombres}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-4 modal-input">
                    <Form.Label>Apellido paterno</Form.Label>
                    <Form.Control
                      type="text"
                      name="appat"
                      placeholder="Primer apellido"
                      value={datos.appat}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-4 modal-input">
                    <Form.Label>Apellido materno</Form.Label>
                    <Form.Control
                      type="text"
                      name="apmat"
                      placeholder="Segundo apellido"
                      value={datos.apmat}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                <div className="col-md-3 modal-input">
                    <Form.Label>C.I.</Form.Label>
                    <Form.Control
                      type="text"
                      name="ci"
                      placeholder="N° Cédula de Identidad"
                      value={datos.ci}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-md-5 modal-input">
                    <Form.Label>Foto</Form.Label>
                      <Form.Control
                        type="file"
                        isInvalid={!isFoto1Valid}
                        onChange={(e) => handleFoto1Change(e.target.files[0])}
                        disabled={isDisabled}
                      />
                      <Form.Control.Feedback type="invalid">
                            Por favor selecciona una imagen válida.
                      </Form.Control.Feedback>
                      {(foto1 && isFoto1Valid) && (
                          <img
                          className="img-fluid img-cuadrada-150 mb-2 mt-3"
                          src={URL.createObjectURL(foto1)}
                          />
                      )}
                  </div>
                  <div className="col-md-4 modal-input" style={{display:"flex", flexDirection:"column"}}>
                  {actualFoto1Url && (
                      <>
                      <Form.Label>Foto actual</Form.Label>
                      <Image 
                          className="img-fluid img-cuadrada-150 mb-2"
                          src={actualFoto1Url}
                      />
                      </>
                  )}
                  </div>
                  <Col
              md={4}
              className="d-flex flex-column align-items-center"
            >
            </Col>
                </Form.Group>
                <Form.Label className="mb-3 mt-3">SUBDISCIPLINAS</Form.Label>
                <SelectSubdisciplinas tecnico={currentTecnico || {iduniv: universidad.id}} onSaveSubdisciplinas={handleSaveSubdisciplinas} subdisciplinasSelected={subdisciplinasSelected}/>
                
                
                <Button
                  variant={currentTecnico ? "warning" : "info"}
                  type="submit"
                  className="btn-modal"
                  disabled={personaExistente && !docenteVerified}
                >
                  {currentTecnico ? "Actualizar" : "Crear"}
                </Button>
                <Button
                  variant="secondary"
                  onClick={closeForm}
                  className="btn-modal"
                >
                  Cancelar
                </Button>
              </Form>
  );
};

export default FormTecnico;
