import { useDispatch, useSelector } from "react-redux";
import { fetchPredios, removePredio } from "../../actions/predioActions";
import { Button } from "react-bootstrap";
const DeletePredio = ({ onHandleClose }) => {
  const dispatch = useDispatch();
  const currentPredio = useSelector((state) => state.predio.current);

  const handleDelete = () => {
    dispatch(removePredio(currentPredio.id)).then(() => {
      onHandleClose();
      dispatch(fetchPredios()); // Actualiza la lista de predios
    });
  };

  return (
    <>
      <p>
        ¿Estás seguro que deseas eliminar el predio {currentPredio && `${currentPredio.nombre}`}?
      </p>
      <div className="custom-button-group">
        <Button
          variant="secondary"
          onClick={onHandleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          className="ml-2"
        >
          Eliminar
        </Button>
      </div>
    </>
  );
};

export default DeletePredio;