import { Button, Card } from "react-bootstrap";
import defaultImage from '../../../assets/disciplinas/default.jpg'
import { UPLOADS_BASE_URL } from "../../../util/axiosInstance";
import { getAuthToken } from "../../../util/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUploadUrl } from "../../../util/valueCalculator";


const CardDisciplina = ({disciplina}) => {
  const dispatch = useDispatch();
    const [fotoPreview, setFotoPreview] = useState(defaultImage);
    const navigate = useNavigate();

    useEffect(() => {
        if (disciplina) {
          if (disciplina.foto1) {
            setFotoPreview(getUploadUrl(disciplina.foto1));
          }else if (disciplina.foto2) {
            setFotoPreview(getUploadUrl(disciplina.foto2));
             }
        }
      }, [disciplina]);

      const handleViewSubdisciplinas = (disciplina) => {
        dispatch({ type: 'GET_DISCIPLINA_BY_ID', payload: disciplina });
        navigate(`/disciplina/${disciplina.id}`);
      };
    
    return (  <Card onClick={() => handleViewSubdisciplinas(disciplina)}>
    <Card.Img variant="top" src={fotoPreview || defaultImage} alt={disciplina.nombre} className="image-card"/>
    <Card.Body className="card-body">
      <Card.Title>{disciplina.nombre}</Card.Title>
    </Card.Body>
  </Card>)
}

export default CardDisciplina;