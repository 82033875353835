import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoles } from "../../actions/rolActions";
import {
  updateUsuarioRoles,
} from "../../actions/usuarolActions";
import {
  Modal,
  Button,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { fetchUsuarios } from "../../actions/usuarioActions";
import SelectSubdisciplinas from "./SelectSubdisciplinas";
import { getSubdisciplinaFullName } from "../../util/valueCalculator";

const UsuarioRol = ({ show, handleClose, usuario }) => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.rol.roles);
  const [selectedResponsabilidades, setSelectedResponsabilidades] = useState(
    []
  );
  const currentUsuarioId = useSelector(state => state.sesion.id)
  const [availableRoles, setAvailableRoles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModalSubdisciplinas, setShowModalSubdisciplinas] = useState({
    rol: null,
  });
  const rolesUsuario = usuario
    ? usuario.responsabilidades.map((res) => res.Rol)
    : [];
    const subdisciplinasEnca = selectedResponsabilidades
    .filter(
      (res) =>
        res.Subdisciplina !== null &&
        res.Rol.nombre === "enca_universidad_disciplina"
    )
    .map((res) => res.Subdisciplina);
  const subdisciplinasAdmin = selectedResponsabilidades
    .filter(
      (res) =>
        res.Subdisciplina !== null && res.Rol.nombre === "admin_disciplina"
    )
    .map((res) => res.Subdisciplina);

//  console.log("selectedResponsabilidades", selectedResponsabilidades);
  useEffect(() => {
    if (usuario) {
      setSelectedResponsabilidades([...usuario.responsabilidades]);
    }
    dispatch(fetchRoles());
  }, [dispatch, usuario]);

  useEffect(() => {
    if (rolesUsuario) {
      const available = roles.filter(
        (rol) =>
          !rolesUsuario.some(
            (rolU) =>
              rolU.id === rol.id &&
              rol.nombre !== "admin_disciplina" &&
              rol.nombre !== "enca_universidad_disciplina"
          ) && rol.nombre !== "docente"
      );
      setAvailableRoles(available);
    }
  }, [roles, usuario]);

  const handleAddRoles = (rolToAdd) => {
   //console.log("rolToAdd", rolToAdd);
    if (
      rolToAdd.nombre === "admin_disciplina" ||
      rolToAdd.nombre === "enca_universidad_disciplina"
    ) {
      setShowModalSubdisciplinas({ rol: rolToAdd });
    } else {
      setSelectedResponsabilidades((prevSelected) => [
        ...prevSelected,
        { Rol: rolToAdd },
      ]);
      setAvailableRoles((prevAvailableRoles) =>
        prevAvailableRoles.filter((rol) => rol.id !== rolToAdd.id)
      );
    }
  };

  const handleRemoveRoles = (resToRemove) => {
    if (
      resToRemove.Rol.nombre !== "admin_disciplina" &&
      resToRemove.Rol.nombre !== "enca_universidad_disciplina"
    ) {
      setAvailableRoles((prevAvailableRoles) => [
        ...prevAvailableRoles,
        resToRemove.Rol,
      ]);
    }
    setSelectedResponsabilidades((prevSelected) =>{
      return prevSelected.filter((res) => res.Subdisciplina?.id !== resToRemove.Subdisciplina?.id || res.Rol.nombre !== resToRemove.Rol.nombre)}
    );
  };

  const handleCloseModalSubdis = () => {
    setShowModalSubdisciplinas({ rol: null });
  };

  const handleSave = async () => {
    const resToSend = selectedResponsabilidades.map((res) => ({
      id: res.id? res.id : -1,
      idrol: res.Rol.id,
      idusu: usuario.id,
      idsubdis: res.Subdisciplina? res.Subdisciplina.id:null
    }));
    const fd = new FormData();
    fd.append("responsabilidades", JSON.stringify(resToSend));

    dispatch(updateUsuarioRoles(usuario.id, fd)).then(() => {
      dispatch(fetchUsuarios());
      handleClose();
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRoles = availableRoles.filter((rol) =>
    rol.nombreDescriptivo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!usuario || !usuario.id) {
    return null;
  }
  const handleSaveSubdisciplinas = (rol, subdis) => {
    if (subdis && subdis.length > 0) {
      const newResponsabilidades = subdis.map((sub) => ({
        Rol: rol,
        Subdisciplina: sub,
      }));
      setSelectedResponsabilidades([
        ...selectedResponsabilidades,
        ...newResponsabilidades,
      ]);
    }
  };
  const sortedResponsabilidades = [...selectedResponsabilidades].sort(
    (a, b) => {
      if (a.Rol.id < b.Rol.id) {
        return -1;
      }
      if (a.Rol.id > b.Rol.id) {
        return 1;
      }
      return 0;
    }
  );

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Gestión de Roles del usuario: {usuario.login}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mt-3">
                <div className="w-100">
                  <h5>Roles Disponibles</h5>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Buscar por nombre"
                      aria-label="Buscar por nombre"
                      aria-describedby="basic-addon2"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {filteredRoles.map((rol) => (
                      <ListGroup.Item key={rol.id}>
                        <div className="d-flex justify-content-between">
                          {rol.nombreDescriptivo}
                          <Button
                            variant="outline-info"
                            size="sm"
                            onClick={() => handleAddRoles(rol)}
                          >
                            <i className="bi bi-plus-lg"></i>
                          </Button>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
              <div className="col-lg-8 mt-3">
                <div className="w-100">
                  <h5>Roles asignados al usuario</h5>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {sortedResponsabilidades.map((res) => (
                      <ListGroup.Item key={res.id}>
                        <div className="d-flex justify-content-between">
                          {res.Rol.nombreDescriptivo}
                          <span style={{color:'gray', fontSize:"0.85rem"}}>
                           {res.Rol.nombre === "admin_disciplina" && res.Subdisciplina&&
                            getSubdisciplinaFullName(res.Subdisciplina)}
                          {res.Rol.nombre === "enca_universidad_disciplina" &&
                          res.Subdisciplina&&
                            
                              usuario.persona.Universidad.sigla +
                              " - " +
                              res.Subdisciplina.nombre}
                               {res.Rol.nombre === "enca_universidad" &&
                            
                              usuario.persona.Universidad.sigla}
                               </span>
                            {((currentUsuarioId === usuario.id? res.Rol.nombre !== "admin" : true) && res.Rol.nombre !== "docente" )&&
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleRemoveRoles(res)}
                          >
                            <i className="bi bi-dash"></i>
                          </Button>
}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            variant="info"
            onClick={handleSave}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      {showModalSubdisciplinas.rol !== null && (
        <SelectSubdisciplinas
          rol={showModalSubdisciplinas.rol}
          subdisciplinasSelected={
            showModalSubdisciplinas.rol.nombre === "admin_disciplina"
              ? subdisciplinasAdmin
              : subdisciplinasEnca
          }
          onSaveSubdisciplinas={handleSaveSubdisciplinas}
          onClose={handleCloseModalSubdis}
        />
      )}
    </>
  );
};

export default UsuarioRol;
