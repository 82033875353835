import React from 'react';
import QRCode from 'qrcode.react';
import ReactDOMServer from 'react-dom/server'; // Importa ReactDOMServer

export const generateQrCodeImage = (url, size) => {
    return new Promise((resolve, reject) => {
        // Crea un SVG del QR code
        const svg = document.createElement('svg');
        svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svg.setAttribute('version', '1.1');
        svg.setAttribute('width', size);
        svg.setAttribute('height', size);

        const qrCode = (
            <QRCode
                value={url}
                size={size}
                level="H"
                includeMargin={true}
                renderAs="svg"
                style={{ width: size, height: size }}
            />
        );

        // Renderiza el QR code a un SVG
        const qrCodeSvg = ReactDOMServer.renderToStaticMarkup(qrCode);
        svg.innerHTML = qrCodeSvg;

        // Convierte el SVG a una URL de datos en formato PNG
        const svgBlob = new Blob([svg.outerHTML], { type: 'image/svg+xml' });
        const svgUrl = URL.createObjectURL(svgBlob);
        
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            URL.revokeObjectURL(svgUrl);  // Libera el objeto URL
            resolve(canvas.toDataURL('image/png'));
        };
        img.onerror = (error) => {
            URL.revokeObjectURL(svgUrl);  // Libera el objeto URL en caso de error
            reject(error);
        };
        img.src = svgUrl;
    });
};