import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { getPersonaFullName } from "../../util/valueCalculator";
import { removePersonaTipo } from "../../actions/personasTipoActions";

const DeleteInvitado = ({ handleClose, currentInvitado,actualizarLista }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(removePersonaTipo(currentInvitado.tipo2, currentInvitado.id)).then(() => {
      handleClose();
      actualizarLista() // Actualiza la lista de disciplinas
    });
  };
  return (
    <>
      <p>
        Estás seguro que deseas eliminar a la persona especial {currentInvitado && getPersonaFullName(currentInvitado)}?
      </p>
      <div className="custom-button-group">
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          className="ml-2"
        >
          Eliminar
        </Button>
      </div>
    </>
  );
};

export default DeleteInvitado;
