import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubdisciplinaList from "./PublicSubdisciplinas";
import "../../../styles/Disciplina.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import defaultImage from '../../../assets/disciplinas/default.jpg'
import { fetchDisciplinaById } from "../../../actions/disciplinaActions";
import { getUploadUrl } from "../../../util/valueCalculator";


const VerPublicDisciplina = ({ handleCloseSubdisciplinaModal }) => {
  const { disId } = useParams();
  const navigate = useNavigate();
  const [fotoPreview, setFotoPreview] = useState('');

  const dispatch = useDispatch();
  const disciplina = useSelector((state) =>
    state.disciplina.disciplina);


  useEffect(() => {
    if (disciplina) {
      if (disciplina.foto2) {
          setFotoPreview(getUploadUrl(disciplina.foto2));
      }else if (disciplina.foto1) {
        setFotoPreview(getUploadUrl(disciplina.foto1));
      }
    }else{
        dispatch(fetchDisciplinaById(disId))
      }
  }, [disciplina]);
 
  const handleImageError = () => {
        setFotoPreview(defaultImage);
    };
  if (!disciplina) {
    return <p>Disciplina no encontrada</p>;
  }

  return (
    <div className="disciplina-page">
      <div
        className="disciplina-header"
        style={{ backgroundImage: `url(${fotoPreview})` }}
        onError={handleImageError}
      >
        <div className="volver-container">
          <Button
            variant="light"
            onClick={() => navigate(`/disciplinas`)}
          >
            <i className="bi bi-arrow-left"></i> Volver
          </Button>
        </div>
        <div className="disciplina-title-container">
          <h1 className="disciplina-title">{disciplina.nombre}</h1>
        </div>
      </div>
      <div className="disciplina-content">
        <SubdisciplinaList
          disciplina={disciplina}
        />
      </div>
    </div>
  );
};

export default VerPublicDisciplina;
