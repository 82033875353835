import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import Header from "../components/plantillas/Header";
import { sesionActions } from "../reducers/session_slice";
import { useDispatch, useSelector } from "react-redux";
import { getTokenDuration } from "../util/auth";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { eventoActions } from "../reducers/evento_slice";
import { fasesEvento } from "../util/valueCalculator";
import { fetchEvento } from "../actions/eventoAction";
import Footer from "../components/plantillas/Footer";

const mensaje = 'Sesión finalizada. Inicie sesión nuevamente, por favor.'
const RootLayout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useLoaderData();
    const evento = useSelector(state => state.evento)
    const fase = fasesEvento(evento.estado)
    const {rolActivo} = useSelector(state => state.sesion); 
    //Al recargar la aplicacion, verificamos el token
    //Si ha expirado despachamos el logout
    //Si sigue vigente seteamos el usuario de la sesion
  
    useEffect(()=>{
        dispatch(fetchEvento(2024))
    },[dispatch])

    useEffect(() => {
        if(!token) {
            return;
        }

        if(token === 'EXPIRED') {
            dispatch(sesionActions.logout());
            
            return;
        }
        
        dispatch(sesionActions.setUser());

        const tokenDuration = getTokenDuration();
           // Definir un temporizador para el aviso 5 minutos antes de que el token expire
            const cautionMinutesBefore = tokenDuration - 2 * 60 * 1000; // 5 minutos antes en milisegundos

            if (cautionMinutesBefore > 0) {
                setTimeout(() => {
                    // Mostrar aviso
                    alert("Tu sesión expirará en 2 minutos. Por favor, guarda tu trabajo.");
                }, cautionMinutesBefore);
            }
        //Definimos un temporizador con la duracion que le queda al token
        //Para el logout automatico
        setTimeout(() => {
            dispatch(sesionActions.logout());
            
            navigate('/auth');
        }, tokenDuration)

    }, [token]);


    return (<>
        <Header/>
                <Outlet />
                {fase !=="SIN ESPECIFICAR" && rolActivo && (
                <div className="event-phase">
                    Fase del evento: {fase}
                </div>
            )}
           {!rolActivo && <Footer />}
        <ToastContainer position="top-right" autoClose={4000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>)
}
export default RootLayout;