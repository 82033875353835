import { createSlice } from "@reduxjs/toolkit";
const initialState ={
  medalleros:[],
  medalleroGeneral:[],
  current: null
}
const medalleroSlice= createSlice({
  name: "medallero",
  initialState: initialState,
  reducers: { 
    setMedalleros(state, action) {
    return { ...state, medalleros: action.payload };  
    },
    setMedalleroGeneral(state, action) {
      return { ...state, medalleroGeneral: action.payload };  
      },
    setCurrentMedallero(state, action) {
      return { ...state, current: action.payload }; 
    }
  }
});

export const medalleroActions = medalleroSlice.actions;
export default medalleroSlice.reducer;
