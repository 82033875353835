import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubdisciplinaById } from "../../actions/subdisciplinaActions";
import { useParams } from "react-router-dom";
import { Button, Modal, Table } from "react-bootstrap";
import CrearButton from "../generales/CrearButton";
import { fixtureActions } from "../../reducers/fixture_slice";
import FormFixture from "./FormFixture";
import DeleteFixture from "./DeleteFixture";
import { fetchFixturesBySubdisciplina } from "../../actions/fixtureActions";
import FormFixturedet from "./FormFixturedet";
import { getCategoriaText, getGeneroText } from "../Utils.js"

function formatear(fecha) {
  if (fecha) {
    const fec = fecha.split("T")[0];
    const [year, month, day] = fec.split("-");
    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
}

function formatearHora(hora) {
  if (hora) {
    const hr = hora.split(":")[0];
    const min = hora.split(":")[1];
    return hr + ":" + min;
  } else {
    return "";
  }
}

const ListFixtures = ({ subdisciplina, onClose }) => {
  const subdisId=subdisciplina.id;
  const dispatch = useDispatch();
  //const subdisciplina = useSelector((state) => state.subdisciplina.subdisciplina);
  const fixtures = useSelector((state) => state.fixture.fixtures);
  const [modal, setModal] = useState({
    tipo: 0,
    titulo: "",
    body: <></>,
  });
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedFixture, setSelectedFixture] = useState(null);

  useEffect(() => {
    dispatch(fetchFixturesBySubdisciplina(subdisId));
    if (!subdisciplina) {
      dispatch(fetchSubdisciplinaById(subdisId));
    }
  }, [subdisciplina, subdisId, dispatch]);

  const [sortConfig, setSortConfig] = useState({
    key: ["fecha", "hora"],
    direction: ["asc", "asc"],
  });

  const sortArray = (array, key, direction) => {
    return array.sort((a, b) => {
      for (let i = 0; i < key.length; i++) {
        if (a[key[i]] < b[key[i]]) {
          return direction[i] === "asc" ? -1 : 1;
        }
        if (a[key[i]] > b[key[i]]) {
          return direction[i] === "asc" ? 1 : -1;
        }
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (
      sortConfig.key.includes(key) &&
      sortConfig.direction[sortConfig.key.indexOf(key)] === "asc"
    ) {
      direction = "desc";
    }

    let newKeys = [key];
    let newDirections = [direction];

    if (key !== "fecha" && key !== "hora") {
      newKeys.push("fecha");
      newKeys.push("hora");
      newDirections.push(
        sortConfig.key.includes("fecha")
          ? sortConfig.direction[sortConfig.key.indexOf("fecha")]
          : "asc"
      );
      newDirections.push(
        sortConfig.key.includes("hora")
          ? sortConfig.direction[sortConfig.key.indexOf("hora")]
          : "asc"
      );
    }

    setSortConfig({ key: newKeys, direction: newDirections });
  };

  const sortedList = sortConfig.key
    ? sortArray([...fixtures], sortConfig.key, sortConfig.direction)
    : fixtures;

  const handleShowModal = (tipo, fixture) => {
    dispatch(fixtureActions.setCurrentFixture(fixture));
    let titulo = "";
    let body = <></>;
    if (tipo === 1) {
      titulo = subdisciplina.nombre+' - '+getGeneroText(subdisciplina.genero)+' - '+getCategoriaText(subdisciplina.categoria);
      body = <FormFixture subdisId={subdisId} onHandleClose={handleClose} />;
    } else if (tipo === 2) {
      titulo = subdisciplina.nombre+' - '+getGeneroText(subdisciplina.genero)+' - '+getCategoriaText(subdisciplina.categoria);
      body = <FormFixture subdisId={subdisId} onHandleClose={handleClose} />;
    } else if (tipo === 3) {
      titulo = "Confirmar eliminación";
      body = <DeleteFixture onHandleClose={handleClose} />;
    }
    setModal({ tipo, titulo, body });
  };

  const handleShowDetail = (fixture) => {
    setSelectedFixture(fixture);
    setShowDetailModal(true);
  };

  const handleClose = () => {
    dispatch(fixtureActions.setCurrentFixture(null));
    setModal({ tipo: 0, titulo: "", body: <></> });
    setShowDetailModal(false);
  };
console.log("subdisciplina",subdisciplina)
  return (
    <div className="container-large">
      <Button
        variant="light"
        onClick={onClose}
        className="back-button"
      >
        <i className="bi bi-arrow-left"></i> Volver
      </Button>
      <div className="contenedor container-large">
        <div>
          <CrearButton
            onHandleCreate={() => handleShowModal(1, null)}
            className="float-end"
          >
            Nuevo Fixture
          </CrearButton>
        </div>

        <div className="table-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="col-cod">N°</th>
                <th onClick={() => handleSort("fecha")}>Fecha</th>
                <th onClick={() => handleSort("hora")}>Hora</th>
                <th>Descripcion</th>
                <th>Predio</th>
                <th className="col-actions-md">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {sortedList.map((fixture, index) => {
                return (
                  <tr key={fixture.id}>
                    <td>{index + 1}</td>
                    <td>{formatear(fixture.fecha)}</td>
                    <td>{formatearHora(fixture.hora)}</td>
                    <td>{fixture.descripcion}</td>
                    <td>{fixture.Predio.nombre}</td>
                    <td>
                      <Button
                        variant="outline-warning"
                        size="sm"
                        onClick={() => handleShowModal(2, fixture)}
                        className="action-button"
                         title="Editar fixture"
                      >
                        <i className="bi bi-pencil"></i>
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleShowModal(3, fixture)}
                        className="action-button"
                         title="Eliminar fixture"
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                      <Button
                        variant="outline-dark"
                        size="sm"
                        onClick={() => handleShowDetail(fixture)}
                        title="Ver detalles del fixture"
                      >
                        <i className="bi bi-list-columns-reverse"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal
        show={showDetailModal}
        onHide={handleClose}
        size="xl"
        centered
        dialogClassName="modal-wide"
      >
        <Modal.Header closeButton>
          <Modal.Title>{subdisciplina.nombre+' - '+getGeneroText(subdisciplina.genero)+' - '+getCategoriaText(subdisciplina.categoria)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedFixture && (
            <FormFixturedet tipoParticipacion={subdisciplina.tipo_participacion} fixture={selectedFixture} onHandleClose={handleClose} />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={modal.tipo > 0}
        onHide={handleClose}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.body}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ListFixtures;
