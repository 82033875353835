import { useState } from "react";
import { Alert, Button, Form, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx"; // Importa la librería xlsx
import { addDocenteMasivo } from "../../actions/docenteActions";
import { toast } from "react-toastify";
const erroresImportacion = 
{    e1: "El género solo puede ser FEMENINO o MASCULINO",
     e2: "Todos los campos deben estar llenados: CI, NOMBRE, PATERNO, MATERNO, FECHA DE NACIMIENTO Y GENERO: Registro #",
     e3: "Ya existe un docente con",
     e4: "No existe la columna CI o alguna(s) fila(s) tiene(n) la columna CI vacía.",
  
    }
const generoPorCategoria = (genero) =>{
    if(genero.toLowerCase() === "femenino" || genero.toLowerCase() === "damas")
        return "D"
    if(genero.toLowerCase() === "masculino" || genero.toLowerCase() === "varones")
        return "V"
    return null
}
const ImportarExcelDocentes = ({universidadId, onFinish}) => {
    const [importedData, setImportedData] = useState([]);
    const dispatch=useDispatch()
    const [errors, setErrors] = useState([]);
    const docentes = useSelector((state) => state.docente.docentes);
  
  const getDocente= (ci) => {
    const docente = docentes.find(d => d.ci === `${ci}`);
    return docente ? docente : null;
}
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const rawData = XLSX.utils.sheet_to_json(sheet, { raw: true });
      
            const uniqueErrors = new Set();
            const ciCounts = {};
               
            const data = rawData.map((row, index) => {
               const ci=row['CI'] || '';

                if (ci === '') {
                    uniqueErrors.add(erroresImportacion['e4']);
                    return null;  // Devolver null si el docente no existe
                }

                if (ciCounts[ci]) {
                    ciCounts[ci]++;
                } else {
                    ciCounts[ci] = 1;
                }

                const docente = getDocente(ci)
                   
                if (docente) {
                    uniqueErrors.add(erroresImportacion['e3'] + ` CI: ${ci}`);
                    return null;  // Devolver null si el docente no existe
                }
              
              const genero = row['GENERO'] ? row['GENERO'].toUpperCase():'';
              const categoria = generoPorCategoria(genero)
              
              if(categoria && categoria[0] !== 'D' && categoria[0] !== 'V') {
                uniqueErrors.add(erroresImportacion['e1']);
              }
              const appat=row['PATERNO'] || '';
              const apmat=row['MATERNO'] || '';
              const nombres=row['NOMBRE'] || '';
              const excelDate=row['FECHA DE NACIMIENTO']
              let fecnac="";
              if (typeof excelDate !== 'number' || isNaN(excelDate)) {
                uniqueErrors.add("Error en el formato de la fecha "+`. Registro #${index+1}`);
            }else{
                fecnac = formatDate(excelDate)
            }
           
              if(ci === '' || appat === '' || apmat === ''  ||nombres === '' ||fecnac === '' )
                { uniqueErrors.add(erroresImportacion['e2']+` ${index+1}`);}
              return {
                ci: ci,
                appat: appat.toUpperCase(),
                apmat: apmat.toUpperCase(),
                nombres: nombres.toUpperCase(),
                fecnac: fecnac.toUpperCase(),
                genero: genero,
                iduniv: universidadId,
                tipo: "docente"
              };
            }).filter(docente => docente !== null);;
            setErrors([...uniqueErrors])
            setImportedData(data); // Guardar los datos importados en el estado
          };
          reader.readAsBinaryString(file);
        }
      };
      
      const formatDate = (excelDate) => {
    
        const jsDate = new Date((excelDate - (25567 + 2)) * 86400 * 1000);
        return jsDate.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
      };
      const handleSubmit = (e) => {
        e.preventDefault();
       //console.log("Datos importados:", importedData);

        
        const transformedData = importedData
            .map((docente) => 
             ({
                ...docente,
                genero: generoPorCategoria(docente.genero)
            }))
        dispatch(addDocenteMasivo(transformedData))
        .then(data => {
            toast.success(data.mensaje);
            if (data.errores) {
              const errores = data.errores.map(error => `Error en el docente con CI ${error.docenteData.ci}: ${error.errores[0]}`);
              setErrors(errores);
            } else {
              onFinish()
              setImportedData([]); // Clear imported data after successful submission
          }
        })
        .catch(error => {
            toast.error(error.mensaje);
            const errores = error.errores.map(err => `Error en el docente con CI ${err.docenteData.ci}: ${err.errores[0]}`);
            setErrors(errores);
        });
      }
    
    return (
        <>
        <Form onSubmit={handleSubmit}>
            <Form.Control
                type="file"
                name="importedData"
                accept=".xlsx"
                onChange={handleFileChange}
            />
               <Button variant="info" className="float-end mt-3 ml-2" type="submit">Crear</Button>
               <Button variant="secondary" onClick={onFinish} className="float-end mt-3" type="button">Cancelar</Button>
            </Form>
        {errors.length > 0 && (
            <Alert variant="danger" className="mt-3">
                <ul>
                    <p>REVISE SU ARCHIVO EXCEL</p>
                    {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                    ))}
                </ul>
            </Alert>
        )}
        {importedData.length > 0 && (
            <Table striped bordered hover className="mt-3">
                <thead>
                    <tr>
                        <th>N°</th>
                        <th>CI</th>
                        <th>Nombre Completo</th>
                        <th>Fecha de Nacimiento</th>
                        <th>Género</th>
                    </tr>
                </thead>
                <tbody>
                    {importedData.map((docente, index) =>
             
                    (
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{docente.ci}</td>
                            <td>{`${docente.appat} ${docente.apmat} ${docente.nombres}`}</td>
                            <td>{docente.fecnac}</td>
                            <td>{docente.genero}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        )}
    </>
    )
}
export default ImportarExcelDocentes;