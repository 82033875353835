const adminSistemaMenu = [
  {
    title: "Administración general del Sistema",
    tipo: "dropdown",
    options: [
      { name: "Países", path: "/paises" },
      { name: "Departamentos", path: "/ciudades" },
      { name: "Usuarios", path: "/usuarios" }  
    ],
  }
];

export default adminSistemaMenu;
