import { Outlet } from "react-router-dom";
import image_deportes from '../assets/deportes.jpg'

const PaginaAutenticacion = () => {

    return ( 
        <div className="container-auth" style={{backgroundImage: `url(${image_deportes})`,}}>
            <Outlet />
        </div>
       )
}
export default PaginaAutenticacion;