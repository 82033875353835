import { toast } from 'react-toastify';
import { getUsuarios, getUsuarioById, createUsuario, updateUsuario, deleteUsuario } from '../services/usuarioService';
import apiService from '../services/apiService';

const usuariosService = apiService('/usuarios');
export const fetchUsuarios = () => async dispatch => {
  try {
    const data = await getUsuarios();
    dispatch({ type: 'GET_USUARIOS', payload: data });
  }
catch(error){
  const theError = error.response;
  // console.log("Mensaje", theError.data.message)
  // console.log("Status", theError.status)
  toast.error("Error al cargar la lista de usuarios:  "+theError.data.message)
  
}
};

export const fetchUsuarioById = (id) => async dispatch => {
  try {
    const data = await getUsuarioById(id);
    dispatch({ type: 'GET_USUARIO_BY_ID', payload: data });
  } catch (error) {
    console.error('fetchUsuarioById error:', error);
  }
};
export const fetchDocenteDataByCi = (ci) => async dispatch => {
  try {
    const data = await usuariosService.getAllBySomeId("docentes",ci);
    return data;
  } catch (error) {
    toast.error("Hubo problemas al obtener datos de la persona...")
  }
};
export const addUsuario = (usuario) => async dispatch => {
  try {
    const data = await createUsuario(usuario);
    toast.success("Usuario creado exitosamente...")
  
  } catch (error) {
    throw error.response.data.errors;
    toast.error("Hubo problemas al crear el usuario, revise los datos... no puede haber dos usuarios con los mismos datos")
  }
};

export const addUsuarioTipoAdministrativo = (usuario) => async dispatch => {
  try {
    const data = await usuariosService.createByTipo('administrativos',usuario);
    toast.success("Usuario creado exitosamente...")
  
  } catch (error) {
    throw error.response.data.errors;
    toast.error("Hubo problemas al crear el usuario, revise los datos... no puede haber dos usuarios con los mismos datos")
  }
};
export const modifyUsuario = (id, usuario) => async dispatch => {
  try {
    const data = await updateUsuario(id, usuario);
    toast.success("Usuario modificado exitosamente...")
  } catch (error) {
   //console.log("Error", error)
    throw error.response.data.errors;
  }
};
export const modifyUsuarioTipoAdministrativo = (id, usuario) => async dispatch => {
  try {
    const data = await usuariosService.updateSomeDato("administrativos",id, usuario);
    toast.success("Usuario modificado exitosamente...")
  } catch (error) {
    throw error.response.data.errors;
};
}

export const removeUsuario = (id) => async dispatch => {
  try {
    const data = await deleteUsuario(id);
    dispatch({ type: 'DELETE_USUARIO', payload: id });
  } catch (error) {
    console.error('removeUsuario error:', error);
  }
};

