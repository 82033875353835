export const sortSubdisciplinas = (a, b, categoryMap) => {
    const compareStrings = (str1, str2) => str1.localeCompare(str2);
  
    const compare = compareStrings(a.nombre, b.nombre);
    if (compare !== 0) return compare;
  
    const compareGenero = compareStrings(a.genero, b.genero);
    if (compareGenero !== 0) return compareGenero;
  
    const compareCategoria = () => {
      if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
      if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
      return 0;
    };
  
    return compareCategoria();
  };