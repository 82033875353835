// src/reducers/usuarolReducer.js
const initialState = {
    usuariorol: [],
    usuaroles:[],
    loading: false,
    error: null,
};

const usuarioRolReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USUARIOLES_SUCCESS':
            return {
                ...state,
                usuaroles: action.payload,
                error: null
            };
        case 'FETCH_USUARIOLES_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        case 'GET_USUARIO_ROLES':
            return { ...state, usuariorol: action.payload };
        case 'DELETE_USUARIO_ROLES':
            return { ...state, usuariorol: state.usuariorol.filter(ur => ur.idusuario !== action.payload) };
        case 'SAVE_USUARIO_ROLES':
            return { ...state, usuariorol: [...state.usuariorol, action.payload] };
        default:
            return state;
    }
};

export default usuarioRolReducer;
