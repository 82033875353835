import { useDispatch, useSelector } from "react-redux";
import {
  fetchFixtures,
  addFixture,
  updateFixture,
  fetchFixturesBySubdisciplina,
} from "../../actions/fixtureActions";
import { useEffect, useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { fetchPredios } from "../../actions/predioActions";

const FormFixture = ({ subdisId = null, onHandleClose }) => {
//  console.log("SubdisID=",subdisId)
  const dispatch = useDispatch();
  const currentFixture = useSelector((state) => state.fixture.current);
  const predios = useSelector((state) => state.predio.predios);
  const evento = useSelector((state) => state.evento);  // Obtener fechas del evento

  const [datos, setDatos] = useState({
    idpredio: "",
    descripcion: "",
    fecha: evento ? evento.fechain.split("T")[0] : "", // Fecha por defecto igual a la fecha de inicio del evento
    hora: "",
    resultado: "",
    tipo: 1,   // Tipo por defecto
    estado: 1, // Estado por defecto
    idsub: subdisId // Añadir la disciplina (subdisciplina) al estado
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (currentFixture) {
      const { fecha, resultado, Predio, ...datosFixture } = currentFixture;
      const formattedFecha = fecha ? fecha.split("T")[0] : "";
      setDatos({ ...datosFixture, fecha: formattedFecha, idsub: subdisId });
    }
  }, [currentFixture, subdisId]);

  useEffect(() => {
    const fetchAndSetSelectLists = async () => {
      dispatch(fetchPredios());
    };
    fetchAndSetSelectLists();
  }, [dispatch]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");  // Clear previous error message

    // Validar fecha dentro del rango del evento
    const fechaFixture = new Date(datos.fecha);
    const fechaInicio = new Date(evento.fechain);
    const fechaFin = new Date(evento.fechafin);

    if (fechaFixture < fechaInicio || fechaFixture > fechaFin) {
      setError(`La fecha del fixture debe estar entre ${evento.fechain} y ${evento.fechafin}.`);
      return;
    }

    const fd = new FormData(e.target);
    fd.append('idsub', subdisId);
    fd.append('tipo', 1);   // Añadir tipo por defecto
    fd.append('estado', 1); // Añadir estado por defecto

    if (currentFixture) {
      dispatch(updateFixture(currentFixture.id, fd)).then(() => {
        onHandleClose();
        dispatch(fetchFixturesBySubdisciplina(subdisId));
      });
    } else {
      dispatch(addFixture(fd)).then(() => {
        onHandleClose();
        dispatch(fetchFixturesBySubdisciplina(subdisId));
      });
    }
  };

  return (
    <div className="contenedor">
      <div className="text-center">
      <h4 className="mb-4">{currentFixture ? "Editar Fixture":"Nuevo Fixture"}</h4>
      </div>
      <Form onSubmit={handleSubmit} className="modal-oval">
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group className="row">
          <div className="col-md-6 modal-input">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              name="descripcion"
              placeholder="Descripción"
              value={datos.descripcion}
              onChange={handleInputChange}
              maxLength={255} 
            />
          </div>
          <div className="col-md-6 modal-input">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              name="fecha"
              value={datos.fecha}
              onChange={handleInputChange}
              required
            />
          </div>
        </Form.Group>
        <Form.Group className="row">
          <div className="col-md-6 modal-input">
            <Form.Label>Hora</Form.Label>
            <Form.Control
              type="time"
              name="hora"
              value={datos.hora}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-6 modal-input">
            <Form.Label>Predio</Form.Label>
            <Form.Control
              as="select"
              name="idpredio"
              value={datos.idpredio}
              onChange={handleInputChange}
              required
            >
              <option value="">- Seleccionar predio -</option>
              {predios.map((predio) => (
                <option key={predio.id} value={predio.id}>
                  {predio.nombre}
                </option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>
        <Button
          variant={currentFixture ? "warning" : "info"}
          type="submit"
          className="btn-modal"
        >
          {currentFixture ? "Actualizar" : "Crear"}
        </Button>
        <Button
          variant="secondary"
          onClick={onHandleClose}
          className="btn-modal"
        >
          Cancelar
        </Button>
      </Form>
    </div>
  );
};

export default FormFixture;
