import { useRouteError } from "react-router-dom";
import error404Image from "../assets/error_404.png";
import errorImage from "../assets/error.png";

const PaginaError = () => {
  const error = useRouteError();

  let title = "Algo ha ocurrido!";
  let message = "Algo anda mal";
  let image =errorImage;
  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = "Página no encontrada!";
    message = "No pudimos encontrar el recurso buscado";
    image=error404Image;
}

  return (
    <div className="container-error">
      <div>
        <h1>{title}</h1>
        <p>{message}</p>
      </div>
      <div>
        <img
          src={image}
          alt="Error Image"
        />
      </div>
    </div>
  );
};
export default PaginaError;
