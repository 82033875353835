import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDisciplinas,
  removeDisciplina,
} from "../../actions/disciplinaActions";
import DisciplinaForm from "./DisciplinaForm";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActionsContainer from "../generales/ActionsContainer";
import CardDisciplina from "./CardDisciplina";
//FUNCION Imprimir Lista
function printList(disciplinas, subdisciplinas) {
  // const doc = new jsPDF();
  // const date = new Date().toLocaleDateString();

  // doc.text("LISTA DE DISCIPLINAS Y SUBDISCIPLINAS", 14, 16);
  // doc.text(`Fecha: ${date}`, 14, 24);

  // disciplinas.forEach((disciplina, index) => {
  //   doc.autoTable({
  //     head: [["ID", "Nombre", "Estado"]],
  //     body: [[disciplina.id, disciplina.nombre, "Activo"]],
  //     startY: index === 0 ? 32 : doc.autoTable.previous.finalY + 10,
  //   });

  //   const filteredSubdisciplinas = subdisciplinas.filter(
  //     (sub) => sub.iddisc === disciplina.id && sub.estado === 1
  //   );

  //   if (filteredSubdisciplinas.length > 0) {
  //     doc.autoTable({
  //       head: [["Nombre", "Género", "Categoría", "Cont", "Estado"]],
  //       body: filteredSubdisciplinas.map((sub) => [
  //         sub.nombre,
  //         sub.genero === "V" ? "VARONES" : "DAMAS",
  //         sub.categoria === "L"
  //           ? "LIBRE"
  //           : sub.categoria === "M"
  //           ? "MASTER"
  //           : "SENIOR",
  //         sub.cont,
  //         sub.estado === 1 ? "Activo" : "Inactivo",
  //       ]),
  //       startY: doc.autoTable.previous.finalY + 5,
  //       theme: "plain",
  //     });
  //   }
  // });

  // doc.text(
  //   "Página 1",
  //   doc.internal.pageSize.width - 30,
  //   doc.internal.pageSize.height - 10
  // );
  // window.open(doc.output("bloburl"), "_blank");
}

//COMPONENTE
//Lista de Disciplinas
const DisciplinaList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disciplinas = useSelector((state) => state.disciplina.disciplinas);
  const [showModal, setShowModal] = useState(false);
  const [currentDisciplina, setCurrentDisciplina] = useState(null);
  const [sortBy, setSortBy] = useState("nombre");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    dispatch(fetchDisciplinas());
  }, [dispatch]);

  const handleDelete = async (id) => {
    if (window.confirm("¿Está seguro de que desea eliminar esta disciplina?")) {
      try {
        const response = await dispatch(removeDisciplina(id));
        toast.success("Disciplina eliminada correctamente.");
      } catch (error) {
        toast.error(
          "Error al eliminar la disciplina. Verifique si está asociada a una subdisciplina."
        );
      }
    }
  };

  const handleEdit = (disciplina) => {
    setCurrentDisciplina(disciplina);
    setShowModal(true);
  };

  const handleCreate = () => {
    setCurrentDisciplina(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentDisciplina(null);
  };

  const handleBack = () => {
    navigate('/');
  };

  const sortedList = [...disciplinas].sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }
    return a[sortBy] < b[sortBy] ? 1 : -1;
  });

  const handlePrint = () => {
    printList(sortedList);
  };

  return (
    <div className=" disciplina-page">
       <div className="buttons-container">
          <Button
            variant="light"
            onClick={handleBack}
          >
            <i className="bi bi-arrow-left"></i> Volver
          </Button>
        </div>
    <div className="container-large">
     
      <h1>Lista de Disciplinas</h1>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <Form.Group className="d-flex align-items-center">
          <Form.Label className="mr-2">Ordenar por:</Form.Label>
          <Form.Control
            as="select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="mr-3"
          >
            <option value="id">ID</option>
            <option value="nombre">Nombre</option>
          </Form.Control>
          <Form.Control
            as="select"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="asc">Ascendente</option>
            <option value="desc">Descendente</option>
          </Form.Control>
        </Form.Group>
        <Button
          variant="info"
          onClick={handleCreate}
          className="mb-3"
        >
          <i className="bi bi-plus-lg"></i>
          Nueva Disciplina
        </Button>
      </div>
      <div className="card-grid">
        {sortedList.map((disciplina) => (
          <CardDisciplina
            key={disciplina.id}
            disciplina={disciplina}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            disabled={!disciplina.estado}
          />
        ))}
      </div>
     
      <ActionsContainer onPrint={handlePrint}/>

      <DisciplinaForm
        currentDisciplina={currentDisciplina}
        showModal={showModal}
        handleClose={handleClose}
      />
    </div>
    </div>
  );
};

export default DisciplinaList;
