import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { getPersonaFullName } from "../../util/valueCalculator";
import { toast } from "react-toastify";
import { cambiarEstadoTecnico } from "../../actions/tecnicoActions";

const HabilitacionTecnico = ({ handleClose, currentTecnico,actualizarLista }) => {
  const dispatch = useDispatch();

  
  const cambiarEstado = () => {
    if (currentTecnico) {
        dispatch(cambiarEstadoTecnico(currentTecnico.id, {estado: !currentTecnico.estado2})).then(() => {
          actualizarLista()
          handleClose()
          })
          .catch(errores => {
            for(const err of errores){
              toast.error(err)
            }
          });
      }
}
  return (
    <>
      <p>
        Estás seguro que deseas {currentTecnico.estado2===1 ? "DESHABILITAR":"HABILITAR"} al personal extra {currentTecnico && getPersonaFullName(currentTecnico)}?
      </p>
      <div className="custom-button-group">
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant={currentTecnico.estado2===1 ? "danger":"warning"}
          onClick={cambiarEstado}
          className="ml-2"
        >
          Confimar
        </Button>
      </div>
    </>
  );
};

export default HabilitacionTecnico;
