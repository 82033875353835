import { createSlice } from "@reduxjs/toolkit";
const initialState ={
  id: null,
  nombre: null,
  universidadSigla: null,
  universidadId:null,
  rolActivo: null,
  foto:null,
  roles: [],
}
const sesionSlice= createSlice({
  name: "sesion",
  initialState: initialState,
  reducers: {
    login(state, action) {
      localStorage.setItem('sessionData', JSON.stringify(action.payload));
     if(action.payload.roles) {
        localStorage.setItem('rolActivo', action.payload.roles[0]);
        return {...action.payload, rolActivo: action.payload.roles[0]};
      }
      return action.payload
    },

    setUser() {
      const sessionData = localStorage.getItem('sessionData');
      const rolActivo = localStorage.getItem('rolActivo');
  
      if (sessionData) {
        try{
          const sesion = JSON.parse(sessionData);
         
          // console.log("Session:",sesion)
          return {...sesion, rolActivo:rolActivo};

        } catch (error) {
          console.error('Error parsing user data from localStorage:', error);
      } }
    },
    changeRol(state, action) {
      localStorage.setItem('rolActivo', action.payload);
      return {...state, rolActivo: action.payload};
    },
    logout(){
      localStorage.removeItem('token');
      localStorage.removeItem('sessionData');
      localStorage.removeItem('expiration');
      localStorage.removeItem('rolActivo');
      
      return initialState; // Devuelve el estado inicial al cerrar sesión
    }
  },
});

export const sesionActions = sesionSlice.actions;
export default sesionSlice.reducer;
