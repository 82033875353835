import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPersonasAsistentesEvento } from '../../actions/eventoAction';
import { Button, Form, Table } from 'react-bootstrap';
import ItemPersona from './ItemPersona';
import { debounce } from 'lodash';
import { fetchUniversidades } from '../../actions/universidadAction';
import { calculateAge, getModalidadByEdad, getPersonaFullNameAppat, tipo2Persona } from '../../util/valueCalculator';

import PanelOpcionesImprimir from './PanelOpcionesImprimir';
const ReporteGlobalPersonas = () => {
  const dispatch = useDispatch();
  const universidades = useSelector((state) => state.universidad.universidades) || [];
  const [filters, setFilters] = useState({ 
    universidad: 'todo',
    tipo: 'todo', 
    categoriaDeportista: 'todo',
    modalidadDeportista: 'todo' });
  const [searchTerm, setSearchTerm] = useState("");
  const [ personas, setPersonas ] = useState([])
  const eventoData = useSelector(state => state.evento);
  const [panelOpcionesModal, setPanelOpcionesModal] = useState({show:false});
  useEffect(()=>{
    dispatch(fetchPersonasAsistentesEvento(2024)).then(data => {
        const personasData = data.map(d =>({...d,
        edad : calculateAge(d.fecnac, eventoData.fechaedad).years
         }))
        setPersonas(personasData)
    })
    dispatch(fetchUniversidades());
  },[dispatch])


  const debouncedSearchTerm = useMemo(() => debounce((value) => setSearchTerm(value), 300), []);

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  const getContentForPrintAndExport = () => {
    const content = {
      head: [['N°', 'PATERNO', 'MATERNO', 'NOMBRES', 'CI','UNIVERSIDAD','TIPO']],
      body: personas.filter(persona => 
          (filters.universidad !=="todo"? filters.universidad === persona.Universidad.sigla : true ) &&
          (filters.tipo !=="todo"? (filters.tipo === persona.tipo || filters.tipo === persona.tipo2 ? true: false) : true)&&
          (filters.tipo ==="docente" && filters.modalidadDeportista !=="todo"? getModalidadByEdad(persona.edad) === filters.modalidadDeportista : true)
        ).map((p, index) => {
        const tipo1 = p.tipo === "docente"? "DEPORTISTA" : "";
        const tipo2 = tipo2Persona(p.tipo2)
      return [index+1,
        p.appat,
        p.apmat,
        p.nombres,
        p.ci,
        p.Universidad.sigla,
        `${tipo1}${tipo1 && tipo2? "; ":""}${tipo2}`
      ]
    })
    };

    return content;
  };

  const handleShowPanelOpcionesModal = () => {
    setPanelOpcionesModal({show:true});
  };
  
  const handleClosePanel = () => {
    setPanelOpcionesModal({show:false});
  };


  const handleFilters = (filter, value) => {
    if(filter === "tipo") setFilters((prev) => ({ ...prev, modalidadDeportista: "todo" }));
      setFilters((prev) => ({ ...prev, [filter]: value }));
  };
  const filteredList = useMemo(()=>{
    return personas.filter(persona => 
      { 
        const entraEnLista = (
        (filters.universidad !=="todo"? filters.universidad === persona.Universidad.sigla : true ) &&
        (filters.tipo !=="todo"? 
          (filters.tipo === persona.tipo2? persona.estado2 === 1: 
              (filters.tipo ==="docente" && persona.tipo === filters.tipo? persona.estado === 3 : false)
        ):true ) &&
        (filters.categoriaDeportista !=="todo"? persona.genero === filters.categoriaDeportista : true) &&
        (filters.modalidadDeportista !=="todo" && filters.tipo ==="docente"? getModalidadByEdad(persona.edad) === filters.modalidadDeportista : true) &&
        searchTerm.split(' ').every(term =>
            (`${getPersonaFullNameAppat(persona)}`.toLowerCase().includes(term.toLowerCase()) ||
            `${persona.ci}`.toLowerCase().includes(term.toLowerCase())
            )
      ))
      if(entraEnLista) console.log("Persona",persona)
       return entraEnLista;
    }
    )
  },[personas,filters,searchTerm])
  
  return ( 
  <div className="container-large">
      <h1>Reporte de Asistentes del Evento</h1>
      
      <div className="row align-items-center mb-4" style={{ fontSize: "0.9rem" }}>
    
        <div className="col-6 col-md-3">
          <Form.Label className="mt-2">Buscar:</Form.Label>
          <Form.Control type="text" size="sm" placeholder="Buscar por nombre o CI" 
                        onChange={(e) => setSearchTerm(e.target.value)} />
        </div>
        <div className="col-6 col-md-2">
          <Form.Label className="mt-2">Universidad:</Form.Label>
          <Form.Control as="select" size="sm" name="universidad" value={filters.universidad} 
                        onChange={(e) => handleFilters("universidad", e.target.value)}>
            <option value="todo">- Todas -</option>
            {universidades.map((universidad) => (
              <option key={universidad.id} value={universidad.sigla}>
                {universidad.sigla}
              </option>
            ))}
          </Form.Control>
        </div>
        <div className="col-6 col-md-2 mt-2">
          <Form.Label>Tipo:</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={filters.tipo}
            onChange={(e) => handleFilters("tipo", e.target.value)}
            className="mr-2"
          >
            <option value="todo">- Todo -</option>
            {["docente","tecnico","personal","invitado","comite"].map(tipo => (
              <option key={tipo} value={tipo}>{tipo2Persona(tipo)}</option>
            ))}
          </Form.Control>
        </div>
       {filters.tipo === "docente" &&  
       <>
       {/* <div className="col-6 col-md-2 mt-2">
          <Form.Label>Categoría:</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={filters.categoriaDeportista}
            onChange={(e) => handleFilters("categoriaDeportista", e.target.value)}
            className="mr-2"
          >
            <option value="todo">- Todo -</option>
            {["D","V"].map(cat => (
              <option key={cat} value={cat}>{genderMap[cat]}</option>
            ))}
          </Form.Control>
        </div> */}
         <div className="col-6 col-md-2 mt-2">
          <Form.Label>Modalidad:</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            value={filters.modalidadDeportista}
            onChange={(e) => handleFilters("modalidadDeportista", e.target.value)}
            className="mr-2"
          >
            <option value="todo">- Todo -</option>
            {["LIBRE","SENIOR","MASTER"].map(cat => (
              <option key={cat} value={cat}>{cat}</option>
            ))}
          </Form.Control>
        </div>
        </>}
        <div className="col-6 col-md-3 mt-2">

          <Button
            size="sm"
                variant="outline-dark"
                onClick={() => handleShowPanelOpcionesModal("imprimir")}
                className="action-button ml-md-2 mb-2 mb-md-0"
            >
                <i className="bi bi-arrow-up-right-square"></i> Exportar
            </Button>
        
        </div>
      </div>
    <div
    className="table-container"
    style={{ maxHeight: '550px', overflowY: "scroll" }}
  >
    <Table
      bordered hover className="table-bordered" style={{ fontSize: 'small' }}
    >
      <thead className="sticky-header custom-table-header">
        <tr>
          <th className="col-cod">N°</th>
         
          <th>Paterno</th>
          <th>Materno</th>
          <th>Nombres</th>
          <th >C.I.</th>
          <th>Universidad</th>
          <th>Tipo</th> 
          {filters.tipo === "docente" &&
           <>
           {/* <th>Categoría</th> */}
            <th>Modalidad</th></> }
          </tr>
      </thead>
      <tbody>
        {filteredList.map((persona, index) => {
          const indice = index + 1;
          return (
            <ItemPersona
              key={persona.id}
              persona={persona}
              indice={indice}
              filters={filters}
            />
          );
        })}
      </tbody>
    </Table>
  </div>

<PanelOpcionesImprimir 
    show={panelOpcionesModal.show} 
    imprimir={true}
    filteredList={filteredList}
    handleClose={handleClosePanel}
    filters={{...filters, searchTerm: searchTerm}}/>
  </div>
  );
};

export default ReporteGlobalPersonas;
