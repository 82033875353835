import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addRol, modifyRol, fetchRoles } from '../../actions/rolActions';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

//COMPONENTE 
//FORMULARIO DE ROL
const RolForm = ({ currentRol, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [estado, setEstado] = useState('1'); // Por defecto, activo
  const [descripcion, setDescripcion] = useState('');

  useEffect(() => {
    if (currentRol) {
      setNombre(currentRol.nombre);
      setEstado(currentRol.estado);
      setDescripcion(currentRol.descripcion);
    } else {
      setNombre('');
      setEstado('1'); // Resetear a activo
      setDescripcion('');
    }
  }, [currentRol]);

  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    const rolData = { nombre, descripcion, estado };
    if (currentRol) {
      dispatch(modifyRol(currentRol.id, rolData)).then(() => {
        handleClose();
        dispatch(fetchRoles());  // Actualiza la lista de roles
      });
    } else {
      dispatch(addRol(rolData)).then(() => {
        handleClose();
        dispatch(fetchRoles());  // Actualiza la lista de roles
      });
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{currentRol ? 'Editar Rol' : 'Nuevo Rol'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-small">
          <Form onSubmit={handleSubmit} className="modal-oval">
            <Form.Group controlId="formNombreRol"  className='modal-input'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del Rol"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescripcionRol" className='modal-input'>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                placeholder="Descripción del Rol"
                value={descripcion}
                onChange={e => setDescripcion(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEstadoRol" className='modal-input'>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                value={estado}
                onChange={e => setEstado(e.target.value)}
              >
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </Form.Control>
            </Form.Group>
            <Button variant={currentRol ? 'warning' : 'info'} type="submit"  className="btn-modal">
              {currentRol ? 'Actualizar' : 'Crear'}
            </Button>
            <Button variant="secondary" onClick={handleClose} className="btn-modal">
              Cancelar
            </Button>   
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RolForm;
