import { useDispatch, useSelector } from "react-redux";
import { fetchPredios, addPredio, updatePredio } from "../../actions/predioActions";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useInputFile } from "../../hooks/useInputImage";
import { getUploadUrl } from "../../util/valueCalculator";

const PredioForm = ({ onHandleClose }) => {
    const [datos, setDatos] = useState({
        nombre: '',
        ubicacion: '',
        direccion: '',
        relato: '',
        tipo: 1,
        estado: 1
    });
    const {
        file: foto1,
        handleFileChange: handleFoto1Change,
        resetInputFile: resetFoto1,
        isValid: isFoto1Valid
      } = useInputFile();
    
      const {
        file: foto2,
        handleFileChange: handleFoto2Change,
        resetInputFile: resetFoto2,
        isValid: isFoto2Valid
      } = useInputFile();
    
    const dispatch = useDispatch();
    const currentPredio = useSelector(state => state.predio.current);

    useEffect(() => {
        if (currentPredio) {
            setDatos(currentPredio);
        }
    }, [currentPredio]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos(prevDatos => ({ ...prevDatos, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fd = new FormData();
        Object.keys(datos).forEach(key => {
            fd.append(key, datos[key]);
        });
        
        
    if (foto1 instanceof File || foto1 instanceof Blob)
        fd.append("foto1", foto1);
      if (foto2 instanceof File || foto2 instanceof Blob)
        fd.append("foto2", foto2);

        if (currentPredio) {
            dispatch(updatePredio(currentPredio.id, fd)).then(() => {
                onHandleClose();
                dispatch(fetchPredios());
            });
        } else {
            dispatch(addPredio(fd)).then(() => {
                onHandleClose();
                dispatch(fetchPredios());
            });
        }
    };
    const actualFoto1Url = currentPredio && currentPredio.foto1 ? getUploadUrl(currentPredio.foto1) : null;
    const actualFoto2Url = currentPredio && currentPredio.foto2 ? getUploadUrl(currentPredio.foto2) : null;
    
    return (
        <div className="contenedor">
            <Form onSubmit={handleSubmit} className="modal-oval">
                <Form.Group className="row">
                    <div className="col-md-4 modal-input">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            name="nombre"
                            placeholder="Nombre"
                            value={datos.nombre}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="col-md-8 modal-input">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                            type="text"
                            name="direccion"
                            placeholder="Dirección"
                            value={datos.direccion}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <div className="col-md-12 modal-input">
                        <Form.Label>Ubicación</Form.Label>
                        <Form.Control
                            type="text"
                            name="ubicacion"
                            placeholder="Enlace de la ubicacion en google maps"
                            value={datos.ubicacion}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <div className="col-md-4 modal-input">
                        <Form.Label>Relato</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="relato"
                            placeholder="Relato"
                            value={datos.relato}
                            onChange={handleInputChange}
                            maxLength={255} 
                        />
                    </div>
                    <div className="col-md-4 modal-input">
                        <Form.Label>Tipo</Form.Label>
                        <Form.Control
                            as="select"
                            name='tipo'
                            value={datos.tipo}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="1">Abierto</option>
                            <option value="2">Cerrado</option>
                        </Form.Control>
                    </div>
                    <div className="col-md-4 modal-input">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                            as="select"
                            name='estado'
                            value={datos.estado}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="1">Activo</option>
                            <option value="0">Inactivo</option>
                        </Form.Control>
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <div className="col-md-6 modal-input">
                        <Form.Label>Foto 1</Form.Label>
                         <Form.Control
                            type="file"
                            name="foto1"
                            isInvalid={!isFoto1Valid}
                            onChange={(e) => handleFoto1Change(e.target.files[0])}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor selecciona una imagen válida.
                        </Form.Control.Feedback>
                        {(foto1 && isFoto1Valid) && (
                            <img
                            className="img-fluid mb-2 mt-3"
                            style={{ maxHeight: "200px" }}
                            src={URL.createObjectURL(foto1)}
                            />
                        )}
                    </div>
                    <div className="col-md-6 modal-input">
                        {actualFoto1Url && (
                            <>
                            <Form.Label>Foto 1 actual</Form.Label>
                            <img
                                className="img-fluid mb-2"
                                style={{ maxHeight: "200px" }}
                                src={actualFoto1Url}
                            />
                            </>
                        )}
                        </div>
                    <div className="col-md-6 modal-input">
                        <Form.Label>Foto 2</Form.Label>
                         <Form.Control
                            type="file"
                            name="foto2"
                            isInvalid={!isFoto2Valid}
                            onChange={(e) => handleFoto2Change(e.target.files[0])}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor selecciona una imagen válida.
                        </Form.Control.Feedback>
                        {(foto2 && isFoto2Valid) && (
                            <img
                                className="img-fluid mb-2 mt-3"
                                style={{ maxHeight: "200px" }}
                                src={URL.createObjectURL(foto2)}
                            />
                            )}
                    </div>
                    <div className="col-md-6 modal-input">
                        {actualFoto2Url && (
                            <>
                            <Form.Label>Foto 2 actual</Form.Label>
                            <img
                                className="img-fluid mb-2"
                                style={{ maxHeight: "200px" }}
                                src={actualFoto2Url}
                            />
                            </>
                        )}
                    </div>
                </Form.Group>
                <Button variant={currentPredio ? 'warning' : 'info'} type="submit" className="btn-modal">
                    {currentPredio ? 'Actualizar' : 'Crear'}
                </Button>
                <Button variant="secondary" onClick={onHandleClose} className="btn-modal">
                    Cancelar
                </Button>
            </Form>
        </div>
    );
};

export default PredioForm;