const adminEventoMenu = [
  {
    title: "Evento",
    tipo: "dropdown",
    options: [
      { name: "Disciplinas", path: "/disciplinas-admin" },
      { name: "Predios", path: "/predios" },
      { name: "Datos generales", path: "/evento-datos-generales" },
      { name: "Retroceder aprobación", path: "/desaprobacion" } ,
      { name: "Reportes de docentes", path: "/reporte-docente" },   
      { name: "Reportes de docentes por disciplinas", path: "/reporte-disciplina" },   
      { name: "Reporte global de asistentes", path: "/reporte-asistentes" },   
      { name: "Comité organizador e invitados", path: "/personas-especiales" },
     ],
  },
  {
    title: "Preparación del Evento",
    tipo: "dropdown",
    options: [
      { name: "Universidades", path: `/universidades/${encodeURIComponent("LISTA DE UNIVERSIDADES")}/3` },
      { name: "Participación universidades en subdisciplinas", path: "/subdisciplinas" },
      { name: "Inscripciones deportistas", path: `/universidades/${encodeURIComponent("INSCRIPCIÓN DOCENTE")}/1` },
      { name: "Aprobación de inscripciones", path: `/universidades/${encodeURIComponent("APROBACIÓN DOCENTE")}/2` },
    ],
  },
  {
    title: "Desarrollo del Evento",
    tipo: "dropdown",
    options: [
      { name: "Gestión del fixture", path: "/desarrollo-subdisciplinas/1" },
      { name: "Gestión de noticias", path: "/desarrollo-subdisciplinas/2" },
      { name: "Gestión del medallero", path: "/medalleros-admin" },
      { name: "Visualización Medallero general", path: "/medallero-general" },
      { name: "VIsualización Medallero por disciplinas", path: "/medalleros" },
      { name: "Visualización Programa",  path: "/fixtures" },
      { name: "Control de asistencia", path: "/control-asistencia" },

    ],
  }, 
  
  {
    title: "Cierre evento",
    tipo: "dropdown",
    options: [
      // { name: "Impresion de certificados", path: "/impresiones" },
      { name: "Impresión del medallero oficial",path: "/medallero-general"  },
      { name: "Impresión del medallero completo",path: "/medalleros"  },
      { name: "Backup base de datos", path: "/backup" },
    ],
  }
];

export default adminEventoMenu;
