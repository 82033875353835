const menu = [
  { title: "Disciplinas", tipo: "button", path: "/disciplinas" },
  { title: "Programa", tipo: "button", path: "/fixtures" },
  {
    title: "Medallero",
    tipo: "dropdown",
    options: [
      { name: "Medallero general", path: "/medallero-general" },
      { name: "Medallero por disciplinas", path: "/medalleros" },
    ],
  },
  { title: "Noticias", tipo: "button", path: "/noticias" },
  { title: "Deportistas", tipo: "button", path: "/buscador-deportista" }, 
  { title: "Descargar app", tipo: "button", path: "/descargar-aplicacion" }

];
export default menu;

