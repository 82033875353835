import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/Subdisciplina.css";
import "../../../styles/Disciplina.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Row, Col } from "react-bootstrap";
import defaultImage from "../../../assets/disciplinas/default.jpg";
import {
  fetchSubdisciplinaById
} from "../../../actions/subdisciplinaActions";
import { getUploadUrl } from "../../../util/valueCalculator";



const VerPublicSubdisciplina = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subdisId } = useParams();
  const [fotoPreview, setFotoPreview] = useState(null);
  const subdisciplina = useSelector(
    (state) => state.subdisciplina.subdisciplina
  );
  const disciplina = useSelector((state) => state.disciplina.disciplina);

  useEffect(() => {
    if (subdisciplina) {
      if (subdisciplina.foto1) {
        setFotoPreview(getUploadUrl(subdisciplina.foto1));
      } else if(disciplina)
              {if(disciplina.foto1) setFotoPreview(getUploadUrl(disciplina.foto1));
               else if(disciplina.foto2) setFotoPreview(getUploadUrl(disciplina.foto2))
              }
               } else {
      dispatch(fetchSubdisciplinaById(subdisId));
    }
  }, [subdisciplina]);

  const handleImageError = () => {
    setFotoPreview(defaultImage);
  };

  if (!subdisciplina) {
    return <p>Subdisciplina no encontrada</p>;
  }
  
  return (
    <div className="subdisciplina-page">
      <Button
        variant="light"
        onClick={() => navigate(`/disciplina/${subdisciplina.iddisc}`)}
        className="back-button"
      >
        <i className="bi bi-arrow-left"></i> Volver
      </Button>
      <div className="subdisciplina-container">
        {fotoPreview !== '' && <div
          className="subdisciplina-image-container"
          style={{ backgroundImage: `url(${fotoPreview})` }}
          onError={handleImageError}
        ></div>}
       
        <div className="subdisciplina-details-container">
          <h1 className="subdisciplina-title">{subdisciplina.nombre}</h1>
          <div className="subdisciplina-info" style={{fontSize:"1.5rem"}}>
            {subdisciplina.relato && <div className="subdisciplina-info-item">
              <p>{subdisciplina.relato}</p>
            </div>}
            <div className="subdisciplina-info-item">
              <strong style={{fontSize:"1.2rem"}}>Categoría:  </strong>{subdisciplina.genero === "V" ? "VARONES" : "DAMAS"}
            </div>
            <div className="subdisciplina-info-item">
              <strong style={{fontSize:"1.2rem"}}>Modalidad: </strong>
                {subdisciplina.categoria === "L"
                  ? "LIBRE"
                  : subdisciplina.categoria === "M"
                  ? "MASTER"
                  : "SENIOR"}
            </div>
            <div className="subdisciplina-info-item">
              <strong style={{fontSize:"1.2rem"}}>Edad Mínima</strong>
              <p>{subdisciplina.edadmin}</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong style={{fontSize:"1.2rem"}}>Tipo de participación</strong>
              <p>{subdisciplina.tipo_participacion.toUpperCase()}</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong style={{fontSize:"1.2rem"}}>Cantidad mínima requerida</strong>
              <p>{subdisciplina.edadmax}</p>
            </div>
            <div className="subdisciplina-info-item">
              <strong style={{fontSize:"1.2rem"}}>Cantidad máxima requerida</strong>
              <p>{subdisciplina.maxdep}</p>
            </div>
          
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default VerPublicSubdisciplina;
