import { sendLogin, sendRegister} from '../services/authService';
import { sesionActions } from '../reducers/session_slice';
import { toast } from 'react-toastify';

export const login = (user) => async dispatch => {
    try {
      const data = await sendLogin(user);
      const { token, message, sessionData } = data;
    // //  console.log('login data:', sessionData); // Depuración

        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);

        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expiration.toISOString());
        dispatch(sesionActions.login(sessionData));
        return message;

    } catch (error) {
      toast.error(`Error en el servidor al iniciar sesión`);
    }
  };
  
export const register = (user) => async dispatch => {
    try {
      const data = await sendRegister(user);
     //console.log('register data:', data); // Depuración
     
    } catch (error) {
      console.error('register error:', error);
    }
  };
  