import { useDispatch, useSelector } from "react-redux";
import { fetchDocentes, fetchDocentesByUniversidad, removeDocente } from "../../actions/docenteActions";
import { Button } from "react-bootstrap";

const DeleteDocente = ({ onHandleClose }) => {
  const dispatch = useDispatch();
  const currentDocente = useSelector((state) => state.docente.current);

  const handleDelete = () => {
    dispatch(removeDocente(currentDocente.id)).then(() => {
      onHandleClose();
      dispatch(fetchDocentesByUniversidad(currentDocente.iduniv)); // Actualiza la lista de disciplinas
    });
  };
  return (
    <>
      <p>
        El docente puede estar inscrito en alguna subdisciplina.
        Estás seguro que deseas eliminar al docente {currentDocente && `${currentDocente.nombres} ${currentDocente.appat}`}?
      </p>
      <div className="custom-button-group">
        <Button
          variant="secondary"
          onClick={onHandleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          className="ml-2"
        >
          Eliminar
        </Button>
      </div>
    </>
  );
};

export default DeleteDocente;
