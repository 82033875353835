import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubdisciplinaById,
  fetchSubdisciplinas,
  fetchSubdisciplinasByDisciplinaId,
} from "../../actions/subdisciplinaActions";
import SubdisciplinaForm from "./SubdisciplinaForm";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import ActionsContainer from "../generales/ActionsContainer"; // Asegúrate de que la ruta sea correcta
import { categoryMap, estadoDisciplina } from "../../util/valueCalculator";

// COMPONENTE
// Lista de Subdisciplinas
const ListSubdisciplinas = ({ disciplina = null }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subdisciplinas = useSelector((state) => {
    if (disciplina) return state.subdisciplina.subdisciplinasByDisciplinaId;
    else return state.subdisciplina.subdisciplinas;
  });
  const rolActivo = useSelector(state => state.sesion.rolActivo);
 
  const [showModal, setShowModal] = useState(false);
  const [currentSubdisciplina, setCurrentSubdisciplina] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    genero: "",
    categoria: "",
  });
  const estadoEvento= useSelector(state => state.evento.estado)
 
  useEffect(() => {
    if (disciplina) {
      dispatch(fetchSubdisciplinasByDisciplinaId(disciplina.id));
    } else {
      dispatch(fetchSubdisciplinas());
    }
  }, [dispatch, disciplina]);

  const handleCreate = () => {
    setCurrentSubdisciplina(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentSubdisciplina(null);
  };

  const handleFilters = (filter, value) => {
    setFilters((prev) => ({ ...prev, [filter]: value }));
  };
  const handlePrint = () => {};

  const sortedList = [...subdisciplinas].sort((a, b) => {
    const compareStrings = (str1, str2) => str1.localeCompare(str2);

    const compare = compareStrings(a.nombre, b.nombre);
    if (compare !== 0) return compare;

    const compareGenero = compareStrings(a.genero, b.genero);
    if (compareGenero !== 0) return compareGenero;

    const compareCategoria = () =>{
      if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
      if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
     return 0
    }
    return compareCategoria();
  });

  const filteredList = sortedList.filter(
    (subdisciplina) =>
      subdisciplina.nombre.toLowerCase().includes(searchTerm.toLowerCase()) &&
      subdisciplina.genero.includes(filters.genero) &&
      subdisciplina.categoria.includes(filters.categoria)
  );

  const handleVerSubdisciplina = (subdisciplina) => {
    dispatch(fetchSubdisciplinaById(subdisciplina.id));
    navigate(`/disciplina-admin/${disciplina.id}/subdisciplina-admin/${subdisciplina.id}`);
  };

  const formatGenero = (genero) => {
    switch (genero) {
      case "D":
        return "DAMAS";
      case "V":
        return "VARONES";
      default:
        return genero;
    }
  };

  const formatCategoria = (categoria) => {
    switch (categoria) {
      case "L":
        return "LIBRE";
      case "S":
        return "SENIOR";
      case "M":
        return "MASTER";
      default:
        return categoria;
    }
  };
  const handleClick = (subdisciplina) => {
    if (subdisciplina.estado === 1) {
      handleVerSubdisciplina(subdisciplina);
    }
  };

  return (
    <div className={disciplina ? "contenedor" : "contenedor container-medium"}>
      <h1>SUBDISCIPLINAS</h1>
      {disciplina && estadoEvento === 0 &&(
        <div className="mb-3">
          <Button
            variant="info"
            onClick={handleCreate}
            className="float-end"
          >
            <i className="bi bi-plus-lg"></i> Nueva subdisciplina
          </Button>
        </div>
      )}
      <Form.Group
        controlId="filtrosSubdisciplinas"
        className="row"
      >
        <div className="col-md-4 modal-input">
          <Form.Label>Buscar</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Categoría</Form.Label>
          <Form.Control
            as="select"
            value={filters.genero}
            onChange={(e) => handleFilters("genero", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="V">VARONES</option>
            <option value="D">DAMAS</option>
          </Form.Control>
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Modalidad</Form.Label>
          <Form.Control
            as="select"
            value={filters.categoria}
            onChange={(e) => handleFilters("categoria", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="L">LIBRE</option>
            <option value="S">SENIOR</option>
            <option value="M">MASTER</option>
          </Form.Control>
        </div>
      </Form.Group>
      <div
        className="table-container"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <Table
          bordered
          hover
        >
          <thead>
            <tr>
              <th className="col-cod">N°</th>
              <th>Nombre</th>
              <th>Categoría</th>
              <th>Modalidad</th>
              {rolActivo === "admin_evento" &&<th>Estado</th>}
              {!disciplina && <th>Disciplina</th>}
            </tr>
          </thead>
          <tbody>
            {filteredList.map((subdisciplina, index) => {

              return(
              <tr
                key={subdisciplina.id}
                onClick={() => handleClick(subdisciplina)}
              >
                <td>{index + 1}</td>
                <td>{subdisciplina.nombre}</td>
                <td>{formatGenero(subdisciplina.genero)}</td>
                <td>{formatCategoria(subdisciplina.categoria)}</td>
                {rolActivo === "admin_evento" && <td style={{backgroundColor: subdisciplina.estado === 0?"#FFE4E1":"#F0FFF0"}}><span>{estadoDisciplina(subdisciplina.estado)}</span></td>}
                {!disciplina && <td>{subdisciplina.Disciplina.nombre}</td>}
              </tr>
            )})}
          </tbody>
        </Table>
      </div>
      {!disciplina && <ActionsContainer onPrint={handlePrint} />}
      {disciplina && (
        <SubdisciplinaForm
          showModal={showModal}
          handleClose={handleCloseModal}
          disciplina={disciplina}
          currentSubdisciplina={currentSubdisciplina}
        />
      )}
    </div>
  );
};

export default ListSubdisciplinas;
