import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/usuaroles');

export const getUsuarioRoles = async (idUsuario) => {
    try {
        const response = await api.get(`/${idUsuario}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los roles del usuario:', error);
        throw error;
    }
};

export const saveUsuarioRoles = async (data) => {
    try {
        const response = await api.post(`/save`, data);
        return response.data;
    } catch (error) {
        console.error('Error al guardar los roles del usuario:', error);
        throw error;
    }
};

export const deleteUsuarioRoles = async (idUsuario) => {
    try {
        const response = await api.delete(`/deleteAll/${idUsuario}`);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar los roles del usuario:', error);
        throw error;
    }
};


export const getUsuaroles = async () => {
    try {
        const response = await api.get('/');
        return response.data;
    } catch (error) {
        throw error;
    }
};
