import axios from 'axios';
import { getAuthToken } from './auth';

export const API_URL_BASE = process.env.REACT_APP_API_URL; 
export const UPLOADS_BASE_URL=process.env.REACT_APP_UPLOADS_URL

const createAxiosInstance = (path) => {
    // Crear una instancia de Axios con la configuración base
    const api = axios.create({
      baseURL: API_URL_BASE + path
    });
      // Configurar un interceptor para agregar el token de autorización a cada solicitud
    api.interceptors.request.use(
      config => {
        const token = getAuthToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    
    return api;
  };
  
  export default createAxiosInstance;