// src/store.js

import { configureStore } from '@reduxjs/toolkit';
import paisReducer from './reducers/paisReducer';
import ciudadReducer from './reducers/ciudadReducer';
import rolReducer from './reducers/rolReducer';
import procesoReducer from './reducers/procesoReducer';
import usuarioReducer from './reducers/usuarioReducer';
import rolprocReducer from './reducers/rolprocReducer';
import usuarioRolReducer from './reducers/usuarolReducer';
import disciplinaReducer from './reducers/disciplinaReducer'; // Importar el reducer
import subdisciplinaReducer from './reducers/subdisciplinaReducer'; // Importar el reducer
import sesionReducer from './reducers/session_slice'; // Importar el reducer
import universidadReducer from './reducers/universidad_slice'; // Importar el reducer
import docenteReducer from './reducers/docente_slice'; // Importar el reducer
import eventoReducer from './reducers/evento_slice'; // Importar el reducer
import docsubReducer from './reducers/docsubReducer'; // Importar el reducer
import predioReducer from './reducers/predio_slice'; // Importar el reducer
import fixtureReducer from './reducers/fixture_slice'; // Importar el reducer
import noticiaReducer from './reducers/noticia_slice'; // Importar el reducer
import medalleroReducer from './reducers/medallero_slice'; // Importar el reducer
import fixturedetReducer from './reducers/fixturedet_slice'; // Importar el reducer


const store = configureStore({
  reducer: {
    sesion: sesionReducer,
    evento: eventoReducer,
    pais: paisReducer,
    ciudad: ciudadReducer,
    universidad: universidadReducer,
    docente: docenteReducer,
    predio: predioReducer,
    fixture: fixtureReducer,
    noticia: noticiaReducer,
    medallero: medalleroReducer,
    rol: rolReducer,
    proceso: procesoReducer,
    usuario: usuarioReducer,
    rolproc: rolprocReducer,
    usuarioRol: usuarioRolReducer,
    disciplina: disciplinaReducer, // Añadir el reducer
    subdisciplina: subdisciplinaReducer, // Añadir el reducer
    docsub: docsubReducer, // Añadir el reducer
    fixturedet: fixturedetReducer,
  },
});

export default store;
