// src/actions/usuarolActions.js
import { toast } from 'react-toastify';
import apiService from '../services/apiService';
import { getUsuarioRoles, deleteUsuarioRoles, saveUsuarioRoles,getUsuaroles } from '../services/usuarolService';


const usuarolService = apiService('/usuaroles');

export const fetchUsuaroles = () => async dispatch => {
    try {
        const data = await getUsuaroles();
        dispatch({
            type: 'FETCH_USUARIOLES_SUCCESS',
            payload: data
        });
    } catch (error) {
        dispatch({
            type: 'FETCH_USUARIOLES_FAILURE',
            payload: error.message
        });
    }
};


export const fetchUsuarioRoles = (idUsuario) => async dispatch => {
    try {
        const data = await getUsuarioRoles(idUsuario);
        dispatch({ type: 'GET_USUARIO_ROLES', payload: data });
    } catch (error) {
        console.error('fetchUsuarioRoles error:', error);
    }
};

export const removeUsuarioRoles = (idUsuario) => async dispatch => {
    try {
        await deleteUsuarioRoles(idUsuario);
        dispatch({ type: 'DELETE_USUARIO_ROLES', payload: idUsuario });
    } catch (error) {
        console.error('removeUsuarioRoles error:', error);
    }
};

export const addUsuarioRoles = (usuarioRol) => async dispatch => {
    try { 
        const data = await saveUsuarioRoles(usuarioRol);
        dispatch({ type: 'SAVE_USUARIO_ROLES', payload: data });
    } catch (error) {
        console.error('addUsuarioRoles error:', error);
    }
};

//'/usuaroles/:idusu'
export const updateUsuarioRoles = (idusu, body) => async dispatch => {
    try {
     
      const data = await usuarolService.update(idusu, body);
      toast.success(`Roles del usuario actualizados exitosamente`);
       }  catch (error) {
        toast.error(`Error al actualizar roles del usuario`);
      }
  };