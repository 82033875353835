import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/procesos');

export const getProcesos = async () => {
  const response = await api.get('/');
  return response.data;
};

export const getProcesoById = async (id) => {
  const response = await api.get(`/${id}`);
  return response.data;
};

export const createProceso = async (proceso) => {
  const response = await api.post('/', proceso);
  return response.data;
};

export const updateProceso = async (id, proceso) => {
  const response = await api.put(`/${id}`, proceso);
  return response.data;
};

export const deleteProceso = async (id) => {
  const response = await api.delete(`/${id}`);
  return response.data;
};
