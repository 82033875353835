const encargadoUniversidadMenu = [{
  title: "Universidad",
  tipo: "dropdown",
  options: [
    { name: "Docentes", path:`/docentes/${encodeURIComponent("DOCENTES")}/3` },
    { name: "Personal extra", path: `/personal-extra` },
    { name: "Pre-inscripción en subdisciplinas", path: `/preinscripcion-subdisciplinas/1` },
  ],
},
  {
    title: "Inscripciones",
    tipo: "dropdown",
    options: [
      { name: "Inscripciones por docente", path: `/docentes/${encodeURIComponent("INSCRIPCIÓN POR DOCENTES")}/1` },
      { name: "Inscripciones por disciplina", path:"/incripcion-disciplina"},
      { name: "Reportes de inscripciones disciplinas", path: "/reporte-disciplina" },   
    ],
  },
  // {
  //   title: "Cierre evento",
  //   tipo: "dropdown",
  //   options: [
  //     { name: "Impresión del medallero oficial", path: "/desarrollo/3"}
  //   ],
  // }
];

export default encargadoUniversidadMenu;

