// src/services/ciudadService.js

import createAxiosInstance from '../util/axiosInstance';

const api = createAxiosInstance('/ciudades');


export const getCiudades = async () => {
  const response = await api.get('/');
  return response.data;
};

export const getCiudadById = async (id) => {
  const response = await api.get(`/${id}`);
  return response.data;
};

export const createCiudad = async (ciudad) => {
  const response = await api.post('/', ciudad);
  return response.data;
};

export const updateCiudad = async (id, ciudad) => {
  const response = await api.put(`/${id}`, ciudad);
  return response.data;
};

export const deleteCiudad = async (id) => {
  const response = await api.delete(`/${id}`);
  return response.data;;
};
