import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUniversidades, fetchUniversidadesBySubdis } from "../../actions/universidadAction";
import { Button, Form } from "react-bootstrap";
import { addMedallero, fetchMedalleros, updateMedallero } from "../../actions/medalleroActions";
import { getSubdisciplinaFullName } from "../../util/valueCalculator";

const FormMedallero = ({ onHandleClose }) => {
  const dispatch = useDispatch();
  const [datos, setDatos] = useState({
    subdisciplinaId: "",
    oroIdUniv: "",
    plataIdUniv: "",
    bronceIdUniv: "",
  });
  const [errors, setErrors] = useState([]);
  const [universidadesToSelect, setUniversidadesToSelect] = useState([]);
  const currentMedallero = useSelector((state) => state.medallero.current);

  useEffect(() => {
    if (currentMedallero) {
      const {
        Subdisciplina,
        OroUniversidad,
        PlataUniversidad,
        BronceUniversidad,
      } = currentMedallero;
      setDatos({
        subdisciplinaId:Subdisciplina.id,
        oroIdUniv: OroUniversidad && OroUniversidad.id,
        plataIdUniv:PlataUniversidad && PlataUniversidad.id,
        bronceIdUniv:BronceUniversidad && BronceUniversidad.id,
      });
    }
  }, [currentMedallero]);

  useEffect(() => {
    const fetchDataToSelect = async () => {
      const universidadesFetched = await dispatch(fetchUniversidades());
      if (universidadesFetched) {
        // const list = universidadesFetched.map(item => item.universidades)
        setUniversidadesToSelect(universidadesFetched);
      }
    };
    if(datos.subdisciplinaId){fetchDataToSelect();}

  }, [dispatch,datos.subdisciplinaId]);

  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const valueInt = value===""?"":Number(value);
    setDatos((prevDatos) => ({ ...prevDatos, [name]: valueInt }));
  };

  const validateForm = () => {
    const validationErrors = [];

    if (!datos.subdisciplinaId) {
      validationErrors.push("Debe seleccionar una subdisciplina.");
    }
    if (!datos.oroIdUniv && (datos.plataIdUniv || datos.bronceIdUniv)) {
      validationErrors.push("Debe seleccionar un oro.");
    }
    if (!datos.plataIdUniv && datos.bronceIdUniv) {
      validationErrors.push("Debe seleccionar una plata.");
    }
    return validationErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }
   //console.log("Enviando medallero", datos);
    if (currentMedallero) {
      dispatch(updateMedallero(currentMedallero.Subdisciplina.id, datos)).then(() => {
        onHandleClose();
        dispatch(fetchMedalleros());  // Actualiza la lista de medalleros
      });
    } else {
      dispatch(addMedallero(datos)).then(() => {
        onHandleClose();
        dispatch(fetchMedalleros());  // Actualiza la lista de medalleros
      });
  };
}
   const subdisNombre = currentMedallero ? getSubdisciplinaFullName(currentMedallero.Subdisciplina) : "";
  
  return (
    <>
      <h5>
        Subdisciplina:{" "}
        {subdisNombre}
      </h5>

      <div className="contenedor">
        <Form
          onSubmit={handleSubmit}
          className="modal-oval"
        >
          {errors.length > 0 && (
            <div
              className="alert alert-danger"
              role="alert"
            >
              {errors.map((error, index) => (
                <p key={error}>{error}</p>
              ))}
            </div>
          )}
        <Form.Group className="row">
              <div className="col-md-4 modal-input">
                <Form.Label>Oro Universidad</Form.Label>
                <Form.Control
                  as="select"
                  name="oroIdUniv"
                  value={datos.oroIdUniv}
                  onChange={handleInputChange}
                >
                  <option value="">- Seleccionar universidad -</option>
                  {universidadesToSelect.map((univ) => {
                        if (
                          univ.id !== datos.bronceIdUniv &&
                          univ.id !== datos.plataIdUniv
                        ) {
                          return (
                            <option key={univ.id} value={univ.id}>
                               {`${univ.sigla}    -----  ${univ.nombre}`}
                            </option>
                          );
                        }
                        return null;
                      })}
                </Form.Control>
              </div>
              <div className="col-md-4 modal-input">
                <Form.Label>Plata Universidad</Form.Label>
                <Form.Control
                  as="select"
                  name="plataIdUniv"
                  value={datos.plataIdUniv}
                  onChange={handleInputChange}
                >
                  <option value="">- Seleccionar universidad -</option>
                  {universidadesToSelect.map((univ) => {
                        if (
                          univ.id !== datos.bronceIdUniv &&
                          univ.id !== datos.oroIdUniv
                        ) {
                          return (
                            <option key={univ.id} value={univ.id}>
                              {`${univ.sigla}    -----  ${univ.nombre}`}
                            </option>
                          );
                        }
                        return null;
                      })}
                </Form.Control>
              </div>{" "}
              <div className="col-md-4 modal-input">
                <Form.Label>Bronce Universidad</Form.Label>
                <Form.Control
                  as="select"
                  name="bronceIdUniv"
                  value={datos.bronceIdUniv}
                  onChange={handleInputChange}
                >
                  <option value="">- Seleccionar universidad -</option>
                  {universidadesToSelect.map((univ) => {
                        if (
                          univ.id !== datos.oroIdUniv &&
                          univ.id !== datos.plataIdUniv
                        ) {
                          return (
                            <option key={univ.id} value={univ.id}>
                                {`${univ.sigla}    -----  ${univ.nombre}`}
                            </option>
                          );
                        }
                        return null;
                      })}
                </Form.Control>
              </div>
            </Form.Group>
          <Button
            variant={currentMedallero ? "warning" : "info"}
            type="submit"
            className="btn-modal"
          >
            {currentMedallero ? "Actualizar" : "Crear"}
          </Button>
          <Button
            variant="secondary"
            onClick={onHandleClose}
            className="btn-modal"
          >
            Cancelar
          </Button>
        </Form>
      </div>
    </>
  );
};

export default FormMedallero;
