import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import placeholder from '../../assets/docentes/docente_default.png';
import axios from 'axios';
import moment from 'moment';
import { calculateAge, getBackgroundColor, getPersonaFullName, getSubdisciplinaFullName, getUploadUrl, tipo2Persona } from '../../util/valueCalculator';
import QRCode from 'qrcode.react';
import MensajeCentral from '../mensajes/MensajeCentral';

const API_URL_BASE = process.env.REACT_APP_API_URL; 

const PerfilInvitado = ({currentInvitado=null}) => {
    const { ci } = useParams();
    const location = useLocation(); 
    const [invitado, setInvitado] = useState(null);
    const [fotoPreview, setFotoPreview] = useState(placeholder);
    const [error, setError] = useState(null)
    const type = invitado?.tipo2==="invitado" || location.pathname.includes("invitado")? "invitado" : invitado?.tipo2==="comite"  || location.pathname.includes("comite")? "comite" : null
    useEffect(() => {
        if (ci) {
           
            axios.get(`${API_URL_BASE}/public-persona-tipo/${type}/${ci}`)
                .then(response => {
                    const data = response.data;
                    setInvitado(data.persona);

                    if (data.persona.foto1) {
                       setFotoPreview(getUploadUrl(data.persona.foto1));
                    }
                })
                .catch(error =>  {
                    if(error && error.response && error.response.data)
                    {setError("Invitado no encontrado")}
                    else{setError("Error, intente de nuevo más tarde...")}
                });
        }else if (currentInvitado) {
             setInvitado(currentInvitado);
              if (currentInvitado.foto1) {
                setFotoPreview(getUploadUrl(currentInvitado.foto1));
             }
          }
    }, [ci,currentInvitado]);

    if(error){
        return <MensajeCentral>{error}</MensajeCentral>;
    }
    if (!invitado) {
        return <MensajeCentral>Esperando datos del invitado...</MensajeCentral>;
    }

    const backgroundColor = "#060477";
    const columnaCentradaClasses = "d-flex justify-content-center align-items-center"
    const tipoPersona =tipo2Persona(invitado.tipo2)
    return (
        <Card border="0" style={{ color:"white",backgroundColor, margin: ci? "20px": "0px"}}>
            <Card.Body>
                <Row>
                    <Col xs={12} lg={4} className={columnaCentradaClasses}>
                        <Card.Img
                            variant="top"
                            src={fotoPreview}
                            style={{ maxWidth: "300px", objectFit: "cover", marginBottom:"20px"}}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <Card.Text>
                            <strong>{tipoPersona}</strong>
                            <br/>
                            <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}> {getPersonaFullName(invitado).toUpperCase()} <br/></span>
                            </Card.Text>
                            <Card.Text>
                                {invitado.relato2} <br/>
                              </Card.Text>
                            <Card.Text>C.I.:<strong>{invitado.ci}</strong> <br/>
                            Registro:<strong>{" "}
                            {tipoPersona}</strong> <br/>
                            Universidad:<strong> {invitado.Universidad.sigla} </strong><br/>
                            <strong>Estado:</strong>{" "}
                            {invitado.estado2 === 1 ? (
                                <span className={`badge text-bg-success`}>ACTIVO</span>
                         
                            ):(
                                <span className={`badge text-bg-secondary`}>INACTIVO</span>
                            )}
                        </Card.Text>
                        </Col>
                       {!ci && invitado?.estado2 === 1 &&  <Col xs={12} lg={4} className={`mt-3 ${columnaCentradaClasses}`}> 
                       <QRCode
                            value={`${process.env.REACT_APP_OFFICIAL_DOMAIN}/perfil-${type}/${invitado.ci}`}
                            size={150}  // Aumenta el tamaño del QR
                            level="H"
                            includeMargin={true}
                        />
                          </Col>}
                </Row>
              
            </Card.Body>
        </Card>
    );
};

export default PerfilInvitado;
