import { toast } from 'react-toastify';
import apiService from '../services/apiService';
import {
    getSubdisciplinas,
    getSubdisciplinasByDisciplinaId,
    getSubdisciplinaById,
    createSubdisciplina,
    updateSubdisciplina,
    deleteSubdisciplina,
    sdupdateStatus,
    sddeletePreinscripciones
    
  } from '../services/subdisciplinaService';

  const subdisciplinasService = apiService('/subdisciplinas');


  export const updateSubdisciplinaStatus = (ids, status) => async dispatch => {
    try {// console.log("En action ids=",ids, "Status=")
        const data = await sdupdateStatus(ids, status);
        toast.success(`Subdisciplinas actualizadas exitosamente.`);
    } catch (error) {
        toast.error('Error al actualizar el estado de las subdisciplinas.');
    }
};

export const deletePreinscripciones = (ids) => async dispatch => {
    try {
        const data = await sddeletePreinscripciones(ids);
        toast.success(`Preinscripciones eliminadas exitosamente.`);
    } catch (error) {
        toast.error('Error al eliminar preinscripciones.');
    }
};

  export const fetchSubdisciplinas = () => async dispatch => {
    try {
      const data = await getSubdisciplinas();
      dispatch({ type: 'GET_SUBDISCIPLINAS', payload: data });
    } catch (error) {
      console.error('fetchSubdisciplinas error:', error);
    }
  };
  
  export const fetchSubdisciplinasByUniversidad = (iduniv) => async dispatch => {
    try {
     const data = await subdisciplinasService.getAllBySomeId('universidad',iduniv);
      dispatch({ type: 'GET_SUBDISCIPLINAS', payload: data });
    } catch (error) {
      console.error('fetchSubdisciplinasByDisciplinaId error:', error);
    }
  };
    
  export const fetchSubdisciplinasByDisciplinaId = (iddisc) => async dispatch => {
    try {
      const data = await getSubdisciplinasByDisciplinaId(iddisc);
      dispatch({
        type: 'GET_SUBDISCIPLINAS_BY_DISCIPLINA_ID',
        payload:  data 
      });
    } catch (error) {
      console.error('fetchSubdisciplinasByDisciplinaId error:', error);
    }
  };
    
    
  export const fetchSubdisciplinasByDocenteId = (iddoc) => async dispatch => {
    try {
      const data = await subdisciplinasService.getAllBySomeId('docente', iddoc);
      dispatch({
        type: 'GET_SUBDISCIPLINAS_BY_DISCIPLINA_ID',
        payload:  data 
      });
    } catch (error) {
      console.error('fetchSubdisciplinasByDisciplinaId error:', error);
    }
  };
    
    
  export const fetchSubdisciplinasByUsuarioId = (idusu, rol) => async dispatch => {
    try {
      const data = await subdisciplinasService.getAllBySomeIdAndRol('usuario', idusu, rol);
     
      dispatch({ type: 'GET_SUBDISCIPLINAS', payload: data });
    } catch (error) {
      toast.error("Error al cargar las disciplinas.")
    }
  };
  
  export const fetchSubdisciplinaById = (id) => async dispatch => {
    try {
      const data = await getSubdisciplinaById(id);
      dispatch({ type: 'GET_SUBDISCIPLINA_BY_ID', payload: data });
     //console.log('fetchSubdisciplinaById data:', data);
    
    } catch (error) {
      console.error('fetchSubdisciplinaById error:', error);
    }
  };
   
  export const fetchSubdisciplinasSinMedallero = () => async dispatch => {
    try {
      const data = await subdisciplinasService.getAllBySomeId('sinmedallero', '');
      return data;
    } catch (error) {
      toast.error(`Error al cargar la lista de subdisciplinas.`);
    }
  };
    
    
  export const addSubdisciplina = (formData) => async dispatch => {
    try {
      const data = await createSubdisciplina(formData);
      dispatch({ type: 'CREATE_SUBDISCIPLINA', payload: data });
      toast.success(`Subdisciplina creada exitosamente.`);
   
    } catch (error) {
      toast.error('Error al crear nueva subdisciplina.');
    }
  };
  
  export const modifySubdisciplina = (id, formData) => async dispatch => {
    try {
      const data = await updateSubdisciplina(id, formData);
      toast.success(`Subdisciplina editada exitosamente.`);
   
    } catch (error) {
      toast.error('Error al editar la subdisciplina.');
    }
  };
  
  export const removeSubdisciplina = (id) => async dispatch => {
    try {
      const data = await deleteSubdisciplina(id);
      dispatch({ type: 'DELETE_SUBDISCIPLINA', payload: id });
      toast.success(`Subdisciplina eliminada exitosamente.`);
    } catch (error) {
      toast.error(error.response && error.response.data ? error.response.data:"Error al eliminar la subdisciplina.");
   }
  };
  