import { getRolProcesos, deleteRolProcesos, saveRolProcesos } from '../services/rolprocService';

export const fetchRolProcesos = (idRol) => async dispatch => {
  try {
    const data = await getRolProcesos(idRol);
    dispatch({ type: 'GET_ROL_PROCESOS', payload: data });
  } catch (error) {
    console.error('fetchRolProcesos error:', error);
  }
};

export const removeRolProcesos = (idRol) => async dispatch => {
  try {
    await deleteRolProcesos(idRol);
    dispatch({ type: 'DELETE_ROL_PROCESOS', payload: idRol });
  } catch (error) {
    console.error('removeRolProcesos error:', error);
  }
};

export const addRolProcesos = (rolProceso) => async dispatch => {
  try {
    const data = await saveRolProcesos(rolProceso);
    dispatch({ type: 'SAVE_ROL_PROCESOS', payload: data });
  } catch (error) {
    console.error('addRolProcesos error:', error);
  }
};
