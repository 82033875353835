// services/docsubService.js
import { toast } from "react-toastify";
import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/docsubs');

export const getDocsubByUniversidad = async (idUniv) => {
  try {
    const response = await api.get(`/universidad/${idUniv}`);
    return response.data;
  } catch (error) {
    toast.error(error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const goInscribirDocente = async (docenteData) => {
  try {
    const response = await api.post('/inscribir', docenteData);
    return response.data;
  } catch (error) {
    toast.error(error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const updateDocenteSubdisciplinasService = async (docenteData) => {
  try {
    const { id } = docenteData; // Asume que docenteData tiene un campo id
    const response = await api.put(`/${id}`, docenteData);
    return response.data;
  } catch (error) {
   //console.log("saliendo del service con error:", error);
    toast.error(error.response ? error.response.data.message : error.message);
    throw error;
  }
};

export const updateDocenteAproveService = async (iddoc,data) => {
  try {
    //const { id } = docenteData; // Asume que docenteData tiene un campo id
   //console.log("Entro al service... de aprove service, con ",data)
    const response = await api.put(`estado/${iddoc}`, data);
    return response.data;
  } catch (error) {
   //console.log("saliendo del service con error:", error);
    toast.error(error.response ? error.response.data.message : error.message);
    throw error;
  }
};


export const updateSubdisciplinaDocentesService = async (idsub,data) => {
  try {
   //console.log("Entro al service... de updateSubdisciplinaDocentesService, con ",idsub)
      const response = await api.put(`/update-subdisciplina-docentes/${idsub}`, data);
     //console.log("En service armo> ",response)
      return response.data;
  } catch (error) {
     //console.log("Erorr en service armo> ",error)
      toast.error(error.response ? error.response.data.message : error.message);
      throw error;
  }
};

export default {
  getDocsubByUniversidad,
  goInscribirDocente,
  updateDocenteSubdisciplinasService,
  updateSubdisciplinaDocentesService,
  updateDocenteAproveService
};
