import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNoticias } from "../../actions/noticiaActions";
import CardNoticia from "./CardNoticia";
import { Form, Modal } from "react-bootstrap";
import { fetchSubdisciplinas } from "../../actions/subdisciplinaActions";
import { noticiaActions } from "../../reducers/noticia_slice";
import FormNoticia from "./FormNoticia";
import DeleteNoticia from "./DeleteNoticia";
import '../../styles/Noticias.css'
import '../../styles/Publico.css'
import esperandoImg from "../../assets/esperandosentado.png"

import { categoryMap, genderMap, getSubdisciplinaFullName } from "../../util/valueCalculator";
import MensajeCentral from "../mensajes/MensajeCentral";
const Noticias = () => {
  const dispatch = useDispatch();
  const noticias = useSelector((state) => state.noticia.noticias);
  const disciplinas = useSelector((state) => state.disciplina.disciplinas);
  const subdisciplinas = useSelector(
    (state) => state.subdisciplina.subdisciplinas
  );
  const [disciplinaId, setDisciplinaId] = useState();
  const [subdisciplinaId, setSubdisciplinaId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(""); // Estado para la fecha seleccionada
  const [modal, setModal] = useState({ tipo: 0, titulo: "", body: <></> });
  const estadoEvento= useSelector(state => state.evento.estado)

  useEffect(() => {
    dispatch(fetchSubdisciplinas());
    dispatch(fetchNoticias());
  }, [dispatch]);
  if (estadoEvento < 1) {
    return (
      <>
      <MensajeCentral image={  <img className="mt-5" src={esperandoImg} alt="Ya inician los juegos" width={200} height={200}/> }>
            Esperando para ver las noticias del evento...
      </MensajeCentral>
    
      </>
    );
  }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  let listNoticias=noticias;

  if(subdisciplinaId !=="")
    {if(subdisciplinaId==='-1') {
      listNoticias = noticias.filter(
      (noticia) =>
        (noticia.tipo === 'general')
   )}
    else { 
      listNoticias = noticias.filter(
        (noticia) =>
          (`${noticia.subdisciplinaId}` === subdisciplinaId)
     )
    }}

    if (selectedDate) {
     //console.log("Fechas",selectedDate, listNoticias[0].fecha,listNoticias[0].fecha.startsWith(selectedDate))
      listNoticias = listNoticias.filter(
        (noticia) => noticia.fecha && noticia.fecha.startsWith(selectedDate)
      );
    }
  
    
    const filteredNoticias = listNoticias.filter(
      (noticia) =>{
        const subdiName = getSubdisciplinaFullName(noticia.Subdisciplina)
        return((noticia.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
         noticia.relato.toLowerCase().includes(searchTerm.toLowerCase()) ||
         (subdiName !== "Sin especificar"? subdiName.toLowerCase().includes(searchTerm.toLowerCase()):true)) &&
        (noticia.estado === 1))}
   )

//Show modal 
const handleShowModal= (tipo, noticia) =>{
    dispatch(noticiaActions.setCurrentNoticia(noticia));
    let titulo = '';
    let body = <></>;
    if (tipo === 1) { titulo = "Nueva Noticia"; body = (<FormNoticia onHandleClose={handleClose} />); }
    else if (tipo === 2) { titulo = "Editar Noticia"; body = (<FormNoticia  onHandleClose={handleClose} />); }
    else if (tipo === 3) { titulo = "Confirmar eliminación"; body = (<DeleteNoticia onHandleClose={handleClose} />); }

    setModal({ tipo, titulo, body });
  }

  //Cerrar modal  
  const handleClose = () => {
    dispatch(noticiaActions.setCurrentNoticia(null));
    setModal({ tipo: 0, titulo: "", body: <></> });
  };
  const sortSubdis = [...subdisciplinas].sort((a, b) => {
    if (a.nombre < b.nombre) return -1;
    if (a.nombre > b.nombre) return 1;
    if (a.genero < b.genero) return -1;
    if (a.genero > b.genero) return 1;
    if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
    if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
    return 0;
  })
  return (<>
   <h1>Noticias</h1>
      <Form>
        <Form.Group className="row">
        <div className="col-8 col-md-4 modal-input">
            <Form.Label>Buscar</Form.Label>
            <Form.Control
              type="text"
              title="Subdisciplina o titulo"
              placeholder="Buscar por subdisciplina o titulo"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-4 col-md-4 modal-input">
                <Form.Label>Fecha</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-4 modal-input">
                <Form.Label>Filtrar por tipo</Form.Label>
                <Form.Control
                  as="select"
                  value={subdisciplinaId}
                  onChange={(e) => setSubdisciplinaId(e.target.value)}
                >
                  <option
                    key="default"
                    value=""
                  >
                    - Todas -
                  </option>
                  <option
                    key="general"
                    value="-1"
                  >GENERAL</option>
                  {sortSubdis.map((subdisciplina) => (
                    <option
                      key={subdisciplina.id}
                      value={subdisciplina.id}
                    >
                      {`${subdisciplina.nombre} - ${genderMap[subdisciplina.genero]} - ${categoryMap[subdisciplina.categoria]?categoryMap[subdisciplina.categoria].nombre:""}`}
                    </option>
                  ))}
                </Form.Control>
              </div>
             
              <div className="social-media-links">
            <a href="https://www.facebook.com/share/TYzsxvHSPMcB5kvN/?mibextid=qi2Omg" title="Noticias en facebook" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
              <i className="bi bi-facebook" style={{ fontSize: "1.5rem" }}></i>
            </a>
      </div> 
        </Form.Group>
      </Form>
      <div className="noticias-list">
        {filteredNoticias.map((noticia) => (
          <CardNoticia
            key={noticia.id}
            noticia={noticia}
            showModal={handleShowModal} 
          />
        ))}
      </div>
     <Modal
     show={modal.tipo > 0}
     onHide={handleClose}
     size="lg"
     centered
   >
     <Modal.Header closeButton>
       <Modal.Title>{modal.titulo}</Modal.Title>
     </Modal.Header>
     <Modal.Body>{modal.body}</Modal.Body>
   </Modal>
 </>
  );
};

export default Noticias;
