import { toast } from "react-toastify";
import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/usuarios');

export const getUsuarios = async () => {

    const response = await api.get('/');
    return response.data;
};

export const getUsuarioById = async (id) => {
  const response = await api.get(`/${id}`);
  return response.data;
};

export const createUsuario = async (usuario) => {
  const response = await api.post('/', usuario);
  return response.data;
};

export const updateUsuario = async (id, usuario) => {
  const response = await api.put(`/${id}`, usuario);
  return response.data;
};

export const deleteUsuario = async (id) => {
  const response = await api.delete(`/${id}`);
  return response.data;
};
