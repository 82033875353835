import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  recepciones: [],
  recepcion: null,
};

const recepcionSlice = createSlice({
  name: 'recepcion',
  initialState,
  reducers: {
    setRecepciones(state, action) {
      state.recepciones = action.payload;
    },
    setRecepcion(state, action) {
      state.recepcion = action.payload;
    },
    addRecepcion(state, action) {
      state.recepciones.push(action.payload);
    },
    updateRecepcion(state, action) {
      const { id, changes } = action.payload;
      const index = state.recepciones.findIndex(recepcion => recepcion.id === id);
      if (index !== -1) {
        state.recepciones[index] = { ...state.recepciones[index], ...changes };
      }
    },
    removeRecepcion(state, action) {
      state.recepciones = state.recepciones.filter(recepcion => recepcion.id !== action.payload);
    },
  },
});

export const recepcionActions = recepcionSlice.actions;
export default recepcionSlice.reducer;
