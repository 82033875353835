// actions/docsubActions.js
import { toast } from 'react-toastify';
import { docenteActions } from '../reducers/docente_slice';
import apiService from '../services/apiService';
import  {updateSubdisciplinaDocentesService,getDocsubByUniversidad, updateDocenteSubdisciplinasService,  goInscribirDocente, updateDocenteAproveService}from '../services/docsubService';

const docSubsService = apiService('/docsubs');

export const INSCRIBIR_DOCENTE_REQUEST = 'INSCRIBIR_DOCENTE_REQUEST';
export const INSCRIBIR_DOCENTE_SUCCESS = 'INSCRIBIR_DOCENTE_SUCCESS';
export const INSCRIBIR_DOCENTE_FAILURE = 'INSCRIBIR_DOCENTE_FAILURE';

export const FETCH_DOCSUB_BY_UNIVERSIDAD_REQUEST = 'FETCH_DOCSUB_BY_UNIVERSIDAD_REQUEST';
export const FETCH_DOCSUB_BY_UNIVERSIDAD_SUCCESS = 'FETCH_DOCSUB_BY_UNIVERSIDAD_SUCCESS';
export const FETCH_DOCSUB_BY_UNIVERSIDAD_FAILURE = 'FETCH_DOCSUB_BY_UNIVERSIDAD_FAILURE';

export const FETCH_DOCSUB_BY_DOCENTE_REQUEST = 'FETCH_DOCSUB_BY_DOCENTE_REQUEST';
export const FETCH_DOCSUB_BY_DOCENTE_SUCCESS = 'FETCH_DOCSUB_BY_DOCENTE_SUCCESS';
export const FETCH_DOCSUB_BY_DOCENTE_FAILURE = 'FETCH_DOCSUB_BY_DOCENTE_FAILURE';

export const FETCH_DOCSUB_BY_SUBDISCIPLINA_REQUEST = 'FETCH_DOCSUB_BY_SUBDISCIPLINA_REQUEST';
export const FETCH_DOCSUB_BY_SUBDISCIPLINA_SUCCESS = 'FETCH_DOCSUB_BY_SUBDISCIPLINA_SUCCESS';
export const FETCH_DOCSUB_BY_SUBDISCIPLINA_FAILURE = 'FETCH_DOCSUB_BY_SUBDISCIPLINA_FAILURE';



export const UPDATE_DOCENTE_SUBDISCIPLINAS_REQUEST = 'UPDATE_DOCENTE_SUBDISCIPLINAS_REQUEST';
export const UPDATE_DOCENTE_SUBDISCIPLINAS_SUCCESS = 'UPDATE_DOCENTE_SUBDISCIPLINAS_SUCCESS';
export const UPDATE_DOCENTE_SUBDISCIPLINAS_FAILURE = 'UPDATE_DOCENTE_SUBDISCIPLINAS_FAILURE';

export const UPDATE_DOCENTE_APROVE_REQUEST = 'UPDATE_DOCENTE_APROVE_REQUEST';
export const UPDATE_DOCENTE_APROVE_SUCCESS = 'UPDATE_DOCENTE_APROVE_SUCCESS';
export const UPDATE_DOCENTE_APROVE_FAILURE = 'UPDATE_DOCENTE_APROVE_FAILURE';


export const UPDATE_SUBDISCIPLINA_DOCENTES_REQUEST = 'UPDATE_SUBDISCIPLINA_DOCENTES_REQUEST';
export const UPDATE_SUBDISCIPLINA_DOCENTES_SUCCESS = 'UPDATE_SUBDISCIPLINA_DOCENTES_SUCCESS';
export const UPDATE_SUBDISCIPLINA_DOCENTES_FAILURE = 'UPDATE_SUBDISCIPLINA_DOCENTES_FAILURE';

export const FETCH_DOCSUB_REQUEST = 'FETCH_DOCSUB_REQUEST';
export const FETCH_DOCSUB_SUCCESS = 'FETCH_DOCSUB_SUCCESS';
export const FETCH_DOCSUB_FAILURE = 'FETCH_DOCSUB_FAILURE';


export const updateSubdisciplinaDocentes = (idsub,data) => async (dispatch) => {
  try {  //console.log("en action updateSubdisciplinaDocentes con:",idsub, data )
      dispatch({ type: UPDATE_SUBDISCIPLINA_DOCENTES_REQUEST });
      const response = await updateSubdisciplinaDocentesService(idsub,data);
      //console.log("Despues del service en action: ", response)
      dispatch({ type: UPDATE_SUBDISCIPLINA_DOCENTES_SUCCESS, payload: response });
  } catch (error) { //console.log("EN action conerror",error)
      dispatch({
          type: UPDATE_SUBDISCIPLINA_DOCENTES_FAILURE,
          payload: error.response ? error.response.data.message : error.message,
      });
  }
};

export const approveDocenteSubdisciplinas = (iddoc,docenteData) => async (dispatch) => {
   try {
    
    dispatch({ type: UPDATE_DOCENTE_APROVE_REQUEST });

    const data = await updateDocenteAproveService(iddoc,docenteData);

    dispatch({
      type: UPDATE_DOCENTE_APROVE_SUCCESS,
      payload: data,
    });

   // toast.success('Inscripciones actualizadas correctamente');
  } catch (error) {
    dispatch({
      type: UPDATE_DOCENTE_APROVE_FAILURE,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });

   // toast.error(error.response ? error.response.data.message : error.message);
  }
};


export const updateDocenteSubdisciplinas = (docenteData) => async (dispatch) => {
  //console.log("en action updateDocenteSubdisciplina con:", docenteData )
  try {
    
    dispatch({ type: UPDATE_DOCENTE_SUBDISCIPLINAS_REQUEST });

    const data = await updateDocenteSubdisciplinasService(docenteData);

    dispatch({
      type: UPDATE_DOCENTE_SUBDISCIPLINAS_SUCCESS,
      payload: data,
    });

   // toast.success('Inscripciones actualizadas correctamente');
  } catch (error) {
    dispatch({
      type: UPDATE_DOCENTE_SUBDISCIPLINAS_FAILURE,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });

   // toast.error(error.response ? error.response.data.message : error.message);
  }
};






export const fetchDocsubByUniversidad = (idUniv) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_DOCSUB_BY_UNIVERSIDAD_REQUEST });
   // console.log("En action  de fetchDocsubByUniversidad con ",idUniv)
    const data = await getDocsubByUniversidad( idUniv);
    //console.log("despues de  action  de fetchDocsubByUniversidad con ",data)
    dispatch({
      type: FETCH_DOCSUB_BY_UNIVERSIDAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_DOCSUB_BY_UNIVERSIDAD_FAILURE,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};


export const fetchDocentesBySubdisciplina = (idsub) => async (dispatch) => {
  dispatch({ type: FETCH_DOCSUB_BY_SUBDISCIPLINA_REQUEST });
  try {  
    const data = await docSubsService.getAllBySomeId( 'subdisciplina', idsub);
   //console.log("despues de  action  de fetchDocentesBySubdisciplina con ",data)
    dispatch({
      type: FETCH_DOCSUB_BY_SUBDISCIPLINA_SUCCESS,
      payload: data,
    });
    return data;

  } catch (error) {
    dispatch({ type: FETCH_DOCSUB_BY_UNIVERSIDAD_FAILURE });
    toast.error("Error al cargar la lista de docentes inscritos en la subdisciplina.")
  }
};

export const fetchSubdisciplinasByDocente = (iddoc) => async (dispatch) => {
  dispatch({ type: FETCH_DOCSUB_BY_DOCENTE_REQUEST });
  try {

    const data = await docSubsService.getAllBySomeId( 'docente', iddoc);
    dispatch({
      type: FETCH_DOCSUB_BY_DOCENTE_SUCCESS,
      payload: data,
    });
   //console.log("despues de  action  de fetchSubdisciplinasByDocente con ",data)
    return data;

  } catch (error) {
    dispatch({ type: FETCH_DOCSUB_BY_DOCENTE_FAILURE });
    toast.error("Error al cargar la lista de  subdisciplinas  de un docente.")
  }
};

export const inscribirDocente = (docenteData) => async (dispatch) => {
  try {
    dispatch({ type: INSCRIBIR_DOCENTE_REQUEST });

    const data = await goInscribirDocente(docenteData);

    dispatch({
      type: INSCRIBIR_DOCENTE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSCRIBIR_DOCENTE_FAILURE,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};


export const inscribirDocentesMasivo = (inscripcionesData) => async (dispatch) => {
  try {
    const data = await docSubsService.createByTipo("masivo", inscripcionesData);
    return data;
  } catch (error) {
      // Lanzar el error para que pueda ser capturado en el componente
      throw error.response ? error.response.data : { mensaje: 'Error al crear los docentes' };
  }
};


export const fetchDocsub = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_DOCSUB_REQUEST });

    const data = await docSubsService.getAll(); // Suponiendo que esta función obtiene todos los docsub
    //console.log("Datos recibidos del backend (fetchDocsub):", data);
    dispatch({
      type: FETCH_DOCSUB_SUCCESS,
      payload: data,
    });
  } catch (error) {
    //console.error("Error en fetchDocsub:", error);
    dispatch({
      type: FETCH_DOCSUB_FAILURE,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};
