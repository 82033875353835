import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/rolprocesos');

export const getRolProcesos = async (idRol) => {
    try {
        const response = await api.get(`/${idRol}`);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los procesos del rol:', error);
        throw error;
    }
};

export const saveRolProcesos = async (data) => {
    try {
        const response = await api.post(`/save`, data);
        return response.data;
    } catch (error) {
        console.error('Error al guardar los procesos del rol:', error);
        throw error;
    }
};

export const deleteRolProcesos = async (idRol) => {
    try {
        const response = await api.delete(`/deleteAll/${idRol}`);
        return response.data;
    } catch (error) {
        console.error('Error al eliminar los procesos del rol:', error);
        throw error;
    }
};
