// src/actions/medalleroActions.js

import { medalleroActions } from '../reducers/medallero_slice';
import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const medallerosService = apiService('/medalleros');

export const fetchMedalleros = () => async dispatch => {
  try {
    const data = await medallerosService.getAll()
    dispatch(medalleroActions.setMedalleros(data));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de medalleros.`);
  }
};
export const fetchMedallerosByUsuarioId = (idusu, rol) => async dispatch => {
  try {
    const data = await medallerosService.getAllBySomeIdAndRol('usuario', idusu, rol);
    dispatch(medalleroActions.setMedalleros(data));
  } catch (error) {
    toast.error("Error al cargar las disciplinas.")
  }
};
export const fetchMedallerosByDisciplina = (iddisc) => async dispatch => {
  try {
    const data = await medallerosService.getAllBySomeId('disciplina', iddisc)
    dispatch(medalleroActions.setMedalleros(data));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de medalleros de la disciplina`);
  }
};
export const fetchMedallero = (idsub) => async dispatch => {
  try {
    const data = await medallerosService.getAllBySomeId('subdisciplina', idsub)
     dispatch(medalleroActions.setMedalleros([data]));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de medalleros de la disciplina`);
  }
};

export const fetchMedalleroGeneral = () => async dispatch => {
  try {
    const data = await medallerosService.getAllBySomeId('general')
   //console.log("Medallero general", data)
    dispatch(medalleroActions.setMedalleroGeneral(data));
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de medalleros de la disciplina`);
  }
};

export const addMedallero = (medallero) => async dispatch => {
  try {
    const data = await medallerosService.create(medallero);
    toast.success(`Medallero creado exitosamente.`);
  } catch (error) {
    const theError = error.response.data.error;
   //console.log("Mensaje", theError.data)
   //console.log("Status", theError.status)

    toast.error(`Error al crear el nueva medallero`,theError);
  }
};

export const updateMedallero = (id, medallero) => async dispatch => {
  try {
    const data = await medallerosService.update(id, medallero);
    toast.success(`Medallero editado exitosamente`);
  } catch (error) {
    toast.error(`Error al editar el medallero seleccionado.`);
  }
};

export const removeMedallero= (id) => async dispatch => {
  try {
    const data = await medallerosService.remove(id);
    toast.success(`Medallero eliminado exitosamente`);
  } catch (error) {
    toast.error(`Error al eliminar el medallero seleccionado.`);
  }
};
