
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { reiniciarRecepcion } from "../../actions/recepcionActions";

const ReiniciarRecepcion = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  useEffect(()=>{
  
  },[dispatch])
  const handleShowModal = () => {
    setShowModal(true)
  }
  const handleReiniciar = () => {
    dispatch(reiniciarRecepcion())
    setShowModal(false)
  }
  const onHandleClose = () => {
    setShowModal(false)
  }
  return (
    <> <div className="row justify-content-center align-items-center mb-4 mt-5">
              <div className="col-8">
                <h1>Reinicio de control de asistencia</h1>
               <p>Se eliminará la lista de asistentes verificados actual y se comenzará de nuevo.</p>
            </div> 
            </div> 
        <div className="row justify-content-center align-items-center mb-4 mt-5">
            <div className="col-4">
            <Button variant="warning" onClick={handleShowModal}> Reiniciar control de asistencia</Button>
            </div>
            </div>
      <Modal show={showModal} onClose={onHandleClose} centered>
        <Modal.Header>Confirmar</Modal.Header>
        <Modal.Body>
        <p>
        Estás seguro que deseas reiniciar el control de asistencia?
      </p>
      <div className="custom-button-group">
        <Button
          variant="secondary"
          onClick={onHandleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="warning"
          onClick={handleReiniciar}
          className="ml-2"
        >
          Reiniciar
        </Button>
      </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ReiniciarRecepcion;
