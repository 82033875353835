// InscripcionUniversidad.js

import { useDispatch, useSelector } from "react-redux";
import { fetchSubdisciplinas } from "../../actions/subdisciplinaActions";
import { Button, ListGroup, Alert, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { updateSubdisciplinasUniversidad, getSubdisciplinasByUniversidad } from "../../actions/universidadAction";
import { categoryMap, genderMap } from "../../util/valueCalculator";
import CustomPrintModal from "../CustomPrintModal";

const InscripcionUniversidad = ({ universidad }) => {
    const dispatch = useDispatch();
    const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
    const [selectedSubdisciplinas, setSelectedSubdisciplinas] = useState([]);
    const [availableSubdisciplinas, setAvailableSubdisciplinas] = useState([]);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [hasModifications, setHasModifications] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [searchTerm1, setSearchTerm1] = useState("");
    const [filters1, setFilters1] = useState({
      genero: "",
      categoria: "",
    });
    const [searchTerm2, setSearchTerm2] = useState("");
    const [filters2, setFilters2] = useState({
      genero: "",
      categoria: "",
    });
    const evento = useSelector((state) => state.evento);
  
    useEffect(() => {
        dispatch(fetchSubdisciplinas());
    }, [dispatch]);

    useEffect(() => {
        if (subdisciplinas) {
            const sortedSubdisciplinas = [...subdisciplinas].sort((a, b) => {
                if (a.nombre < b.nombre) return -1;
                if (a.nombre > b.nombre) return 1;
                if (a.genero < b.genero) return -1;
                if (a.genero > b.genero) return 1;
                if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
                if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
      return 0;
            });
            
        
            setAvailableSubdisciplinas(sortedSubdisciplinas.filter(sub => !selectedSubdisciplinas.some(sel => sel.id === sub.id)));
        }
    }, [subdisciplinas, selectedSubdisciplinas]);

    useEffect(() => {
        const fetchSubdisciplinasPreinscritas = async () => {
            try {
                const preinscritas = await dispatch(getSubdisciplinasByUniversidad(universidad.id));
                const formattedSubdisciplinas = preinscritas.map(sub => ({
                    id: sub.subdisciplinas.id,
                    nombre: sub.subdisciplinas.nombre,
                    genero: sub.subdisciplinas.genero,
                    categoria: sub.subdisciplinas.categoria,
                }));
                const sortedSubdisciplinas = [...formattedSubdisciplinas].sort((a, b) => {
                    if (a.nombre < b.nombre) return -1;
                    if (a.nombre > b.nombre) return 1;
                    if (a.genero < b.genero) return -1;
                    if (a.genero > b.genero) return 1;
                    if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
                    if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
          return 0;
                });
                setSelectedSubdisciplinas(sortedSubdisciplinas);
            } catch (error) {
                console.error("Error al cargar las subdisciplinas preinscritas:", error);
            }
        };
        fetchSubdisciplinasPreinscritas();
    }, [dispatch, universidad.id]);

    const handleAddSubdis = (subdisToAdd) => {
        setSelectedSubdisciplinas((prevSelected) => [
            ...prevSelected,
            subdisToAdd
        ]);
        setHasModifications(true);
        setHasSaved(false);
    };

    const handleFilters = (list, filter, value) => {
        if(list === 1)
        setFilters1((prev) => ({ ...prev, [filter]: value }));
        if(list === 2)
        setFilters2((prev) => ({ ...prev, [filter]: value }));
      
    };

    const handleRemoveSubdis = (subdisToRemove) => {
        setSelectedSubdisciplinas((prevSelected) =>
            prevSelected.filter((sub) => sub.id !== subdisToRemove.id)
        );
        setHasModifications(true);
        setHasSaved(false);
    };

    const handleSave = async () => {
        if (!hasModifications && selectedSubdisciplinas.length === 0) {
            setShowAlert(true);
            return;
        }
        const subdisToSend = selectedSubdisciplinas.map((subdis) => subdis.id);
        const fd = new FormData();
        fd.append("subdisciplinas", JSON.stringify(subdisToSend));
       //console.log("subdisciplinas enviando", subdisToSend);
        dispatch(updateSubdisciplinasUniversidad(universidad.id, fd)).then(() => {
           //console.log("updateSubdisciplinasUniversidad Listoo");
            setHasModifications(false);
            setHasSaved(true);
        });
    };

    const getPrintContent = () => {
        const head = [['N°','Nombre', 'Categoría', 'Modalidad']];
        const body = selectedSubdisciplinas.filter((subdis)=>subdis.genero.includes(filters2.genero)).map((subdis, index) => [
            index+1,
            subdis.nombre,
            genderMap[subdis.genero],
            categoryMap[subdis.categoria].nombre
        ]);
        return { head, body };
    };

    const handleShowPreview = () => {
        setPrintContent(getPrintContent());
        setShowPreviewModal(true);
    };

    const handleClosePreview = () => {
        setShowPreviewModal(false);
    };
    const filteredListAvailables = availableSubdisciplinas.filter(
        (subdisciplina) =>
        subdisciplina.nombre.toLowerCase().includes(searchTerm1.toLowerCase()) &&
        subdisciplina.genero.includes(filters1.genero) &&
        subdisciplina.categoria.includes(filters1.categoria)
    );
    const sortedSelectedSubdisciplinas = [...selectedSubdisciplinas].sort((a, b) => {
        if (a.nombre < b.nombre) return -1;
        if (a.nombre > b.nombre) return 1;
        if (a.genero < b.genero) return -1;
        if (a.genero > b.genero) return 1;
        if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
        if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
return 0;
    });
    const filteredListSelected = sortedSelectedSubdisciplinas.filter(
        (subdisciplina) =>
        subdisciplina.nombre.toLowerCase().includes(searchTerm2.toLowerCase()) &&
        subdisciplina.genero.includes(filters2.genero) &&
        subdisciplina.categoria.includes(filters2.categoria)
    );
    return (
        <div className="container" style={{ fontSize: "12px" }}>
            {showAlert && (
                <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                    No hay nada para grabar
                </Alert>
            )}
            
            <div className="row">
                <div className="col-lg-6 mt-3">
                <Form.Group
        controlId="filtrosSubdisciplinas"
        className="row"
      >
        <div className="col-md-4 modal-input">
          <Form.Label>Buscar</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm1}
            onChange={(e) => setSearchTerm1(e.target.value)}
          />
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Categoría</Form.Label>
          <Form.Control
            as="select"
            value={filters1.genero}
            onChange={(e) => handleFilters(1,"genero", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="V">VARONES</option>
            <option value="D">DAMAS</option>
          </Form.Control>
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Modalidad</Form.Label>
          <Form.Control
            as="select"
            value={filters1.categoria}
            onChange={(e) => handleFilters(1,"categoria", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="L">LIBRE</option>
            <option value="S">SENIOR</option>
            <option value="M">MASTER</option>
          </Form.Control>
        </div>
      </Form.Group>
                    <div className="w-100">
                        <h5>Subdisciplinas Disponibles</h5>
                        <ListGroup style={{ maxHeight: "500px", overflowY: "auto" }}>
                            {filteredListAvailables.map((subdis) => (
                                <ListGroup.Item key={subdis.id}>
                                    <div className="d-flex justify-content-between">
                                        {`${subdis.nombre} ${genderMap[subdis.genero]} ${categoryMap[subdis.categoria].nombre}`}
                                        <Button
                                            variant="outline-info"
                                            size="sm"
                                            onClick={() => handleAddSubdis(subdis)}
                                        >
                                            <i className="bi bi-plus-lg"></i>
                                        </Button>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </div>
                <div className="col-lg-6 mt-3">
                <Form.Group
        controlId="filtrosSubdisciplinas"
        className="row"
      >
        <div className="col-md-4 modal-input">
          <Form.Label>Buscar</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm2}
            onChange={(e) => setSearchTerm2(e.target.value)}
          />
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Categoría</Form.Label>
          <Form.Control
            as="select"
            value={filters2.genero}
            onChange={(e) => handleFilters(2,"genero", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="V">VARONES</option>
            <option value="D">DAMAS</option>
          </Form.Control>
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Modalidad</Form.Label>
          <Form.Control
            as="select"
            value={filters2.categoria}
            onChange={(e) => handleFilters(2,"categoria", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="L">LIBRE</option>
            <option value="S">SENIOR</option>
            <option value="M">MASTER</option>
          </Form.Control>
        </div>
      </Form.Group>
                    <div className="w-100">
                        <h5>Subdisciplinas Inscritas de la Universidad</h5>
                        <ListGroup style={{ maxHeight: "500px", overflowY: "auto" }}>
                            {filteredListSelected.map((subdis) => (
                                <ListGroup.Item key={subdis.id}>
                                    <div className="d-flex justify-content-between">
                                        {`${subdis.nombre} ${genderMap[subdis.genero]} ${categoryMap[subdis.categoria].nombre}`}
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            onClick={() => handleRemoveSubdis(subdis)}
                                        >
                                            <i className="bi bi-dash"></i>
                                        </Button>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
            <Button
                    variant="dark"
                    onClick={handleShowPreview}
                    disabled={hasModifications || selectedSubdisciplinas.length === 0}
                >
                    <i className="bi bi-printer"></i> Imprimir
                </Button>
                <Button
                    variant="info"
                    onClick={handleSave}
                    className="mr-2"
                    disabled={!hasModifications && hasSaved}
                >
                    Guardar
                </Button>
            </div>

            <CustomPrintModal
                show={showPreviewModal}
                handleClose={handleClosePreview}
                title1={`${universidad?.sigla} - SUBDISCIPLINAS PREINSCRITAS ${filters2.genero===""? "": (filters2.genero === "D"?"CATEGORÍA DAMAS": "CATEGORÍA VARONES")}`}
                title2={evento.estado === 0 ?"B O R R A D O R": "A P R O B A D A"}
                content={printContent}
            />
        </div>
    );
};

export default InscripcionUniversidad;
