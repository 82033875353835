import { createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import PaginaError from "./pages/Error";
import PaginaInicio from "./pages/Inicio";
import PaisList from "./components/pais/PaisList";
import LoginForm from './components/autenticacion/LoginForm';
import RegisterForm from './components/autenticacion/RegisterForm';
import CiudadList from "./components/ciudad/CiudadList";
import RolList from "./components/usuarolproc/RolList";
import ProcesoList from "./components/usuarolproc/ProcesoList";
import UsuarioList from "./components/usuarolproc/UsuarioList";
import DisciplinaList from "./components/disciplinasub/DisciplinaList";
import PaginaAutenticacion from "./pages/Autenticacion";
import { checkAdminDisciplinaLoader, checkAdminEventoLoader, checkAdminSistemaLoader, checkAuthLoader, checkEncaUniversidadLoader, tokenLoader } from "./util/auth";
import UniversidadList from "./components/universidad/UniversidadList";
import SubdisciplinasPreparacion from "./components/disciplinasub/ListSubdisciplinasPreparacion";
import Disciplina from "./components/disciplinasub/VerDisciplina";
import VerSubdisciplina from "./components/disciplinasub/VerSubdisciplina";
import DocenteList from "./components/docente/ListDocente";
import PrediosList from "./components/predios/ListPredios";
import ListFixtures from "./components/fixtures/ListFixturesDesarrollo";
import AllFixtures from "./components/fixtures/AllFixtures";
import Noticias from "./components/noticia/AllNoticias";
import ListNoticias from "./components/noticia/ListNoticiasDesarrollo";
import Medalleros from "./components/medallero/AllMedalleros";
import ListMedallero from "./components/medallero/ListMedalleroDesarrollo";
import SubdisciplinasDesarrollo from "./components/disciplinasub/ListSubdisciplinasDesarrollo";
import MedalleroGeneral from "./components/medallero/MedalleroGeneral";
import PerfilDocenteQR from "./components/docente/PerfilDocenteQR"; 
import InscripcionUniversidad from "./components/universidad/InscripcionUniversidad";
import SubdisciplinaList from "./components/universidad/SubdisciplinaList";
import PaginaPublico from "./pages/Publico";
import PublicDisciplinas from "./components/disciplinasub/public/PublicDisciplinas";
import VerPublicDisciplina from "./components/disciplinasub/public/VerPublicDisciplina";
import VerPublicSubdisciplina from "./components/disciplinasub/public/VerPublicSubdisciplina";
import PerfilUsuario from "./components/usuarolproc/PerfilUsuario";
import EventoIndex from "./components/evento/EventoIndex";
import BackupDatabase from "./components/backup/BackupDatabase"; 
import ListDocente2 from "./components/docente/ListDocente2";
import CuerpoTecnicoList from "./components/tecnicos/ListCuerpoTecnico";
import PerfilTecnicoQR from "./components/tecnicos/PerfilTecnicoQR";
import DesaprobarDocente from "./components/docente/DesaprobarDocente";
import ReporteDocente from "./components/reportes/ReporteDocente";
import ListInvitados from "./components/invitados/ListInvitados";
import PerfilInvitado from "./components/invitados/PerfilInvitado";
import ReporteDisciplinas from "./components/reportes/ReporteDisciplinas";
import BuscadorDocente from "./components/docente/BuscadorDocente";
import QRReader from './components/qr/QRReader'; // Importar el nuevo componente
import DownloadAppModal from "./components/plantillas/DownloadAppModal"; 
import ReporteGlobalPersonas from "./components/reportes/ReporteGlobalPersonas";
import ListaRecepciones from "./components/qr/ListaRecepciones";
import ReiniciarRecepcion from "./components/qr/ReiniciarRecepcion";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <PaginaError />,
    id: 'root',
    loader: tokenLoader,
    children: [
      { path: '/', element: <PaginaInicio /> },
      {
        element: <PaginaPublico />,
        children: [
          { path: "/fixtures", element: <AllFixtures /> },
          { path: "/noticias", element: <Noticias /> },
          { path: "/buscador-deportista", element: <BuscadorDocente /> },
          { path: "/medallero-general", element: <MedalleroGeneral /> },
          { path: "/medalleros", element: <Medalleros /> },
          { path: "/disciplinas", element: <PublicDisciplinas /> },
          { path: "/disciplina/:disId", element: <VerPublicDisciplina /> },
          { path: "/disciplina/:disId/subdisciplina/:subdisId", element: <VerPublicSubdisciplina /> },
          { path: "/descargar-aplicacion", element: <DownloadAppModal /> },
        ]
      },
      { path: "/paises", element: <PaisList />, loader: checkAdminSistemaLoader },
      { path: "/ciudades", element: <CiudadList />, loader: checkAdminSistemaLoader },
      { path: "/roles", element: <RolList />, loader: checkAdminSistemaLoader },
      { path: "/procesos", element: <ProcesoList />, loader: checkAdminSistemaLoader },
      { path: "/usuarios", element: <UsuarioList />, loader: checkAdminSistemaLoader },
      { path: "/perfil-usuario", element: <PerfilUsuario />, loader: checkAuthLoader },
      { path: "/universidades/:titulo/:funcionalidad", element: <UniversidadList />, loader: checkAdminEventoLoader },
      { path: "/universidades/subdisciplinas", element: <InscripcionUniversidad />, loader: checkAdminEventoLoader },
      { path: "/disciplinas-admin", element: <DisciplinaList />, loader: checkAdminEventoLoader },
      { path: "/desarrollo-subdisciplinas/:tipo", element: <SubdisciplinasDesarrollo />, loader: checkAdminDisciplinaLoader },
      { path: "/incripcion-disciplina", element: <SubdisciplinasPreparacion />, loader: checkEncaUniversidadLoader },
      { path: "/disciplina-admin/:disId", element: <Disciplina />, loader: checkAuthLoader },
      { path: "/disciplina-admin/:disId/subdisciplina-admin/:subdisId", element: <VerSubdisciplina />, loader: checkAuthLoader },
      { path: "/fixture/:subdisId", element: <ListFixtures />, loader: checkAuthLoader },
      { path: "/noticias-admin", element: <ListNoticias />, loader: checkAdminDisciplinaLoader },
      { path: "/medalleros-admin", element: <ListMedallero />, loader: checkAdminDisciplinaLoader },
      { path: "/predios", element: <PrediosList />, loader: checkAdminEventoLoader },
      { path: "/docentes/:titulo/:funcionalidad", element: <DocenteList />, loader: checkEncaUniversidadLoader },
      { path: "/personal-extra", element: <CuerpoTecnicoList />, loader: checkEncaUniversidadLoader },
      { path: "/preinscripcion-subdisciplinas/:funcionalidad", element: <SubdisciplinasPreparacion />, loader: checkEncaUniversidadLoader },
      { path: "/perfil-personal-extra/:ci", element: <PerfilTecnicoQR /> },
      { path: "/perfil-docente/:ci", element: <PerfilDocenteQR /> },
      { path: "/perfil-invitado/:ci", element: <PerfilInvitado /> },
      { path: "/perfil-comite/:ci", element: <PerfilInvitado /> },
      { path: "/subdisciplinas", element: <SubdisciplinaList />, loader: checkAdminEventoLoader },
      { path: "/evento-datos-generales", element: <EventoIndex />, loader: checkAdminEventoLoader },
      { path: "/backup", element: <BackupDatabase />, loader: checkAdminEventoLoader }, 
      { path: "/impresiones/:universidadId", element: <ListDocente2 />, loader: checkAdminEventoLoader },
      { path: "/reporte-docente", element: <ReporteDocente />, loader: checkAdminEventoLoader }, 
      { path: "/reporte-disciplina", element: <ReporteDisciplinas />, loader: checkEncaUniversidadLoader },
      { path: "/reporte-asistentes", element: <ReporteGlobalPersonas />, loader: checkAdminEventoLoader },
      { path: "/desaprobacion", element: <DesaprobarDocente />, loader: checkAdminEventoLoader },
      { path: "/personas-especiales", element: <ListInvitados />, loader: checkAdminEventoLoader },
      { path: "/lector-qr", element: <QRReader /> }, // Nueva ruta para el lector de QR
      { path: "/lista-asistentes-verificados", element: <ListaRecepciones /> }, // Nueva ruta para el lector de QR
      { path: "/control-asistencia", element: <ReiniciarRecepcion />, loader: checkAdminEventoLoader },

    ]
  },
  {
    path: '/auth',
    element: <PaginaAutenticacion />,
    children: [
      { index: '/', element: <LoginForm /> },
      { path: '/auth/registro', element: <RegisterForm /> }
    ]
  }
]);

export default router;
