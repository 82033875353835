import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import placeholder from '../../assets/docentes/docente_default.png';
import axios from 'axios';
import moment from 'moment';
import { calculateAge, categoryMap, getBackgroundColor, getPersonaFullName, getSubdisciplinaFullName, getUploadUrl } from '../../util/valueCalculator';
import QRCode from 'qrcode.react';
import MensajeCentral from '../mensajes/MensajeCentral';
import { useSelector } from 'react-redux';
import { sortSubdisciplinas } from '../../util/functions';

const API_URL_BASE = process.env.REACT_APP_API_URL; 

const PerfilTecnicoQR = ({currentTecnico=null}) => {
    const { ci } = useParams();
    const [tecnico, setTecnico] = useState(null);
    const [subdisciplinas, setSubdisciplinas] = useState([]);
    const [fotoPreview, setFotoPreview] = useState(placeholder);
    const [error, setError] = useState(null)
    const eventoData = useSelector(state => state.evento);

    useEffect(() => {
       
        if (ci) {
            axios.get(`${API_URL_BASE}/public-tecnico/${ci}`)
                .then(response => {
                    const data = response.data;
                    const {subdisciplinasTecnico, ...tecData} = data.tecnico
                    setTecnico(tecData);
                    setSubdisciplinas(subdisciplinasTecnico);

                    if (tecData.foto1) {
                       setFotoPreview(getUploadUrl(tecData.foto1));
                    }
                    setError(null)
                })
                .catch(error => {
                    if(error && error.response && error.response.data)
                    {setError("Técnico no encontrado")}
                    else{setError("Error, intente de nuevo más tarde...")}
                })
        }else if (currentTecnico) {
              const { subdisciplinasTecnico, ...tecnico } = currentTecnico;
              setTecnico(tecnico);
              setSubdisciplinas(subdisciplinasTecnico)
              
              if (tecnico.foto1) {
                setFotoPreview(getUploadUrl(tecnico.foto1));
             }
          }
    }, [ci,currentTecnico]);
    
    const desarrolloFase = ci && eventoData.estado === 2 && tecnico && tecnico.estado2===0
    if(error || desarrolloFase){
        return <MensajeCentral>{error}</MensajeCentral>;
    }
    if (!tecnico) {
        return <MensajeCentral>Esperando datos del técnico...</MensajeCentral>;
    }

    const edad = calculateAge(tecnico.fecnac, new Date()); // Ajustar la fecha de referencia según sea necesario
    const backgroundColor = "#461876";
    const subdisSorted = subdisciplinas.sort((a, b) => sortSubdisciplinas(a, b, categoryMap));
    const subdisciplinasInscritas = Array.from(
        new Set(
            subdisSorted.map(subdisciplina => `${subdisciplina.nombre}-${subdisciplina.genero}`)
        )
      ).map(uniqueSub => {
        const [nombre, genero] = uniqueSub.split('-');
        return getSubdisciplinaFullName({ nombre, genero });
      });

    const columnaCentradaClasses = "mb-3 d-flex justify-content-center align-items-start"
   const tipoPersonal =tecnico.tipo2 === "tecnico" ? "Cuerpo técnico" : (tecnico.tipo2 === "personal"?"Personal de apoyo":"")
    return (
        <Card border="0" style={{ color:"white",backgroundColor, margin: ci? "20px": "0px"}}>
            <Card.Body>
                <Row>
                    <Col xs={12} lg={4} className={columnaCentradaClasses}>
                        <Card.Img
                            variant="top"
                            src={fotoPreview}
                            style={{ width: "300px", height: "300px", objectFit: "cover", margin: '0 auto' }}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <Card.Text>
                            <strong>{tipoPersonal.toUpperCase()}</strong>
                            <br/>
                            <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}> {getPersonaFullName(tecnico).toUpperCase()} <br/></span>
                            </Card.Text>
                            <Card.Text>
                             {tecnico.relato2}
                            </Card.Text>
                            <Card.Text>C.I.:<strong>{tecnico.ci}</strong> <br/>
                            Tipo:{" "}
                            <strong>{tipoPersonal.toUpperCase()}</strong><br/>
                           Universidad: <strong>{tecnico.Universidad.sigla} </strong><br/>
                            <strong>Estado:</strong>{" "}
                            {tecnico.estado2 === 1 ? (
                                <span className="badge text-bg-success badge-shadow">ACTIVO</span>
                         
                            ):(
                                <span className="badge text-bg-secondary badge-shadow">INACTIVO</span>
                            )}
                        </Card.Text>
                        <br/>
                        {!ci && tecnico?.estado2 === 1 &&   
                            <QRCode
                                value={`${process.env.REACT_APP_OFFICIAL_DOMAIN}/perfil-personal-extra/${tecnico.ci}`}
                                size={150}  // Aumenta el tamaño del QR
                                level="H"
                                includeMargin={true}
                            />
                            }
                      
                    </Col>
                    <Col xs={12} lg={4}>
                      
                            <strong>SUBDISCIPLINAS:</strong>
                            <br/>
                            {subdisciplinasInscritas.length > 0 ? (
                                <ul>
                                {subdisciplinasInscritas.map((nombre, index) => (
                                    <li key={index}>{nombre}</li>
                                ))}
                                </ul>
                            ) : (
                                "No forma parte del cuerpo técnico de ninguna subdisciplina."
                            )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default PerfilTecnicoQR;
