const initialState = {
    procesos: [],
    proceso: null,
  };
  
  const procesoReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_PROCESOS':
        return { ...state, procesos: action.payload };
      case 'GET_PROCESO_BY_ID':
        return { ...state, proceso: action.payload };
      case 'CREATE_PROCESO':
        return { ...state, procesos: [...state.procesos, action.payload] };
      case 'UPDATE_PROCESO':
        return {
          ...state,
          procesos: state.procesos.map(p =>
            p.id === action.payload.id ? action.payload : p
          ),
        };
      case 'DELETE_PROCESO':
        return {
          ...state,
          procesos: state.procesos.filter(p => p.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default procesoReducer;
