// src/actions/ciudadActions.js

import { toast } from 'react-toastify';
import { getCiudades, getCiudadById, createCiudad, updateCiudad, deleteCiudad } from '../services/ciudadService';

export const fetchCiudades = () => async dispatch => {
  try {
    const data = await getCiudades();
    return data;
  } catch (error) {
    toast.error(`Error al cargar la lista de ciudades`);
  }
};

export const fetchCiudadById = (id) => async dispatch => {
  try {
    const data = await getCiudadById(id);
    return data;
  } catch (error) {
    toast.error(`Error al cargar la ciudad seleccionada`);
  }
};

export const addCiudad = (ciudad) => async dispatch => {
  try {
    await createCiudad(ciudad);
    toast.success(`Ciudad ${ciudad.nombre} creada exitosamente`);
  } catch (error) {
    toast.error(`Error al crear la nueva ciudad`);
  }
};

export const modifyCiudad = (id, ciudad) => async dispatch => {
  try {
    await updateCiudad(id, ciudad);
    toast.success(`Ciudad ${ciudad.nombre} editada exitosamente`);
  } catch (error) {
    toast.error(`Error al editar la ciudad seleccionada`);
  }
};

export const removeCiudad = (id) => async dispatch => {
  try {
    const data = await deleteCiudad(id);
    toast.success(`Ciudad eliminada exitosamente`);
  } catch (error) {
    toast.error(`Error al eliminar la ciudad seleccionada`);
  }
};
