import "../styles/Inicio.css";
import portada from "../assets/portadacortada.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchEvento } from "../actions/eventoAction";
import AllNoticias from "../components/noticia/AllNoticias"
import { Outlet } from "react-router-dom";
const PaginaPublico = ({children = null}) => {
  const dispatch = useDispatch();
  const evento = useSelector(state => state.evento)

  return (
    <>
       <div className="general-publico-page all-fixtures-page" style={{ backgroundImage: `url(${portada})`}}>
      <div className="container-large contenedor-publico mt-4 mb-4">
        
         <Outlet />
        </div>
      </div>
      
    </>
  );
};
export default PaginaPublico;
