import { getRoles, getRolById, createRol, updateRol, deleteRol } from '../services/rolService';

export const fetchRoles = () => async dispatch => {
  try {
    const data = await getRoles();
    dispatch({ type: 'GET_ROLES', payload: data });
  } catch (error) {
    console.error('fetchRoles error:', error);
  }
};

export const fetchRolById = (id) => async dispatch => {
  try {
    const data = await getRolById(id);
    dispatch({ type: 'GET_ROL_BY_ID', payload: data });
  } catch (error) {
    console.error('fetchRolById error:', error);
  }
};

export const addRol = (rol) => async dispatch => {
  try {
    const data = await createRol(rol);
    dispatch({ type: 'CREATE_ROL', payload: data });
  } catch (error) {
    console.error('addRol error:', error);
  }
};

export const modifyRol = (id, rol) => async dispatch => {
  try {
    const data = await updateRol(id, rol);
    dispatch({ type: 'UPDATE_ROL', payload: data });
  } catch (error) {
    console.error('modifyRol error:', error);
  }
};

export const removeRol = (id) => async dispatch => {
  try {
    const data = await deleteRol(id);
    dispatch({ type: 'DELETE_ROL', payload: id });
  } catch (error) {
    console.error('removeRol error:', error);
  }
};
