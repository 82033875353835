import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Table, Modal } from 'react-bootstrap';
import { fetchMedalleroGeneral, fetchMedalleros } from '../../actions/medalleroActions';
import { getSubdisciplinaFullName, getUploadUrl } from '../../util/valueCalculator';
import CustomPrintModal from '../CustomPrintModal';
import * as XLSX from 'xlsx';
import MensajeCentral from '../mensajes/MensajeCentral';
import esperandoImg from "../../assets/esperando.png";
import "../../styles/Medalleros.css";

const MedalleroGeneral = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const medalleroGeneral = useSelector((state) => state.medallero.medalleroGeneral);
  const medalleros = useSelector((state) => state.medallero.medalleros);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedMedallero, setSelectedMedallero] = useState(null);
 
  const [showDocentes, setShowDocentes] = useState(false);

   const estadoEvento= useSelector(state => state.evento.estado)
  const rolActivo = useSelector(state => state.sesion.rolActivo);
  useEffect(() => {
    console.log("fetchMedalleroGeneral")
    dispatch(fetchMedalleroGeneral());
    dispatch(fetchMedalleros());
  }, [dispatch]);

  // Filter and sort the list
  const filteredList = [...medalleroGeneral].filter((universidad) =>
    universidad.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    universidad.sigla.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedList = [...filteredList].sort((a, b) => {
    if (a.oro !== b.oro) {
      return b.oro - a.oro;
    } else if (a.plata !== b.plata) {
      return b.plata - a.plata;
    } else {
      return b.bronce - a.bronce;
    }
  });

  // if (estadoEvento < 2) {
  //   return (
  //     <>
  //       <MensajeCentral image={<img src={esperandoImg} alt="Ya inician los juegos" width={200} height={200} />}>
  //         Esperando a que inicien los juegos...
  //       </MensajeCentral>
  //     </>
  //   );
  // }

  const handleBackToRoot = () => {
    navigate('/');
  };

  const handleShowPreview = () => {
    const content = {
      head: [['N°', 'UNIVERSIDAD', 'SIGLA', 'ORO', 'PLATA', 'BRONCE']],
      body: sortedList.map((universidad, index) => [
        index + 1,
        universidad.nombre,
        universidad.sigla,
        universidad.oro,
        universidad.plata,
        universidad.bronce
      ]),
    };
    setPrintContent(content);
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const handleExportExcel = () => {
    const data = sortedList.map((universidad, index) => ({
      Nro: index + 1,
      Universidad: universidad.nombre,
      Sigla: universidad.sigla,
      Oro: universidad.oro,
      Plata: universidad.plata,
      Bronce: universidad.bronce,
    }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Medallero General');
    XLSX.writeFile(workbook, 'medallero_general.xlsx');
  };

  const handleMedalClick = (universityId, medalType, sigla) => {
    const universityMedals = medalleros.filter(medallero =>
     { 
      return (medalType === 'oro' && medallero.OroUniversidad?.id === universityId) ||
      (medalType === 'plata' && medallero.PlataUniversidad?.id === universityId) ||
      (medalType === 'bronce' && medallero.BronceUniversidad?.id === universityId)
}    );
    setModalContent({ medals: universityMedals, medalType, sigla });
    setShowDocentes(false);
    setModalVisible(true);
  };

  const handleSubdisciplinaClick = (medallero, medalType) => {
    setSelectedMedallero(medallero);
    setShowDocentes(true);
  };

  const handleBackToSubdisciplinas = () => {
    setShowDocentes(false);
  };

  const renderModalContent = () => {
    if (!modalContent) return null;
    const { medals, medalType, sigla } = modalContent;
console.log("selectedMedallero",selectedMedallero)
    return (
      <>
        <Modal.Header closeButton onClick={() => setModalVisible(false)}>
          <Modal.Title>{`${medalType.toUpperCase()} - ${sigla}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showDocentes && selectedMedallero ? (
            <>
              <Button variant="link" onClick={handleBackToSubdisciplinas}>Volver a Subdisciplinas</Button>
              {selectedMedallero[`${medalType}Docentes`] && selectedMedallero[`${medalType}Docentes`].length > 0 ? (
                <ul>
                  {selectedMedallero[`${medalType}Docentes`].map((docente, idx) => (
                    <li key={idx} className='link-med'>{`${docente.appat} ${docente.apmat} ${docente.nombres}`}</li>
                  ))}
                </ul>
              ) : (
                
                <p>No hay docentes registrados.</p>
              )}
            </>
          ) : (
            <ul>
              {medals.map((medal, index) => {
                return(
                <li key={index}>
                 
                    {`${getSubdisciplinaFullName(medal.Subdisciplina)}`}

                </li>
              )})}
            </ul>
          )}
        </Modal.Body>
      </>
    );
  };
 
  return (
    <>
      <h1>Medallero General</h1>
      <div className="d-flex justify-content-between align-items-center mb-4 mt-3">
        <Form.Group className="d-flex align-items-center mr-3">
          <Form.Label className="mr-2">Buscar:</Form.Label>
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar por nombre"
          />
        </Form.Group>
        {rolActivo === "admin_evento" && 
        <div>
          <Button variant="dark" onClick={handleShowPreview} className="mr-2">Imprimir PDF</Button>
          <Button variant="success" onDoubleClick={handleExportExcel}>Exportar Excel</Button>
        </div>}
      </div>
      <div className="table-container1" style={{ maxHeight: '90vh', overflowY: 'scroll' }}>
        <Table striped bordered hover className="table-bordered">
          <thead className="sticky-header1">
            <tr>
              <th>N°</th>
              <th colSpan={3}>UNIVERSIDAD</th>
              <th>ORO</th>
              <th>PLATA</th>
              <th>BRONCE</th>
            </tr>
          </thead>
          <tbody>
            {sortedList.map((universidad, index) => (
              <tr key={universidad.id}>
                <td>{index + 1}</td>
                <td>
                  <img
                    className="escudo"
                    src={`${getUploadUrl(universidad.escudo)}`}
                    alt={universidad.sigla}
                  />
                </td>
                <td>{universidad.nombre}</td>
                <td>{universidad.sigla}</td>
                <td>
                  <Button variant="link" className='link-med' onClick={() => handleMedalClick(universidad.id, 'oro', universidad.sigla)}>
                    {universidad.oro}
                  </Button>
                </td>
                <td>
                  <Button variant="link" className='link-med' onClick={() => handleMedalClick(universidad.id, 'plata', universidad.sigla)}>
                    {universidad.plata}
                  </Button>
                </td>
                <td>
                  <Button variant="link" className='link-med' onClick={() => handleMedalClick(universidad.id, 'bronce', universidad.sigla)}>
                    {universidad.bronce}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1="Medallero General"
        title2="Lista de Universidades"
        content={printContent}
      />
      <Modal show={modalVisible} onHide={() => setModalVisible(false)} centered>
        {renderModalContent()}
      </Modal>
    </>
  );
};

export default MedalleroGeneral;
