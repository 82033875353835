import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { fetchDocentesByUniversidad } from '../../actions/docenteActions';
import { fetchDocentesBySubdisciplina, fetchSubdisciplinasByDocente, updateSubdisciplinaDocentes } from '../../actions/docsubActions';
import { fetchSubdisciplinasByUniversidad } from '../../actions/subdisciplinaActions';
import { calculateAge, getPersonaFullName, getPersonaFullNameAppat, getSubdisciplinaFullName } from '../../util/valueCalculator';
import MensajeCentral from '../mensajes/MensajeCentral';
import Subdisciplina from './VerSubdisciplina';
import CustomPrintModal from '../CustomPrintModal';

const InscripcionSubdisciplina = ({ subdisciplina, universidad, onRequestClose }) => {
  const dispatch = useDispatch();
  const docentes = useSelector((state) => state.docente.docentes);
  const docsubs = useSelector((state) => state.docsub.docsubsBySubdisciplina);
  const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
  const eventoData = useSelector((state) => state.evento); // Assuming you have the event data in your state
  const [selectedDocentes, setSelectedDocentes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [mensaje, setMensaje] = useState('');
  const [initialSelectedDocentes, setInitialSelectedDocentes] = useState([]);
  const [loading, setLoading] = useState(true);
  const estadoEvento= eventoData.estado
  const [modalSubdis, setModalSubdis] =useState(false)
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [sort, setSort] = useState({ by: "appat", order: "asc" });
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await dispatch(fetchDocentesByUniversidad(universidad.id));
      await dispatch(fetchDocentesBySubdisciplina(subdisciplina.id));
      await dispatch(fetchSubdisciplinasByUniversidad(universidad.id));
      setLoading(false);
    };
    loadData();
  }, [dispatch, universidad.id, subdisciplina.id]);

  useEffect(() => {
    if (!loading && docsubs.length > 0) {
      const inscritos = docsubs
        .filter(docsub => docsub.idsub === subdisciplina.id)
        .map(docsub => {
          const doc = docentes.find(doc => doc?.id === docsub.iddoc)
          if(doc)
          {return ({...doc, edad:calculateAge(doc.fecnac, eventoData.fechaedad).years})}
          else{return doc}
        }
        ).filter(docente => docente); // Filtrar undefined

      setSelectedDocentes(inscritos);
      setInitialSelectedDocentes(inscritos);
    }
  }, [docentes, docsubs, subdisciplina.id, loading]);
  
  const handleSort = (e) => {
    const { name, value } = e.target;
    setSort((prevSort) => ({ ...prevSort, [name]: value }));
  };
  const handleSelectDocente = (docente) => {
    if (!selectedDocentes.includes(docente)) {
      handleCheckRequirements(docente);
    }
  };

  const handleRemoveDocente = async (docente) => {
  if(docente.estado === 3){
      setMensaje(`El docente no puede ser removido, ya está APROBADO.`);
      return;
    }

    setSelectedDocentes(selectedDocentes.filter(d => d.id !== docente.id));
  };

  const handleSave = () => {
    const initialDocentes = initialSelectedDocentes.map(doc => doc.id);
    const currentDocentes = selectedDocentes.map(doc => doc.id);

    const docentesToRemove = initialDocentes.filter(id => !currentDocentes.includes(id));
    const docentesToAdd = currentDocentes.filter(id => !initialDocentes.includes(id));

    if (docentesToRemove.length === 0 && docentesToAdd.length === 0) {
      alert('No se han realizado cambios en las inscripciones.');
      onRequestClose();
      return;
    }

    const updateData = {
      idsub: subdisciplina.id,
      docentes: currentDocentes,
      iduniv: universidad.id
    };

    dispatch(updateSubdisciplinaDocentes(subdisciplina.id, updateData)).then(() => {
      alert('Inscripción registrada correctamente.');
      onRequestClose();
    });
  };

  const handleCheckRequirements = async (docente) => {
    // Obteniendo todas las subdisciplinas en las que el docente está inscrito
    const subdisciplinasDocente = await dispatch(fetchSubdisciplinasByDocente(docente.id));
    const disciplinasInscritas = new Set(
      subdisciplinasDocente.map(sub => {
        const subdisciplinaData = subdisciplinas.find(s => s.id === sub.idsub);
        return subdisciplinaData ? subdisciplinaData.iddisc : null;
      }).filter(disc => disc !== null)
    );
    if(docente.estado === 3){
      setMensaje(`El docente no puede ser removido, ya está APROBADO.`);
      return;
    }
    
    
    // Verificar si el docente está intentando inscribirse en una nueva disciplina que excedería el máximo permitido
  
    if (disciplinasInscritas.size >= eventoData.maxsub && !disciplinasInscritas.has(subdisciplina.iddisc)) {
      setMensaje(`El docente no puede inscribirse en más de ${eventoData.maxsub} disciplinas.`);
      return;
    }

    if(subdisciplina.tipo_participacion !== 'Postas')
    {  // Filtrar subdisciplinas del docente en la misma disciplina de la subdisciplina actual, excluyendo 'Postas'
      const subdisciplinasMismaDisciplina = subdisciplinasDocente.filter(sub => {
        const subdisciplinaData = subdisciplinas.find(s => s.id === sub.idsub);
        return subdisciplinaData && subdisciplinaData.iddisc === subdisciplina.iddisc && sub.tipo_participacion !== 'Postas';
      });

      if (subdisciplinasMismaDisciplina.length >= 3) {
        setMensaje('El docente ya está inscrito en 3 subdisciplinas de esta misma disciplina (excluyendo Postas).');
        return;
      }
    }else{
    // Verificar si ya tiene una subdisciplina de tipo 'Postas' en la misma disciplina
    const tienePosta = subdisciplinasDocente.some(sub => sub.tipo_participacion === 'Postas');

    if (tienePosta) {
      setMensaje('El docente ya está inscrito en una subdisciplina de tipo POSTAS de esta misma disciplina.');
      return;
    }
   }
    // Control de edad mínima para la subdisciplina
    const edad = calculateAge(docente.fecnac, eventoData.fechaedad);
    if (edad.years < subdisciplina.edadmin) {
      setMensaje(`El docente no cumple con la edad mínima requerida para la subdisciplina (Edad mínima: ${subdisciplina.edadmin} años).`);
      return;
    }

    // Verificar la capacidad máxima de docentes en la subdisciplina para la universidad específica
    //const inscritosPorUniversidad = docsubs.filter(ins => ins.idsub === subdisciplina.id && ins.iduniv === universidad.id).length;
    if (selectedDocentes.length >= subdisciplina.maxdep) {
      setMensaje(`No hay plazas disponibles en esta subdisciplina para la universidad ${universidad.sigla}.`);
      return;
    }

    // Verificar que el docente no esté ya inscrito en la subdisciplina seleccionada
    if (selectedDocentes.some(inscribed => inscribed.id === docente.id)) {
      setMensaje('El docente ya está inscrito en esta subdisciplina.');
      return;
    }

    // Si el docente pasa todos los controles, se agrega a la lista de docentes seleccionados
    setSelectedDocentes([...selectedDocentes, docente]);
    setMensaje(`El docente ha sido inscrito correctamente.`);
  };
  if (estadoEvento < 1) {
    return (
      <MensajeCentral accionBloqueada="inscripcionDocente" accionDesbloqueadora="preinscripcionUniversidades"/>
    );
  }

  if (loading) {
    return <div>Cargando...</div>;
  }

  const handleShowSubdisciplina = () =>{
    setModalSubdis(true)
  }
  const handleCloseSubdisciplina = () =>{
    setModalSubdis(false)
  }
  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };
  
  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['N°', 'Docente', 'CI', 'Edad']],
      body: sortedListSelected.map((doc, index) => [
        index+1,
        getPersonaFullNameAppat(doc),
        doc.ci,
        doc.edad+" años"
      ])
    };
    const title1 = `LISTA DE DOCENTES ${universidad.sigla}`
    const title2 = `${getSubdisciplinaFullName(subdisciplina)}`
    return {title1,title2, content};
  };

  const filteredDocentes = docentes.filter((docente) =>{
    const edad = calculateAge(docente.fecnac, eventoData.fechaedad)
   return( docente &&
    !selectedDocentes.includes(docente) &&
     ((`${getPersonaFullName(docente)}`.toLowerCase().includes(searchTerm.toLowerCase())) ||
    docente.ci.toLowerCase().includes(searchTerm.toLowerCase())) &&
    docente.genero === subdisciplina.genero &&
    subdisciplina.edadmin <= edad.years
     // Exclude selected docentes
    )
    }
  ).map(docente =>{ 
    const edad = calculateAge(docente.fecnac, eventoData.fechaedad)
    return ({
    ...docente,
    edad:edad.years
  })});


  const sortedListAvailables =[...filteredDocentes].sort((a, b) => {
    if (sort.order === "asc") {
        return a[sort.by] > b[sort.by] ? 1 : -1;
      }
      return a[sort.by] < b[sort.by] ? 1 : -1;
    });


    const sortedListSelected =[...selectedDocentes].sort((a, b) => {
      if (sort.order === "asc") {
        return a[sort.by] > b[sort.by] ? 1 : -1;
      }
      return a[sort.by] < b[sort.by] ? 1 : -1;
      });
  
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
  
        <Form.Group className="search-field1" style={{ flex: 1}}>
        <Button variant="warning" onClick={handleShowSubdisciplina} className="mb-3">
        <i className="bi bi-eye"></i> Ver info subdisciplina</Button>
 
        <br/>
        <div className="row">
        <div className="col-10 col-lg-5 mt-2">
          <Form.Label>Buscar Docente</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar por nombre o CI"
          />
          </div>
           <div className="col-10 col-lg-4 mt-2">
              <Form.Label >Ordenar por:</Form.Label>
                <Form.Control
                    as="select"
                    size="sm"
                    name="by"
                    value={sort.by}
                    onChange={handleSort}  
                    >
              <option value="appat">Apellido paterno</option>
              <option value="edad">Edad</option>
            </Form.Control>
                <Form.Control
                  as="select"
                  size="sm"
                  name="order"
                  value={sort.order}
                  onChange={handleSort}  
                  >
            <option value="asc">Ascendente</option>
            <option value="desc">Descendente</option>
          </Form.Control>
              </div>
              </div>
        </Form.Group>
        <div className="row align-content-end mb-4" style={{fontSize:"0.9rem"}}>
        <div className="col-8 col-md-5 mt-2">
        <Button
                    variant="dark"
                    onClick={handleShowPreview}
                    disabled={selectedDocentes.length === 0}
                >
                    <i className="bi bi-printer"></i> Imprimir
                </Button>
                </div>
                <div className="col-6 col-md-3 mt-2">
            <Button variant="primary" onClick={handleSave}>Guardar</Button>
           </div>
           <div className="col-8 col-md-3 mt-2">
            <Button variant="secondary" onClick={onRequestClose} className="ml-3">Cancelar</Button>
            </div>
          </div>
      </div>
      {mensaje && <div style={{backgroundColor:"#FFFACD", padding:"10px 50px 10px 50px", margin:"10px"}}> <p style={{color:"#8A2BE2"}}>{mensaje}</p></div>}
      <div
          className="table-container1"
          style={{ maxHeight: "550px", overflowY: "scroll" }}
        >
        
      <div className="d-flex justify-content-between">
        <div style={{ flex: 1, marginRight: '10px' }}>
          <h3>Docentes Disponibles</h3>
          <div style={{ height: '700px', overflowY: 'scroll' }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>N°</th>
                  <th>Nombre Completo</th>
                  <th>CI</th>
                  <th>Edad</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {sortedListAvailables.filter(doc => doc.estado < 3 && doc.estado>0).map((docente, index) => {
                  return(
                  <tr key={docente.id}>
                    <td>{index + 1}</td>
                    <td>{`${docente.appat || ''} ${docente.apmat || ''} ${docente.nombres || ''}`}</td>
                    <td>{docente.ci}</td>
                    <td>{docente.edad}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleSelectDocente(docente)}
                      >
                        Seleccionar
                      </Button>
                    </td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        </div>
        <div style={{ flex: 1, marginLeft: '10px'}}>
         
          <div className="d-flex justify-content-between">
          <div style={{ flex: 1, marginRight: '10px' }}>
            
          <h3>Docentes Seleccionados</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>N°</th>
                <th>Nombre Completo</th>
                <th>CI</th>
                <th>Edad</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {sortedListSelected.map((docente, index) => {
                 const edad = calculateAge(docente.fecnac, eventoData.fechaedad)
                return(
                <tr key={docente.id}>
                  <td>{index + 1}</td>
                  <td>{`${docente.appat || ''} ${docente.apmat || ''} ${docente.nombres || ''}`}</td>
                  <td>{docente.ci}</td>
                  <td>{edad.years}</td>
                  <td>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleRemoveDocente(docente)}
                    >
                      Quitar
                    </Button>
                  </td>
                </tr>
              )})}
            </tbody>
          </Table>
          </div>   </div>
         
        </div>
      </div>
      </div>
      <CustomPrintModal
        show={showPreviewModal}
        handleClose={handleClosePreview}
        title1={printContent.title1}
        title2={printContent.title2}
        content={printContent.content}
        copia={false}
      />
          <Modal show={modalSubdis} onHide={handleCloseSubdisciplina} size="lg" centered>
            <Modal.Header closeButton className="custom-modal-header">
              <Modal.Title>Descripción Subdisciplina</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Subdisciplina subdisciplinaProp={subdisciplina} onClose={handleCloseSubdisciplina}/>
            </Modal.Body>
          </Modal>
    </>
  );
};

export default InscripcionSubdisciplina;
