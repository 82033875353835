import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubdisciplinas, fetchSubdisciplinasByUniversidad } from "../../actions/subdisciplinaActions";
import { Button, Form, InputGroup, ListGroup, Modal } from "react-bootstrap";
import { categoryMap, getSubdisciplinaFullName } from "../../util/valueCalculator";
import { sortSubdisciplinas } from "../../util/functions";
import { fetchDisciplinas } from "../../actions/disciplinaActions";


const SelectSubdisciplinas = ({
  tecnico,
  subdisciplinasSelected = [],
  onSaveSubdisciplinas,
}) => {
  const dispatch = useDispatch();

  const subdisciplinas = useSelector(
    (state) => state.subdisciplina.subdisciplinas
  );
  const disciplinas = useSelector((state) => state.disciplina.disciplinas);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [selectedSubdis, setSelectedSubdis] = useState([]);
  
//  console.log("Subdis selected en select",  selectedSubdis.some((sel) => sel.id === 6),selectedSubdis)

  const [isModified, setIsModified] = useState(false);

  useEffect(() => {

    dispatch(fetchSubdisciplinasByUniversidad(tecnico.iduniv));
    dispatch(fetchDisciplinas()); 
    setSearchTerm("")
  }, [dispatch, tecnico.iduniv]);
  
  useEffect(() => {
    setSelectedSubdis(subdisciplinasSelected);
  }, [subdisciplinasSelected]);

  const handleChangeSubdis = (subdis) => {
    setSelectedSubdis((prevSelected) => {
      if (prevSelected.some((sel) => sel.id === subdis.id)) {
        return prevSelected.filter((sel) => sel.id !== subdis.id);
      } else {
        return [...prevSelected, subdis]; 
      }
    });
    setIsModified(true);
  };
  
  const handleSearchChange = (e) => { 
    setSearchTerm(e.target.value);
  };

  const filteredSubdis = [...subdisciplinas].filter((subdis) =>
    (filterBy !== "" ? `${subdis.iddisc}` === filterBy : true) &&
    subdis.nombre.toLowerCase().includes(searchTerm.toLowerCase())
   ).sort((a, b) => sortSubdisciplinas(a, b, categoryMap));

  const handleSave = () => {
    setIsModified(false);
    onSaveSubdisciplinas(selectedSubdis);
  };

  const disciplineIds = new Set([...subdisciplinas].map((sub) => sub.iddisc));
  const filteredDisciplinas = disciplinas.filter((disciplina) =>
    disciplineIds.has(disciplina.id)
  );

  return (
    <div>
      <div className="container">
        <div className="w-100">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Buscar por nombre"
              aria-label="Buscar por nombre"
              aria-describedby="basic-addon2"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>
          <Form.Group className="d-flex align-items-center mb-3">
            <Form.Label className="mr-2">Disciplina:</Form.Label>
            <Form.Control
              as="select"
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value)}
              className="mr-2"
            >
              <option value="">Todas</option>
              {filteredDisciplinas.map((dis) => (
                <option key={dis.id} value={dis.id}>
                  {dis.nombre}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
            {filteredSubdis.map((subdis) => (
              <ListGroup.Item key={subdis.id}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={selectedSubdis.some((sel) => sel.id === subdis.id)}
                    onChange={() => handleChangeSubdis(subdis)}
                    id={`subdis-${subdis.id}`}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`subdis-${subdis.id}`}
                  >
                    {getSubdisciplinaFullName(subdis)}
                  </label>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button
            disabled={!isModified}
            variant="success"
            className="mt-4"
            onClick={handleSave}
          >
            <i className="bi bi-check-circle"></i> Confirmar subdisciplinas seleccionadas
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectSubdisciplinas;