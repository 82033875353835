import React from 'react';
import { Button } from "react-bootstrap";
import { estadoTecnico, tipo2Persona } from '../../util/valueCalculator';

const ItemInvitado = ({ invitado, indice, showModal }) => {
    const tipoPersona =tipo2Persona(invitado.tipo2)
    return (
        <tr>
            <td>{indice}</td>
            <td>{invitado.ci}</td>
            <td>{invitado.appat}</td>
            <td>{invitado.apmat}</td>
            <td>{invitado.nombres}</td>
            <td>{invitado.relato2}</td>
            <td>{tipoPersona}</td>
            <td>{invitado.Universidad.sigla}</td>    
            <td>{estadoTecnico(invitado.estado2)}</td>        
            <td>
                <Button
                    variant="outline-dark"
                    size="sm"
                     onClick={() => showModal(4, invitado)}
                    className="action-button"
                >
                    Perfil
                </Button>
                <Button
                    variant="outline-warning"
                    size="sm"
                    onClick={() => showModal(2, invitado)}
                    className="action-button"
                >
                    <i className="bi bi-pencil"></i>
                </Button>
                <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => showModal(3, invitado)}
                >
                    <i className="bi bi-trash"></i>
                </Button>    
            </td>
        </tr>
    );
}

export default ItemInvitado;