import React, { useEffect, useState } from 'react';
import { Table, Button, Alert, Modal, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubdisciplinas, updateSubdisciplinaStatus, deletePreinscripciones } from '../../actions/subdisciplinaActions';
import { fetchUniversidades, getSubdisciplinasByUniversidad } from '../../actions/universidadAction';
import { cambiarFaseEvento, fetchEvento } from '../../actions/eventoAction';
import CustomPrintModalH from '../CustomPrintModalH';
import { categoryMap, genderMap } from '../../util/valueCalculator';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import ModalCambioFase from '../evento/ModalCambioFase';
import { toast } from 'react-toastify';
const SubdisciplinaList = () => {
    const dispatch = useDispatch();
    const [subdisciplinasData, setSubdisciplinasData] = useState([]);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showGrabacionModal, setShowGrabacionModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [showAlert, setShowAlert] = useState(false);
    const [showPhaseAlert, setShowPhaseAlert] = useState(false);

    const subdisciplinas = useSelector(state => state.subdisciplina?.subdisciplinas || []);
    const universidades = useSelector(state => state.universidad?.universidades || []);
    const gestion = useSelector(state => state.evento?.gestion || '');
    const evento = useSelector(state => state.evento)
    const [searchTerm, setSearchTerm] = useState("");
    const [filters, setFilters] = useState({
      genero: "",
      categoria: "",
    });
    useEffect(() => {
        dispatch(fetchSubdisciplinas());
        dispatch(fetchUniversidades());
    }, [dispatch]);

    useEffect(() => {
        if (universidades.length > 0) {
            const fetchData = async () => {
                const data = await Promise.all(
                    universidades.map(async (uni) => {
                        const response = await dispatch(getSubdisciplinasByUniversidad(uni.id));
                        return { ...uni, subdisciplinas: response || [] };
                    })
                );
                setSubdisciplinasData(data);
            };
            fetchData();
        }
    }, [universidades, subdisciplinas, dispatch]);

    const countSubdisciplinas = () => {
        return subdisciplinas.map(sub => {
            const count = subdisciplinasData.reduce((acc, uni) => {
                const hasSub = uni.subdisciplinas?.some(uSub => uSub.idsub === sub.id);
                return acc + (hasSub ? 1 : 0);
            }, 0);
            return { ...sub, count, habilitada:count>=4?"SÍ":"NO" };
        });
    };

    const handleFilters = (filter, value) => {
        setFilters((prev) => ({ ...prev, [filter]: value }));
      };
    const handleShowPreview = () => {
        if (subdisciplinas.length === 0) {
            setShowAlert(true);
            return;
        }
        setPrintContent(getPrintContent());
        setShowPreviewModal(true);
    };

    const handleClosePreview = () => {
        setShowPreviewModal(false);
    };

    const handleShowGrabacion = () => {
        if (evento.estado === 0) {
            setShowGrabacionModal(true);
        } 
    };

    const handleCloseGrabacion = () => {
        setShowGrabacionModal(false);
    };

    const handleConfirmGrabacion = async () => {
        const countedSubdisciplinas = countSubdisciplinas();
        const subdisciplinasToDisable = countedSubdisciplinas.filter(sub => sub.count < 4).map(sub => sub.id);
        const subdisciplinasToEnable = countedSubdisciplinas.filter(sub => sub.count >= 4).map(sub => sub.id);

        try{dispatch(updateSubdisciplinaStatus(subdisciplinasToDisable, 0)).then(()=>{
            dispatch(updateSubdisciplinaStatus(subdisciplinasToEnable, 1))

            dispatch(cambiarFaseEvento(gestion, { estado: 1 })).then(()=>{
                dispatch(fetchEvento(gestion))
                setShowPhaseAlert(true);
            });
    
        }); // 0 for "Inhabilitada"
        }catch(error) {toast.error("Errores al grabar pre-inscripciones")}
       
        handleCloseGrabacion();
        dispatch(fetchSubdisciplinas()); // Refresh the list after update
    };

    const countedSubdisciplinas = countSubdisciplinas();
    const sortedSubdisciplinas = countedSubdisciplinas.sort((a, b) => {
        if (a.nombre < b.nombre) return -1;
        if (a.nombre > b.nombre) return 1;
        if (genderMap[a.genero] < genderMap[b.genero]) return -1;
        if (genderMap[a.genero] > genderMap[b.genero]) return 1;
        if (categoryMap[a.categoria].orden < categoryMap[b.categoria].orden) return -1;
        if (categoryMap[a.categoria].orden > categoryMap[b.categoria].orden) return 1;
        return 0;
    });
    const filteredList = sortedSubdisciplinas.filter(
        (subdisciplina) =>
          subdisciplina.nombre.toLowerCase().includes(searchTerm.toLowerCase()) &&
          subdisciplina.genero.includes(filters.genero) &&
          subdisciplina.categoria.includes(filters.categoria)
      );

      
    const getPrintContent = () => {
        const head = [
            ["N°","Nombre", "Categoría", "Modalidad", "Habilitada", "Cant", ...universidades.map(uni => uni.sigla)]
        ];

        const body = filteredList
            .filter(sub => sub.estado === 1 && sub.genero.includes(filters.genero))
            .map((sub, index) => {
                const row = [
                    index+1,
                    `${sub.nombre}`,
                    genderMap[sub.genero],
                    categoryMap[sub.categoria].nombre,
                    sub.habilitada,
                    sub.count
                ];

                universidades.forEach(uni => {
                    const hasSub = subdisciplinasData.find(u => u.id === uni.id)?.subdisciplinas?.some(uSub => uSub.idsub === sub.id);
                    row.push(hasSub ? '1' : '0');
                });

                return row;
            });

        return { head, body };
    };

    return (
        <div className="container" style={{ fontSize: "12px" }}>
            <h1 className='mt-4 mb-3'>PRE-INSCRIPCIÓN DE UNIVERSIDADES EN LAS SUBDISCIPLINAS</h1>
            <div className="d-flex justify-content-between mt-3">
                <Button variant="danger" onClick={handleShowGrabacion} disabled={evento.estado>0? true: false}>
                    Grabación Subdisciplinas
                </Button>
                <Button variant="dark" onClick={handleShowPreview}>
                    Vista Previa de Impresión
                </Button>
            </div>

            {showAlert && (
                <Alert variant="warning" onClose={() => setShowAlert(false)} dismissible>
                    No hay subdisciplinas disponibles para mostrar.
                </Alert>
            )}
            {showPhaseAlert && (
                <ModalCambioFase show={showPhaseAlert} onClose={() => setShowPhaseAlert(false)} fase={evento.estado}/>
            )}
             <Form.Group
        controlId="filtrosSubdisciplinas"
        className="row"
      >
        <div className="col-md-4 modal-input">
          <Form.Label>Buscar</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Categoría</Form.Label>
          <Form.Control
            as="select"
            value={filters.genero}
            onChange={(e) => handleFilters("genero", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="V">VARONES</option>
            <option value="D">DAMAS</option>
          </Form.Control>
        </div>
        <div className="col-md-4 modal-input">
          <Form.Label>Modalidad</Form.Label>
          <Form.Control
            as="select"
            value={filters.categoria}
            onChange={(e) => handleFilters("categoria", e.target.value)}
            required
          >
            <option value="">Todas</option>
            <option value="L">LIBRE</option>
            <option value="S">SENIOR</option>
            <option value="M">MASTER</option>
          </Form.Control>
        </div>
      </Form.Group>
            <Table bordered hover id="subdisciplinaTable">
                <thead>
                    <tr>
                        <th className='col-cod'>N°</th>
                        <th>Nombre</th>
                        <th>Categoría</th>
                        <th>Modalidad</th>
                        <th>Habilitada</th>
                        <th>Cant</th>
                        {universidades.map(uni => <th key={uni.sigla}>{uni.sigla}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {filteredList.map((sub, index) => {
                        const backColor=sub.count>=4? "PaleGreen":"LightCoral"
                        return(
                        <tr key={sub.id}>
                             <td style={{backgroundColor:backColor}}>{index+1}</td>
                            <td style={{backgroundColor:backColor}}>{`${sub.nombre}`}</td>
                            <td style={{backgroundColor:backColor}}>{genderMap[sub.genero]}</td>
                            <td style={{backgroundColor:backColor}}>{categoryMap[sub.categoria].nombre}</td>
                            <td style={{backgroundColor:backColor}}>{sub.habilitada}</td>
                            <td style={{backgroundColor:backColor}}>{sub.count}</td>
                            {universidades.map(uni => (
                                <td key={uni.sigla}>
                                    {subdisciplinasData.find(u => u.id === uni.id)?.subdisciplinas?.some(uSub => uSub.idsub === sub.id) ? '1' : '0'}
                                </td>
                            ))}
                        </tr>
                    )})}
                </tbody>
            </Table>
            <CustomPrintModalH
                show={showPreviewModal}
                handleClose={handleClosePreview}
                title1={`LISTA DE SUBDISCIPLINAS PREINSCRITAS ${filters.genero===""? "": (filters.genero === "D"?"CATEGORÍA DAMAS": "CATEGORÍA VARONES")}`}
                title2={evento.estado === 0 ? "B O R R A D O R" : "A P R O B A D A"}
                content={printContent}
            />

            <Modal show={showGrabacionModal} onHide={handleCloseGrabacion}>
                <Modal.Header closeButton>
                    <Modal.Title>Advertencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <ExclamationTriangleFill color="red" size={48} />
                        </Col>
                        <Col>
                            <p>
                                La grabación de subdisciplinas es un proceso importante. Se inhabilitarán las subdisciplinas que tengan menos de 4 universidades preinscritas. Cada universidad solo podrá inscribir en las subdisciplinas que se validan a partir de la grabación.
                            </p>
                            <p>
                                ¿Está seguro de continuar?
                            </p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseGrabacion}>No</Button>
                    <Button variant="primary" onClick={handleConfirmGrabacion}>Sí</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SubdisciplinaList;
