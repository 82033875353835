import apiService from '../services/apiService';
import { toast } from 'react-toastify';

const personasTipoService = (tipo) => apiService(`/personas/${tipo}`);

export const fetchPersonasTipo = (tipo) => async dispatch => {
    try {
      const data = await personasTipoService(tipo).getAll()
      return data
    } catch (error) {
      toast.error(`Error al cargar la lista de personasTipo`);
    }
  };
  export const fetchPersonasTipoByUniversidad = (iduniv, tipo) => async dispatch => {
    try {
      const data = await personasTipoService(tipo).getAllBySomeId('universidad', iduniv)
      return data;
    } catch (error) {
      toast.error(`Error al cargar la lista de personasTipo de la universidad`);
    }
  };
   
export const addPersonaTipo = (tipo, personaTipo) => async dispatch => {
  try {
    const data = await personasTipoService(tipo).create(personaTipo);
    return data
  } catch (error) {
    throw error.response.data.errors;
   }
};

export const modifyPersonaTipo = (tipo, id, personaTipo) => async dispatch => {
  try {
    const data = await personasTipoService(tipo).update(id, personaTipo);
    return data
  } catch (error) {
   //console.log("Error", error)
    throw error.response.data.errors;
  }
};
export const removePersonaTipo = (tipo, id) => async dispatch => {
  try {
    const data = await personasTipoService(tipo).remove(id);
    return data
  } catch (error) {
    console.error('removePersonaTipo error:', error);
  }
};