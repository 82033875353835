// reducers/docsubReducer.js
import {
  INSCRIBIR_DOCENTE_REQUEST,
  INSCRIBIR_DOCENTE_SUCCESS,
  INSCRIBIR_DOCENTE_FAILURE,
  FETCH_DOCSUB_BY_UNIVERSIDAD_REQUEST,
  FETCH_DOCSUB_BY_UNIVERSIDAD_SUCCESS,
  FETCH_DOCSUB_BY_DOCENTE_REQUEST,
  FETCH_DOCSUB_BY_DOCENTE_SUCCESS,
  FETCH_DOCSUB_BY_UNIVERSIDAD_FAILURE,
  FETCH_DOCSUB_BY_SUBDISCIPLINA_REQUEST,
  FETCH_DOCSUB_BY_SUBDISCIPLINA_SUCCESS,
  FETCH_DOCSUB_BY_SUBDISCIPLINA_FAILURE,
  FETCH_DOCSUB_BY_DOCENTE_FAILURE,
  UPDATE_SUBDISCIPLINA_DOCENTES_REQUEST,
  UPDATE_SUBDISCIPLINA_DOCENTES_SUCCESS,
  UPDATE_SUBDISCIPLINA_DOCENTES_FAILURE,
  FETCH_DOCSUB_REQUEST,
  FETCH_DOCSUB_SUCCESS,
  FETCH_DOCSUB_FAILURE
} from '../actions/docsubActions';

const initialState = {
  loading: false,
  success: false,
  error: null,
  docsubs:null,
  data: null,
  docsubsByUniv: [],
  docsubsByDocente: [],
  docsubsBySubdisciplina: [],

};

export const docsubReducer = (state = initialState, action) => {
  switch (action.type) {
      case INSCRIBIR_DOCENTE_REQUEST:
      case FETCH_DOCSUB_REQUEST:
      case FETCH_DOCSUB_BY_UNIVERSIDAD_REQUEST:
      case FETCH_DOCSUB_BY_DOCENTE_REQUEST:  
      case FETCH_DOCSUB_BY_SUBDISCIPLINA_REQUEST:  
      case UPDATE_SUBDISCIPLINA_DOCENTES_REQUEST:
          return { ...state, loading: true, error: null };
      case INSCRIBIR_DOCENTE_SUCCESS:
      case UPDATE_SUBDISCIPLINA_DOCENTES_SUCCESS:
          return { ...state, loading: false, success: true, data: action.payload };
      case FETCH_DOCSUB_SUCCESS:
       // console.log("FETCH_DOCSUB_SUCCESS action dispatched with payload:", action.payload);
          return { ...state, loading: false, docsubs: action.payload };
      case FETCH_DOCSUB_BY_DOCENTE_SUCCESS:
        return { ...state, loading: false, docsubsByDocente: action.payload };
      case FETCH_DOCSUB_BY_SUBDISCIPLINA_SUCCESS:
          return { ...state, loading: false, docsubsBySubdisciplina: action.payload };   
      case FETCH_DOCSUB_BY_UNIVERSIDAD_SUCCESS:
        return { ...state, loading: false, docsubsByUniv: action.payload };
      case INSCRIBIR_DOCENTE_FAILURE:
      case FETCH_DOCSUB_BY_UNIVERSIDAD_FAILURE:
      case FETCH_DOCSUB_BY_SUBDISCIPLINA_FAILURE:     
      case FETCH_DOCSUB_BY_DOCENTE_FAILURE:   
      case FETCH_DOCSUB_FAILURE:
      case UPDATE_SUBDISCIPLINA_DOCENTES_FAILURE:
          return { ...state, loading: false, error: action.payload };
      default:
          return state;
  }
};






export default docsubReducer;
