import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addProceso, modifyProceso, fetchProcesos } from '../../actions/procesoActions';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

//COMPONENTE 
//FORMULARIO DE PROCESO
const ProcesoForm = ({ currentProceso, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [estado, setEstado] = useState('1'); // Por defecto, activo

  useEffect(() => {
    if (currentProceso) {
      setNombre(currentProceso.nombre);
      setDescripcion(currentProceso.descripcion);
      setEstado(currentProceso.estado);
    } else {
      setNombre('');
      setDescripcion('');
      setEstado('1'); // Resetear a activo
    }
  }, [currentProceso]);

  const dispatch = useDispatch();

  const handleSubmit = e => {
    e.preventDefault();
    const procesoData = { nombre, descripcion, estado };
    if (currentProceso) {
      dispatch(modifyProceso(currentProceso.id, procesoData)).then(() => {
        handleClose();
        dispatch(fetchProcesos());  // Actualiza la lista de procesos
      });
    } else {
      dispatch(addProceso(procesoData)).then(() => {
        handleClose();
        dispatch(fetchProcesos());  // Actualiza la lista de procesos
      });
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{currentProceso ? 'Editar Proceso' : 'Nuevo Proceso'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-small">
          <Form onSubmit={handleSubmit} className="modal-oval">
            <Form.Group controlId="formNombreProceso" className='modal-input'>
              <Form.Label>Nombre del Proceso</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre del Proceso"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formDescripcionProceso" className='modal-input'>
              <Form.Label>Descripción del Proceso</Form.Label>
              <Form.Control
                type="text"
                placeholder="Descripción del Proceso"
                value={descripcion}
                onChange={e => setDescripcion(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formEstadoProceso" className='modal-input'>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                value={estado}
                onChange={e => setEstado(e.target.value)}
              >
                <option value="1">Activo</option>
                <option value="2">Inactivo</option>
              </Form.Control>
            </Form.Group>
            <Button variant={currentProceso ? 'warning' : 'info'} type="submit"  className="btn-modal">
              {currentProceso ? 'Actualizar' : 'Crear'}
            </Button>
            <Button variant="secondary" onClick={handleClose} className="btn-modal">
              Cancelar
            </Button>   
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProcesoForm;

