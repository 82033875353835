import { Button, Col, Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { calculateAge,  categoriaPorGenero,  categoryMap,  estadoDocente, formatearFecha, formatearFechaToExport, genderMap, getPersonaFullName, getPersonaFullNameAppat, getSubdisciplinaFullName, getSubdisciplinaShortName } from "../../util/valueCalculator";
import { useState } from "react";
import { fetchDocsubByUniversidad } from "../../actions/docsubActions";
import CustomPrintModal from "../CustomPrintModal";
import excelIcono from '../../assets/excel-icono.png'
import ModalPrintCredencial from "../credenciales/ModalPrintCredencial";

const PanelOpciones = ({show, funcionalidadNum=0, universidad=null, imprimir=false, exportar=false,filteredList,handleClose, filters={}}) => {
    const dispatch = useDispatch();

    const docsubs = useSelector((state) => state.docsub.docsubsByUniv);
    const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
    const docentes = useSelector((state) => state.docente.docentes);
    const eventoData = useSelector(state => state.evento);  // Añadir esto si no está ya
    const [loading, setLoading] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const rolActivo = useSelector(state => state.sesion.rolActivo);
    const [credentialPreview, setCredentialPreview] = useState({
      show:false,
      fondo:true,
      deportistas:null
  })
    
    //DEFINICION DE LOS TITULOS
    const getTitulos = (tipo) => { 
      let titulo2 =""
      let titulo1= "LISTA";

      titulo1 += filters.searchTerm !== ""? " PARCIAL DE DOCENTES": " DE DOCENTES"
        
      titulo1 += tipo === "pdf"? ` DE LA ${universidad.sigla}` : ` ${universidad.sigla}`
      titulo1 += filters.estadoDocente !== ""? ` ${estadoDocente(filters.estadoDocente).estadoName}` : ""
      
      return {titulo1, titulo2}
    }
    
    const handleExportToExcel = () => {
      
        const data = filteredList.map((docente, index) => {
          const subdisciplinasList = docsubs
            .filter((docsub) => docsub.iddoc === docente.id)
            .map((docsub) => {
              const subdisciplina = subdisciplinas.find(
                (sub) => sub.id === docsub.idsub
              );
              return subdisciplina ? getSubdisciplinaFullName(subdisciplina) : "Desconocido";
            });
            const edad = calculateAge(docente.fecnac, eventoData.fechaedad).years;
          
            return {
            "N°": index+1,
             "CI": docente.ci,
            'PATERNO': docente.appat,
            'MATERNO': docente.apmat,
            'NOMBRE': docente.nombres,
            'GENERO': categoriaPorGenero(docente.genero),
            'FECHA DE NACIMIENTO':formatearFechaToExport(docente.fecnac),
            'EDAD': `${edad} años`,
            "SUBDISCIPLINAS": subdisciplinasList.join(";  "),
            'ESTADO': estadoDocente(docente.estado).estadoName,
            'CONSIDERACIONES': docente.relato,
          };
        });
    
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Shee1");
        const {titulo1} = getTitulos("excel")
        XLSX.writeFile(workbook, `${titulo1}.xlsx`);
      };

      const handleExportPorDisciplina = () => {
        const workbook = XLSX.utils.book_new();
        let hasSheets = false;
      
        // Iterar sobre cada subdisciplina
        subdisciplinas.forEach((subdisciplina) => {
          // Filtrar los docentes que pertenecen a la subdisciplina actual
          const docentesPorSubdisciplina = filteredList.filter((docente) =>
            docsubs.some((docsub) => docsub.iddoc === docente.id && docsub.idsub === subdisciplina.id)
          );
      
          // Verificar si hay docentes inscritos en la subdisciplina
          if (docentesPorSubdisciplina.length > 0) {
            // Mapear los datos de los docentes
            const data = docentesPorSubdisciplina.map((docente, index) => {
              const edad = calculateAge(docente.fecnac, eventoData.fechaedad).years;
              return {
                'N°': index + 1,
                'DOCENTE': getPersonaFullNameAppat(docente),
                'CI': docente.ci,
                'EDAD': `${edad} años`,
                'CONSIDERACIONES': docente.relato,
                'ESTADO': estadoDocente(docente.estado).estadoName,
                'DISCIPLINA':  subdisciplina.nombre,
                'CATEGORIA':  genderMap[subdisciplina.genero],
                'MODALIDAD':  categoryMap[subdisciplina.categoria].nombre,
              };
            });
      
            // Crear una hoja para la subdisciplina actual
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, getSubdisciplinaShortName(subdisciplina));
            hasSheets = true;
          }
        });
      
        // Añadir una hoja vacía temporal si no se han añadido otras hojas
        if (!hasSheets) {
          const worksheet = XLSX.utils.aoa_to_sheet([['No hay datos disponibles']]);
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sin datos');
        }
      
        // Guardar el archivo Excel
        XLSX.writeFile(workbook, `INSCRITOS-POR-SUBDISCIPLINA-${universidad.sigla}.xlsx`);
      };
      const getDocentesWithSubdisciplinas =(docentesFiltrados)=>{
        const docentesWithSubdisciplinas = docentesFiltrados.map((docente, index) => {
          const subdisciplinasList = docsubs
            .filter((docsub) => docsub.iddoc === docente.id && docente.estado > 1)
            .map((docsub) => {
              const subdisciplina = subdisciplinas.find(
                (sub) => sub.id === docsub.idsub
              );
              
              return subdisciplina ? getSubdisciplinaFullName(subdisciplina) : "Desconocido";
            });
          
          return [
            index+1,
            docente.ci,
            `${docente.appat} ${docente.apmat} ${docente.nombres}`,
            subdisciplinasList.join(";  "),
            estadoDocente(docente.estado).estadoName,
          ];
        });
        return docentesWithSubdisciplinas
      }
      const getPreviewContent = (docentesFiltrados) => {
        if (!Array.isArray(docsubs) || !Array.isArray(docentes)) {
          return null;
        }
        const docentesWithSubdisciplinas = getDocentesWithSubdisciplinas(docentesFiltrados)
    
        const tableContent = {
          head: [["#", "C.I.", "Nombre", "Subdisciplinas", "Estado"]],
          body: docentesWithSubdisciplinas,
        };
    
        return tableContent;
      };

      const handleShowPreview = async () => {
        setLoading(true);
        setPrintContent(getPreviewContent(filteredList));
        setLoading(false);
        setShowPreviewModal(true);
      };

      const handlePrintAprobados = async () => {
        setLoading(true);
    
        await dispatch(fetchDocsubByUniversidad(universidad.id));
        const aprobados = filteredList.filter((docente) =>
          docsubs.some((docsub) => docsub.iddoc === docente.id && docsub.estado === 2)
        );
        setPrintContent(getPreviewContent(aprobados));
        setLoading(false);
        setShowPreviewModal(true);
      };

      const handleClosePreview = () => {
        setShowPreviewModal(false);
      };
    
      const getPorDisciplinaContent = (docentes, docsubs, subdisciplinas) => {
        const content = {
          head: [["Subdisciplina", "Docente", "C.I.", "Edad","Estado"]],
          body: [],
        };
        const data = docentes.map((docente) => {
          const docenteSubdisciplinas = docsubs.filter((docsub) => docsub.iddoc === docente.id);
         
          docenteSubdisciplinas.forEach((docsub) => {
            const subdisciplina = subdisciplinas.find((sub) => sub.id === docsub.idsub);
            const edad = calculateAge(docente.fecnac, eventoData.fechaedad).years;
            const row = [
              subdisciplina ? getSubdisciplinaFullName(subdisciplina): "Desconocido",
              getPersonaFullNameAppat(docente),
              docente.ci,
              `${edad} años`,
              estadoDocente(docente.estado).estadoName,
            ];
            content.body.push(row);
          });
        });
    
        content.body.sort((a, b) => {
          if (a[0] < b[0]) return -1;
          if (a[0] > b[0]) return 1;
          if (a[1] < b[1]) return -1;
          if (a[1] > b[1]) return 1;
          if (a[2] < b[2]) return -1;
          if (a[2] > b[2]) return 1;
          return 0;
        });
    
        return content;
      };
            
    const handleShowPreviewCredential = (fondo) =>{
    
          setCredentialPreview({show:true, fondo:fondo,deportistas:filteredList})
      }

      const handleClosePreviewCredential = () =>{
        setCredentialPreview({show:false, fondo:true, deportista:null})
      }
        const handlePrintPorDisciplina = () => {
            const content = getPorDisciplinaContent(filteredList, docsubs, subdisciplinas);
            setPrintContent(content);
            setShowPreviewModal(true);
        };

    let title = "Panel de opciones"
    let backColor = "#083267"
    let body = <></>
    if(imprimir){ title += " IMPRESIÓN";
    }
    else if(exportar) title += " EXPORTAR A EXCEL "

    let title1, title2;
    if(showPreviewModal){
      const {titulo1, titulo2} = getTitulos("pdf")
      title1 = titulo1;
      title2 = titulo2
    }

    return (
        <>
        <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {exportar && !imprimir && (
                <>
                   
                    <Button
                        variant="outline-success"
                        onDoubleClick={handleExportToExcel}
                        disabled={loading}
                        className="action-button mb-2"
                    >
                       <img src={excelIcono} alt="excel" className="icon-class" /> {loading ? "Cargando..." : "Exportar lista de DOCENTES"}
                    </Button>
                    {funcionalidadNum !== 3 && (
                    <Button
                    variant="outline-success"
                    onDoubleClick={handleExportPorDisciplina}
                    disabled={loading}
                    className="action-button"
                    >
                    <img src={excelIcono} alt="excel" className="icon-class" /> {loading ? "Cargando..." : "Exportar inscritos POR SUBDISCIPLINA"}
                    </Button>
                    )}
                </>
                )}
            {imprimir && !exportar && (<>
              {rolActivo==="admin_evento" && <>
              <p>Para generar los credenciales debe estar seleccionado el filtro por estado APROBADO</p>
              <Button
                    variant="dark"
                    onClick={() => handleShowPreviewCredential(true)}
                    className="action-button mb-2"
                    disabled={Number(filters.estadoDocente) !== 3}
                ><i className="bi bi-person-vcard"></i> Generar credenciales digitales
                </Button>
                 <Button
                 variant="dark"
                 onClick={() => handleShowPreviewCredential(false)}
                 className="action-button mb-2"
                 disabled={Number(filters.estadoDocente) !== 3}
             > <i className="bi bi-printer"></i> Imprimir credenciales
             </Button></>}
             <p>Para quitar la marca de agua debe estar seleccionado el filtro por estado APROBADO</p>
                    <Button
                        variant="dark"
                        onClick={handleShowPreview}
                        disabled={loading}
                        className="action-button mb-2"
                    >
                        <i className="bi bi-printer"></i>  {loading ? "Cargando..." : "Imprimir lista de DOCENTES"}
                    </Button>
                    {/* {funcionalidadNum !== 3 && (
                        <Button
                            variant="dark"
                            onClick={handlePrintPorDisciplina}
                            disabled={loading}
                        className="action-button mb-2"
                        >
                            <i className="bi bi-printer"></i>  {loading ? "Cargando..." : "Imprimir inscripciones POR SUBDISCIPLINA"}
                        </Button>
                        )} */}
            </>)}   
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
              <CustomPrintModal
              show={showPreviewModal}
              handleClose={handleClosePreview}
              title1={title1}
              title2={title2}
              content={printContent}
              copia={false}
              filters={filters}
            />
            <ModalPrintCredencial handleClose={handleClosePreviewCredential} 
            show={credentialPreview.show} 
            fondo={credentialPreview.fondo}
            list={credentialPreview.deportistas} 
            universidad={universidad}
            title1={`Credenciales ${credentialPreview.fondo ? "Digital":"Impresion"} ${filters.modalidad ? filters.modalidad:"Todo"} ${universidad?.sigla}`}/>
        </>
    )
}

export default PanelOpciones;