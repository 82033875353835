import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import placeholder from '../../assets/docentes/docente_default.png';
import { fetchSubdisciplinasByDocente } from '../../actions/docsubActions';
import moment from 'moment';
import { estadoDocente, formatShowFecha, genderMap, getBackgroundColor, getModalidadByEdad, getSubdisciplinaFullName, getUploadUrl } from '../../util/valueCalculator';
import ModalPrintCredencial from '../credenciales/ModalPrintCredencial';

const calculateAge = (birthdate, referenceDate) => {
  const birth = new Date(birthdate);
  const reference = new Date(referenceDate);

  let years = reference.getFullYear() - birth.getFullYear();
  let months = reference.getMonth() - birth.getMonth();
  let days = reference.getDate() - birth.getDate();

  if (days < 0) {
    months -= 1;
    days += new Date(reference.getFullYear(), reference.getMonth(), 0).getDate();
  }

  if (months < 0) {
    years -= 1;
    months += 12;
  }

  return { years, months, days };
};

const formatDate = (dateString) => {
  return moment(dateString).format('YYYY-MM-DD');
};

const PerfilDocente = () => {
  const dispatch = useDispatch();
  const currentDocente = useSelector((state) => state.docente.current);
  const docsubs = useSelector(state => state.docsub.docsubsByDocente);
  const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
  const eventoData = useSelector(state => state.evento);

  const [fotoPreview, setFotoPreview] = useState(placeholder);
  const [credentialPreview, setCredentialPreview] = useState({
    show:false,
    deportista:null
})
  const docente = useMemo(() => {
    if (currentDocente) {
      const fecnac = currentDocente.fecnac
        ? currentDocente.fecnac.split("T")[0]
        : "";
      return { ...currentDocente, fecnac: fecnac};
    }
    return null;
  }, [currentDocente]);

  useEffect(() => {
    if (docente) {
      dispatch(fetchSubdisciplinasByDocente(docente.id));
    }
  }, [docente, dispatch]);

  useEffect(() => {
    if (docente && docente.foto1) {
         setFotoPreview(getUploadUrl(docente.foto1));
    }
  }, [docente]);

  const subdisciplinasInscritas = useMemo(() => {
    if (docente && docsubs && subdisciplinas) {
      const inscripciones = docsubs.filter(docsub => docsub.iddoc === docente.id);
      return inscripciones.map(inscripcion => {
        const subdisciplina = subdisciplinas.find(sub => sub.id === inscripcion.idsub);
        if (subdisciplina) {
          return getSubdisciplinaFullName(subdisciplina);
        }
        else {return "Subdisciplina no encontrada";}
      });
    }
    return [];
  }, [docente, docsubs, subdisciplinas]);

  if (!docente) {
    return (<p>Esperando datos del docente...</p>);
  }

 
  const edad = calculateAge(docente.fecnac, eventoData.fechaedad);
  const modalidadDocente = getModalidadByEdad(edad.years);
  const backgroundColor = getBackgroundColor(modalidadDocente);
  const columnaCentradaClasses = "d-flex justify-content-center  mb-3 align-items-center"
  const {color, estadoName } = estadoDocente(docente.estado)
  
  const handleShowPreviewCredential = () =>{
    const disciplinasInscritas = [...new Set(subdisciplinasInscritas.map(sub => sub.iddisc))];

    const deportista = [{...docente,edad:edad.years,
                  modalidad:modalidadDocente,
                  categoria:genderMap[docente.genero],
                  subdisciplinas: subdisciplinasInscritas,
                  disciplinas:disciplinasInscritas
                }]

    setCredentialPreview({show:true, deportista:deportista})
}
  
const handleClosePreviewCredential = () =>{
  setCredentialPreview({show:false, deportista:null})
}

  return (
    <>
    <Card border="0" style={{ color:"white",backgroundColor, width: '100%', margin: '0px' }}>
      <Card.Body>
        <Row>
          <Col xs={12} md={4} className={columnaCentradaClasses}>
            <Card.Img
              variant="top"
              src={fotoPreview}
              style={{ width: "100%", maxWidth:"300px", height: "300px", objectFit: "cover", margin: '0 auto' }}
              onError={() => setFotoPreview(placeholder)}
            />
          </Col>
          <Col xs={12} md={4}>
            <Card.Text>
            <strong>DEPORTISTA</strong>
            <br/>
              <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{`${docente.appat} ${docente.apmat} ${docente.nombres}`}</span> <br/>
              <br/>
              <strong>C.I.: </strong> {docente.ci} <br/>
              <strong>Categoría: </strong>
              {docente.genero === "D" ? "DAMAS" : "VARONES"} <br/>
              <strong>Modalidad: </strong>
              {modalidadDocente} <br/>
              <strong>Edad:  </strong> {edad.years} años<br/>
              <strong>Fecha de Nacimiento:  </strong> {formatShowFecha(docente.fecnac)} <br/>
                <strong>Universidad:  </strong> {docente.Universidad.sigla} <br/>
               <strong>Estado: </strong>{" "}
                 <span className={`badge text-bg-${color} badge-shadow`}>{estadoName}</span>
                 </Card.Text>
            <Card.Text>
              <strong>Consideraciones:</strong>
              <br/>
              {docente.relato ? docente.relato : "No hay consideraciones disponibles."}
            </Card.Text>
          </Col>
          <Col xs={12} md={4} className='mt-3'>
              <strong>Subdisciplinas:</strong>
              <br/>
              {subdisciplinasInscritas.length > 0 ? (
                <ul>
                  {subdisciplinasInscritas.map((nombre, index) => (
                    <li key={index}>{nombre}</li>
                  ))}
                </ul>
              ) : (
                "No está inscrito en ninguna subdisciplina."
              )}
               {docente?.estado === 3 && <QRCode
                value={`${process.env.REACT_APP_OFFICIAL_DOMAIN}/perfil-docente/${docente.ci}`}
                size={150}  // Aumenta el tamaño del QR
                level="H"
                includeMargin={true}
            />}
          </Col>
         
        </Row>
      </Card.Body>
    </Card>
   {docente.estado === 3 && 
   <ModalPrintCredencial handleClose={handleClosePreviewCredential} 
            show={credentialPreview.show} 
            list={credentialPreview.deportista} 
            title1={`Credencial ${currentDocente?.Universidad?.sigla}`}/>

   } </>
  );
};

export default PerfilDocente;
