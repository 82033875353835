// Import statements
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUniversidades, removeUniversidad } from '../../actions/universidadAction';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import { Button, Table } from 'react-bootstrap';
import UniversidadForm from './UniversidadForm';
import { toast } from 'react-toastify';
import DeleteModal from '../generales/DeleteModal';
import RepresentanteForm from './RepresentanteForm';
import CustomPrintModal from '../CustomPrintModal';
import InscripcionUniversidad from './InscripcionUniversidad';
import MensajeCentral from '../mensajes/MensajeCentral';

const UniversidadList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { titulo, funcionalidad } = useParams();
  const universidades = useSelector((state) => state.universidad.universidades);
  const [currentUniversidad, setCurrentUniversidad] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalRepresentante, setShowModalRepresentante] = useState(false);
  const [sortBy, setSortBy] = useState('nombre');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [] });
  const [showInscripcionModal, setShowInscripcionModal] = useState(false);
  const evento = useSelector((state) => state.evento);
  const estadoEvento= evento.estado

  useEffect(() => {
    dispatch(fetchUniversidades());
  }, [dispatch]);

  const handleDisciplinas = (universidad) => {
    if (evento.estado === 1) {
      navigate("/incripcion-disciplina", { state: { universidad } });
    } else {
      toast.warning("La fase de inscripciones terminó...");
    }
  };

  const handleDocentes = (universidad) => {
    if(funcionalidadNum === 3){ navigate(`/docentes/${encodeURIComponent("DOCENTES")}/${funcionalidad}`, { state: { universidad } });}
      else{navigate(`/docentes/${encodeURIComponent(titulo)}/${funcionalidad}`, { state: { universidad } });}
  };
  const handleCuerpoTecnico = (universidad) => {
    console.log("Univr tecnicos", universidad)
      navigate(`/personal-extra`, { state: { universidadLocation:universidad } });
  };

  const handleImpresiones = (universidad) => {
    navigate(`/impresiones/${universidad.id}`, { state: { universidad } });
  };

  const handleShowInscripcion = (universidad) => {
    if (evento.estado === 0) {
      setCurrentUniversidad(universidad);
      setShowInscripcionModal(true);
    } else {
      toast.warning("La habilitación de Disciplinas ya está cerrada.. consulte con el administrador..");
    }
  };

  const filteredList = universidades.filter((universidad) =>
    universidad.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedList = [...filteredList].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }
    return a[sortBy] < b[sortBy] ? -1 : 1;
  });

  const handleDelete = async () => {
    try {
      const response = await dispatch(removeUniversidad(currentUniversidad.id));
      dispatch(fetchUniversidades());
      toast.success('Universidad eliminada correctamente');
    } catch (error) {
      toast.error(`${error.message}`);
    }

    setShowDeleteModal(false);
  };

  const handleDeleteModal = (universidad) => {
    setShowDeleteModal(true);
    setCurrentUniversidad(universidad);
  };

  const handleEdit = (universidad) => {
    setCurrentUniversidad(universidad);
    setShowModal(true);
  };

  // const handleRepresentante = (universidad) => {
  //   setCurrentUniversidad(universidad);
  //   setShowModalRepresentante(true);
  // };

  const handleCreate = () => {
    setCurrentUniversidad(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setCurrentUniversidad(null);
  };

  const handleBackToRoot = () => {
    navigate('/');
  };

  const handleShowPreview = () => {
    setPrintContent(getPreviewContent());
    setShowPreviewModal(true);
  };

  const handleClosePreview = () => {
    setShowPreviewModal(false);
  };

  const getPreviewContent = () => {
    const content = {
      head: [['ID', 'Sigla', 'Nombre', 'Ciudad', 'Estado']],
      body: sortedList.map((universidad, index) => [
        (index + 1),
        universidad.sigla,
        universidad.nombre,
        universidad.Ciudad.nombre,
        universidad.estado === 1 ? 'Activo' : 'Inactivo'
      ])
    };
    return content;
  };

  const handleCloseInscripcion = () => {
    setShowInscripcionModal(false);
    setCurrentUniversidad(null);
  };
  
  const funcionalidadNum = Number(funcionalidad);
  
  if (estadoEvento < 1 && (funcionalidadNum === 1 || funcionalidadNum === 2)) {
    return (
      <MensajeCentral accionBloqueada={funcionalidadNum === 2? "aprobacionDeportista" : "inscripcionDocente"} accionDesbloqueadora="preinscripcionUniversidades"/>
    );
  }
  return (
    <>
      <div className="contenedor container-large">
        <h1>{titulo} {titulo !== "LISTA DE UNIVERSIDADES" ?" POR UNIVERSIDADES":""}</h1>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Form.Group className="d-flex align-items-center mr-3">
            <Form.Label className="mr-2">Buscar:</Form.Label>
            <Form.Control
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Buscar por nombre"
            />
          </Form.Group>
         
          {funcionalidadNum === 3 && (
            <Button variant="info" onClick={handleCreate} className="mb-3">
              <i className="bi bi-plus-lg"></i>
              Nueva Universidad
            </Button>
          )}
        </div>
        <div className="mb-3">
          <Button variant="light" onClick={handleBackToRoot} style={{ width: 'auto' }}>
          <i className="bi bi-arrow-left"></i>  Volver
          </Button>
        </div>
        <div className="table-container1" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
          <Table striped bordered hover className="table-bordered">
            <thead className="sticky-header1">
              <tr>
                <th className="col-cod">N°</th>
                <th>Sigla</th>
                <th>Nombre</th>
                <th>Ciudad</th>
                {/* <th>Estado</th> */}
                <th className="col-actions-lg">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {sortedList.map((universidad, index) => (
                <tr key={universidad.id}>
                  <td>{index + 1}</td>
                  <td>{universidad.sigla}</td>
                  <td>{universidad.nombre}</td>
                  <td>{universidad.Ciudad.nombre}</td>
                  {/* <td>{universidad.estado === 1 ? 'Activo' : 'Inactivo'}</td> */}
                  <td>
                    {funcionalidadNum === 3 && (
                      <>
                        <Button variant="outline-warning" size="sm" onClick={() => handleEdit(universidad)} className="action-button">
                          <i className="bi bi-pencil"></i>
                        </Button>
                        <Button variant="outline-danger" size="sm" onClick={() => handleDeleteModal(universidad)}>
                          <i className="bi bi-trash"></i>
                        </Button>
                        {/* <Button variant="outline-dark" size="sm" onClick={() => handleRepresentante(universidad)}>
                          Representantes
                        </Button> */}
                        <Button variant="outline-primary" size="sm" onClick={() => handleCuerpoTecnico(universidad)}>
                          Personal extra
                        </Button>
                        <Button variant="outline-secondary" size="sm" title="Pre-inscripción" onClick={() => handleShowInscripcion(universidad)}>
                          Pre-inscri.
                        </Button>
                        {/* <Button variant="outline-success" size="sm" onClick={() => handleImpresiones(universidad)}>
                          Impres.
                        </Button> */}
                      </>
                    )}
                    
                    <>
                      <Button variant="outline-primary" size="sm" onClick={() => handleDocentes(universidad)}>
                        Docentes
                      </Button>
                     
                    </>
                    

                    {funcionalidadNum === 1 && estadoEvento===1 && (
                    <Button variant="outline-secondary" size="sm" onClick={() => handleDisciplinas(universidad)}>
                      Disciplinas
                    </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {funcionalidadNum === 3 && (
          <>
          <div className="d-flex justify-content-start mt-3">
            <Button variant="info" onClick={handleShowPreview}>
              Imprimir Lista
            </Button>
          </div>

        <Modal show={showModal} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {currentUniversidad ? 'Editar Universidad' : 'Nueva Universidad'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UniversidadForm
              currentUniversidad={currentUniversidad}
              showModal={showModal}
              handleClose={handleClose}
            />
          </Modal.Body>
        </Modal>

        {currentUniversidad && showDeleteModal && (
          <DeleteModal
            show={true}
            handleClose={() => setShowDeleteModal(false)}
            elemento={`la ${currentUniversidad.nombre}`}
          >
            <Button variant="danger" onClick={handleDelete}>
              Eliminar
            </Button>
          </DeleteModal>
        )}
{/* 
        <RepresentanteForm
          showModal={showModalRepresentante}
          handleClose={() => {
            setShowModalRepresentante(false);
            setCurrentUniversidad(null);
          }}
          currentUniversidad={currentUniversidad}
        /> */}
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1="LISTA DE UNIVERSIDADES"
          title2=""
          content={printContent}
          copia={false}
        />
       <Modal show={showInscripcionModal} onHide={handleCloseInscripcion} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            Preinscripción Subdisciplinas - {currentUniversidad? currentUniversidad.sigla:""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentUniversidad && <InscripcionUniversidad universidad={currentUniversidad} />}
        </Modal.Body>
      </Modal>
        </>
      )}
      </div>
    </>
  );
};

export default UniversidadList;
