import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDisciplinas
} from "../../../actions/disciplinaActions";
import Form from "react-bootstrap/Form";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import CardDisciplina from "./PublicCardDisciplina";

//COMPONENTE
//Lista de Disciplinas
const PublicDisciplinas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disciplinas = useSelector((state) => state.disciplina.disciplinas);
  const [sortBy, setSortBy] = useState("nombre");
  const [sortOrder, setSortOrder] = useState("asc");
 
  useEffect(() => {
    dispatch(fetchDisciplinas());
  }, [dispatch]);
  
  const filteredDisciplinas = disciplinas.filter(
    (dis) => dis.estado === 1
 )

  const sortedList = [...filteredDisciplinas].sort((a, b) => {
    if (sortOrder === "asc") {
      return a[sortBy] > b[sortBy] ? 1 : -1;
    }
    return a[sortBy] < b[sortBy] ? 1 : -1;
  });

  return (
    <>
      <h1>Disciplinas</h1>

        <Form.Group className="row mb-3">
        <div className="col-md-3 modal-input">
          <Form.Label >Ordenar por nombre:</Form.Label>
          </div>
          <div className="col-md-4 modal-input">
          <Form.Control
            as="select"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="asc">Ascendente</option>
            <option value="desc">Descendente</option>
          </Form.Control>
          </div>
        </Form.Group>
       
      <div className="card-grid">
        {sortedList.map((disciplina) => (
          <CardDisciplina
            key={disciplina.id}
            disciplina={disciplina}
          />
        ))}
      </div>

    </>
  );
};

export default PublicDisciplinas;
