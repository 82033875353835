import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDisciplinas } from "../../actions/disciplinaActions";
import { fetchFixtures } from "../../actions/fixtureActions";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import '../../styles/FixtureGeneral.css';
import { FaMapMarkerAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import portada from "../../assets/portadacortada.jpeg";
import FixtureDetailsView from "./FixtureDetailsView";
import { predioActions } from "../../reducers/predio_slice";
import CardPredio from '../predios/CardPredio'
import { getSubdisciplinaFullName } from "../../util/valueCalculator";
import MensajeCentral from "../mensajes/MensajeCentral";
import esperandoImg from "../../assets/esperandosentado.png"

const monthNames = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

const dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

function formatear(fecha) {
  if (fecha) {
    const [year, month, day] = fecha.split("T")[0].split("-");
    const date = new Date(year, month - 1, day);
    const dayName = dayNames[date.getDay()];
    const monthName = monthNames[parseInt(month) - 1];
    return { day, dayOfWeek: dayName, month: monthName, year };
  } else {
    return { day: "", dayOfWeek: "", month: "", year: "" };
  }
}

function formatearHora(hora) {
  if (hora) {
    const [hr, min] = hora.split(":");
    return `${hr}:${min}`;
  } else {
    return "";
  }
}

const AllFixtures = () => {
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState({ key: ["fecha", "hora"], direction: ["asc", "asc"] });
  const [expandedFixture, setExpandedFixture] = useState(null);

  const disciplinas = useSelector((state) => state.disciplina.disciplinas);
  const [selectedDisciplina, setSelectedDisciplina] = useState("");
  const [fixtures, setFixtures] = useState([]);
  const [modal, setModal] = useState({
    tipo: 0,
    titulo: "",
    body: <></>,
});
const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(""); // Estado para la fecha seleccionada
  const estadoEvento= useSelector(state => state.evento.estado)

  useEffect(() => {
    dispatch(fetchDisciplinas());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
        const fetchedFixtures = await dispatch(fetchFixtures());
       // console.log("Data", fetchedFixtures);
        if (fetchedFixtures) setFixtures(fetchedFixtures);
      }
    fetchData();
  }, [dispatch]);
   
  if (estadoEvento < 1) {
    return (
      <>
      <MensajeCentral image={  <img className="mt-5" src={esperandoImg} alt="Ya inician los juegos" width={200} height={200}/> }>
            Esperando para ver la programación oficial... 
      </MensajeCentral>
    
      </>
    );
  }
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  const handleShowModal = (tipo, predio) => {
    dispatch(predioActions.setCurrentPredio(predio));
    let titulo = '';
    let body = <></>;
    if (tipo === 4) { titulo = "Información del predio"; body = (<CardPredio />) }
    setModal({ tipo, titulo, body });
}

const handleClose = () => {
    dispatch(predioActions.setCurrentPredio(null));
    setModal({ tipo: 0, titulo: "", body: <></> });
};
  const sortArray = (array, key, direction) => {
    return array.sort((a, b) => {
      for (let i = 0; i < key.length; i++) {
        if (a[key[i]] < b[key[i]]) {
          return direction[i] === "asc" ? -1 : 1;
        }
        if (a[key[i]] > b[key[i]]) {
          return direction[i] === "asc" ? 1 : -1;
        }
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key.includes(key) && sortConfig.direction[sortConfig.key.indexOf(key)] === "asc") {
      direction = "desc";
    }

    let newKeys = [key];
    let newDirections = [direction];

    if (key !== "fecha" && key !== "hora") {
      newKeys.push("fecha", "hora");
      newDirections.push(
        sortConfig.key.includes("fecha") ? sortConfig.direction[sortConfig.key.indexOf("fecha")] : "asc",
        sortConfig.key.includes("hora") ? sortConfig.direction[sortConfig.key.indexOf("hora")] : "asc"
      );
    }
   
    setSortConfig({ key: newKeys, direction: newDirections });
  };
  let listFixtures = fixtures;
  if(selectedDisciplina){
    listFixtures = fixtures.filter(fix => `${fix.Subdisciplina.iddisc}` === selectedDisciplina)
  }
  if (selectedDate) {
    listFixtures = listFixtures.filter(
      (fix) => fix.fecha && fix.fecha.startsWith(selectedDate)
    );
  }
      
  listFixtures = listFixtures.filter(
    (fix) =>
     ( getSubdisciplinaFullName(fix.Subdisciplina).toLowerCase().includes(searchTerm.toLowerCase()) || 
      fix.descripcion.toLowerCase().includes(searchTerm.toLowerCase())) &&
      fix.estado === 1
 )
  const sortedList = sortConfig.key ? sortArray([...listFixtures], sortConfig.key, sortConfig.direction) : listFixtures;

  const groupedFixtures = sortedList.reduce((acc, fixture) => {
    const date = fixture.fecha.split("T")[0];
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(fixture);
    return acc;
  }, {});

  const toggleExpand = (fixtureId) => {
    setExpandedFixture(expandedFixture === fixtureId ? null : fixtureId);
  };
  return (
    <>   
         <h1>Programa del evento</h1>
 
       <Form.Group className="mb-3 row">
       <div className="col-md-4 modal-input">
            <Form.Label>Buscar</Form.Label>
            <Form.Control
              type="text"
              placeholder="Buscar por descripción o subdisciplina"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
       <div className="col-md-4 modal-input">
         <Form.Label>Seleccionar Disciplina</Form.Label>
         <Form.Control
           as="select"
           value={selectedDisciplina}
           onChange={(e) => setSelectedDisciplina(e.target.value)}
         >
           <option value="">- Todo -</option>
           {disciplinas.map((disciplina) => (
             <option key={disciplina.id} value={disciplina.id}>
               {disciplina.nombre.toUpperCase()}
             </option>
           ))}
         </Form.Control>
         </div>
         <div className="col-md-4 modal-input">
                <Form.Label>Filtrar por fecha</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className="form-control"
                  placeholder="Seleccionar fecha"
                />
              </div>
       </Form.Group>
 
       <div className="fixtures-container">
         {Object.keys(groupedFixtures).map((date) =>{
           const {day, month, dayOfWeek, year} = formatear(date);
         return(
           <div key={date} className="fixture-date-group">
             <Row className="fixture-row mb-3">
               <Col md={2} className="date-col">
               <p><strong>{dayOfWeek}</strong></p>
               <h3 style={{fontSize:'2.6rem'}}>{day}</h3>
               <p><strong>{month}</strong></p>
               </Col>
               <Col md={10}>
                 {groupedFixtures[date].map((fixture, index) => 
                 { const tamano=groupedFixtures[date].length;
                  
                   return(
                   <React.Fragment key={fixture.id}>
                   <Row className={`mb-2 ${index<tamano-1 ? "fixture-row" : ""}`} style={{fontSize:'0.9rem'}}>
                     <Col md={1} className="fixture-time">
                       {formatearHora(fixture.hora)}
                     </Col>
                     <Col md={4} className="fixture-subdisciplina">
                       {`${getSubdisciplinaFullName(fixture.Subdisciplina)}`}
                     </Col>
                     <Col md={3} className="fixture-description">
                       <strong>{fixture.descripcion}</strong>
                     </Col>
                     <Col md={3} className="fixture-predio">
                   
                     <Button variant="link" size="sm" style={{textDecoration:"none"}}onClick={() => handleShowModal(4, fixture.Predio)}>
                     <FaMapMarkerAlt className="mr-1" /> {fixture.Predio.nombre}
                       </Button>
                     </Col>
                     <Col md={1} className="expand-icon">
                       <Button variant="link" title="Ver detalles del fixture" onClick={() => toggleExpand(fixture.id)}>
                         {expandedFixture === fixture.id ? <FaEyeSlash /> : <FaEye />}
                       </Button>
                     </Col>
                   </Row>
                   {expandedFixture === fixture.id && (
                     <Row className="mb-2">
                       <Col md={{ span: 10, offset: 2 }}>
                         <FixtureDetailsView fixture={fixture} />
                       </Col>
                     </Row>
                   )}
                   </React.Fragment>
                 )})}
               </Col>
             </Row>
           </div>
         )})}
       </div>
       <Modal
                show={modal.tipo > 0}
                onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modal.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modal.body}</Modal.Body>
            </Modal>
       </>
   );
};

export default AllFixtures;
