import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRecepciones } from "../../actions/recepcionActions";
import { Button, Form, Table } from "react-bootstrap";
import { formatearFecha, formatearFechaToExport, getPersonaFullName, getPersonaFullNameAppat } from "../../util/valueCalculator";
import { fetchUniversidades } from "../../actions/universidadAction";

// COMPONENTE CiudadList
const ListaRecepciones = () => {
    const dispatch = useDispatch();
    const [recepciones, setRecepciones] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filters, setFilters] = useState({ 
        universidad: 'todo'});
    const universidades = useSelector((state) => state.universidad.universidades) || [];
 
    useEffect(() => {
      const fetchAndSetRecepciones = async () => {
        const recepcionesFetched = await dispatch(fetchRecepciones());
        if (recepcionesFetched) {
            setRecepciones(recepcionesFetched);
        }
      };
      dispatch(fetchUniversidades());
      fetchAndSetRecepciones();
    }, [dispatch]);

    const handleFilters = (filter, value) => {
          setFilters((prev) => ({ ...prev, [filter]: value }));
      };

    const filteredList = [...recepciones].filter(rec => { 
        const docente = rec.persona
        return((`${getPersonaFullNameAppat(docente)}`.toLowerCase().includes(searchTerm.toLowerCase())) ||
        docente.ci.toLowerCase().includes(searchTerm.toLowerCase()) )&&
        (filters.universidad !=="todo"? filters.universidad === docente.Universidad.sigla : true ) })
  return(
    <div className="container-large">
     <h1>Lista de asistentes verificados</h1>
     <div className="row mb-3">
        <div className="col-6 col-lg-5 mt-2">
          <Form.Label>Buscar persona</Form.Label>
          <Form.Control
            type="text"
            size="sm"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Buscar por nombre o CI"
          />
          </div>
          
        <div className="col-6 col-md-2">
          <Form.Label className="mt-2">Universidad:</Form.Label>
          <Form.Control as="select" size="sm" name="universidad" value={filters.universidad} 
                        onChange={(e) => handleFilters("universidad", e.target.value)}>
            <option value="todo">- Todas -</option>
            {universidades.map((universidad) => (
              <option key={universidad.id} value={universidad.sigla}>
                {universidad.sigla}
              </option>
            ))}
          </Form.Control>
        </div>
       
      </div>
      <div className='table-container1' style={{ maxHeight: '650px', overflowX:"auto" }}>
        <Table striped bordered hover className="table-bordered">
          <thead className="sticky-header1">
            <tr>
              <th className='col-cod'>N°</th>
              <th>CI</th>
              <th>Paterno</th>
              <th>Materno</th>
              <th>Nombre</th>
              <th>Universidad</th>
              <th>Fecha</th>
              <th>Hora</th>
            </tr>
          </thead>
          <tbody>
            {filteredList.map((recep, index) => (
              <tr key={recep.id}>
                <td>{index + 1}</td>
                <td>{recep.ci}</td>
                <td>{recep.persona.appat}</td>
                <td>{recep.persona.apmat}</td>
                <td>{recep.persona.nombres}</td>
                <td>{recep.persona.Universidad.sigla}</td>
                <td>{formatearFechaToExport(recep.fecha)}</td>
                <td>{recep.hora}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      </div>
  )
    
  };
  
  export default ListaRecepciones;
  
  