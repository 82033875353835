import { useDispatch } from "react-redux";
import { fetchUsuarios, removeUsuario } from "../../actions/usuarioActions";
import { Button, Modal } from "react-bootstrap";

const DeleteUsuario = ({ onHandleClose, currentUsuario, showModal }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(removeUsuario(currentUsuario.id)).then(() => {
      onHandleClose();
      dispatch(fetchUsuarios()); // Actualiza la lista de disciplinas
    });
  };
  return (
    <Modal show={showModal} onHide={onHandleClose} size="md" centered>
    <Modal.Header closeButton>
    <Modal.Title>Confirmar eliminación</Modal.Title>
    </Modal.Header>
    <Modal.Body>  <p>
        Estás seguro que deseas eliminar al usuario: {currentUsuario && `${currentUsuario.login} `}?
      </p>
      <div className="custom-button-group">
        <Button
          variant="secondary"
          onClick={onHandleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={handleDelete}
          className="ml-2"
        >
          Eliminar
        </Button>
      </div></Modal.Body>
</Modal>
  );
};

export default DeleteUsuario;
