import { toast } from "react-toastify";
import apiService from "../services/apiService";
import { predioActions } from "../reducers/predio_slice";

const prediosService = apiService('/predios');

export const fetchPredios = () => async dispatch => {
  try {
    const data = await prediosService.getAll()
    dispatch(predioActions.setPredios(data));
    return data;
    
  } catch (error) {
    toast.error(`Error al cargar la lista de predios`);
  }
};


export const addPredio = (predio) => async dispatch => {
    try {
        const data = await prediosService.create(predio);
        toast.success(`Predio creado exitosamente`) ;
      
    } catch (error) {
      toast.error(`Error al crear el nuevo predio`);
    }
  };
  
  export const updatePredio = (id, predio) => async dispatch => {
    try {
      const data = await prediosService.update(id, predio);
      toast.success(`Predio editado exitosamente`);
    } catch (error) {
      toast.error(`Error al editar el predio seleccionada`);
    }
  };
  
  export const removePredio= (id) => async dispatch => {
    try {
      const data = await prediosService.remove(id);
      toast.success(`Predio eliminado exitosamente`);
    } catch (error) {
      toast.error(error.response && error.response.data ? error.response.data:"Error al eliminar el predio.");
    }
  };