// src/reducers/disciplinaReducer.js

const initialState = {
    disciplinas: [],
    disciplina: null,
  };
  
  const disciplinaReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_DISCIPLINAS':
        return { ...state, disciplinas: action.payload };
      case 'GET_DISCIPLINA_BY_ID':
        return { ...state, disciplina: action.payload };
      case 'CREATE_DISCIPLINA':
        return { ...state, disciplinas: [...state.disciplinas, action.payload] };
      case 'UPDATE_DISCIPLINA':
        return {
          ...state,
          disciplinas: state.disciplinas.map(d =>
            d.id === action.payload.id ? action.payload : d
          ),
        };
      case 'DELETE_DISCIPLINA':
        return {
          ...state,
          disciplinas: state.disciplinas.filter(d => d.id !== action.payload),
        };
      default:
        return state;
    }
  };
  
  export default disciplinaReducer;
  