import "../../styles/Medalleros.css";
import { Button } from "react-bootstrap";
import Medalla from "./Medalla";

const ItemMedallero = ({ medallero, showModal }) => {
  const categoria=medallero.Subdisciplina.genero === "V"? "VARONES" : "DAMAS"
  const modalidad = medallero.Subdisciplina.categoria === "S" ? "SENIOR" : 
                  (medallero.Subdisciplina.categoria === "M" ? "MASTER" : 
                  (medallero.Subdisciplina.categoria === "L" ? "LIBRE" : ""));
  const classes=categoria === "DAMAS" ? "card-medallero card-damas" : "card-medallero card-varones"
  return (
    <div className={classes}>
      <div className="medallero-title">
        <h3>{medallero.Subdisciplina.nombre}</h3>
        <p>{`${categoria} ${modalidad}`}</p>
      </div>
      <div className="medallero-medals">
        <Medalla medalla="oro" universidad={medallero.OroUniversidad} />
        <Medalla medalla="plata" universidad={medallero.PlataUniversidad} />
        <Medalla medalla="bronce" universidad={medallero.BronceUniversidad} />
      </div>
      <div className="mt-auto d-flex justify-content-end">
        <Button
          variant="warning"
          size="sm"
          onClick={() => showModal(2, medallero)}
          className="action-button"
        >
          <i className="bi bi-pencil"></i>
        </Button>
      </div>
    </div>
  );
};

export default ItemMedallero;
