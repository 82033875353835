import React, { useEffect, useState, useMemo } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocentesByUniversidad } from "../../actions/docenteActions";
import { fetchDocsubByUniversidad } from "../../actions/docsubActions";
import { fetchSubdisciplinas } from "../../actions/subdisciplinaActions";
import { fetchUniversidadBySigla } from "../../actions/universidadAction";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { calculateAge, getModalidadByEdad, genderMap, categoryMap } from "../../util/valueCalculator";

const ListDocente2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { universidadId } = useParams();
  const universidadParam = location.state ? location.state.universidad : null;
  const dispatch = useDispatch();

  const docentes = useSelector((state) => state.docente.docentes);
  const docsubs = useSelector((state) => state.docsub.docsubsByUniv);
  const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
  const universidadSigla = useSelector(state => state.sesion.universidadSigla);
  const eventoData = useSelector((state) => state.evento); 

  const [universidad, setUniversidad] = useState(null);
  const [sort, setSort] = useState({ by: "appat", order: "asc" });
  const [searchTerm, setSearchTerm] = useState("");
  const [modalidad, setModalidad] = useState("todos");
  const [categoria, setCategoria] = useState("todos");

  useEffect(() => {
    if (universidadParam) {
      setUniversidad(universidadParam);
    } else if (universidadSigla) {
      dispatch(fetchUniversidadBySigla(universidadSigla))
        .then((data) => { setUniversidad(data) });
    }
  }, [dispatch, universidadSigla, universidadParam]);

  useEffect(() => {
    if (universidadId) {
      dispatch(fetchDocentesByUniversidad(universidadId));
      dispatch(fetchSubdisciplinas());
      dispatch(fetchDocsubByUniversidad(universidadId));
    }
  }, [dispatch, universidadId]);

  const sortedList = useMemo(() => {
    return [...docentes].sort((a, b) => {
      const key = sort.by;
      if (sort.order === "asc") {
        return a[key].localeCompare(b[key]);
      }
      return b[key].localeCompare(a[key]);
    });
  }, [docentes, sort]);

  const debouncedSearchTerm = useMemo(
    () => debounce((value) => setSearchTerm(value), 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  const filteredList = useMemo(() => {
    return sortedList.filter(
      (docente) => {
        const edad = calculateAge(docente.fecnac, eventoData.fechaedad).years;
        const modalidadDocente = getModalidadByEdad(edad);
        const categoriaDocente = genderMap[docente.genero];
        const hasApprovedDocsub = docsubs.some((docsub) => docsub.iddoc === docente.id && docsub.estado === 2);
        return (
          hasApprovedDocsub &&
          (docente.appat.toLowerCase().includes(searchTerm.toLowerCase()) ||
            docente.ci.toLowerCase().includes(searchTerm.toLowerCase()) ||
            docente.nombres.toLowerCase().includes(searchTerm.toLowerCase())) &&
          (modalidad === "todos" || modalidad === modalidadDocente) &&
          (categoria === "todos" || categoria === categoriaDocente)
        );
      }
    );
  }, [sortedList, searchTerm, modalidad, categoria, eventoData.fechaedad, docsubs]);

  const handleSort = (key) => {
    setSort((prevSort) => ({
      by: key,
      order: prevSort.order === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchChange = (e) => {
    debouncedSearchTerm(e.target.value);
  };

  const handleModalidadChange = (e) => {
    setModalidad(e.target.value);
  };

  const handleCategoriaChange = (e) => {
    setCategoria(e.target.value);
  };

  const getSubdisciplinas = (docenteId) => {
    const subdisciplinasList = docsubs
      .filter((docsub) => docsub.iddoc === docenteId && docsub.estado === 2)
      .map((docsub) => {
        const subdisciplina = subdisciplinas.find((sub) => sub.id === docsub.idsub);
        return subdisciplina ? `${subdisciplina.nombre} ${genderMap[subdisciplina.genero]} ${categoryMap[subdisciplina.categoria].nombre}` : "Desconocido";
      });
    return subdisciplinasList.join(", ");
  };

  return (
    <>
      <div className="container-large">
        <h1>Impresiones - {universidad ? universidad.nombre : universidadSigla}</h1>
        <div className="row align-items-center mb-4">
          <div className="col-md-2">
            <Button
              variant="light"
              onClick={() => navigate(-1)}
              style={{ width: "auto" }}
            >
              <i className="bi bi-arrow-left"></i>  Volver
            </Button>
          </div>
          <div className="col-md-3">
            <Form.Label className="mt-2">Buscar:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Buscar por apellido, CI o nombres"
              onChange={handleSearchChange}
              className="mr-3"
            />
          </div>
          <div className="col-md-3">
            <Form.Label className="mt-2">Modalidad:</Form.Label>
            <Form.Control
              as="select"
              value={modalidad}
              onChange={handleModalidadChange}
              className="mr-3"
            >
              <option value="todos">Todos</option>
              <option value="LIBRE">Libre</option>
              <option value="SENIOR">Senior</option>
              <option value="MASTER">Master</option>
            </Form.Control>
          </div>
          <div className="col-md-3">
            <Form.Label className="mt-2">Categoría:</Form.Label>
            <Form.Control
              as="select"
              value={categoria}
              onChange={handleCategoriaChange}
              className="mr-3"
            >
              <option value="todos">Todos</option>
              <option value="VARONES">Varon</option>
              <option value="DAMAS">Dama</option>
            </Form.Control>
          </div>
        </div>
        <div className="table-container1" style={{ maxHeight: "550px", overflowY: "scroll" }}>
          <Table striped bordered hover className="table-bordered" style={{ fontSize: "small" }}>
            <thead className="sticky-header1">
              <tr>
                <th className="col-cod">N°</th>
                <th onClick={() => handleSort("ci")} style={{ cursor: "pointer", width: "8%" }}>C.I.</th>
                <th onClick={() => handleSort("appat")} style={{ cursor: "pointer", width: "10%" }}>Paterno</th>
                <th onClick={() => handleSort("apmat")} style={{ cursor: "pointer", width: "10%" }}>Materno</th>
                <th onClick={() => handleSort("nombres")} style={{ cursor: "pointer", width: "10%" }}>Nombres</th>
                <th>Edad</th>
                <th>Subdisciplinas</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredList.map((docente, index) => {
                const edad = calculateAge(docente.fecnac, eventoData.fechaedad).years;
                return (
                  <tr key={docente.id}>
                    <td>{index + 1}</td>
                    <td>{docente.ci}</td>
                    <td>{docente.appat}</td>
                    <td>{docente.apmat}</td>
                    <td>{docente.nombres}</td>
                    <td>{edad}</td>
                    <td style={{ fontSize: "small" }}>{getSubdisciplinas(docente.id)}</td>
                    <td>
                      <Button variant="outline-primary" size="sm" className="mr-2">
                        Carnet
                      </Button>
                      <Button variant="outline-success" size="sm">
                        Certificado
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button variant="primary" className="mr-2">
            Imprimir Carnets
          </Button>
          <Button variant="success">
            Imprimir Certificados
          </Button>
        </div>
      </div>
    </>
  );
};

export default ListDocente2;
