import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import QrScanner from 'react-qr-scanner';
import { verificarOCrearRecepcion } from '../../actions/recepcionActions'; // Importar la acción para recepcion
import { toast } from 'react-toastify';
import { FaCamera, FaSyncAlt } from 'react-icons/fa'; // Importar los íconos de react-icons
import { Button } from 'react-bootstrap';

const QRReader = () => {
  const { rolActivo } = useSelector((state) => state.sesion);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [resultado, setResultado] = useState(null); // Estado para almacenar el resultado de la acción
  const [fondoColor, setFondoColor] = useState(''); // Estado para el color de fondo
  const [qrData, setQrData] = useState(null); // Estado para los datos del QR
  const [startScan, setStartScan] = useState(false); // Estado para controlar el inicio de la lectura de QR
  const [camera, setCamera] = useState('user'); // Estado para alternar entre cámaras
  const timerRef = useRef(null); // Referencia para almacenar el temporizador

  useEffect(() => {
    if (rolActivo !== 'controlEvento') {
      navigate('/acceso-denegado');
    }
  }, [rolActivo, navigate]);

  const handleScan = async (data) => {
    if (data) {
      clearTimeout(timerRef.current); // Limpiar el temporizador si se ha escaneado el QR
      console.log('Datos del QR:', data);
      try {
        const url = new URL(data.text);
        const path = url.pathname;

        // Verifica si la URL escaneada comienza con uno de los prefijos permitidos
        if (
          path.startsWith('/perfil-docente/') ||
          path.startsWith('/perfil-personal-extra/') ||
          path.startsWith('/perfil-invitado/') ||
          path.startsWith('/perfil-comite/')
        ) {
          const ci = path.split('/').pop(); // Extraer el CI del URL
          const fecha = new Date().toISOString().split('T')[0]; // Fecha actual en formato YYYY-MM-DD
          const hora = new Date().toTimeString().split(' ')[0]; // Hora actual en formato HH:MM:SS

          // Ejecutar la acción para verificar o crear una recepción
          dispatch(verificarOCrearRecepcion(ci, fecha, hora))
            .then((data) => {
              setResultado(data.mensaje);
              if (data.mensaje === 'yaregistrado') {
                setFondoColor('red'); // Fondo rojo si ya estaba registrado
                toast.warning('La persona ya ha sido recepcionada.');
              } else if (data.mensaje === 'registrocorrecto') {
                setFondoColor('green'); // Fondo verde si se registró recién
                toast.success('Recepción registrada correctamente.');
              }
              setQrData({ ci }); // Guardar el CI escaneado
            })
            .catch((error) => {
              setError('Error al registrar la recepción.');
              toast.error('Error al registrar la recepción.');
            });
        } else {
          setError('No es un QR válido.');
        }
      } catch (err) {
        console.error('Error al procesar la URL del QR:', err);
        setError('No se pudo procesar el QR correctamente.');
      } finally {
        setStartScan(false); // Detener el escaneo después de leer el QR
      }
    }
  };

  const handleError = (err) => {
    console.error('QR Error:', err);
    setError('Error al leer el QR. Por favor, intente nuevamente.');
  };

  const handleNewScan = () => {
    setResultado(null);
    setFondoColor('');
    setQrData(null);
    setError(null);
    setStartScan(true); // Comenzar el escaneo de QR

    // Establecer un temporizador para manejar el caso en que no se detecte un QR en 5 segundos
    timerRef.current = setTimeout(() => {
      setError('QR no identificado. Por favor, intente nuevamente.');
      setStartScan(false); // Detener el escaneo después de 5 segundos si no se detectó QR
    }, 20000);
  };

  const toggleCamera = () => {
    setCamera((prevCamera) => (prevCamera === 'user' ? 'environment' : 'user'));
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {startScan && (
        <QrScanner
          delay={300}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          constraints={{ video: { facingMode: camera } }} // Configura la cámara según el estado actual
        />
      )}
      {resultado && (
        <div
          style={{
            padding: '20px',
            backgroundColor: fondoColor,
            color: '#fff',
            textAlign: 'center',
            borderRadius: '8px',
          }}
        >
          <h3>CI: {qrData.ci}</h3>
          <p>{resultado === 'yaregistrado' ? 'Ya registrado' : 'Registrado correctamente'}</p>
        </div>
      )}
      {error && <div style={{ marginTop: '20px', fontSize: '18px', color: 'red' }}>{error}</div>}
      <div>
        <Button onClick={handleNewScan} style={{ margin: '20px', padding: '10px 20px', fontSize: '16px' }}>
          Nuevo Registro
        </Button>
        <Button variant="dark" onClick={toggleCamera} style={{ margin: '20px', padding: '10px 20px', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <FaSyncAlt style={{ marginRight: '8px' }} /> Cambiar Cámara ({camera === 'user' ? 'Delantera' : 'Trasera'})
        </Button>
      </div>
    </div>
  );
};

export default QRReader;
