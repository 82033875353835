import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/logo_cub.png';
import LogoutButton from "../autenticacion/LogoutButton";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { sesionActions } from "../../reducers/session_slice";
import menu from "../../data_files/menu_publico";
import adminSistemaMenu from "../../data_files/menus/admin_sistema_menu";
import adminEventoMenu from "../../data_files/menus/admin_evento_menu";
import adminDisciplinaMenu from "../../data_files/menus/admin_disciplina_menu";
import encargadoUniversidadMenu from "../../data_files/menus/enca_universidad_menu";
import React, { useEffect, useState } from 'react';
import usuarioImage from '../../assets/usuario.png';
import { fasesEvento, getUploadUrl } from "../../util/valueCalculator";
import encaControlRecepcionMenu from "../../data_files/menus/enca_control_recepcion";

const Header = () => {
  const { nombre, rolActivo, roles, foto } = useSelector(state => state.sesion);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fotoUrl, setFotoUrl] = useState(null);

  useEffect(() => {
    setFotoUrl(getUploadUrl(foto));
  }, [foto]);

  // Efecto para redirigir si el rolActivo es controlEvento
  useEffect(() => {
    if (rolActivo === 'controlEvento') {
      console.log("Redirigiendo al lector de QR por rolActivo=", rolActivo);
      navigate('/lector-qr'); // Redirige al lector de QR
    }
  }, [rolActivo, navigate]);

  function handleRolChange(rol) {
    dispatch(sesionActions.changeRol(rol));
    navigate('/');
  }

  function handleErrorFoto() {
    setFotoUrl(getUploadUrl(usuarioImage));
  }

  const getMenu = (rol) => {
    if (rol === 'admin') return adminSistemaMenu;
    if (rol === 'admin_evento') return adminEventoMenu;
    if (rol === 'admin_disciplina') return adminDisciplinaMenu;
    if (rol === 'enca_universidad') return encargadoUniversidadMenu;
    if (rol === 'controlEvento') return encaControlRecepcionMenu;
    return [];
  };

  const menus = nombre && rolActivo ? getMenu(rolActivo) : menu;

  return (
    <header>
      <Navbar expand="lg" className="navbar header" data-bs-theme="dark">
        <Container>
          <Navbar.Brand>
            <Link to="/" className="brand-link">
              <img
                alt="Logo CIAB"
                src={logo}
                width="60"
                height="50"
                className="d-inline-block align-top"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="principal-nav" />
          <Navbar.Collapse id="principal-nav">
            <Nav className="me-auto" id="nav-menu">
              {menus.map((item) => {
                return (
                  <React.Fragment key={item.title}>
                    {item.tipo === "button" && (
                      <Link className="nav-link" to={item.path} role="button">
                        {item.title}
                      </Link>
                    )}
                    {item.tipo === "dropdown" && (
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {item.title}
                        </a>
                        <ul className="dropdown-menu mb-2">
                          {item.options.map((option, index) => {
                            return (
                              <li key={option.name + "" + index}>
                                <a className="dropdown-item" href={option.path}>
                                  {option.name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </Nav>

            <Nav className="ms-auto d-flex nav-user" id="nav-user">
              {!nombre ? (
                <Link to="/auth" className="button btn-custom-primary-outline">
                  Ingresar al sistema
                </Link>
              ) : (
                <>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={fotoUrl ? fotoUrl : usuarioImage}
                        className="rounded-img"
                        onError={handleErrorFoto}
                      />
                    </a>
                    <ul
                      className="dropdown-menu mb-2"
                      style={{ width: "200px" }}
                    >
                      <li
                        style={{
                          marginLeft: "15px",
                          fontWeight: "bold",
                          marginRight: "15px",
                        }}
                      >
                        {nombre}
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/perfil-usuario">
                          Perfil
                        </Link>
                      </li>
                      <li>
                        <LogoutButton className="dropdown-item" />
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Rol: {rolActivo ? rolActivo : 'No tiene rol'}
                    </a>
                    <ul className="dropdown-menu mb-2">
                      <p className="dropdown-header">CAMBIAR DE ROL</p>
                      {roles.map(rol => {
                        if (rol === rolActivo) { return null; }
                        return (
                          <li key={rol}>
                            <button className="dropdown-item" onClick={() => handleRolChange(rol)}>
                              {rol}
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
