import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import placeholder from '../../assets/docentes/docente_default.png';
import axios from 'axios';
import { calculateAge, estadoDocente, formatShowFecha, getBackgroundColor, getModalidadByEdad, getUploadUrl } from '../../util/valueCalculator';
import MensajeCentral from '../mensajes/MensajeCentral';

import logo from '../../assets/graficos/logo-juegos.png';
import { useSelector } from 'react-redux';
const UPLOADS_BASE_URL = process.env.REACT_APP_UPLOADS_URL; // Asegúrate de definir la URL base correcta
const API_URL_BASE = process.env.REACT_APP_API_URL; 

const PerfilDocenteQR = ({currentDocente}) => {
    const { ci } = useParams();
     const [docente, setDocente] = useState(null);
    const [subdisciplinas, setSubdisciplinas] = useState([]);
    const [fotoPreview, setFotoPreview] = useState(placeholder);
    const [error, setError] = useState(null)
    const eventoData = useSelector(state => state.evento);
    useEffect(() => {
      let ciDocente;
        if (ci) {
          ciDocente=ci
        }else if(currentDocente){
         ciDocente = currentDocente.ci
        }

        if(ciDocente){
          axios.get(`${API_URL_BASE}/public-docente/${ciDocente}`)
          .then(response => {
              const data = response.data;
              setDocente(data.docente);
              setSubdisciplinas(data.subdisciplinas);

              if (data.docente.foto1) {
                  setFotoPreview(getUploadUrl(data.docente.foto1));
              }
              setError(null)
          })
          .catch(error => {
            if(error && error.response && error.response.data)
            {setError("Docente no encontrado")}
            else{setError("Error, intente de nuevo más tarde...")}
        });
        }
    }, [ci,currentDocente]);

    if(error){
      return <MensajeCentral>{error}</MensajeCentral>;
    }
    if (!docente) {
      return <MensajeCentral>Esperando datos del docente...</MensajeCentral>;
    }

    
  const edad = calculateAge(docente.fecnac, eventoData.fechaedad);
    
    const subdisciplinasInscritas = subdisciplinas.map(subdisciplina => {
        const genero = genderMap[subdisciplina.genero] || 'Género no encontrado';
        const categoria = categoryMap[subdisciplina.categoria] || 'Categoría no encontrada';
        return `${subdisciplina.nombre} - ${genero} - ${categoria}`;
    });

     const modalidadDocente = getModalidadByEdad(edad.years);
     const backgroundColor = getBackgroundColor(modalidadDocente);
 
     const columnaCentradaClasses = "d-flex justify-content-center align-items-top mb-3"
     let bgcolorlight = "#e1ffc3";
     if(modalidadDocente==="SENIOR"){bgcolorlight = "#fffac3"}
     if(modalidadDocente==="MASTER"){bgcolorlight = "#f2bebe"}
      const {color, estadoName } = estadoDocente(docente.estado)
     let widthCard="80%"
     if(currentDocente) widthCard = "100%"
    return (
        <Card border="5" className="card-perfil" style={{ backgroundColor, width: widthCard, margin: '30px auto 20px auto'}}>
        <Card.Body>
        <Row>
           
<Col xs={12} md={6} lg={4} style={{ flexDirection: "column"}} className={columnaCentradaClasses}>
    <div style={{ position: "absolute", top: 0, left: 0 }}>
        <Card.Img
            variant="top"
            src={logo}
            style={{ width: "100%", maxWidth: "135px", maxHeight: "135px", objectFit: "cover", margin: '0px auto 15px auto' }}
            onError={() => setFotoPreview(placeholder)}
        />
    </div>
    <Card.Img
        variant="top"
        src={fotoPreview}
        style={{ width: "100%", maxWidth: "400px", maxHeight: "400px", objectFit: "cover", margin: '0 auto' }}
        onError={() => setFotoPreview(placeholder)}
    />
</Col>
            
            <Col xs={12} md={6} lg={8} >
            <Row>
              <Card.Text>
              <span style={{ fontSize: '1.3rem', fontWeight: 'bold',color:bgcolorlight}}>
              DEPORTISTA</span>
                <span style={{ fontSize: '1.8rem', fontWeight: 'bold',color:"white" }}>
                <br/>{`${docente.appat} ${docente.apmat} ${docente.nombres}`}</span>
                </Card.Text>
                </Row>
                <Row>
                <Col xs={12} md={12} lg={6} className='custom-margin'>
                <div style={{backgroundColor:bgcolorlight, padding:"15px", borderRadius:"10px"}}>
                <Card.Text style={{ fontSize: '1.2rem'}}>
                C.I.: 
                <strong>{docente.ci} </strong> <br/>
                Categoría: 
                <strong>{`${docente.genero === "D" ? " DAMAS" : " VARONES"} - ${modalidadDocente}`} </strong>
                <br/>
                Edad:  <strong> {edad.years} años </strong> <br/>
                Fecha de Nacimiento: <br/><strong> {formatShowFecha(docente.fecnac)}  </strong>  <br/><br/>
                Universidad:  <strong>{docente.Universidad.sigla} <br/> </strong> 
                Estado:   <strong> <span className={`badge text-bg-${color} badge-shadow`}>{estadoName}</span>
                 </strong> 
              </Card.Text>
              <Card.Text style={{ fontSize: '1.2rem'}}>
                <strong>Consideraciones:</strong>
                <br/>
                {docente.relato ? docente.relato : "No hay consideraciones disponibles."}
              </Card.Text>
              </div>
            </Col>
            <Col xs={12} md={12} lg={6} style={{ fontSize: '1rem'}} className='custom-margin'>
                <strong style={{color:"white" }}>SUBDISCIPLINAS:</strong>
                <br/>
                <div style={{backgroundColor:bgcolorlight, padding:"5px 10px 1px 0px", borderRadius:"10px"}}>
                   {subdisciplinasInscritas.length > 0 ? (
                  <ul>
                    {subdisciplinasInscritas.map((nombre, index) => (
                      <li key={index}>{nombre}</li>
                    ))}
                  </ul>
                ) : (
                  "No está inscrito en ninguna subdisciplina."
                )}
                </div>
                
            </Col>
            </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
};

export default PerfilDocenteQR;

const genderMap = {
    'D': 'DAMAS',
    'V': 'VARONES'
};

const categoryMap = {
    'S': 'SENIOR',
    'M': 'MASTER',
    'L': 'LIBRE'
};
