import React from 'react';
import { Button } from "react-bootstrap";
import { categoryMap, estadoTecnico, getSubdisciplinaFullName } from '../../util/valueCalculator';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { cambiarEstadoTecnico } from '../../actions/tecnicoActions';
import { sortSubdisciplinas } from '../../util/functions';

const ItemTecnico = ({ tecnico, indice, showModal, inscripcionDocente, funcionalidad, actualizarLista }) => {
    const rolActivo = useSelector(state => state.sesion.rolActivo);
    const estadoEvento= useSelector(state => state.evento.estado)
    const dispatch = useDispatch();
    const tipoPersonal =tecnico.tipo2 === "tecnico" ? "Cuerpo técnico" : (tecnico.tipo2 === "personal"?"Personal de apoyo":"")
    const subdisTec = tecnico.subdisciplinasTecnico.sort((a, b) => sortSubdisciplinas(a, b, categoryMap));
    return (
        <tr>
            <td>{indice}</td>
            <td>{tecnico.ci}</td>
            <td>{tecnico.appat}</td>
            <td>{tecnico.apmat}</td>
            <td>{tecnico.nombres}</td>
            <td>{estadoTecnico(tecnico.estado2)}</td>
            <td>{tecnico.Universidad.sigla}</td>
            <td>{tipoPersonal}</td>
            <td style={{fontSize:"0.7rem"}}>
                {Array.from(
                    new Set(
                    subdisTec.map(sub => `${sub.nombre}-${sub.genero}`)
                    )
                ).map(uniqueSub => {
                    const [nombre, genero] = uniqueSub.split('-');
                    return (
                    <p key={`${nombre}-${genero}`}>
                        {getSubdisciplinaFullName({ nombre, genero })}
                    </p>
                    );
                })}
                </td>
            {/* <td>{docente.estado === 1 ? "Activo" : "Inactivo"}</td> */}
            <td>
                <Button
                    variant="outline-dark"
                    size="sm"
                     onClick={() => showModal(4, tecnico)}
                    className="action-button"
                >
                    Perfil
                </Button>
                {estadoEvento<2 && (
                    <>
                     {tecnico.estado2 ===0 && rolActivo === "enca_universidad" && (
                        <>
                    <Button
                                    variant="outline-warning"
                                    size="sm"
                                    onClick={() => showModal(2, tecnico)}
                                    className="action-button"
                                >
                                    <i className="bi bi-pencil"></i>
                                </Button>
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => showModal(3, tecnico)}
                                >
                                    <i className="bi bi-trash"></i>
                                </Button>
                                </>
                        )}     
                        {rolActivo === "admin_evento" && (
                                <Button
                                variant={tecnico.estado2===1 ? "outline-danger":"outline-warning"}
                                    size="sm"
                                    onClick={() => showModal(5, tecnico)}
                                >
                                {tecnico.estado2 === 1 ? "Deshabilitar":"Habilitar"}
                                </Button>
                        )}    
                            
                    </>
                )}          
            </td>
        </tr>
    );
}

export default ItemTecnico;