import { useDispatch, useSelector } from "react-redux";
import { useInputFile } from "../../hooks/useInputImage";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { fetchUniversidades } from "../../actions/universidadAction";
import { Button, Col, Form, Image } from "react-bootstrap";
import { getUploadUrl } from "../../util/valueCalculator";
import { addPersonaTipo, modifyPersonaTipo } from "../../actions/personasTipoActions";
import { fetchDocenteDataByCi } from "../../actions/tecnicoActions";

const initialState = {
  nombres: "",
  appat: "",
  apmat: "",
  ci: "",
  iduniv: "",
  iddoc: "",
  fecnac: "1930-01-01",
  tipo: "administrativo",
  tipo2: "invitado",
  foto1: "",
  relato2: "",
  genero: "O",
  estado2: 0,
  iduniv: "",
  }
const FormInvitado = ({ currentInvitado = null,
     handleClose, actualizarLista}) => {
  const [datos, setDatos] = useState(initialState);
  const {
    file: foto1,
    handleFileChange: handleFoto1Change,
    resetInputFile: resetFoto1,
    isValid: isFoto1Valid
  } = useInputFile();
  const [universidades, setUniversidades] = useState([]);
  const rolActivo = useSelector(state => state.sesion.rolActivo);
  const [ isSending, setIsSending ] = useState(false)
  const dispatch = useDispatch();
  const [ci, setCi] = useState('');
  const [personaExistente, setPersonaExistente] = useState(false);
  const [docenteVerified, setDocenteVerified] = useState(false);

  useEffect(() => {
    if (currentInvitado) {
      if(currentInvitado.tipo === "docente"){ setDocenteVerified(true);setPersonaExistente(true)}
         setDatos({ ...currentInvitado, 
            relato2:currentInvitado.relato2?currentInvitado.relato2:"" ,
            iddoc: currentInvitado.id});
    }
  }, [currentInvitado]);

  useEffect(() => {
    const fetchAndSetUniversidades = async () => {
      const universidadesFetched = await dispatch(fetchUniversidades());
      if (universidadesFetched) {
        setUniversidades(universidadesFetched);
      }
    };
    fetchAndSetUniversidades();
  }, [dispatch]);

  const handleVerifyDocente = async () => {
    const docente = await dispatch(fetchDocenteDataByCi(ci));
    if (!docente.message) {
      setDocenteVerified(true);
      const {id, representante, ...docenteInfo} = docente
      setDatos({ ...docenteInfo, 
          tipo2:"invitado",
          iddoc: docente.id });
      toast.success('Persona verificada exitosamente');
    } else {
      if(docente.message && docente.message.includes("Ya existe un usuario")) setCi("")
      resetForm()
      toast.error(docente.message);
    }
  };
  function handleInputChange(event) {
    const { name, value } = event.target;
    setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
  }
  
  const handleCheckboxChange = (event) => {
    resetForm()
    setPersonaExistente(event.target.checked);
  };
  function handleSubmit(e) {
    e.preventDefault();
    
    setIsSending(true)
    const fd = new FormData();

    if(personaExistente && docenteVerified){
      fd.set("estado2", datos.estado2);
      fd.set("tipo2", datos.tipo2);
      fd.set("relato2", datos.relato2);
    }
    else if(!personaExistente){
    // Agregar los datos de 'datos' al FormData
    for (const key in datos) {
      if (datos.hasOwnProperty(key) && key !== "foto1") {
        fd.set(key, datos[key]);
      }
    }
    // Agregar la foto si está presente
    if (foto1) {
      fd.append("foto1", foto1);
    } else if (datos.foto1) {
      fd.append("foto1", datos.foto1);
    }
    fd.set("nombres", datos.nombres.trim().toUpperCase());
    fd.set("appat", datos.appat.trim().toUpperCase());
    fd.set("apmat", datos.apmat.trim().toUpperCase());  
    fd.set("ci", datos.ci.trim());  
  
    // // Para debug: mostrar el contenido del FormData en la consola
    // for (const pair of fd.entries()) {
    // //  console.log(`${pair[0]}: ${pair[1]}`);
    // }
  }

    if (currentInvitado || personaExistente) {
      dispatch(modifyPersonaTipo(datos.tipo2, datos.iddoc, fd)).then(() => {
        setIsSending(false)
        closeForm();
        actualizarLista()
        })
        .catch(errores => {
          setIsSending(false)
          if (Array.isArray(errores)) {
          for(const err of errores){
            toast.error(err)
          }
        }else{
          toast.error("Error al modificar datos del invitado.")
        }
        });
    } else{
      dispatch(addPersonaTipo(datos.tipo2,fd)).then(() => {
        setIsSending(false)
        closeForm();
        actualizarLista()
           })
           .catch(errores => {
            setIsSending(false)
            if (Array.isArray(errores)) {
            for(const err of errores){
              toast.error(err)
            } }else{
              toast.error("Error al registrar nueva persona.")
            }
          });
    }
   }
  const resetForm = () => {
    setDocenteVerified(false);
    setPersonaExistente(false)
    resetFoto1();
    setDatos(initialState)
  }
  const closeForm = () => {
    resetForm();
    handleClose();
  }
 const actualFoto1Url = currentInvitado ? getUploadUrl(currentInvitado.foto1) : null;
 const isDisabled = docenteVerified || isSending || personaExistente
  return (<Form
                onSubmit={handleSubmit}
                className="modal-oval"
              >   
              <Form.Label className="mb-4">DATOS PERSONALES</Form.Label>
              <Form.Group className="row">
                  {!currentInvitado &&(
              
              <>
                  <div className="col-md-3 modal-input">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id="default-checkbox"
                      label="Persona existente"
                      checked={personaExistente} // Mantener el estado sincronizado
                      onChange={handleCheckboxChange} // Actualizar el estado cuando cambie
                    />
                  </div>
                {personaExistente && 
                <>
                <div className="col-6 col-md-3 modal-input">
                <Form.Label>CI de la persona</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="CI de la persona"
                  value={ci}
                  onChange={(e) => setCi(e.target.value)}
                  required={personaExistente}
                />
                </div>
                <div className="col-6 col-md-3 modal-input">
                
                  <Button variant="secondary" onClick={handleVerifyDocente} className="mt-4">
                    Verificar si existe
                  </Button>
                </div>
                </>
            }  
              </>
            )} </Form.Group>
               <Form.Group className="row">
               <div className="col-4 col-md-3 modal-input">
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              as="select"
              value={datos.tipo2}
              name="tipo2"
              onChange={handleInputChange}
              required
            >
            <option value="invitado">Invitado</option>
            <option value="comite">Comité organizador</option>
     </Form.Control>
          </div>
              <div className="col-4 col-md-3 modal-input">
                    <Form.Label>C.I.</Form.Label>
                    <Form.Control
                      type="text"
                      name="ci"
                      placeholder="N° Cédula de Identidad"
                      value={datos.ci}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-8 col-md-6 modal-input">
                    <Form.Label>Universidad</Form.Label>
                    <Form.Control
                      as="select"
                      name="iduniv"
                      value={datos.iduniv}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    >
                          <option
                            key="default"
                            value=""
                            disabled
                            >
                            - Seleccionar universidad -
                          </option>
                      {universidades.map((uni) => (
                        <option key={uni.id} value={uni.id}>
                          {`${uni.sigla} - ${uni.nombre}`}</option>
                      ))}
                    </Form.Control>
                  </div>
              </Form.Group>
               <Form.Group className="row">
                  <div className="col-md-4 modal-input">
                    <Form.Label>Nombres</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombres"
                      placeholder="Nombre(s)"
                      value={datos.nombres}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-6 col-md-4 modal-input">
                    <Form.Label>Apellido paterno</Form.Label>
                    <Form.Control
                      type="text"
                      name="appat"
                      placeholder="Primer apellido"
                      value={datos.appat}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col-6 col-md-4 modal-input">
                    <Form.Label>Apellido materno</Form.Label>
                    <Form.Control
                      type="text"
                      name="apmat"
                      placeholder="Segundo apellido"
                      value={datos.apmat}
                      onChange={handleInputChange}
                      required
                      disabled={isDisabled}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="row">
                <div className="col-8 col-md-8 modal-input">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  name="relato2"
                  placeholder="Relato"
                  value={datos.relato2}
                  maxLength={255} 
                  onChange={handleInputChange}
                />
              </div>
                <div className="col-4 col-md-4 modal-input">
             <Form.Label>Estado</Form.Label>
                <Form.Control
                   as="select"
                   name="estado2"
                  value={datos.estado2}
                  onChange={handleInputChange}
                >
                    <option value="1">Activo</option>
                    <option value="0">Inactivo</option>
                </Form.Control>
                </div>
                </Form.Group>  
                <Form.Group className="row">                
                  <div className="col-md-8 modal-input">
                    <Form.Label>Foto</Form.Label>
                      <Form.Control
                        type="file"
                        isInvalid={!isFoto1Valid}
                        onChange={(e) => handleFoto1Change(e.target.files[0])}
                        disabled={isDisabled}
                      />
                      <Form.Control.Feedback type="invalid">
                            Por favor selecciona una imagen válida.
                      </Form.Control.Feedback>
                      {(foto1 && isFoto1Valid) && (
                          <img
                          className="img-fluid img-cuadrada-150 mb-2 mt-3"
                          src={URL.createObjectURL(foto1)}
                          />
                      )}
                  </div>
                  <div className="col-md-4 modal-input" style={{display:"flex", flexDirection:"column"}}>
                  {actualFoto1Url && (
                      <>
                      <Form.Label>Foto actual</Form.Label>
                      <Image 
                          className="img-fluid img-cuadrada-150 mb-2"
                          src={actualFoto1Url}
                      />
                      </>
                  )}
                  </div>
                  <Col
              md={4}
              className="d-flex flex-column align-items-center"
            >
            </Col>
                </Form.Group>  
                
                <Button
                  variant={currentInvitado ? "warning" : "info"}
                  type="submit"
                  className="btn-modal"
                >
                  {currentInvitado ? "Actualizar" : "Crear"}
                </Button>
                <Button
                  variant="secondary"
                  onClick={closeForm}
                  className="btn-modal"
                >
                  Cancelar
                </Button>
              </Form>
  );
};

export default FormInvitado;
