import { useEffect, useState } from "react";
import imgDefaultUniv from "../../assets/universidades/default.png";
import oroMedalla from "../../assets/oro.png";
import plataMedalla from "../../assets/plata.png";
import bronceMedalla from "../../assets/bronce.png";
import { getUploadUrl } from "../../util/valueCalculator";

//COMPONENTE MEDALLA
const Medalla = ({ medalla, universidad }) => {
  //console.log("En medalla " + medalla, docente,docente2, universidad, tipo);
  const [fotoPreview, setFotoPreview] = useState("")

  useEffect(() => {
      if (universidad && universidad.escudo) {
        setFotoPreview(getUploadUrl(universidad.escudo));
    }
  }, [universidad]);

  const handleUniImageError = () => {
    setFotoPreview(imgDefaultUniv);
  };
  const getMedalImage = (medalType) => {
    switch (medalType.toLowerCase()) {
      case 'oro':
        return oroMedalla;
      case 'plata':
        return plataMedalla;
      case 'bronce':
        return bronceMedalla;
      default:
        return null;
    }
  };
  return (
    <div className={`medal ${medalla}`}>
      <div className="medal-label"> 
        <img src={getMedalImage(medalla)} alt={`Medalla de ${medalla}`} className="medal-icon" />
      </div>
      {universidad ? (
        <><img
          className="foto-uni"
          src={fotoPreview}
          alt={universidad.nombre}
          onError={handleUniImageError}
        />
        <p style={{ fontSize: "0.95rem", marginTop: "10px" }}>
        {`${universidad.sigla}`}
      </p>
      </>
      ) : (
        "Sin definir"
      )}
    </div>
  );
};
export default Medalla;
