import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsuarios, removeUsuario } from '../../actions/usuarioActions';

import UsuarioRol from './UsuarioRol';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ActionsContainer from '../generales/ActionsContainer';
import CustomPrintModal from '../CustomPrintModal';
import FormUsuario from './FormUsuario';
import DeleteUsuario from './DeleteUsuario';
//import './UsuarioList.css';  // Importa tu archivo CSS

const UsuarioList = () => {
    const dispatch = useDispatch();
    const usuariosConRoles = useSelector(state => state.usuario.usuarios);

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRolModal, setShowRolModal] = useState(false);
    const [currentUsuario, setCurrentUsuario] = useState(null);
    const [sortBy, setSortBy] = useState('ci');
    const [sortOrder, setSortOrder] = useState('asc');
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });

    useEffect(() => {
        dispatch(fetchUsuarios());
    }, [dispatch]);

    const handleDeleteModal = (usuario) => {
        setCurrentUsuario(usuario);
        setShowDeleteModal(true);
    };

    const handleEdit = usuario => {
        setCurrentUsuario(usuario);
        setShowModal(true);
    };

    const handleCreate = () => {
        setCurrentUsuario(null);
        setShowModal(true);
    };

    const handleManageRoles = usuario => {
        setCurrentUsuario(usuario);
        setShowRolModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setCurrentUsuario(null);
    };
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setCurrentUsuario(null);
    };
    
    const handleCloseRolModal = () => {
        setShowRolModal(false);
        setCurrentUsuario(null);
        dispatch(fetchUsuarios());
    };

    const sortedList = [...usuariosConRoles].sort((a, b) => {
        const aItem = sortBy === "ci" || sortBy === "appat"? a.persona[sortBy] : a[sortBy]
        const bItem = sortBy === "ci" || sortBy === "appat"? b.persona[sortBy] : b[sortBy]
        if (sortOrder === 'asc') {
            return aItem >bItem ? 1 : -1;
        }
        return aItem < bItem ? -1 : 1;
    });

    const handleShowPreview = () => {
        // setPrintContent(getPreviewContent());
        // setShowPreviewModal(true);
    };

    const handleClosePreview = () => {
        setShowPreviewModal(false);
    };

    // const getPreviewContent = () => {
    //     const content = {
    //         head: [['ID', 'CI', 'Nombre', 'Login', 'Roles']],
    //         body: sortedList.map(usuario => [
    //             usuario.id,
    //             usuario.persona.ci,
    //             usuario.nombre,
    //             usuario.login,
    //             usuario.roles.map(rol => rol.nombre).join(', ')
    //         ])
    //     };
    //     return content;
    // };

    return (
        <div className="contenedor container-medium">
            <h1>Lista de Usuarios</h1>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <Form.Group className="d-flex align-items-center">
                    <Form.Label className="mr-2">Ordenar por:</Form.Label>
                    <Form.Control
                        as="select"
                        value={sortBy}
                        onChange={e => setSortBy(e.target.value)}
                        className="mr-3"
                    >
                        <option value="ci">CI</option>
                        <option value="appat">Paterno</option>
                        <option value="login">Login</option>
                    </Form.Control>
                </Form.Group>
                <Button variant="info" onClick={handleCreate} className="mb-3">
                    <i className="bi bi-plus-lg"></i>
                    Nuevo Usuario
                </Button>
            </div>
            <div className="table-container1" style={{ maxHeight: '650px', overflowY: 'scroll' }}>
                <Table striped bordered hover className="table-bordered">
                    <thead className="sticky-header1">
                        <tr>
                            <th className='col-cod'>N°</th>
                            <th>CI</th>
                            <th>Nombre completo</th>
                            <th>Universidad</th>
                            <th>Login</th>
                            <th className='col-actions-lg'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedList.map((usuario, index) => (
                            <tr key={usuario.id}>
                                <td>{index+1}</td>
                                <td>{usuario.persona? usuario.persona.ci:""}</td>
                                <td>{usuario.persona? `${usuario.persona.nombres} ${usuario.persona.appat} ${usuario.persona.apmat}`:""}</td>
                                <td>{usuario.persona? usuario.persona.Universidad.sigla:""}</td>
                                <td>{usuario.login}</td>
                                <td>
                                    <Button variant="outline-warning" size="sm" onClick={() => handleEdit(usuario)} className="action-button">
                                        <i className="bi bi-pencil"></i>
                                    </Button>
                                    <Button variant="outline-danger" size="sm" onClick={() => handleDeleteModal(usuario)} className="action-button">
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                    <Button variant="outline-dark" size="sm" onClick={() => handleManageRoles(usuario)}>
                                        Gestionar Roles
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
{/*            
            <ActionsContainer onPrint={handleShowPreview} /> */}
            <FormUsuario currentUsuario={currentUsuario} showModal={showModal} handleClose={handleClose} />
            {showRolModal && currentUsuario && (
                <UsuarioRol show={showRolModal} handleClose={handleCloseRolModal} usuario={currentUsuario} />
            )}
            <DeleteUsuario currentUsuario={currentUsuario} showModal={showDeleteModal} onHandleClose={handleCloseDeleteModal}/>
            <CustomPrintModal
                show={showPreviewModal}
                handleClose={handleClosePreview}
                title1="LISTA DE USUARIOS"
                title2=""
                content={printContent}
                copia={false}
            />
        </div>
    );
};

export default UsuarioList;
