import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNoticiasBySubdisciplina, fetchNoticiasByTipo } from "../../actions/noticiaActions";
import { fetchDisciplinas } from "../../actions/disciplinaActions";
import ItemNoticia from "./ItemNoticiaDesarrollo";
import { Form, Modal } from "react-bootstrap";
import { fetchSubdisciplinaById, fetchSubdisciplinas, fetchSubdisciplinasByDisciplinaId, fetchSubdisciplinasByUsuarioId } from "../../actions/subdisciplinaActions";
import { noticiaActions } from "../../reducers/noticia_slice";
import FormNoticia from "./FormNoticia";
import DeleteNoticia from "./DeleteNoticia";
import CrearButton from "../generales/CrearButton";
import '../../styles/Noticias.css'
const ListNoticias = ({ subdisciplina, onClose }) => {
  const dispatch = useDispatch();
  const noticias = useSelector((state) => state.noticia.noticias);
  const {rolActivo, id} = useSelector(state => state.sesion)
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, setModal] = useState({ tipo: 0, titulo: "", body: <></> });
  const [tipoNoticias, setTipoNoticias] = useState("");

  useEffect(() => {
    if (subdisciplina) {
     //console.log("Cambio la subdis", subdisciplina)
    const tipo = subdisciplina.id === 0 && rolActivo === "admin_evento"? "general":"subdisciplina"
   
   //console.log("setTipoNoticias", tipo) 
    dispatch(fetchNoticiasByTipo(tipo, subdisciplina.id))
    setTipoNoticias(tipo)
    }
  }, [subdisciplina, dispatch]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredNoticias = noticias.filter(
    (noticia) =>
      noticia.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      noticia.relato.toLowerCase().includes(searchTerm.toLowerCase())
  );
//Show modal 
const handleShowModal= (tipo, noticia) =>{
    dispatch(noticiaActions.setCurrentNoticia(noticia));
    let titulo = '';
    let body = <></>;
    if (tipo === 1) { titulo = "Nueva Noticia"; body = (<FormNoticia subdisciplina={subdisciplina} tipo={tipoNoticias} onHandleClose={handleClose} />); }
    else if (tipo === 2) { titulo = "Editar Noticia"; body = (<FormNoticia subdisciplina={subdisciplina} tipo={tipoNoticias} onHandleClose={handleClose} />); }
    else if (tipo === 3) { titulo = "Confirmar eliminación"; body = (<DeleteNoticia tipo={tipoNoticias} onHandleClose={handleClose} />); }

    setModal({ tipo, titulo, body });
  }

  //Cerrar modal  
  const handleClose = () => {
    dispatch(noticiaActions.setCurrentNoticia(null));
    setModal({ tipo: 0, titulo: "", body: <></> });
  };
  return (<>
    <div className="noticias-page container mt-2">
      <h1>Noticias</h1>
      <Form>
        <Form.Group className="row">
          <div className="col-lg-8 modal-input">
            <Form.Label>Buscar</Form.Label>
            <Form.Control
              type="text"
              placeholder="Buscar por título o relato"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="col-lg-4 modal-input mt-4">
          <CrearButton
            onHandleCreate={() => handleShowModal(1, null)}
            className="float-end mb-4"
          >
            Nueva Noticia
          </CrearButton>
          </div>
        </Form.Group>
      </Form>
      <div className="noticias-list">
        {filteredNoticias.map((noticia) => (
          <ItemNoticia
            key={noticia.id}
            noticia={noticia}
            tipo={tipoNoticias}
            showModal={handleShowModal} 
          />
        ))}
      </div>
    </div>
     <Modal
     show={modal.tipo > 0}
     onHide={handleClose}
     size="lg"
     centered
   >
     <Modal.Header closeButton>
       <Modal.Title>{modal.titulo}</Modal.Title>
     </Modal.Header>
     <Modal.Body>{modal.body}</Modal.Body>
   </Modal>
 </>
  );
};

export default ListNoticias;