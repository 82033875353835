import { useState } from "react";
import { useSelector } from "react-redux";
import ModalPrintCredencial from "../credenciales/ModalPrintCredencial";
import CustomPrintModal from "../CustomPrintModal";
import { estadoDocente, getModalidadByEdad, tipo2Persona } from "../../util/valueCalculator";
import { Button, Modal } from "react-bootstrap";
import * as XLSX from "xlsx";
import excelIcono from '../../assets/excel-icono.png';

const PanelOpcionesImprimir = ({ show, universidad, imprimir = false, filteredList, handleClose, filters = {} }) => {
    
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const rolActivo = useSelector(state => state.sesion.rolActivo);
    const [credentialPreview, setCredentialPreview] = useState({
      show: false,
      fondo: true,
      deportistas: null
    });
  
  //DEFINICION DE LOS TITULOS
  const getTitulos = (tipo) => { 
    let titulo1 = tipo === "pdf"? "REPORTE DE ASISTENTES DEL EVENTO" : "ASISTENTES"
    let titulo2= ""; 

    if(filters.searchTerm !== "") { titulo1+= " PARCIAL"; }

        if(filters.tipo !== "todo") { titulo1+= tipo !== "pdf" ? ` ${tipo2Persona(filters.tipo, true)}`:""; 
                                     titulo2 += `${tipo2Persona(filters.tipo, true)}`
                            if(filters.tipo === "docente" && filters.modalidadDeportista !=="todo") {
                                titulo2 += tipo === "pdf"? ` MODALIDAD ${filters.modalidadDeportista}` : ` ${filters.modalidadDeportista}`
                            }
      
                                    }
        if(filters.universidad !== "todo") {titulo1+= tipo !== "pdf" ? ` ${filters.universidad}`:""; 
                                            
                                            if(filters.tipo==="todo")  titulo2 += tipo === "pdf"? ` UNIVERSIDAD ${filters.universidad}` : ` ${filters.universidad}`
                                            titulo2 += ` ${filters.universidad}`    
                                        }
       
    return {titulo1, titulo2}
  }

   
  const exportToExcel = () => {
    const data =  filteredList.map((p, index) => {
      const tipo1 = p.tipo === "docente"? "DEPORTISTA" : "";
      const tipo2 = tipo2Persona(p.tipo2)
    return {
        Nro: index+1,
      Paterno: p.appat,
      Materno: p.apmat,
      Nombres: p.nombres,
      CI: p.ci,
      Universidad: p.Universidad.sigla,
      Tipo: `${tipo1}${tipo1 && tipo2? "; ":""}${tipo2}`
    }
  })
  const {titulo1, titulo2} = getTitulos("excel")
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, titulo2);
    XLSX.writeFile(workbook, `${titulo1}.xlsx`);
  };
   
  
    const getDocentesWithSubdisciplinas = (docentesFiltrados) => {
      const docentesWithSubdisciplinas = docentesFiltrados.map((docente, index) => {
      
        return [
          index + 1,
          docente.ci,
          `${docente.appat} ${docente.apmat} ${docente.nombres}`,
          estadoDocente(docente.estado).estadoName,
        ];
      });
      return docentesWithSubdisciplinas
    }
  
    const getContentForPrintAndExport = () => {
        const content = {
          head: [['N°', 'PATERNO', 'MATERNO', 'NOMBRES', 'CI','UNIVERSIDAD','TIPO']],
          body: filteredList.filter(persona => 
              (filters.universidad !=="todo"? filters.universidad === persona.Universidad.sigla : true ) &&
              (filters.tipo !=="todo"? (filters.tipo === persona.tipo || filters.tipo === persona.tipo2 ? true: false) : true)&&
              (filters.tipo ==="docente" && filters.modalidadDeportista !=="todo"? getModalidadByEdad(persona.edad) === filters.modalidadDeportista : true)
            ).map((p, index) => {
            const tipo1 = p.tipo === "docente"? "DEPORTISTA" : "";
            const tipo2 = tipo2Persona(p.tipo2)
          return [index+1,
            p.appat,
            p.apmat,
            p.nombres,
            p.ci,
            p.Universidad.sigla,
            `${tipo1}${tipo1 && tipo2? "; ":""}${tipo2}`
          ]
        })
        };
    
        return content;
      };
    
    const handleShowPreview = () => {
        setPrintContent(getContentForPrintAndExport());
        setShowPreviewModal(true);
      };
    
      const handleClosePreview = () => {
        setShowPreviewModal(false);
      };
      
  
    const handleShowPreviewCredential = (fondo) => {
      if(filters.tipo !== "todo")
      setCredentialPreview({ show: true, fondo: fondo, deportistas: filteredList })
    }
  
    const handleClosePreviewCredential = () => {
      setCredentialPreview({ show: false, fondo: true, deportista: null })
    }
  
    let title = "Panel de opciones"
  
    let title1, title2;
      const { titulo1, titulo2 } = getTitulos("pdf")
      title1 = titulo1;
      title2 = titulo2

  const titleCrendenciales = `Credenciales ${credentialPreview.fondo ? "Digital" : "Impresion"} ${filters.searchTerm !=="" ? "PARCIAL" : ""} ${filters.tipo !=="todo" ? tipo2Persona(filters.tipo, true) : ""} ${filters.modalidadDeportista !=="todo" ? filters.modalidadDeportista : ""} ${filters.universidad!=="todo"?filters.universidad:""}`
  const disabledCredentials =  (filters.tipo === "docente" && 
                                (filters.universidad === "todo" && filters.modalidadDeportista === "todo" )
                                )  || filters.tipo === "todo" 

  return (
      <>
        <Modal show={show} onHide={handleClose} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {imprimir && (
              <>
                <p><strong>{`${title1} ${title2}`}</strong></p>
                {disabledCredentials && 
                    <p>Debe seleccionar un "Tipo" y/o "Universidad" para generar los credenciales </p>
                }
                {rolActivo === "admin_evento" && <>
                  <Button
                    variant="dark"
                    onClick={() => handleShowPreviewCredential(true)}
                    className="action-button mb-2"
                    disabled={disabledCredentials}
                  ><i className="bi bi-person-vcard"></i> Generar credenciales digitales
                  </Button>
                  <Button
                    variant="dark"
                    onClick={() => handleShowPreviewCredential(false)}
                    className="action-button mb-2"
                    disabled={disabledCredentials}
                  > <i className="bi bi-printer"></i> Imprimir credenciales
                  </Button></>}
                  <br/>
                  <Button variant="dark" 
                  onClick={handleShowPreview} 
                  className='action-button  mb-2'>
                        <i className="bi bi-printer"></i>  Imprimir reporte
                    </Button>
                    <br/>
                    <br/>
                    <Button size="sm" variant="outline-success" onDoubleClick={exportToExcel}>
            <img src={excelIcono} alt="excel" className="icon-class" /> Exportar Excel
          </Button>
              </>)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={handleClosePreview}
          title1={title1}
          title2={title2}
          content={printContent}
        />
        <ModalPrintCredencial handleClose={handleClosePreviewCredential}
          show={credentialPreview.show}
          fondo={credentialPreview.fondo}
          list={credentialPreview.deportistas}
          universidad={universidad}
          tipo={filters.tipo}
          title1={titleCrendenciales} />
      </>
    )
  }
  
  export default PanelOpcionesImprimir;