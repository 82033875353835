// src/components/ciudad/CiudadForm.js

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCiudad, modifyCiudad, fetchCiudades } from '../../actions/ciudadActions';
import { fetchPaises } from '../../actions/paisActions'; // Asumiendo que tienes esta acción
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


const CiudadForm = ({ currentCiudad, showModal, handleClose }) => {
  const [nombre, setNombre] = useState('');
  const [idpais, setIdpais] = useState('');
  const [estado, setEstado] = useState('1'); // Por defecto, activo

  const dispatch = useDispatch();
  const paises = useSelector(state => state.pais.paises);

  useEffect(() => {
    if (currentCiudad) {
      setNombre(currentCiudad.nombre);
      setIdpais(currentCiudad.idpais);
      setEstado(currentCiudad.estado);
    } else {
      setNombre('');
      setIdpais('');
      setEstado('1'); // Resetear a activo
    }
  }, [currentCiudad]);

  useEffect(() => {
    dispatch(fetchPaises());
  }, [dispatch]);

  const handleSubmit = e => {
    e.preventDefault();
    const ciudadData = { nombre, idpais, estado };
    if (currentCiudad) {
      dispatch(modifyCiudad(currentCiudad.id, ciudadData)).then(() => {
        handleClose();
        dispatch(fetchCiudades());  // Actualiza la lista de ciudades
      });
    } else {
      dispatch(addCiudad(ciudadData)).then(() => {
        handleClose();
        dispatch(fetchCiudades());  // Actualiza la lista de ciudades
      });
    }
    setNombre('');
    setIdpais('');
    setEstado('1');
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{currentCiudad ? 'Editar Ciudad' : 'Nueva Ciudad'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-small">
          <Form onSubmit={handleSubmit} className="modal-oval">
            <Form.Group controlId="formNombreCiudad" className='modal-input'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre de la Ciudad"
                value={nombre}
                onChange={e => setNombre(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formIdPais" className='modal-input'>
              <Form.Label>País</Form.Label>
              <Form.Control
                as="select"
                value={idpais}
                onChange={e => setIdpais(e.target.value)}
                required
              >
                <option value="">Seleccionar País</option>
                {paises.map(pais => (
                  <option key={pais.id} value={pais.id}>
                    {pais.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formEstadoCiudad" className='modal-input'>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                value={estado}
                onChange={e => setEstado(e.target.value)}
              >
                <option value="1">Activo</option>
                <option value="2">Inactivo</option>
              </Form.Control>
            </Form.Group>
            <Button variant={currentCiudad ? 'warning' : 'info'} type="submit"  className="btn-modal">
              {currentCiudad ? 'Actualizar' : 'Crear'}
            </Button>
            <Button variant="secondary" onClick={handleClose} className="btn-modal">
              Cancelar
            </Button>   
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CiudadForm;
