import { Button, Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import placeholder from "../../assets/docentes/docente_default.png";
import { useEffect, useMemo, useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { getUploadUrl, tipoPredio } from "../../util/valueCalculator";

const PerfilPredio = () => {
  const predio = useSelector((state) => state.predio.current);

  const [foto1Preview, setFoto1Preview] = useState(placeholder);
  const [foto2Preview, setFoto2Preview] = useState(null);

  useEffect(() => {
    if (predio) {
      if (predio.foto1) {
        setFoto1Preview(getUploadUrl(predio.foto1));
      }

      if (predio.foto2) {
         setFoto2Preview(getUploadUrl(predio.foto2));
      }
    }
  }, [predio]);

  if (!predio) {
    return (<p>Esperando datos del predio...</p>);
  }

  return (
    <Card className="custom-card">
      <Card.Body>
        {(predio.foto1 || predio.foto2) && (
        <Row className="mb-3">
          {predio.foto1 && (
           
              <Col xs={6} md={6}>
                <Card.Img
                  variant="top"
                  src={foto1Preview}
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </Col>
            )}
            {predio.foto2 && (  
              <Col xs={6} md={6}>
                <Card.Img
                  variant="top"
                  src={foto2Preview}
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                />
              </Col>)
            }
         </Row>
        )}
          <Row>
          <Col xs={12}>
            <Card.Text>
              <strong>Nombre: </strong> {predio.nombre} <br/>
              </Card.Text><Card.Text> <strong>Dirección:</strong> {predio.direccion} <br/>
              </Card.Text><Card.Text><strong>Ubicación:</strong> <Button
                variant="outline-danger" 
                href={predio.ubicacion} 
                target="_blank" 
                rel="noopener noreferrer"
                className="ml-2"
                size="sm"
              >
                <FaMapMarkerAlt className="mr-1" /> Ver en Google Maps
              </Button>
              <br/>
              </Card.Text><Card.Text> <strong>Relato:</strong> {predio.relato} <br/>
              </Card.Text><Card.Text> <strong>Tipo:</strong> {tipoPredio(predio.tipo)} <br/>
              </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default PerfilPredio;