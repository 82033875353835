import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import { calculateAge, genderMap, getModalidadByEdad, getPersonaFullName, getSubdisciplinaFullName, getUploadUrl, tipo2Persona } from '../../util/valueCalculator';

import { generateQrCodeImage } from './generateQrCodeImage';
import { useDispatch, useSelector } from 'react-redux';
import credencialAmarillo from "../../assets/pruebas/credencial-amarillo.jpg"
import credencialVerde from "../../assets/pruebas/credencial-verde.jpg"
import credencialRojo from "../../assets/pruebas/credencial-rojo.jpg"
import credencialPersonal from "../../assets/pruebas/credencial-personal.jpg"
import credencialInvitado from "../../assets/pruebas/credencial-invitado.jpg"
import { fetchSubdisciplinas } from '../../actions/subdisciplinaActions';
import { fetchDisciplinas } from '../../actions/disciplinaActions';
import { fetchDocsub } from '../../actions/docsubActions';

const photoX = 38.2;
const photoWidth = 19;
const photoHeight = 20.5;

const nameX = 3.8;
const nameWidth = 78;
const nameHeight = 8;

const universityX = nameX;
const universityWidth = nameWidth;
const universityHeight = nameHeight;

const ciX = nameX;
const ciWidth = 31;
const ciHeight = 5;

const edadX = ciX + ciWidth +4;
const edadWidth = 14;
const edadHeight = ciHeight;

const categoryX = edadX + edadWidth + 3;
const categoryWidth = 24;
const categoryHeight = ciHeight;

const disciplinesX = nameX + 8;
const disciplinesWidth = nameWidth;
const disciplinesHeight = 10.5;


const generatePdf = async (deportistas, setPdfUrl, setPdfDoc,tipo, fondo) => {
    const credentialWidth = 85;
    const credentialHeight = 105;
    const doc = new jsPDF('p', 'mm', [credentialWidth, credentialHeight]);

    for (const [index, deportista] of deportistas.entries()) {
       
        if (index > 0) doc.addPage();

        if (fondo === true) {
            const fondoSrc = tipo==="personal" || tipo==="tecnico"? credencialPersonal : 
                            (tipo==="invitado" || tipo==="comite"  ? credencialInvitado : 
                            (deportista.modalidad === "LIBRE" ? credencialVerde
                             : (deportista.modalidad === "SENIOR" ? credencialAmarillo : 
                                credencialRojo)))

            await new Promise((resolve, reject) => {
                const fondoImg = new Image();
                fondoImg.src = fondoSrc;

                fondoImg.onload = async () => {
                    doc.addImage(fondoImg, 'JPG', 0, 0, credentialWidth, credentialHeight);
                    await cargarFotoDeportista(doc, deportista, index, deportistas.length - 1, setPdfUrl,tipo,fondo);
                    resolve();
                };

                fondoImg.onerror = (error) => {
                    cargarFotoDeportista(doc, deportista, index, deportistas.length - 1, setPdfUrl,tipo,fondo)
                        .then(resolve)
                        .catch(reject);
                };
            });
        } else {
            await cargarFotoDeportista(doc, deportista, index, deportistas.length - 1, setPdfUrl,tipo,fondo);
        }
    }

    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setPdfUrl(pdfUrl);
    setPdfDoc(doc); // Guardar el documento en una variable de estado
};
const cargarFotoDeportista = (doc, deportista, index, deportistasLenght, setPdfUrl,tipo,fondo) => {

    const photoY = (fondo === false ? 29.3 : 28);
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = getUploadUrl(deportista.foto1);

        img.onload = () => {
            doc.addImage(img, 'JPEG', photoX, photoY, photoWidth, photoHeight);
            cargarQRDeportista(doc, deportista,tipo,fondo,photoY, index, deportistasLenght, setPdfUrl)
                .then(resolve)
                .catch(reject);
        };

        img.onerror = (error) => {
            cargarQRDeportista(doc, deportista,tipo,fondo,photoY, index, deportistasLenght, setPdfUrl)
                .then(resolve)
                .catch(reject);
        };
    });
};

const cargarQRDeportista = (doc, deportista,tipo,fondo,photoY) => {

    return new Promise(async (resolve, reject) => {
        try {
            const tipoPersona = tipo === "personal" ||  tipo === "tecnico"? "personal-extra" : tipo
            const qrCodeDataUrl = await generateQrCodeImage(`${process.env.REACT_APP_OFFICIAL_DOMAIN}/perfil-${tipoPersona}/${deportista.ci}`, 200);
            const qrImg = new Image();
            qrImg.src = qrCodeDataUrl;

            qrImg.onload = () => {
                const qrX = photoX + photoWidth + 5;
                const qrY = photoY;
                const qrSize = photoWidth;
                doc.addImage(qrImg, 'PNG', qrX, qrY, qrSize, qrSize);
                drawTextOnly(doc, deportista,tipo,fondo);
                resolve();
            };

            qrImg.onerror = (error) => {
                drawTextOnly(doc, deportista,tipo,fondo);
                resolve();
            };

        } catch (error) {
            drawTextOnly(doc, deportista,tipo,fondo);
            resolve();
        }
    });
};

const drawTextOnly = (doc, deportista,tipo,fondo) => {

    const photoY = (fondo === false ? 29.3 : 28);
    const nameY = photoY + photoHeight + 5.5 + (tipo === "invitado" || tipo === "comite"? nameHeight+ 5 : 0);
    const universityY = nameY + nameHeight + (tipo === "invitado" || tipo === "comite"? 4 : 5);
    const ciY = universityY + universityHeight + 2.5;
    const ciWidth = tipo !== "docente" ? universityWidth : 31;

    const edadY = ciY;
    const categoryY = ciY;
    const disciplinesY = ciY + ciHeight + 3;


    const fullName = getPersonaFullName(deportista);
    const unitWidthUniv = doc.getStringUnitWidth(deportista.Universidad.sigla) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const unitWidthCi = doc.getStringUnitWidth(deportista.ci) * doc.internal.getFontSize() / doc.internal.scaleFactor;
  
    let fontSize = 10;
    doc.setFontSize(fontSize);
    doc.setFont('helvetica', 'normal');
    const lines = doc.splitTextToSize(fullName, nameWidth - 2);

    let currentY;
    if (lines.length === 1) {
        currentY = nameY + 1.5;
    } else {
        currentY = nameY;
        fontSize = 9;
    }
    doc.setFontSize(fontSize);
    lines.forEach(line => {
        const unitWidth = doc.getStringUnitWidth(line) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        doc.text(line, nameX + ((nameWidth / 2) - (unitWidth / 2)), currentY);
        currentY += 3.5;
    });
    doc.setFontSize(11);
    doc.text(deportista.Universidad.sigla, universityX + ((universityWidth / 2) - (unitWidthUniv / 2)), universityY, { maxWidth: universityWidth });

        doc.text(deportista.ci, ciX + ((ciWidth / 2) - (unitWidthCi / 2)), ciY, { maxWidth: ciWidth });
    if(tipo === "docente"){
        const unitWidthEdad = doc.getStringUnitWidth(deportista.edad?deportista.edad+"":"") * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const unitWidthModalidad = doc.getStringUnitWidth(deportista.modalidad?deportista.modalidad:"") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    
        doc.text(deportista.edad+"", edadX + ((edadWidth / 2) - (unitWidthEdad / 2)), edadY, { maxWidth: edadWidth });
        doc.text(deportista.modalidad, categoryX + ((categoryWidth / 2) - (unitWidthModalidad / 2)), categoryY, { maxWidth: categoryWidth });
  
    doc.setFontSize(8);
    let a = 0;
 
    deportista.disciplinas?.forEach(dis => {
        doc.text("- " + dis?.nombre, disciplinesX, disciplinesY + a, { maxWidth: disciplinesWidth });
        a += 3.5;
    });
    }
    else  if(tipo === "tecnico" || tipo === "personal"){
        const tipoText = tipo2Persona(tipo)
        const unitWidthTipoText = doc.getStringUnitWidth(tipoText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    
        doc.text(tipoText, ciX + ((ciWidth / 2) - (unitWidthTipoText / 2)), disciplinesY+3, { maxWidth: ciWidth });
    }
};


const ModalPrintCredencial = ({ show, tipo="docente",handleClose, universidad=null, title1, title2, list, fondo = true }) => {
    const dispatch = useDispatch()
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfDoc, setPdfDoc] = useState(null); // Guardar la instancia de jsPDF
    const inscripcionesByUniv = useSelector(state => state.docsub.docsubsByUniv);
    const inscripciones= useSelector(state => state.docsub.docsubs);
    const subdisciplinas = useSelector((state) => state.subdisciplina.subdisciplinas);
    const disciplinas = useSelector((state) => state.disciplina.disciplinas);
    const eventoData = useSelector(state => state.evento);
    const memoizedInscripciones = useMemo(() => inscripciones, [inscripciones]);
    const memoizedInscripcionesByUniv = useMemo(() => inscripcionesByUniv, [inscripcionesByUniv]);
    const [docsubs, setDocsubs] = useState([])
   
    
  useEffect(() => {
    if(tipo === "docente"){
        dispatch(fetchDocsub());
        dispatch(fetchSubdisciplinas());
        dispatch(fetchDisciplinas());
    }
  }, [dispatch,tipo]);

    useEffect(() => {
      if (show && !universidad && memoizedInscripciones?.length>0) {
        setDocsubs(memoizedInscripciones);
      } else if(show && universidad && memoizedInscripcionesByUniv?.length>0){
        setDocsubs(memoizedInscripcionesByUniv);
      }
    }, [show, universidad, memoizedInscripciones, memoizedInscripcionesByUniv]);
    
    useEffect(() => {
        if (show && list) {
            let listToPdf=[]
            if( tipo==="docente" && docsubs?.length>0)
           {  listToPdf = list.filter(d => d.estado === 3).map(item => {
                const edad = calculateAge(item.fecnac, eventoData.fechaedad);
                const modalidadDocente = getModalidadByEdad(edad.years);
                const discis = disciplinasInscritas(item)
                const deportista = {
                    ...item,
                    edad: edad.years,
                    modalidad: modalidadDocente,
                    categoria: genderMap[item.genero],
                    disciplinas: discis
                };
                return deportista;
            }).sort((a, b) => {
                const order = { 'LIBRE': 1, 'SENIOR': 2, 'MASTER': 3 };
                return order[a.modalidad] - order[b.modalidad];
            });}
            else if( tipo==="personal" || tipo==="tecnico" ){
             
                listToPdf = list.filter(p => p.estado2 === 1).map(item => {
                    const disciplinasUnicas = Array.from(
                        new Set(
                            item.subdisciplinasTecnico.map(sub => sub.Disciplina.nombre)
                        )
                    ).map(nombre => 
                        item.subdisciplinasTecnico.find(sub => sub.Disciplina.nombre === nombre).Disciplina
                    );
                    const perExtra = {
                        ...item,
                        disciplinas: disciplinasUnicas
                    };
                    return perExtra;
                }).sort((a, b) => {
                    const order = { 'tecnico': 1, 'personal': 2};
                    return order[a.tipo2] - order[b.tipo2];
                });
              
            } if( tipo==="invitado" || tipo==="comite"){

                listToPdf = list.filter(p => p.estado2 === 1)
            }
            generatePdf(listToPdf, setPdfUrl, setPdfDoc,tipo, fondo);
        }
    }, [show, list,tipo,docsubs]);



    const disciplinasInscritas = useCallback((docente) => {
      
        if (docente && docsubs && subdisciplinas) {
            const inscripciones = docsubs.filter(docsub => docsub.iddoc === docente.id);
          
            return [...new Set(inscripciones.map(inscripcion => {
                const subdisciplina = subdisciplinas.find(sub => sub.id === inscripcion.idsub);
                const disciplina = disciplinas.find(dis => dis.id === subdisciplina.iddisc);
                if (disciplina) {
                    return disciplina;
                } else {
                    return null;
                }
            }))];
        }
        return [];
    }, [docsubs, subdisciplinas]);
   
    const onClose = () => {
        setPdfUrl(null);
        setPdfDoc(null); // Limpiar la instancia de jsPDF
        handleClose();
    };

    const handleDownload = () => {
        if (pdfDoc) {
            pdfDoc.save(`${title1}.pdf`); // Guardar el PDF con el nombre de archivo deseado
        }
    };

    return (
        <Modal show={show} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl ? (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Credencial"></iframe>
                ) : (
                    <div>Generando PDF...</div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
                <Button variant="info" onClick={handleDownload} disabled={!pdfDoc}>
                <i className="bi bi-download"></i> Descargar PDF
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalPrintCredencial;
