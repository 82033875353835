import { createSlice } from "@reduxjs/toolkit";
const initialState ={
  fixtures:[],
  current: null
}
const fixtureSlice= createSlice({
  name: "fixture",
  initialState: initialState,
  reducers: { 
    setFixtures(state, action) {
    return { ...state, fixtures: action.payload };  
    },

    setCurrentFixture(state, action) {
      return { ...state, current: action.payload }; 
    }
  }
});

export const fixtureActions = fixtureSlice.actions;
export default fixtureSlice.reducer;
