import createAxiosInstance from "../util/axiosInstance";

const api = createAxiosInstance('/subdisciplinas');


export const sdupdateStatus = async (ids, status) => {
  const response = await api.put(`/status/${status}`, { ids, status });
  return response.data;
};

export const sddeletePreinscripciones = async (ids) => {
//  console.log('eN SERVICE preins con ids=',ids)
  const response = await api.delete(`/preinscripciones/${ids}`, { data: { ids } });
  return response.data;
};

export const getSubdisciplinas = async () => {
  const response = await api.get('/');
  return response.data;
};

export const getSubdisciplinasByDisciplinaId = async (iddisc) => {
  const response = await api.get(`/disciplina/${iddisc}`);
  return response.data;
};

export const getSubdisciplinaById = async (id) => {
  const response = await api.get(`/${id}`);
  return response.data;
};

export const createSubdisciplina = async (formData) => {
  const response = await api.post('/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateSubdisciplina = async (id, formData) => {
  const response = await api.put(`/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteSubdisciplina = async (id) => {
  const response = await api.delete(`/${id}`);
  return response.data;
};
