import React, { useState } from 'react';
import { Button, Form, Alert, Modal } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';
import { useSelector } from 'react-redux';
import MensajeCentral from '../mensajes/MensajeCentral';

const BackupDatabase = () => {
  const [filename, setFilename] = useState(`dbjuegos_${moment().format('YYYYMMDD_HHmmss')}`);
  const [showModal, setShowModal] = useState(false);
  const [backupStatus, setBackupStatus] = useState('');
  const navigate = useNavigate();
  const estadoEvento= useSelector(state => state.evento.estado)

  if (estadoEvento < 3) {
    return (
      <MensajeCentral accionBloqueada="backup" accionDesbloqueadora="cierre"/>
    );
  }
  const handleBackup = async () => {
    try {
      const backupApi = apiService('/admin');
      const response = await backupApi.backupDatabase({ filename });
      setBackupStatus(response.message);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      setBackupStatus('Error creating backup');
    }
    setShowModal(true);
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <div className="container mt-5" style={{ maxWidth: '30%', backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '10px' }}>
      <h2>Backup de la Base de Datos</h2>
      <Alert variant="warning" className="d-flex align-items-center">
        <FaExclamationTriangle className="mr-2" size="1.5em" />
        <span>El proceso de backup es delicado y llevará un tiempo. Por favor, tenga cuidado.</span>
      </Alert>
      <Form>
        <Form.Group controlId="formBackupFilename">
          <Form.Label>Nombre del archivo de backup</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese el nombre del archivo"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button variant="primary" onDoubleClick={handleBackup}>
            Realizar Backup
          </Button>
        </div>
      </Form>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Estado del Backup</Modal.Title>
        </Modal.Header>
        <Modal.Body>{backupStatus}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BackupDatabase;
